import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { PackVerification_pack_verification_homeComponent } from './PackVerification.pack_verification_home.component';
import { PackVerification_containertypes_dd_singleComponent } from './PackVerification.containertypes_dd_single.component';
import { PackVerification_measurementunits_dd_singleComponent } from './PackVerification.measurementunits_dd_single.component';
import { PackVerification_reasoncodes_dd_singleComponent } from './PackVerification.reasoncodes_dd_single.component';
import { PackVerification_verification_locations_dd_singleComponent } from './PackVerification.verification_locations_dd_single.component';
import { PackVerification_containertypes_dd_multiComponent } from './PackVerification.containertypes_dd_multi.component';
import { PackVerification_measurementunits_dd_multiComponent } from './PackVerification.measurementunits_dd_multi.component';
import { PackVerification_reasoncodes_dd_multiComponent } from './PackVerification.reasoncodes_dd_multi.component';
import { PackVerification_verification_locations_dd_multiComponent } from './PackVerification.verification_locations_dd_multi.component';
import { PackVerification_packing_list_previewComponent } from './PackVerification.packing_list_preview.component';
import { PackVerification_shipping_containers_failed_verification_widgetComponent } from './PackVerification.shipping_containers_failed_verification_widget.component';
import { PackVerification_shipping_containers_to_verify_widgetComponent } from './PackVerification.shipping_containers_to_verify_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class PackVerification_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public PackVerification: PackVerification_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openpack_verification_home(replaceCurrentView?: boolean) {
    this.logger.log('PackVerification', 'pack_verification_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pack verification',
        referenceName: 'PackVerification_pack_verification_home',
        component: PackVerification_pack_verification_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpack_verification_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('PackVerification', 'pack_verification_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PackVerification_pack_verification_homeComponent,
      'Pack verification',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PackVerification_pack_verification_home') {
      this.logger.log('PackVerification', 'pack_verification_home');
      const title = 'Pack verification';
      const component = PackVerification_pack_verification_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_containertypes_dd_single') {
      const title = 'containertypes_dd';
      const component = PackVerification_containertypes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_measurementunits_dd_single') {
      const title = 'measurementunits_dd';
      const component = PackVerification_measurementunits_dd_singleComponent;
      const inParams:{ typeId: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_reasoncodes_dd_single') {
      const title = 'reasoncodes_dd';
      const component = PackVerification_reasoncodes_dd_singleComponent;
      const inParams:{ parentId: number } = { parentId: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_verification_locations_dd_single') {
      const title = 'verification_locations_dd';
      const component = PackVerification_verification_locations_dd_singleComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_containertypes_dd_multi') {
      const title = 'containertypes_dd';
      const component = PackVerification_containertypes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_measurementunits_dd_multi') {
      const title = 'measurementunits_dd';
      const component = PackVerification_measurementunits_dd_multiComponent;
      const inParams:{ typeId: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_reasoncodes_dd_multi') {
      const title = 'reasoncodes_dd';
      const component = PackVerification_reasoncodes_dd_multiComponent;
      const inParams:{ parentId: number } = { parentId: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_verification_locations_dd_multi') {
      const title = 'verification_locations_dd';
      const component = PackVerification_verification_locations_dd_multiComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_packing_list_preview') {
      this.logger.log('PackVerification', 'packing_list_preview');
      const title = 'Packing list';
      const component = PackVerification_packing_list_previewComponent;
      const inParams:{ shippingContainerId?: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_shipping_containers_failed_verification_widget') {
      const title = 'Failed verification';
      const component = PackVerification_shipping_containers_failed_verification_widgetComponent;
      const inParams:{ warehouseId: number, locationId?: number } = { warehouseId: null, locationId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PackVerification_shipping_containers_to_verify_widget') {
      const title = 'To be verified';
      const component = PackVerification_shipping_containers_to_verify_widgetComponent;
      const inParams:{ warehouseId: number, locationId?: number } = { warehouseId: null, locationId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
