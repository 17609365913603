<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title " >{{headerTitle}}</mat-card-title>
        </div>

      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-activity_details" *ngIf="!content.fields.activity_details.hidden" 
                            class="field-container full {{content.fields.activity_details.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.activity_details.styles.style"
                            [ngClass]="content.fields.activity_details.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.activity_details.label + (content.fields.activity_details.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.activity_details.label}}<span *ngIf="content.fields.activity_details.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="activity_details"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.activity_details.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.activity_details.control.placeholder}}"
                                [ngStyle]="content.fields.activity_details.control.styles.style"
                                [ngClass]="content.fields.activity_details.control.styles.classes"
                                [matTooltip]="content.fields.activity_details.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.activity_details.invalid">
                          <ng-container *ngFor="let error of content.fields.activity_details.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-activity_date" *ngIf="!content.fields.activity_date.hidden" 
                            class="field-container double {{content.fields.activity_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.activity_date.styles.style"
                            [ngClass]="content.fields.activity_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.activity_date.label + (content.fields.activity_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.activity_date.label}}<span *ngIf="content.fields.activity_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="activity_date"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.activity_date.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.activity_date.control.placeholder}}"
                                [ngStyle]="content.fields.activity_date.control.styles.style"
                                [ngClass]="content.fields.activity_date.control.styles.classes"
                                [matTooltip]="content.fields.activity_date.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.activity_date.invalid">
                          <ng-container *ngFor="let error of content.fields.activity_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-activity_user" *ngIf="!content.fields.activity_user.hidden" 
                            class="field-container double {{content.fields.activity_user.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.activity_user.styles.style"
                            [ngClass]="content.fields.activity_user.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.activity_user.label + (content.fields.activity_user.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.activity_user.label}}<span *ngIf="content.fields.activity_user.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="activity_user"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.activity_user.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.activity_user.control.placeholder}}"
                                [ngStyle]="content.fields.activity_user.control.styles.style"
                                [ngClass]="content.fields.activity_user.control.styles.classes"
                                [matTooltip]="content.fields.activity_user.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.activity_user.invalid">
                          <ng-container *ngFor="let error of content.fields.activity_user.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>