import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_available_inventory_gridComponentEntity {
LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, ExpirationDate?: string, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, MaterialId?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Location?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string }}

interface IFootPrintManager_available_inventory_gridComponentInParams {
  materialId: number, warehouseId: number, recommendedAmount?: number, taskId?: number, lotId?: number, vendorLotId?: number}


class FootPrintManager_available_inventory_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_available_inventory_gridComponent;
  entity: IFootPrintManager_available_inventory_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    allocate_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    AllocatedAmount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    AvailableAmount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    InactiveAmount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Lot_LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    VendorLot_ExpirationDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'MM/DD/YYYY', null)
,
null
      ),
    VendorLot_ManufactureDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'MM/DD/YYYY', null)
,
null
      ),
    Location_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Warehouse_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Packaging_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    allocate_quantity: new GridCellModel(
      new CellStyles(['attention'], null),
      new TextModel(null, null,  'number', '0.00', null)
,
      new NumberBoxModel(this.formGroup.controls['allocate_quantity_edit'] as DatexFormControl, null, false, '', '', null)
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_available_inventory_gridComponent, entity: IFootPrintManager_available_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_available_inventory_gridComponent, entity?: IFootPrintManager_available_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'LocationId',this.entity.LocationId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      materialId:  $grid.inParams.materialId ,
      warehouseId:  $grid.inParams.warehouseId ,
      lotId:  $grid.inParams.lotId ,
      vendorLotId:  $grid.inParams.vendorLotId ,
    };
    const data = await this.datasources.Inventory.ds_get_available_inventory_by_materialId_lotId_vendorlotId.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.AllocatedAmount.displayControl as TextModel).text = $row.entity.AllocatedAmount?.toString();
    (this.cells.AvailableAmount.displayControl as TextModel).text = $row.entity.AvailableAmount?.toString();
    (this.cells.InactiveAmount.displayControl as TextModel).text = $row.entity.InactiveAmount?.toString();
    (this.cells.Lot_LookupCode.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.VendorLot_ExpirationDate.displayControl as TextModel).text = $row.entity.VendorLot?.ExpirationDate?.toString();
    (this.cells.VendorLot_ManufactureDate.displayControl as TextModel).text = $row.entity.VendorLot?.ManufactureDate?.toString();
    (this.cells.Location_Name.displayControl as TextModel).text = $row.entity.Location?.Name;
    (this.cells.Warehouse_Name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.Packaging_Name.displayControl as TextModel).text = $row.entity.Packaging?.Name;

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_row_confirmation();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_confirmation(event = null) {
    return this.on_row_confirmationInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_confirmationInternal(
    $row: FootPrintManager_available_inventory_gridComponentRowModel,
  $grid: FootPrintManager_available_inventory_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let quantity = 0
  if ($row.cells.allocate_quantity?.editControl.isChanged) {
  
      $row.cells.allocate_quantity.displayControl.text = $row.cells.allocate_quantity?.editControl.value;
  
      if ($row.cells.allocate_quantity.displayControl.text != '') {
  
          quantity = $row.cells.allocate_quantity?.editControl.value
  
      }
  
      if (quantity > 0) {
  
          const currentPackagedAmount = $grid.inParams.recommendedAmount;
          const taskId = $grid.inParams.taskId;
          const newLotId = $row.entity.LotId;
          const newLocationId = $row.entity.Location.Id;
          let updatedPackagedAmount = +$row.cells.allocate_quantity.displayControl.text;
          const vendorLotId = $row.entity.VendorLot.Id;
          let allocate: boolean = false;
  
          if (updatedPackagedAmount == 0) {
              updatedPackagedAmount = $grid.inParams.recommendedAmount;
          }
  
          try {
              if ($utils.isDefined(updatedPackagedAmount)) {
                  if (updatedPackagedAmount > $grid.inParams.recommendedAmount) {
                      await $shell.FootPrintManager.openErrorDialog('Task cannot be reallocated.', `The requested amount exceeds the required amount`);
                      allocate = false;
                      return;
                  } else {
                      if ($row.entity.AvailableAmount < updatedPackagedAmount) {
                          await $shell.FootPrintManager.openErrorDialog('Task cannot be reallocated.', `There is not enough available inventory to meet the required amount`);
                          allocate = false;
                          return;
                      } else {
                          if ($row.entity.AvailableAmount >= updatedPackagedAmount) {
                              const dialogResult = await $shell.FootPrintManager.openConfirmationDialog('Manually allocate', `Allocate quantity of ${updatedPackagedAmount}`, 'Confirm', 'Cancel')
                              if (dialogResult) {
                                  allocate = true;
                              }
                          } else {
                              const dialogResult = await $shell.FootPrintManager.openConfirmationDialog('Manually allocate', `Allocate available quantity of ${$row.entity.AvailableAmount}`, 'Confirm', 'Cancel')
                              if (dialogResult) {
                                  updatedPackagedAmount = $row.entity.AvailableAmount
                                  allocate = true;
                              }
                          }
                      }
                  }
              }
              if (allocate) {
                  const result = await $flows.SalesOrders.complete_manual_allocation_flow({
                      taskId: taskId,
                      currentPackagedAmount: currentPackagedAmount,
                      updatedPackagedAmount: updatedPackagedAmount,
                      lotId: newLotId,
                      locationId: newLocationId,
                      vendorLotId: vendorLotId
                  });
                  if ($utils.isDefined(result.reason)) {
                      await $shell.FootPrintManager.openErrorDialog('Task cannot be reallocated.', result.reason);
                      return;
                  }
                  else {
                      let title = `Task allocated to location ${$row.entity.Location.Name}`;
                      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
  
                      // Refresh the entire grid as the manual allocation parent task will go to completed status, otherwise run a row refresh
                      if (currentPackagedAmount == updatedPackagedAmount) {
                          await $grid.refresh();
                      }
                      else {
                          await $row.refresh();
                      }
                  }
              }
  
  
          }
          catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Task reallocation error', 'An error occurred during the reallocation of the task', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  
  
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-available_inventory_grid',
  templateUrl: './FootPrintManager.available_inventory_grid.component.html'
})
export class FootPrintManager_available_inventory_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_available_inventory_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootPrintManager_available_inventory_gridComponentInParams = { materialId: null, warehouseId: null, recommendedAmount: null, taskId: null, lotId: null, vendorLotId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     AllocatedAmount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount allocated', false, false, null),       AvailableAmount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount available', false, false, null),       InactiveAmount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount inactive', false, false, null),       Lot_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       VendorLot_ExpirationDate: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', false, false, null),       VendorLot_ManufactureDate: new GridHeaderModel(new HeaderStyles(null, null), 'Manufacture date', false, false, null),       Location_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       Warehouse_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       Packaging_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       allocate_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Quantity', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_available_inventory_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('recommendedAmount') set $inParams_recommendedAmount(value: any) {
    this.inParams['recommendedAmount'] = value;
  }
  get $inParams_recommendedAmount(): any {
    return this.inParams['recommendedAmount'] ;
  }
  @Input('taskId') set $inParams_taskId(value: any) {
    this.inParams['taskId'] = value;
  }
  get $inParams_taskId(): any {
    return this.inParams['taskId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('vendorLotId') set $inParams_vendorLotId(value: any) {
    this.inParams['vendorLotId'] = value;
  }
  get $inParams_vendorLotId(): any {
    return this.inParams['vendorLotId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Available inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      materialId:  $grid.inParams.materialId ,
      warehouseId:  $grid.inParams.warehouseId ,
      lotId:  $grid.inParams.lotId ,
      vendorLotId:  $grid.inParams.vendorLotId ,
    };
    try {
    const data = await this.datasources.Inventory.ds_get_available_inventory_by_materialId_lotId_vendorlotId.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_available_inventory_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
