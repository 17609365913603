import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_purchase_order_lines_gridComponent } from './FootPrintManager.purchase_order_lines_grid.component';
import { FootPrintManager_purchase_order_receiving_gridComponent } from './FootPrintManager.purchase_order_receiving_grid.component';
import { FootPrintManager_purchase_order_received_inventory_gridComponent } from './FootPrintManager.purchase_order_received_inventory_grid.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_purchase_order_tasks_gridComponent } from './FootPrintManager.purchase_order_tasks_grid.component';
import { PurchaseOrders_orderline_total_amount_widgetComponent } from './PurchaseOrders.orderline_total_amount_widget.component';
import { PurchaseOrders_orderline_total_received_widgetComponent } from './PurchaseOrders.orderline_total_received_widget.component';
import { PurchaseOrders_orderline_total_gross_received_widgetComponent } from './PurchaseOrders.orderline_total_gross_received_widget.component';
import { PurchaseOrders_orderclasses_dd_singleComponent } from './PurchaseOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_purchase_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_purchase_order_receiving_gridComponent),
    forwardRef(() => FootPrintManager_purchase_order_received_inventory_gridComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_purchase_order_tasks_gridComponent),
    forwardRef(() => PurchaseOrders_orderline_total_amount_widgetComponent),
    forwardRef(() => PurchaseOrders_orderline_total_received_widgetComponent),
    forwardRef(() => PurchaseOrders_orderline_total_gross_received_widgetComponent),
    forwardRef(() => PurchaseOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
  ],
  selector: 'FootPrintManager-purchase_order_editor',
  templateUrl: './FootPrintManager.purchase_order_editor.component.html'
})
export class FootPrintManager_purchase_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, SealId?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), true, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, 'Unreceive all', 'icon-ic_fluent_screen_cut_20_regular', null)
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, 'Revert', 'icon-ic_fluent_square_hint_arrow_back_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    ),
      on_print: new ToolModel(new SplitButtonModel<{ receiving_report: ButtonModel, unloading_tally_report: ButtonModel, purchase_order_report: ButtonModel, po_label: ButtonModel }>(
        'on_print',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('receiving_report', new ButtonStyles(null, null), false, 'Receiving report', ''),
          new ButtonModel('unloading_tally_report', new ButtonStyles(null, null), false, 'Unloading tally', ''),
          new ButtonModel('purchase_order_report', new ButtonStyles(null, null), false, 'Purchase order report', ''),
          new ButtonModel('po_label', new ButtonStyles(null, null), false, 'License plate labels', '')
        ])
    ),
      options: new ToolModel(new SplitButtonModel<{ discussions: ButtonModel, check_out: ButtonModel, email_requests: ButtonModel, pallet_transactions: ButtonModel, surveys: ButtonModel, temperature_readings: ButtonModel, copy_order: ButtonModel }>(
        'options',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_more_horizontal_20_regular',
        null,
        [
          new ButtonModel('discussions', new ButtonStyles(null, null), false, 'Discussions', 'icon-ic_fluent_chat_20_regular'),
          new ButtonModel('check_out', new ButtonStyles(null, null), false, 'Driver check out', 'icon-ic_fluent_signature_20_regular'),
          new ButtonModel('email_requests', new ButtonStyles(null, null), false, 'Email requests', 'icon-ic_fluent_mail_arrow_up_20_regular'),
          new ButtonModel('pallet_transactions', new ButtonStyles(null, null), false, 'Pallet transactions', 'icon-ic_fluent_arrow_swap_20_regular'),
          new ButtonModel('surveys', new ButtonStyles(null, null), false, 'Surveys', 'icon-ic_fluent_clipboard_bullet_list_ltr_20_regular'),
          new ButtonModel('temperature_readings', new ButtonStyles(null, null), false, 'Temperature readings', 'icon-ic_fluent_temperature_20_regular'),
          new ButtonModel('copy_order', new ButtonStyles(null, null), false, 'Copy order', 'icon-ic_fluent_copy_20_regular')
        ])
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Lookup', true),
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Order class', true),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', true),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order status', false),
    integration_information: new FieldModel(new ButtonModel('', new ButtonStyles(['link'], null), false, 'Integration information', '', null)
, new ControlContainerStyles(null, null), 'Integration information', false),
    ship_from_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Ship from', 'icon-ic_fluent_video_person_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
    ship_from_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Clear', 'icon-ic_fluent_prohibited_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
    ship_from: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false),
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor reference', false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Expected date', false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false),
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Owner reference', false),
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer number', false),
    seal_number: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Seal number', false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false),
    custom_fields: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Custom fields', 'icon-ic_fluent_edit_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      purchase_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      receiving_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Recv tasks', 
        ),
      received_inventory: new TabItemModel(
        this.rootTabGroup, 
        'Recv inv', 
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_purchase_order_lines_purchase_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_purchase_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_purchase_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_purchase_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_purchase_order_receiving_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_purchase_order_receiving_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_purchase_order_receiving_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_received_inventory_purchase_order_received_inventory_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_received_inventory_purchase_order_received_inventory_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.inParams.orderId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_purchase_order_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_purchase_order_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_purchase_order_lines', { read: FootPrintManager_purchase_order_lines_gridComponent }) $tabs_purchase_order_lines: FootPrintManager_purchase_order_lines_gridComponent;
      @ViewChild('$tabs_receiving_tasks', { read: FootPrintManager_purchase_order_receiving_gridComponent }) $tabs_receiving_tasks: FootPrintManager_purchase_order_receiving_gridComponent;
      @ViewChild('$tabs_received_inventory', { read: FootPrintManager_purchase_order_received_inventory_gridComponent }) $tabs_received_inventory: FootPrintManager_purchase_order_received_inventory_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_purchase_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_purchase_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_amount_widget: new WidgetModel(),
      orderline_total_received_widget: new WidgetModel(),
      orderline_total_gross_received_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_amount_widget', { read:  PurchaseOrders_orderline_total_amount_widgetComponent }) $widgets_orderline_total_amount_widget: PurchaseOrders_orderline_total_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  PurchaseOrders_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: PurchaseOrders_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  PurchaseOrders_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: PurchaseOrders_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    seal_number: this.fields.seal_number.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.purchase_order_lines,
      this.tabs.receiving_tasks,
      this.tabs.received_inventory,
      this.tabs.billing,
      this.tabs.contacts,
      this.tabs.tasks,
    ]; 
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Purchase Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.PurchaseOrders.ds_purchase_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.PreferredCarrierId);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrailerId);
    (this.fields.seal_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.SealId);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_amount_widget) && !this.widgets.orderline_total_amount_widget.hidden) {
        this.$widgets_orderline_total_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_purchase_order_lines') {
        if (!isNil(this.$tabs_purchase_order_lines) && !this.tabs.purchase_order_lines.hidden) {
          this.$tabs_purchase_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_receiving_tasks') {
        if (!isNil(this.$tabs_receiving_tasks) && !this.tabs.receiving_tasks.hidden) {
          this.$tabs_receiving_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_received_inventory') {
        if (!isNil(this.$tabs_received_inventory) && !this.tabs.received_inventory.hidden) {
          this.$tabs_received_inventory.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .seal_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.PurchaseOrders.is_purchase_order_deletable_flow({ orderId: $editor.entity.Id });
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const orderLookup = $editor.entity.LookupCode;
              const result = await $flows.PurchaseOrders.delete_purchase_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
                  return;
              }
              else {
                  const title = `Order ${orderLookup} deleted`
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton:true, positionClass: EToasterPosition.bottomRight });
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.PurchaseOrders.is_purchase_order_cancelable_flow({ 
      orderId: $editor.entity.Id,
      shipmentId: $editor.vars.shipmentId  });
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be canceled.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.PurchaseOrders.cancel_purchase_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', `${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
                  return;
              }
              else {
  
                  const title = `Order ${$editor.entity.LookupCode} cancelled`;
                  $shell.FootPrintManager.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
                  await $editor.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.PurchaseOrders.is_purchase_order_completable_flow({ orderId: $editor.entity.Id });
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}.`);
      return;
  } else {
      const order = (await $datasources.PurchaseOrders.ds_get_order_totals_by_orderId.get({ orderId: $editor.entity.Id })).result;
      if ($utils.isDefined(order)) {
  
          var totalOrdered = $utils.isDefined(order.TotalOrdered) ? order.TotalOrdered : 0;
          var totalReceived = $utils.isDefined(order.TotalReceived) ? order.TotalReceived : 0;
          var totalCount = `Received ${totalReceived} of ${totalOrdered}. `
  
      }
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `${totalCount} Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.PurchaseOrders.complete_purchase_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
                  return;
              }
              else {
  
  
                  let title = `Order ${$editor.entity.LookupCode} completed`;
  
                   $shell.FootPrintManager.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
         
  
                  await $editor.refresh();
                  await $editor.tabs.purchase_order_lines.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Shipment created if not found in the on_init flow
  
  // Set editor title
  $editor.title = `Inbound Order ${$editor.entity.LookupCode}`;
  
  var shipFrom = '';
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
      shipFrom = orderAddress.address;
  
  }
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipFrom)) {
      if (!shipFrom.startsWith('ord-')) {
  
          $editor.fields.ship_from.hidden = false;
          $editor.fields.ship_from.control.text = shipFrom;
          $editor.fields.ship_from_clear.hidden = false;
  
      } else {
          $editor.fields.ship_from.hidden = true;
          $editor.fields.ship_from.control.text = '';
          $editor.fields.ship_from_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_from.hidden = true;
      $editor.fields.ship_from.control.text = '';
      $editor.fields.ship_from_clear.hidden = true;
  }
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  }
  else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  
  // Discussions feature
  let discussionCheck = (await $operations.Discussions.Enable_Discussions_Feature.isAuthorized())
  
  if (discussionCheck) {
      const discussions = (await $datasources.Discussions.ds_storage_discussions.getList({
          entity_name: 'Order',
          entity_id: $editor.inParams.orderId.toString()
      })).result;
  
      if ($utils.isDefined(discussions)) {
  
          let containsUnread = false;
          let containsDiscussion = false;
  
          for (let discussion of discussions) {
              let read_on = discussion.read_on
              let discussionData = discussion.discussion
  
              if (!$utils.isDefined(read_on) && discussion.id != '0') {
                  containsUnread = true;
              }
              if ($utils.isDefined(discussionData) && discussion.id != '0') {
                  containsDiscussion = true;
              }
          }
          if (containsDiscussion) {
              if (containsUnread) {
                  $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--OfficeChat";
                  $editor.toolbar.options.control.buttons.discussions.styles.setDestructiveClass();
              }
              else {
                  $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--CannedChat";
                  $editor.toolbar.options.control.buttons.discussions.styles.setDestructiveClass();
              }
          }
          else {
  
              $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--Chat";
              $editor.toolbar.options.control.buttons.discussions.styles.setCreationClass();
  
          }
      }
  }
  else {
      $editor.toolbar.options.control.buttons.discussions.readOnly = true;
  }
  
  
  // Fetch shipments
  const shipments = (await $datasources.PurchaseOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.PurchaseOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.vars.shipmentId = shipments[0].ShipmentId;
          $editor.fields.warehouse.required = true;
  
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.cancel.control.readOnly = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
  
          $editor.toolbar.unreceive.hidden = true;
          $editor.toolbar.unreceive.control.readOnly = true;
  
          // Reset process button in case the editor is refreshed back to a created status
          $editor.toolbar.process.hidden = false;
          $editor.toolbar.process.control.readOnly = false;
          $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  
  
          // disable process if no warehouse
          $editor.toolbar.process.control.readOnly = noWarehouse;
  
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.revert.hidden = true;
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = false;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = true;
          $editor.widgets.orderline_total_received_widget.hidden = true;
  
          if ($editor.tabs.receiving_tasks.active) {
              $editor.tabs.purchase_order_lines.activate();
          }
  
          $editor.tabs.receiving_tasks.hidden = true;
          $editor.tabs.received_inventory.hidden = true;
  
  
      } else if ($editor.entity.OrderStatusId === 2) {
          // processing status
          $editor.toolbar.process.hidden = true;
  
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.cancel.control.readOnly = false;
  
          $editor.toolbar.complete.hidden = false;
          $editor.toolbar.complete.control.readOnly = false;
  
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
          $editor.tabs.receiving_tasks.hidden = false;
          $editor.tabs.received_inventory.hidden = false;
  
          const completedTask = (await $datasources.PurchaseOrders.ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1.get({
              orderId: $editor.inParams.orderId,
              shipmentId: $editor.vars.shipmentId
          })).result;
  
          // Revert and Unreceive button
          if ($utils.isDefined(completedTask)) {
              $editor.toolbar.revert.hidden = false;
              $editor.toolbar.revert.control.readOnly = true;
              $editor.toolbar.on_delete.hidden = true;
  
              $editor.toolbar.unreceive.hidden = false;
              $editor.toolbar.unreceive.control.readOnly = false;
          } else {
              $editor.toolbar.revert.hidden = false;
              $editor.toolbar.revert.control.readOnly = false;
              $editor.toolbar.on_delete.hidden = false;
  
              $editor.toolbar.unreceive.hidden = true;
              $editor.toolbar.unreceive.control.readOnly = true;
          }
  
          // Set custom status
          let order_state = await $flows.PurchaseOrders.get_purchase_order_state({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          });
  
          if ($utils.isDefined(order_state.state)) {
              switch (order_state.state) {
                  case 2: {
                      $editor.fields.order_status.control.text = 'Ready to receive'
                      break;
                  }
                  case 3: {
                      $editor.fields.order_status.control.text = 'Receiving in progress'
                      break;
                  }
                  case 4: {
                      $editor.fields.order_status.control.text = 'Ready to complete'
                      break;
                  }
  
              }
  
          }
  
      } else {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.revert.hidden = true;
          $editor.toolbar.unreceive.hidden = true;
  
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.carrier.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
          $editor.tabs.receiving_tasks.hidden = true;
          $editor.tabs.received_inventory.hidden = false;
      }
  }
  
  //Integration information
  
  let integration_info = (await $flows.FootPrintManager.get_integration_info_by_order_id({order_id: $editor.inParams.orderId}));
  
  if ($utils.isDefined(integration_info?.Source)) {
      $editor.fields.integration_information.hidden = false;
      $editor.fields.integration_information.control.label = integration_info.Source;
  }
  
  $editor.toolbar.cancel.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Cancellation.isAuthorized());
  $editor.toolbar.on_delete.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Deletion.isAuthorized());
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.tabs.receiving_tasks.hidden = true;
  $editor.tabs.received_inventory.hidden = true;
  $editor.fields.integration_information.hidden = true;
  
  // Call common flow to apply the role based permissions
  await $editor.apply_operations();
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  const shipments = (await $datasources.PurchaseOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
          $editor.vars.shipmentId = nextShipmentId;
      }
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const orders = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(orders.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.orderId, entity: payload });
  }
  
  $editor.toolbar.cancel.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Cancellation.isAuthorized());
  $editor.toolbar.on_delete.hidden = (await $operations.FootPrintManager.Disable_InboundOrders_Deletion.isAuthorized());
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const orderlines = (await $datasources.PurchaseOrders.ds_get_orderLines_by_orderId.get({ orderId: $editor.entity.Id })).result;
  if (!$utils.isDefined(orderlines)) {
      throw new Error('Order must have at least one order line to process.')
  }
  
  const result = await $flows.PurchaseOrders.is_purchase_order_processable_flow({ orderId: $editor.entity.Id });
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `Are you sure you want to process order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              $editor.toolbar.process.control.readOnly = true;
              $editor.toolbar.process.control.icon = "icon datex-default-spinner";
              $editor.toolbar.cancel.control.readOnly = true;
              $editor.toolbar.on_delete.control.readOnly = true;
  
  
              const result = await $flows.PurchaseOrders.process_purchase_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', reason);
                  $editor.toolbar.process.control.styles.resetStyle();
                  $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
                  $editor.toolbar.process.control.label = 'Process';
  
  
                  $editor.toolbar.process.control.readOnly = false;
                  $editor.toolbar.cancel.control.readOnly = false;
                  $editor.toolbar.on_delete.control.readOnly = false;
  
                  $editor.refresh();
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} processed`;
                  $shell.FootPrintManager.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
          
                  $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
                  await $editor.refresh();
                  await $editor.tabs.receiving_tasks.activate();
              }
  
          } catch (error) {
              $editor.toolbar.process.control.styles.resetStyle();
              $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
              $editor.toolbar.process.control.label = 'Process';
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order processing error', 'An error occurred during processing of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.vars.shipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  if ($editor.fields.carrier.control.isChanged) {
      payload.PreferredCarrierId = $editor.fields.carrier.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentId = nextShipmentId;
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.carrier.control.value;
      }
     if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.seal_number.control.isChanged) {
          payload.SealId = $editor.fields.seal_number.control.value;
      }
      if ($editor.fields.trailer_number.control.isChanged) {
          payload.TrailerId = $editor.fields.trailer_number.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_ship_from_clicked(event = null) {
    return this.on_ship_from_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5], // Billing, Operations, Residential, Commerical
      accountTypeId: 1, // Vendor
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.PurchaseOrders.is_purchase_order_revertable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.vars.shipmentId
  });
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Revert Order', `Are you sure you want to bring the order back to the original created status  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              // Lock down buttons
              $editor.toolbar.revert.control.readOnly = true;
  
              const result = await $flows.PurchaseOrders.revert_purchase_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.vars.shipmentId
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted.', reason);
                  $editor.toolbar.revert.control.readOnly = false;
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} reverted`
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton:true, positionClass: EToasterPosition.bottomRight });
                  $editor.refresh();
              }
          } catch (error) {
              $editor.toolbar.revert.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order revert error', 'An error occurred while reverting the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_receiving_report_clicked(event = null) {
    return this.on_receiving_report_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receiving_report_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  await $shell.PurchaseOrders.openreceiving_reportDialog({ orderId: $editor.inParams.orderId });
  
  
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.PurchaseOrders.is_purchase_order_unreceivable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be unreceived.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}.`);
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openunreceiving_all_options_formDialog({});
      var userConfirmed = dialogResult.confirm;
      var reasonCodeId = dialogResult.reasonCodeId;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      if (userConfirmed) {
          try {
  
              const result = await $flows.PurchaseOrders.unreceive_purchase_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  reasonCodeId: reasonCodeId
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully unreceived.', reason);
                  await $editor.refresh();
                  await $editor.tabs.received_inventory.activate();
                  return;
              }
  
              else {
                  const title = `Order ${$editor.entity.LookupCode} unreceived`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton:true, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
                  await $editor.tabs.purchase_order_lines.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Unreceive order error', 'An error occurred during unreceiving of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              await $editor.refresh();
              await $editor.tabs.received_inventory.activate();
          }
      }
  }
  }
  on_ship_from_cleared(event = null) {
    return this.on_ship_from_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clearedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  payload.AccountId = null;
  payload.BillingAddresId = null;
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  await $editor.refresh();
  
  }
  on_unloading_tally_clicked(event = null) {
    return this.on_unloading_tally_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unloading_tally_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.PurchaseOrders.openinbound_unloading_tally_reportDialog({ orderId: $editor.inParams.orderId });
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //Get the temperature capture operation
  
  if (await $operations.FootPrintManager.Disable_Temperatures_Entry.isAuthorized()) {
      $editor.toolbar.options.control.buttons.temperature_readings.readOnly = true;
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openorders_attachments_hubDialog({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  on_temperature_readings(event = null) {
    return this.on_temperature_readingsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_temperature_readingsInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  $shell.FootPrintManager.opentemperature_readings_gridDialog({
      orderId: $editor.inParams.orderId
  });
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Order', entityKeys: [{name: 'Id', value: $editor.inParams.orderId}]}, 'modal');
  }
  on_discussions_clicked(event = null) {
    return this.on_discussions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discussions_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.Discussions.opendiscussions_listDialog({
      entity_name: 'Order',
      entity_id: $editor.inParams.orderId.toString()
  },
  'flyout')
  
  await $editor.refresh();
  }
  on_email_requests_clicked(event = null) {
    return this.on_email_requests_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_email_requests_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      entityType: 'Order',
      entityId: $editor.entity.Id
  }, 'modal');
  }
  on_purchase_order_report(event = null) {
    return this.on_purchase_order_reportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_purchase_order_reportInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.PurchaseOrders.openpurchase_order_reportDialog({ orderId: $editor.inParams.orderId });
  
  
  
  }
  on_integration_clicked(event = null) {
    return this.on_integration_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_integration_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'purchase_order_editor.on_integration_clicked');
  //O.Arias - 05/29/2024
  
  await $shell.FootPrintManager.openintegration_info_by_order_idDialog({ order_id: $editor.inParams.orderId }, "flyout");
  }
  on_po_labels_clicked(event = null) {
    return this.on_po_labels_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_po_labels_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openprint_order_labels_formDialog({ orderId: $editor.entity.Id ,context:"po"});
  }
  on_driver_checkout_clicked(event = null) {
    return this.on_driver_checkout_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_driver_checkout_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.opendriver_check_out_hub({
      orderId: $editor.inParams.orderId
  });
  }
  on_pallet_transactions_clicked(event = null) {
    return this.on_pallet_transactions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_transactions_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/12/2024
  
  if ($utils.isDefined($editor.vars.shipmentId)) {
  
      await $shell.FootPrintManager.openpallet_transactions_shipment_gridDialog({
          project_id: $editor.entity.ProjectId,
          shipment_id: $editor.vars.shipmentId,
          order_lookup_code: $editor.entity.LookupCode
      }, "flyout", EModalSize.Large)
  
  } else {
  
      await $shell.FootPrintManager.openInfoDialog("Missing shipment!", "A shipment Id could not be found for this order!")
  }
  }
  on_copy_order_clicked(event = null) {
    return this.on_copy_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_order_clickedInternal(
    $editor: FootPrintManager_purchase_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  await $shell.FootPrintManager.openpurchase_order_copy_formDialog({
  
      order_id: $editor.inParams.orderId
  },
  'modal');
  
  
  }
  //#endregion private flows
}
