import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Inspections_task_statuses_dd_multiComponent } from './Inspections.task_statuses_dd_multi.component'
import { Inspections_task_date_types_dd_singleComponent } from './Inspections.task_date_types_dd_single.component'


interface IFootPrintManager_inspection_tasks_gridComponentEntity {
Id?: number, CancelledDateTime?: string, CompletedDateTime?: string, CreatedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDateTime?: string, StartDateTime?: string, OperationCode?: { Name?: string }, Status?: { Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, LookupCode?: string }, Order?: { Id?: number, LookupCode?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, ReasonCode?: { Name?: string }, Project?: { Id?: number, LookupCode?: string }}

interface IFootPrintManager_inspection_tasks_gridComponentInParams {
  warehouseId: number, operationCodeIds?: number[]}


class FootPrintManager_inspection_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inspection_tasks_gridComponent;
  entity: IFootPrintManager_inspection_tasks_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    task_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    operation_codes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    order: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    location: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    license_plate: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    released_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    started_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    completed_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    cancelled_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    reason_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_inspection_tasks_gridComponent, entity: IFootPrintManager_inspection_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inspection_tasks_gridComponent, entity?: IFootPrintManager_inspection_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      operationCodeIds:  $grid.inParams.operationCodeIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      statusIds:  $grid.filters.status.control.value ,
      dateRange:  { type: $grid.filters.date_type.control.value, from: $grid.filters.date_from.control.value, to: $grid.filters.date_to.control.value } ,
    };
    const data = await this.datasources.Inspections.ds_inspection_tasks_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.task_id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.operation_codes.displayControl as TextModel).text = $row.entity.OperationCode?.Name;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.order.displayControl as TextModel).text = $row.entity.Order?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.ActualSourceLocation?.Name ?? $row.entity.ActualTargetLocation?.Name ?? $row.entity.ExpectedSourceLocation?.Name ?? $row.entity.ExpectedTargetLocation?.Name;
    (this.cells.license_plate.displayControl as TextModel).text = $row.entity.ActualSourceLicensePlate?.LookupCode ?? $row.entity.ActualTargetLicensePlate?.LookupCode ?? $row.entity.ExpectedSourceLicensePlate?.LookupCode ?? $row.entity.ExpectedTargetLicensePlate?.LookupCode;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.released_on.displayControl as TextModel).text = $row.entity.ReleaseDateTime?.toString();
    (this.cells.started_on.displayControl as TextModel).text = $row.entity.StartDateTime?.toString();
    (this.cells.completed_on.displayControl as TextModel).text = $row.entity.CompletedDateTime?.toString();
    (this.cells.cancelled_on.displayControl as TextModel).text = $row.entity.CancelledDateTime?.toString();
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.reason_code.displayControl as TextModel).text = $row.entity.ReasonCode?.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'task_id' : {
        this.on_task_id_clicked();
        break;
      }
      case 'warehouse' : {
        this.on_warehouse_clicked();
        break;
      }
      case 'location' : {
        this.on_location_clicked();
        break;
      }
      case 'license_plate' : {
        this.on_license_plate_clicked();
        break;
      }
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_task_id_clicked(event = null) {
    return this.on_task_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_task_id_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_task_id_clicked');
  if ($utils.isDefined($row.entity.Id)) {
      await $shell.FootPrintManager.openinspection_task_editorDialog({ taskId: $row.entity.Id }, 'flyout');
  
      $grid.refresh();
  }
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_material_clicked');
  if ($utils.isDefined($row.entity.Material?.Id)) {
      $shell.FootPrintManager.opensingle_material_hub({ materialId: $row.entity.Material.Id });
  }
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_lot_clicked');
  if ($utils.isDefined($row.entity.Lot?.Id)) {
      $shell.FootPrintManager.opensingle_lot_hub({ lotId: $row.entity.Lot.Id });
  }
  }
  on_order_clicked(event = null) {
    return this.on_order_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_order_clicked');
  
  }
  on_warehouse_clicked(event = null) {
    return this.on_warehouse_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_warehouse_clicked');
  if ($utils.isDefined($row.entity.Warehouse?.Id)) {
      $shell.FootPrintManager.opensingle_warehouse_hub({ warehouseId: $row.entity.Warehouse.Id });
  }
  }
  on_license_plate_clicked(event = null) {
    return this.on_license_plate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_license_plate_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_license_plate_clicked');
  let licensePlateId =  $row.entity?.ActualSourceLicensePlate?.Id ?? $row.entity?.ActualTargetLicensePlate?.Id ?? $row.entity?.ExpectedSourceLicensePlate?.Id ?? $row.entity?.ExpectedTargetLicensePlate?.Id;
  
  if ($utils.isDefined(licensePlateId)) {
      $shell.FootPrintManager.opensingle_licenseplate_hub({ licenseplateId: licensePlateId});
  }
  }
  on_location_clicked(event = null) {
    return this.on_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_clickedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_location_clicked');
  let locationId =  $row.entity?.ActualSourceLocation?.Id ?? $row.entity?.ActualTargetLocation?.Id ?? $row.entity?.ExpectedSourceLocation?.Id ?? $row.entity?.ExpectedTargetLocation?.Id;
  
  if ($utils.isDefined(locationId)) {
      $shell.FootPrintManager.opensingle_location_hub({ locationId: locationId});
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inspection_tasks_gridComponentRowModel,
  $grid: FootPrintManager_inspection_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_row_data_loaded');
  let dateFormat = `${$settings.FootPrintManager.DateFormat} ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '24 HOUR' ? 'HH:mm' : 'hh:mm a'}`;
  
  if ($utils.isDefinedTrimmed($row.cells.created_on.displayControl.text)) {
      $row.cells.created_on.displayControl.text = $utils.date.format($row.cells.created_on.displayControl.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($row.cells.started_on.displayControl.text)) {
      $row.cells.started_on.displayControl.text = $utils.date.format($row.cells.started_on.displayControl.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($row.cells.released_on.displayControl.text)) {
      $row.cells.released_on.displayControl.text = $utils.date.format($row.cells.released_on.displayControl.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($row.cells.cancelled_on.displayControl.text)) {
      $row.cells.cancelled_on.displayControl.text = $utils.date.format($row.cells.cancelled_on.displayControl.text, dateFormat);
  }
  if ($utils.isDefinedTrimmed($row.cells.completed_on.displayControl.text)) {
      $row.cells.completed_on.displayControl.text = $utils.date.format($row.cells.completed_on.displayControl.text, dateFormat);
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inspections_task_statuses_dd_multiComponent),
    forwardRef(() => Inspections_task_date_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inspection_tasks_grid',
  templateUrl: './FootPrintManager.inspection_tasks_grid.component.html'
})
export class FootPrintManager_inspection_tasks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inspection_tasks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_inspection_tasks_gridComponentInParams = { warehouseId: null, operationCodeIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     task_id: new GridHeaderModel(new HeaderStyles(null, null), 'Task', false, false, null),       operation_codes: new GridHeaderModel(new HeaderStyles(null, null), 'Operation', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       order: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       released_on: new GridHeaderModel(new HeaderStyles(null, null), 'Released on', false, false, null),       started_on: new GridHeaderModel(new HeaderStyles(null, null), 'Started on', false, false, null),       completed_on: new GridHeaderModel(new HeaderStyles(null, null), 'Completed on', false, false, null),       cancelled_on: new GridHeaderModel(new HeaderStyles(null, null), 'Cancelled on', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       reason_code: new GridHeaderModel(new HeaderStyles(null, null), 'Reason', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inspection_tasks_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('operationCodeIds') set $inParams_operationCodeIds(value: any) {
    this.inParams['operationCodeIds'] = value;
  }
  get $inParams_operationCodeIds(): any {
    return this.inParams['operationCodeIds'] ;
  }

  topToolbar = {
      create_inspection_task: new ToolModel(new ButtonModel('create_inspection_task', new ButtonStyles(null, null), false, 'Create task', 'icon-ic_fluent_add_20_regular', null)
    ),
      change_status: new ToolModel(new ButtonModel('change_status', new ButtonStyles(null, null), false, 'Change status', 'icon-ic_fluent_arrow_sync_circle_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_tasks: new ToolModel(new ButtonModel('delete_tasks', new ButtonStyles(['destructive'], null), false, ' Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      survey: new ToolModel(new ButtonModel('survey', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_clipboard_bullet_list_ltr_20_regular', null)
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Status', false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false),
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'From', false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'To', false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inspection tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;

    (this.filters.status.control as SelectBoxModel).reset([1,4]);
    (this.filters.date_type.control as SelectBoxModel).reset('CreatedSysDateTime');

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      operationCodeIds:  $grid.inParams.operationCodeIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      statusIds:  $grid.filters.status.control.value ,
      dateRange:  { type: $grid.filters.date_type.control.value, from: $grid.filters.date_from.control.value, to: $grid.filters.date_to.control.value } ,
    };
    try {
    const data = await this.datasources.Inspections.ds_inspection_tasks_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inspection_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_change_status_clicked(event = null) {
    return this.on_change_status_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_status_clickedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_change_status_clicked');
  let targetStatusId = (await $shell.FootPrintManager.openinspection_select_task_status_formDialog())?.taskStatusId;
  
  if (!$utils.isDefined(targetStatusId)) { return; }
  
  let result = await $flows.Inspections.change_task_statuses_flow({ taskIds: $grid.selectedRows.map(row => row.entity.Id), targetStatusId: targetStatusId });
  
  if ($utils.isDefined(result.reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Error changing task status', 'Errors: ' + (await $flows.Utilities.grammar_format_string_array_flow({values: result.reasons})).formattedValue);
  }
  else {
      $grid.refresh();
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_grid_data_loaded');
  for (let key in $grid.headers) {
      if (!$utils.isDefined($grid.rows.find(row => $utils.isDefinedTrimmed(row.cells[key].displayControl.text)))) {
          $grid.headers[key].hidden = true;
      }
      else {
          $grid.headers[key].hidden =  false;
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_row_selected');
  
  $grid.topToolbar.change_status.control.readOnly = !$grid.hasSelectedRows();
  $grid.topToolbar.survey.control.readOnly = !$grid.hasSelectedRows();
  $grid.topToolbar.delete_tasks.control.readOnly = !$grid.hasSelectedRows();
  }
  on_survey_clicked(event = null) {
    return this.on_survey_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_survey_clickedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_survey_clicked');
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'Task', ids: $grid.selectedRows.map(row => row.entity.Id) }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  }
  on_create_inpection_task_clicked(event = null) {
    return this.on_create_inpection_task_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inpection_task_clickedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_create_inpection_task_clicked');
  let result = await $shell.FootPrintManager.openinspection_task_creation_formDialog({ warehouseId: $grid.inParams.warehouseId }, 'flyout');
  
  
  await $grid.refresh();
  }
  on_delete_task_clicked(event = null) {
    return this.on_delete_task_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_task_clickedInternal(
    $grid: FootPrintManager_inspection_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inspection_tasks_grid.on_delete_task_clicked');
  for (let row of $grid.selectedRows) {
      await $flows.Utilities.crud_delete_flow({
          entitySet: 'Tasks',
          id: row.entity.Id
      });
  }
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
