<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"
[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.lookupcode.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.lookupcode.editControl.styles.style"
            [ngClass]="row.cells.lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Locations-licenseplate_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.status.editControl.type"
        [formControl]="row.formGroup.controls['status_edit']"
        (displayTextChange)="row.cells.status.editControl.displayText=$event"
        [placeholder]="row.cells.status.editControl.placeholder"
        [styles]="row.cells.status.editControl.styles"
        [tooltip]="row.cells.status.editControl.tooltip"
    >
    </Locations-licenseplate_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes"
          [matTooltip]="row.cells.location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Locations-locations_dd_single 
        data-cy="selector"
        [type]="row.cells.location.editControl.type"
        [formControl]="row.formGroup.controls['location_edit']"
        (displayTextChange)="row.cells.location.editControl.displayText=$event"
        [placeholder]="row.cells.location.editControl.placeholder"
        [styles]="row.cells.location.editControl.styles"
        [tooltip]="row.cells.location.editControl.tooltip"
      [warehouseId]="row.$fields_location_selector_inParams_warehouseId"
      [typeId]="row.$fields_location_selector_inParams_typeId"
      [isLoose]="row.$fields_location_selector_inParams_isLoose"
    >
    </Locations-locations_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid
    [licenseplateId]="row.$rowExpand_FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid_inParams_licenseplateId"
    [orderId]="row.$rowExpand_FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid_inParams_orderId"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
