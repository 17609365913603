<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Carrier library</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <label data-cy="label" class="datex-label"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip">{{fields.description.control.label}}</label>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-contents" *ngIf="!fields.contents.hidden" 
                            class="field-container full {{fields.contents.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.contents.styles.style"
                            [ngClass]="fields.contents.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.contents.label + (fields.contents.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.contents.label}}<span *ngIf="fields.contents.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="contents"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.contents.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.contents.control.placeholder}}"
                                [ngStyle]="fields.contents.control.styles.style"
                                [ngClass]="fields.contents.control.styles.classes"
                                [matTooltip]="fields.contents.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.contents.invalid">
                          <ng-container *ngFor="let error of fields.contents.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>