import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_TestOrderReportServiceInParams {
}

interface Iapp_TestOrderReportServiceData {
}

@Injectable({ providedIn: 'root' })
export class app_TestOrderReportService extends ReportBaseService<Iapp_TestOrderReportServiceInParams, Iapp_TestOrderReportServiceData> {

  protected reportReferenceName = 'TestOrderReport';
  protected appReferenceName = 'app';

  constructor(
    utils: UtilsService,
    private datasources: app_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('app', 'TestOrderReport');
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_TestOrderReportServiceInParams) {
  }

  protected async getData(inParams: Iapp_TestOrderReportServiceInParams): Promise<Iapp_TestOrderReportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: Iapp_TestOrderReportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_TestOrderReportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
