<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_type.label + (filters.date_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_type.label}}<span *ngIf="filters.date_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <ShippingContainers-shipping_container_dates_dd_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
            [tooltip]="filters.date_type.control.tooltip"
        >
        </ShippingContainers-shipping_container_dates_dd_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OrderType">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OrderType.displayControl.styles.style"
          [ngClass]="row.cells.OrderType.displayControl.styles.classes"
          [matTooltip]="row.cells.OrderType.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OrderType.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Warehouse_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Warehouse_Name.displayControl.styles.style"
          [ngClass]="row.cells.Warehouse_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Warehouse_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Warehouse_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Owner_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Owner_Name.displayControl.styles.style"
          [ngClass]="row.cells.Owner_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Owner_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Owner_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_Name.displayControl.styles.style"
          [ngClass]="row.cells.Project_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Status.displayControl.styles.style"
          [ngClass]="row.cells.Status.displayControl.styles.classes"
          [matTooltip]="row.cells.Status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Order_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Order_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Order_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Order_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Order_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LoadContainer">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LoadContainer.displayControl.styles.style"
          [ngClass]="row.cells.LoadContainer.displayControl.styles.classes"
          [matTooltip]="row.cells.LoadContainer.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LoadContainer.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PackVerificationDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PackVerificationDate.displayControl.styles.style"
          [ngClass]="row.cells.PackVerificationDate.displayControl.styles.classes"
          [matTooltip]="row.cells.PackVerificationDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PackVerificationDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="BillOfLading">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.BillOfLading.displayControl.styles.style"
          [ngClass]="row.cells.BillOfLading.displayControl.styles.classes"
          [matTooltip]="row.cells.BillOfLading.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.BillOfLading.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="TrackingNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.TrackingNumber.displayControl.styles.style"
          [ngClass]="row.cells.TrackingNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.TrackingNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.TrackingNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SealId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SealId.displayControl.styles.style"
          [ngClass]="row.cells.SealId.displayControl.styles.classes"
          [matTooltip]="row.cells.SealId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SealId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainerNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ContainerNumber.displayControl.styles.style"
          [ngClass]="row.cells.ContainerNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.ContainerNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ContainerNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainsPortalVisibleAttachments">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['ContainsPortalVisibleAttachments_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.ContainsPortalVisibleAttachments.displayControl.styles.style"
                  [ngClass]="row.cells.ContainsPortalVisibleAttachments.displayControl.styles.classes"
                  [matTooltip]="row.cells.ContainsPortalVisibleAttachments.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.ContainsPortalVisibleAttachments.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CustomsReleaseNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CustomsReleaseNumber.displayControl.styles.style"
          [ngClass]="row.cells.CustomsReleaseNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.CustomsReleaseNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CustomsReleaseNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DeclaredValue">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.DeclaredValue.displayControl.styles.style"
          [ngClass]="row.cells.DeclaredValue.displayControl.styles.classes"
          [matTooltip]="row.cells.DeclaredValue.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.DeclaredValue.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Deleted">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['Deleted_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.Deleted.displayControl.styles.style"
                  [ngClass]="row.cells.Deleted.displayControl.styles.classes"
                  [matTooltip]="row.cells.Deleted.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.Deleted.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="EstimatedDeliveryDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.EstimatedDeliveryDateTime.displayControl.styles.style"
          [ngClass]="row.cells.EstimatedDeliveryDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.EstimatedDeliveryDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.EstimatedDeliveryDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Height.displayControl.styles.style"
          [ngClass]="row.cells.Height.displayControl.styles.classes"
          [matTooltip]="row.cells.Height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Length.displayControl.styles.style"
          [ngClass]="row.cells.Length.displayControl.styles.classes"
          [matTooltip]="row.cells.Length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Width.displayControl.styles.style"
          [ngClass]="row.cells.Width.displayControl.styles.classes"
          [matTooltip]="row.cells.Width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Volume.displayControl.styles.style"
          [ngClass]="row.cells.Volume.displayControl.styles.classes"
          [matTooltip]="row.cells.Volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Weight.displayControl.styles.style"
          [ngClass]="row.cells.Weight.displayControl.styles.classes"
          [matTooltip]="row.cells.Weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NmfcNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.NmfcNumber.displayControl.styles.style"
          [ngClass]="row.cells.NmfcNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.NmfcNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.NmfcNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NmfcSubNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.NmfcSubNumber.displayControl.styles.style"
          [ngClass]="row.cells.NmfcSubNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.NmfcSubNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.NmfcSubNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes"
          [matTooltip]="row.cells.Notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PackagedItemCount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PackagedItemCount.displayControl.styles.style"
          [ngClass]="row.cells.PackagedItemCount.displayControl.styles.classes"
          [matTooltip]="row.cells.PackagedItemCount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PackagedItemCount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SailOnBoard">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SailOnBoard.displayControl.styles.style"
          [ngClass]="row.cells.SailOnBoard.displayControl.styles.classes"
          [matTooltip]="row.cells.SailOnBoard.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SailOnBoard.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ShippingCost">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ShippingCost.displayControl.styles.style"
          [ngClass]="row.cells.ShippingCost.displayControl.styles.classes"
          [matTooltip]="row.cells.ShippingCost.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ShippingCost.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ShippingDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ShippingDate.displayControl.styles.style"
          [ngClass]="row.cells.ShippingDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ShippingDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ShippingDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="TrailerNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.TrailerNumber.displayControl.styles.style"
          [ngClass]="row.cells.TrailerNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.TrailerNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.TrailerNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.CreatedSysDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CreatedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysUser.displayControl.styles.classes"
          [matTooltip]="row.cells.CreatedSysUser.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ModifiedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.ModifiedSysDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ModifiedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ModifiedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysUser.displayControl.styles.classes"
          [matTooltip]="row.cells.ModifiedSysUser.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ModifiedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
