<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight_uom.label + (fields.weight_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                            [tooltip]="fields.weight_uom.control.tooltip"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Inventory-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-net_weight"
                     *ngIf="!fieldsets.net_weight.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.net_weight.collapsible }">
                    <div *ngIf="!fieldsets.net_weight.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.net_weight.toggle()">
                      <span class="fieldsetsTitle-text">Net weight</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.net_weight.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.net_weight.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.net_weight.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.net_weight.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-net_weight" *ngIf="!fields.net_weight.hidden" 
                            class="field-container standard {{fields.net_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.net_weight.styles.style"
                            [ngClass]="fields.net_weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.net_weight.label + (fields.net_weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.net_weight.label}}<span *ngIf="fields.net_weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="net_weight"
                                matInput
                                numberBox
                                [format]="fields.net_weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.net_weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.net_weight.control.placeholder}}"
                                [ngStyle]="fields.net_weight.control.styles.style"
                                [ngClass]="fields.net_weight.control.styles.classes"
                                [matTooltip]="fields.net_weight.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.net_weight.invalid">
                          <ng-container *ngFor="let error of fields.net_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-net_auto_calculate" *ngIf="!fields.net_auto_calculate.hidden" 
                            class="field-container standard {{fields.net_auto_calculate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.net_auto_calculate.styles.style"
                            [ngClass]="fields.net_auto_calculate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.net_auto_calculate.label + (fields.net_auto_calculate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.net_auto_calculate.label}}<span *ngIf="fields.net_auto_calculate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="net_auto_calculate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.net_auto_calculate.control.styles.style"
                                      [ngClass]="fields.net_auto_calculate.control.styles.classes"
                                      [matTooltip]="fields.net_auto_calculate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.net_auto_calculate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.net_auto_calculate.invalid">
                          <ng-container *ngFor="let error of fields.net_auto_calculate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-gross_weight"
                     *ngIf="!fieldsets.gross_weight.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.gross_weight.collapsible }">
                    <div *ngIf="!fieldsets.gross_weight.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.gross_weight.toggle()">
                      <span class="fieldsetsTitle-text">Gross weight</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.gross_weight.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.gross_weight.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.gross_weight.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.gross_weight.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-gross_weight" *ngIf="!fields.gross_weight.hidden" 
                            class="field-container standard {{fields.gross_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.gross_weight.styles.style"
                            [ngClass]="fields.gross_weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.gross_weight.label + (fields.gross_weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.gross_weight.label}}<span *ngIf="fields.gross_weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="gross_weight"
                                matInput
                                numberBox
                                [format]="fields.gross_weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.gross_weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.gross_weight.control.placeholder}}"
                                [ngStyle]="fields.gross_weight.control.styles.style"
                                [ngClass]="fields.gross_weight.control.styles.classes"
                                [matTooltip]="fields.gross_weight.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.gross_weight.invalid">
                          <ng-container *ngFor="let error of fields.gross_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-gross_auto_calculate" *ngIf="!fields.gross_auto_calculate.hidden" 
                            class="field-container standard {{fields.gross_auto_calculate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.gross_auto_calculate.styles.style"
                            [ngClass]="fields.gross_auto_calculate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.gross_auto_calculate.label + (fields.gross_auto_calculate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.gross_auto_calculate.label}}<span *ngIf="fields.gross_auto_calculate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="gross_auto_calculate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.gross_auto_calculate.control.styles.style"
                                      [ngClass]="fields.gross_auto_calculate.control.styles.classes"
                                      [matTooltip]="fields.gross_auto_calculate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.gross_auto_calculate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.gross_auto_calculate.invalid">
                          <ng-container *ngFor="let error of fields.gross_auto_calculate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>