<datex-calendar-day-view #$calendarComponent 
  *ngIf="initialized && !$hasMissingRequiredInParams"
  [(pageSize)]="columnsPageSize" [(pageSkip)]="columnsPageSkip"
  [columnsTotalCount]="columnsTotalCount" 
  [eventsTotalCount]="calendarEventsTotalCount" 
  [eventsFetchedCount]="calendarEventsFetchedCount"
  (pageChange)="$columnsPageChange()"
  [currentDate]="viewDate" [events]="calendarEvents" [columns]="columns"
  [dayStartHour]="dayStartHour" [dayStartMinute]="dayStartMinute" 
  [dayEndHour]="dayEndHour" [dayEndMinute]="dayEndMinute" 
  [hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight" 
  (hourSegmentClicked)="$onHourSegmentClicked($event)" 
  (hourSegmentEventDropped)="$onHourSegmentEventDropped($event)"
  [headerTemplate]="customHeaderTemplate"
  [eventTemplate]="customEventTemplate" 
  [matchEventToColumn]="$matchEventToColumn"
  [containerClass]="containerClass"

  [hasUnscheduledEvents]="true"
  [unscheduledEventTemplate]="customUnscheduledEventTemplate"
  [unscheduledEventsTitle]="$unscheduledCalendarEventsTitle()"
  [unscheduledEvents]="unscheduledCalendarEvents"
  [(unscheduledEventsPageSize)]="unscheduledCalendarEventsPageSize" 
  [(unscheduledEventsPageSkip)]="unscheduledCalendarEventsPageSkip"
  [unscheduledEventsTotalCount]="unscheduledCalendarEventsTotalCount" 
  (loadMoreUnscheduledEvents)="$loadMoreUnscheduledEvents()"
    (unscheduledEventDropped)="$onUnscheduleEventDropped($event)"
  >

  <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="on_previous_day" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_previous_day($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="on_next_day" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_next_day($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="on_today" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_today($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="create_appointment" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_create_appointment_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input 
        matInput 
        autocomplete="off" 
        class="datex-textbox query-search" 
        spellcheck="false" 
        placeholder="Filter"
        [(ngModel)]="fullTextSearch"
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-view_date" *ngIf="!filters.view_date.hidden" 
            class="field-container standard {{filters.view_date.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.view_date.styles.style"
            [ngClass]="filters.view_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.view_date.label + (filters.view_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.view_date.label}}<span *ngIf="filters.view_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="view_date"
            [format]="filters.view_date.control.format"
            [mode]="filters.view_date.control.mode"
            [ngStyle]="filters.view_date.control.styles.style"
            [ngClass]="filters.view_date.control.styles.classes"
            [tooltip]="filters.view_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.view_date.invalid">
          <ng-container *ngFor="let error of filters.view_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-area" *ngIf="!filters.area.hidden" 
            class="field-container double {{filters.area.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.area.styles.style"
            [ngClass]="filters.area.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.area.label + (filters.area.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.area.label}}<span *ngIf="filters.area.required" class="required-asterisk">*</span>
          </label>
        </div>
        <DockAppointments-areas_dd_multi 
            data-cy="selector-multi"
            [type]="filters.area.control.type"
            formControlName="area"
            (displayTextChange)="filters.area.control.displayText=$event"
            [placeholder]="filters.area.control.placeholder"
            [styles]="filters.area.control.styles"
            [tooltip]="filters.area.control.tooltip"
          [warehouseIds]="$fields_area_selector_inParams_warehouseIds"
        >
        </DockAppointments-areas_dd_multi>
        <ng-container *ngIf="filters.area.invalid">
          <ng-container *ngFor="let error of filters.area.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-dock_doors" *ngIf="!filters.dock_doors.hidden" 
            class="field-container standard {{filters.dock_doors.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.dock_doors.styles.style"
            [ngClass]="filters.dock_doors.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.dock_doors.label + (filters.dock_doors.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.dock_doors.label}}<span *ngIf="filters.dock_doors.required" class="required-asterisk">*</span>
          </label>
        </div>
        <DockAppointments-dock_doors_dd_multi 
            data-cy="selector-multi"
            [type]="filters.dock_doors.control.type"
            formControlName="dock_doors"
            (displayTextChange)="filters.dock_doors.control.displayText=$event"
            [placeholder]="filters.dock_doors.control.placeholder"
            [styles]="filters.dock_doors.control.styles"
            [tooltip]="filters.dock_doors.control.tooltip"
          [warehouseIds]="$fields_dock_doors_selector_inParams_warehouseIds"
          [parentIds]="$fields_dock_doors_selector_inParams_parentIds"
        >
        </DockAppointments-dock_doors_dd_multi>
        <ng-container *ngIf="filters.dock_doors.invalid">
          <ng-container *ngFor="let error of filters.dock_doors.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container unscheduledEventsToolbar>
      <div class="query-filter">
        <input 
          matInput 
          autocomplete="off" 
          class="datex-textbox query-search" 
          spellcheck="false"
          placeholder="Filter" 
          [(ngModel)]="unscheduledCalendarEventsFullTextSearch" 
          (ngModelChange)="reload()"
          [ngModelOptions]="{updateOn: 'blur'}" 
          matTooltip="true"
          matTooltipClass="datex-control-tooltip">
      </div>
  </ng-container>
</datex-calendar-day-view>

<ng-template #customEventTemplate let-event="event">
 <FootPrintManager-dock_appointment_card
    [dockAppointment]="event.$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment"
    ($refreshEvent)="refresh(false, false, null)"
    >
  </FootPrintManager-dock_appointment_card>
</ng-template>

<ng-template #customHeaderTemplate let-column="column">
  <div [innerHtml]="$getHeaderHtml(column) | safeHtml"></div>
</ng-template>

  <ng-template #customUnscheduledEventTemplate let-event="event">
     <FootPrintManager-dock_appointment_card
    [dockAppointment]="event.$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment"
    ($refreshEvent)="refresh(false, false, null)"
    >
  </FootPrintManager-dock_appointment_card>
  </ng-template>

<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
