import { 
  Component, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  EmbeddedViewRef,
} from '@angular/core';

import { 
  Router,
  ActivatedRoute, 
  ParamMap 
} from '@angular/router';

import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Title} from "@angular/platform-browser";

import { 
  MsalInterceptorConfiguration, 
  MsalService, 
  MSAL_INTERCEPTOR_CONFIG 
} from '@azure/msal-angular';


import { 
  AccountInfo, 
  EndSessionRequest 
} from '@azure/msal-browser';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { isNil, isObject } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexErrorService } from './error/datex-error-service';
import { protectedResources } from './auth-config';

import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { WavelengthUiModule, WavelengthShellService, IMenubarItem, MenuItemModel } from 'wavelength-ui';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


enum EmbedScriptType {
  Inline,
  External
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    WavelengthUiModule
  ],
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  providers: [
    {
      provide: WavelengthShellService,
      useExisting: app_ShellService
    }
  ]
})
export class ShellComponent {

  title: string = "FootPrint Cloud";
  logo: string = "assets/img/footprint_2024_icon_white.svg";

  account: AccountInfo;
  home: IMenubarItem;
  items: IMenubarItem[];
  menubar: any;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,

    private location: Location,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBarService: LoadingBarService,
    private titleService:Title,
    private datexErrorService: DatexErrorService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfiguration: MsalInterceptorConfiguration
  ) {

    this.home = new MenuItemModel(
      'home',
      'ms-Icon ms-Icon--Home',
      'Home',
      [],
      () => this.openHome()
    );

    this.items = [
    
      new MenuItemModel('inbound', 'ms-Icon ms-Icon--DecreaseIndent', 'Inbound', [
          new MenuItemModel('inbound_orders', 'ms-Icon ms-Icon--DecreaseIndent', 'Inbound Orders', [],  () => {
              this.shell.FootPrintManager.openinbound_orders_hub(
              false)}
        ),
          new MenuItemModel('returns_hub', 'ms-Icon ms-Icon--Undo', 'Returns', [],  () => {
              this.shell.FootPrintManager.openreturns_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('outbound', 'ms-Icon ms-Icon--DecreaseIndentMirrored', 'Outbound', [
          new MenuItemModel('outbound_orders', 'ms-Icon ms-Icon--DecreaseIndentMirrored', 'Outbound Orders', [],  () => {
              this.shell.FootPrintManager.openoutbound_orders_hub(
              false)}
        ),
          new MenuItemModel('warehouse_transfers', 'ms-Icon ms-Icon--DependencyAdd', 'Warehouse Transfers', [],  () => {
              this.shell.FootPrintManager.openwarehouse_transfers_hub(
              false)}
        ),
          new MenuItemModel('pack_verification', 'ms-Icon ms-Icon--GenericScan', 'Pack Verification', [],  () => {
              this.shell.FootPrintManager.openpack_verification_hub(
              false)}
        ),
          new MenuItemModel('waves', 'ms-Icon ms-Icon--WebAppBuilderFragment', 'Waves', [],  () => {
              this.shell.FootPrintManager.openwaves_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('transportation', 'ms-Icon ms-Icon--TimelineProgress', 'Transportation', [
          new MenuItemModel('dock_appointments', 'ms-Icon ms-Icon--Calendar', 'Dock Appointments', [],  () => {
              this.shell.FootPrintManager.opendock_appointments_hub(
              false)}
        ),
          new MenuItemModel('dock_doors', 'ms-Icon ms-Icon--ShakeDevice', 'Dock Doors', [],  () => {
              this.shell.FootPrintManager.opendock_doors_hub(
              {
                warehouseIds:  null 
              },
              false)}
        ),
          new MenuItemModel('load_containers_hub', 'ms-Icon ms-Icon--RowsGroup', 'Load Containers', [],  () => {
              this.shell.FootPrintManager.openload_containers_hub(
              false)}
        ),
          new MenuItemModel('shipping_containers', 'ms-Icon ms-Icon--Tiles', 'Shipping Containers', [],  () => {
              this.shell.FootPrintManager.openshipping_containers_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('planning', 'ms-Icon ms-Icon--HighlightMappedShapes', 'Planning', [
          new MenuItemModel('labor_management_hub', 'ms-Icon ms-Icon--WorkforceManagement', 'Labor Management', [],  () => {
              this.shell.FootPrintManager.openlabor_management_hub(
              false)}
        ),
          new MenuItemModel('dock_status', 'icon-datex-LoadContainer', 'Dock status', [],  () => {
              this.shell.opencustom_dock_status_grid(
              {
                warehouse_ids:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('inventory', 'ms-Icon ms-Icon--WebComponents', 'Inventory', [
          new MenuItemModel('inventory', 'ms-Icon ms-Icon--AddIn', 'Inventory', [],  () => {
              this.shell.FootPrintManager.openinventory_hub(
              {
                ownerId:  null ,
                projectId:  null ,
                warehouseId:  null 
              },
              false)}
        ),
          new MenuItemModel('activity', 'icon-ic_fluent_tasks_app_20_regular', 'Activity', [],  () => {
              this.shell.FootPrintManager.openactivity_hub(
              false)}
        ),
          new MenuItemModel('lots', 'ms-Icon ms-Icon--PreviewLink', 'Lots', [],  () => {
              this.shell.FootPrintManager.openlots_hub(
              false)}
        ),
          new MenuItemModel('serialnumbers', 'ms-Icon ms-Icon--BulletedTreeList', 'Serial Numbers', [],  () => {
              this.shell.FootPrintManager.openserialnumbers_hub(
              false)}
        ),
          new MenuItemModel('inventory_transfers', 'ms-Icon ms-Icon--ClosePane', 'Inventory Transfers', [],  () => {
              this.shell.FootPrintManager.openinventory_transfers_hub(
              false)}
        ),
          new MenuItemModel('inventory_counts', 'ms-Icon ms-Icon--ChoiceColumn', 'Inventory Counts', [],  () => {
              this.shell.FootPrintManager.openinventory_counts_hub(
              false)}
        ),
          new MenuItemModel('replenishments', 'ms-Icon ms-Icon--DownloadDocument', 'Replenishments', [],  () => {
              this.shell.FootPrintManager.openreplenishment_hub(
              false)}
        ),
          new MenuItemModel('warehouses', 'ms-Icon ms-Icon--ScaleVolume', 'Warehouses', [],  () => {
              this.shell.FootPrintManager.openwarehouses_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('owners', 'ms-Icon ms-Icon--AccountBrowser', 'Owners', [
          new MenuItemModel('owners', 'ms-Icon ms-Icon--Group', 'Owners', [],  () => {
              this.shell.FootPrintManager.openowners_hub(
              false)}
        ),
          new MenuItemModel('projects', 'ms-Icon ms-Icon--EditContact', 'Projects', [],  () => {
              this.shell.FootPrintManager.openprojects_hub(
              false)}
        ),
          new MenuItemModel('materials', 'ms-Icon ms-Icon--AllApps', 'Materials', [],  () => {
              this.shell.FootPrintManager.openmaterials_hub(
              {
                ownerId:  null ,
                projectId:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('billing', 'ms-Icon ms-Icon--SemiboldWeight', 'Billing', [
          new MenuItemModel('billing_contracts', 'ms-Icon ms-Icon--PenWorkspace', 'Billing Contracts', [],  () => {
              this.shell.FootPrintManager.openbilling_contracts_hub(
              false)}
        ),
          new MenuItemModel('billing_records', 'ms-Icon ms-Icon--WaffleOffice365', 'Billing Records', [],  () => {
              this.shell.FootPrintManager.openbilling_records_hub(
              false)}
        ),
          new MenuItemModel('invoices', 'ms-Icon ms-Icon--Invoice', 'Invoices', [],  () => {
              this.shell.FootPrintManager.openinvoices_hub(
              {
                ownerId:  null ,
                projectId:  null 
              },
              false)}
        ),
          new MenuItemModel('work_orders', 'ms-Icon ms-Icon--AccountActivity', 'Work Orders', [],  () => {
              this.shell.FootPrintManager.openwork_orders_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('integrations', 'ms-Icon ms-Icon--CloudImportExport', 'Integrations', [
          new MenuItemModel('entity_import', 'ms-Icon ms-Icon--PAAction', 'Entity Import', [],  () => {
              this.shell.FootPrintManager.openentity_import_hub(
              false)}
        ),
          new MenuItemModel('orderful', 'ms-Icon ms-Icon--CloudImportExport', 'Ordeful', [],  () => {
              this.shell.Orderful.openorderful_api_hub(
              {
                integration_name:  "Orderful" 
              },
              false)}
        ),
          new MenuItemModel('footprint_api_manager', 'ms-Icon ms-Icon--RevenueManagement', 'Footprint API Manager', [],  () => {
              this.shell.FootPrintApiManager.openfootprint_api_hub(
              {
                integration_name:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('settings', 'ms-Icon ms-Icon--Settings', 'Settings', [
          new MenuItemModel('inventory_configurations', 'ms-Icon ms-Icon--ProductCatalog', 'Inventory configurations', [],  () => {
              this.shell.FootPrintManager.openconfigurations_inventory_hub(
              false)}
        ),
          new MenuItemModel('storage_categories', 'ms-Icon ms-Icon--HomeGroup', 'Storage categories', [],  () => {
              this.shell.FootPrintManager.openconfigurations_storage_categories_hub(
              false)}
        ),
          new MenuItemModel('email_rules', 'icon-ic_fluent_mail_alert_20_regular', 'Email rules', [],  () => {
              this.shell.Notifications.openauto_email_rules_hub(
              {
                projectId:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    ];

    this.menubar = { 
        inbound: this.items.find(i => i.id == 'inbound') as { hidden: boolean, menubar: {  inbound_orders: { hidden: boolean },  returns_hub: { hidden: boolean },  } }, 
        outbound: this.items.find(i => i.id == 'outbound') as { hidden: boolean, menubar: {  outbound_orders: { hidden: boolean },  warehouse_transfers: { hidden: boolean },  pack_verification: { hidden: boolean },  waves: { hidden: boolean },  } }, 
        transportation: this.items.find(i => i.id == 'transportation') as { hidden: boolean, menubar: {  dock_appointments: { hidden: boolean },  dock_doors: { hidden: boolean },  load_containers_hub: { hidden: boolean },  shipping_containers: { hidden: boolean },  } }, 
        planning: this.items.find(i => i.id == 'planning') as { hidden: boolean, menubar: {  labor_management_hub: { hidden: boolean },  dock_status: { hidden: boolean },  } }, 
        inventory: this.items.find(i => i.id == 'inventory') as { hidden: boolean, menubar: {  inventory: { hidden: boolean },  activity: { hidden: boolean },  lots: { hidden: boolean },  serialnumbers: { hidden: boolean },  inventory_transfers: { hidden: boolean },  inventory_counts: { hidden: boolean },  replenishments: { hidden: boolean },  warehouses: { hidden: boolean },  } }, 
        owners: this.items.find(i => i.id == 'owners') as { hidden: boolean, menubar: {  owners: { hidden: boolean },  projects: { hidden: boolean },  materials: { hidden: boolean },  } }, 
        billing: this.items.find(i => i.id == 'billing') as { hidden: boolean, menubar: {  billing_contracts: { hidden: boolean },  billing_records: { hidden: boolean },  invoices: { hidden: boolean },  work_orders: { hidden: boolean },  } }, 
        integrations: this.items.find(i => i.id == 'integrations') as { hidden: boolean, menubar: {  entity_import: { hidden: boolean },  orderful: { hidden: boolean },  footprint_api_manager: { hidden: boolean },  } }, 
        settings: this.items.find(i => i.id == 'settings') as { hidden: boolean, menubar: {  inventory_configurations: { hidden: boolean },  storage_categories: { hidden: boolean },  email_rules: { hidden: boolean },  } }, 
    };
  }

  private addScript(type: EmbedScriptType, data: string) {
    switch(type){
      case EmbedScriptType.Inline:
        this.addInlineScript(data);
        break;
      case EmbedScriptType.External:
        this.addExternalScript(data);
        break;
      default:
        throw new Error(`Unknown script type ${type}`);
    }
  }

  private addInlineScript(body: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = body;
    document.body.appendChild(script);
  }

  private addExternalScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
  }

  private openHome() {    
    this.shell.FootPrintManager.openorders_hub(
      false
    );
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  onInitialized() {
    Promise.resolve().then(() => {
      this.on_init();
    });

    this.account = this.authService.instance.getActiveAccount();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $workspace: ShellComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Hide navigation items
  if (await $operations.FootPrintManager.Disable_Navigation_Inbound.isAuthorized()){
      $workspace.menubar.inbound.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound.isAuthorized()){
      $workspace.menubar.outbound.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation.isAuthorized()){
      $workspace.menubar.transportation.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Planning.isAuthorized()){
      $workspace.menubar.planning.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory.isAuthorized()){
      $workspace.menubar.inventory.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Owners.isAuthorized()){
      $workspace.menubar.owners.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing.isAuthorized()){
      $workspace.menubar.billing.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Integrations.isAuthorized()){
      $workspace.menubar.integrations.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Settings.isAuthorized()){
      $workspace.menubar.settings.hidden = true;
  }
   //This is for the Usersnap Widget
  if($settings.UsersnapDisplay)
  {
      let usersnapContent =  (await $flows.Usersnap.get_usersnap_widget({})).usersnap_scriptContent;
      $workspace.addScript(EmbedScriptType.Inline, usersnapContent);
  }
  //This is for the Help Widget
  if($settings.Document360Display)
  {
      let doc360Content =  (await $flows.Document360.get_document360_widget({})).doc30_scriptContent;
      $workspace.addScript(EmbedScriptType.Inline, doc360Content);
  }
  }
  //#endregion private flows
}
