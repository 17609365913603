import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { EndeavorLabs_predictions_reportService } from './EndeavorLabs.report.index';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public EndeavorLabs: EndeavorLabs_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _predictions_report: EndeavorLabs_predictions_reportService;
  public get predictions_report(): EndeavorLabs_predictions_reportService {
    if(!this._predictions_report) {
      this._predictions_report = this.injector.get(EndeavorLabs_predictions_reportService);
    }
    return this._predictions_report;
  }
}

