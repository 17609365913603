import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { WorkOrders_accessorial_operationcodes_by_projectId_dd_singleComponent } from './WorkOrders.accessorial_operationcodes_by_projectId_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'


interface IFootPrintManager_accessorial_tasks_gridComponentEntity {
Id?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, ProjectId?: number, StatusId?: number, WorkOrderId?: number, OperationCode?: { Name?: string }, AccessorialTaskProperty?: { TaskId?: number, ActualAmount?: number, ActualUom?: number, ConfirmationPromptTriggerId?: number, DivertToProjectId?: number, ExpectedAmount?: number, ExpectedUom?: number }, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }}

interface IFootPrintManager_accessorial_tasks_gridComponentInParams {
  workOrderId?: number, projectId?: number, entityStatusId: number, orderId?: number, warehouseId: number, shippingContainerId?: number, showAllOpCodes?: boolean, shipmentId?: number}


class FootPrintManager_accessorial_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_accessorial_tasks_gridComponent;
  entity: IFootPrintManager_accessorial_tasks_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    owner_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    project_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    operation_code_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    expected_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    actual_amount_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    uom_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['owner_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['project_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    operation_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['operation_code_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    expected_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['expected_amount_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    actual_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['actual_amount_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '', null)
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    billing_record: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    invoice: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }

  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.project.editControl.value;
    
    return expr;
  }
  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.owner.editControl.value;
    
    return expr;
  }
  get $fields_operation_code_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectId ?? $row.cells.project.editControl.value;
    
    return expr;
  }
  get $fields_operation_code_selector_inParams_showAllOpCodes(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.showAllOpCodes;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['owner_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['operation_code_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_operation_code_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_accessorial_tasks_gridComponent, entity: IFootPrintManager_accessorial_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_accessorial_tasks_gridComponent, entity?: IFootPrintManager_accessorial_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      workOrderId:  $grid.inParams.workOrderId ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shippingContainerId:  $grid.inParams.shippingContainerId ,
      shipmentId:  $grid.inParams.shipmentId ,
    };
    const data = await this.datasources.WorkOrders.ds_accessorial_tasks_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project?.Owner?.LookupCode;
    (this.cells.owner.editControl as SelectBoxModel).reset($row.entity.Project?.OwnerId);
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.project.editControl as SelectBoxModel).reset($row.entity.ProjectId);
    (this.cells.operation_code.displayControl as TextModel).text = $row.entity.OperationCode?.Name;
    (this.cells.operation_code.editControl as SelectBoxModel).reset($row.entity.OperationCodeId);
    (this.cells.expected_amount.displayControl as TextModel).text = $row.entity.AccessorialTaskProperty?.ExpectedAmount?.toString();
    (this.cells.expected_amount.editControl as NumberBoxModel).reset($row.entity.AccessorialTaskProperty?.ExpectedAmount);
    (this.cells.actual_amount.displayControl as TextModel).text = $row.entity.AccessorialTaskProperty?.ActualAmount?.toString();
    (this.cells.actual_amount.editControl as NumberBoxModel).reset($row.entity.AccessorialTaskProperty?.ActualAmount);
    (this.cells.uom.editControl as SelectBoxModel).reset($row.entity.AccessorialTaskProperty?.ExpectedUom);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'invoice' : {
        this.on_invoice_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
   $row.entity = {
    
       StatusId: 1
   };
  
  $row.cells.actual_amount.editControl.readOnly = true;
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.operation_code.editControl.value) &&
      $utils.isDefined($row.cells.expected_amount.editControl.value) &&
      $utils.isDefined($row.cells.uom.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  var projectId;
  if ($utils.isDefined($grid.inParams.projectId)) {
      projectId = $grid.inParams.projectId
  }
  else if ($utils.isDefined($row.cells.project.editControl.value)) {
      projectId = $row.cells.project.editControl.value
  }
  else {
      $shell.FootPrintManager.openErrorDialog('Save', 'Please select a project first.');
      throw new Error('Please select a project first.'); // to prevent displayMode 
  }
  
  
  var statusId = $grid.inParams.entityStatusId;
  
  // Work order
  if ($utils.isDefined($grid.inParams.workOrderId)) {
  
  
      try {
  
          const taskRequest = (await $flows.WorkOrders.create_accessorial_task_flow({
              projectId: projectId,
              expectedAmount: $row.cells.expected_amount.editControl.value,
              expectedUom: $row.cells.uom.editControl.value,
              operationCodeId: $row.cells.operation_code.editControl.value,
              notes: $row.cells.notes.editControl.value,
              warehouseId: $grid.inParams.warehouseId,
              workOrderId: $grid.inParams.workOrderId,
              release: statusId == 2 ? true : false  // If the work order is in a released status auto release the task
          }))
  
          $row.entity.Id = taskRequest.taskId;
          await $row.refresh();
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  
  }
  // Shipping Container
  if ($utils.isDefined($grid.inParams.shippingContainerId)) {
      try {
          const taskRequest = (await $flows.WorkOrders.create_accessorial_task_flow({
              projectId: projectId,
              expectedAmount: $row.cells.expected_amount.editControl.value,
              expectedUom: $row.cells.uom.editControl.value,
              operationCodeId: $row.cells.operation_code.editControl.value,
              notes: $row.cells.notes.editControl.value,
              warehouseId: $grid.inParams.warehouseId,
              orderId: $grid.inParams.orderId,
              shippingContainerId: $grid.inParams.shippingContainerId,
              release: true
          }));
  
          $row.entity.Id = taskRequest.taskId;
          await $row.refresh();
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  // Shipment
  else if ($utils.isDefined($grid.inParams.shipmentId)) {
      try {
  
          const taskRequest = (await $flows.WorkOrders.create_accessorial_task_flow({
              projectId: projectId,
              expectedAmount: $row.cells.expected_amount.editControl.value,
              expectedUom: $row.cells.uom.editControl.value,
              operationCodeId: $row.cells.operation_code.editControl.value,
              notes: $row.cells.notes.editControl.value,
              warehouseId: $grid.inParams.warehouseId,
              shipmentId: $grid.inParams.shipmentId,
              orderId: $grid.inParams.orderId,
              release: true
          }))
  
          $row.entity.Id = taskRequest.taskId;
          await $row.refresh();
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  // Order
  else if ($utils.isDefined($grid.inParams.orderId)) {
      try {
  
          const taskRequest = (await $flows.WorkOrders.create_accessorial_task_flow({
              projectId: projectId,
              expectedAmount: $row.cells.expected_amount.editControl.value,
              expectedUom: $row.cells.uom.editControl.value,
              operationCodeId: $row.cells.operation_code.editControl.value,
              notes: $row.cells.notes.editControl.value,
              warehouseId: $grid.inParams.warehouseId,
              orderId: $grid.inParams.orderId,
              release: true
          }))
  
          $row.entity.Id = taskRequest.taskId;
          await $row.refresh();
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  
  
  // Auto complete record
  if ($grid.filters.auto_complete_on_creation.control.value) {
  
      $row.selected = true;
      $grid.on_complete_clicked();
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  if ($utils.isDefined($row.entity.AccessorialTaskProperty?.ExpectedUom)) {
  
      const uom = (await $datasources.WorkOrders.ds_get_measurement_unit_by_unitId.get({ unitId: $row.entity.AccessorialTaskProperty.ExpectedUom })).result;
      if ($utils.isDefined(uom)) {
  
          $row.cells.uom.displayControl.text = uom.Short_name;
      }
  }
  if ($utils.isDefined($row.entity.Id)) {
      const billing = (await $datasources.WorkOrders.ds_get_billingtasks_by_accessorial_taskId.get({ taskId: $row.entity.Id })).result;
      if ($utils.isDefined(billing)) {
          const rate = $utils.isDefined(billing[0]?.BillingRecord?.ApplicableRate) ?
              billing[0]?.BillingRecord?.ApplicableRate : 'n/a';
          const amount = $utils.isDefined(billing[0]?.Amount) ?
              billing[0]?.Amount : 'n/a';
          const total = $utils.isDefined(billing[0]?.BillingRecord?.Total) ?
              billing[0]?.BillingRecord?.Total : 'n/a';
          $row.cells.billing_record.displayControl.text = `Rate: ${rate} Amount: ${amount} Total: ${total}`
  
          if ($utils.isDefined(billing[0]?.BillingRecord?.InvoiceLine?.Invoice?.LookupCode)) {
              const invoice = billing[0]?.BillingRecord?.InvoiceLine?.Invoice?.LookupCode;
              $row.cells.invoice.displayControl.text = `${invoice}`
          }
      }
  }
  
  const row_statusId = $row.entity.StatusId;
  // Completed Status
  if (row_statusId == 2) {
  
      $row.cells.operation_code.editControl.readOnly = true;
      $row.cells.expected_amount.editControl.readOnly = true;
      $row.cells.actual_amount.editControl.readOnly = false;
      $row.cells.uom.editControl.readOnly = true;
  
      // Cancelled
  } else if (row_statusId == 3) {
      $row.cells.operation_code.editControl.readOnly = true;
      $row.cells.expected_amount.editControl.readOnly = true;
      $row.cells.actual_amount.editControl.readOnly = true;
      $row.cells.uom.editControl.readOnly = true;
  }
  else {
  
      $row.cells.operation_code.editControl.readOnly = false;
      $row.cells.expected_amount.editControl.readOnly = false;
      $row.cells.actual_amount.editControl.readOnly = true;
      $row.cells.uom.editControl.readOnly = false;
  }
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.expected_amount.editControl.value) &&
      $utils.isDefined($row.cells.operation_code.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  
  
  try {
  
      const result = (await $flows.WorkOrders.update_accessorial_task_flow({
          taskId: $row.entity.Id,
          expected_amount: $row.cells.expected_amount.editControl.value,
          expected_uom: $row.cells.uom.editControl.value,
          notes: $row.cells.notes.editControl.value,
          actual_amount: $row.cells.actual_amount.editControl.isChanged ? $row.cells.actual_amount.editControl.value : null,
          operation_code_id: $row.cells.operation_code.editControl.value
      })).reason;
  
      const reason = result;
      if ($utils.isDefined(reason)) {
          $shell.FootPrintManager.openInfoDialog('Unable to update record', reason);
      }
  
      await $row.refresh();
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  on_operation_code_change(event = null) {
    return this.on_operation_code_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_operation_code_changeInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  var projectId;
  if ($utils.isDefined($grid.inParams.projectId)) {
      projectId = $grid.inParams.projectId
  }
  else if ($utils.isDefined($row.cells.project.editControl.value)) {
      projectId = $row.cells.project.editControl.value
  }
  
  
  const operationCodeId = $row.cells.operation_code.editControl.value;
  
  if ($utils.isAllDefined(operationCodeId, projectId)) {
      const uom = (await $datasources.WorkOrders.ds_get_accessorial_operationcode_by_operationcodeId_projectId.get({
          projectId: projectId,
          operationCodeId: operationCodeId
      })).result;
  
  
      if ($utils.isDefined(uom)) {
  
          const uomId = uom.BillingContractLine?.ResultingUomId;
          const uomName = uom.BillingContractLine?.ResultingUom?.Short_name;
  
          if ($utils.isDefined(uomId)) {
  
              $row.cells.uom.editControl.value = uomId
              $row.cells.uom.displayControl.text = uomName
          }
      }
      else {
  
          const uom = (await $datasources.WorkOrders.ds_get_operationcode_by_operationCodeId.get({ operationCodeId: operationCodeId })).result;
          if ($utils.isDefined(uom)) {
  
              const uomId = uom.Uom
              if ($utils.isDefined(uomId)) {
  
                  const uomDetails = (await $datasources.WorkOrders.ds_get_measurement_unit_by_unitId.get({ unitId: uomId })).result;
                  if ($utils.isDefined(uomDetails)) {
  
                      $row.cells.uom.editControl.value = uomId;
                      $row.cells.uom.displayControl.text = uomDetails.Short_name;
                  }
              }
          }
  
      }
  }
  else {
      $row.cells.uom.editControl.value = null
      $row.cells.uom.displayControl.text = null
  
  }
  }
  on_invoice_clicked(event = null) {
    return this.on_invoice_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoice_clickedInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  if ($utils.isDefined($row.entity.Id)) {
      const billing = (await $datasources.WorkOrders.ds_get_billingtasks_by_accessorial_taskId.get({ taskId: $row.entity.Id })).result;
      if ($utils.isDefined(billing)) {
  
          const invoiceId = billing[0].BillingRecord?.InvoiceLine?.InvoiceId
  
          if ($utils.isDefined(invoiceId)) {
  
              $shell.FootPrintManager.openinvoice_editor({ invoiceId: invoiceId })
          }
          else {
              throw new Error('Unable to determine invoice.')
          }
      }
      else {
          throw new Error('Unable to determine invoice.')
      }
  }
  else {
      throw new Error('Unable to determine invoice.')
  }
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.cells.project.editControl.value)) {
  const owner = (await $datasources.Owners.ds_get_project_by_projectId.get({projectId: $row.cells.project.editControl.value})).result;
  if ($utils.isDefined(owner)){
      $row.cells.owner.editControl.value = owner[0].OwnerId;
      $row.cells.owner.displayControl.text = owner[0].Owner?.LookupCode;
  }
  }
  else {
      $row.cells.owner.editControl.value = null;
  }
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $row: FootPrintManager_accessorial_tasks_gridComponentRowModel,
  $grid: FootPrintManager_accessorial_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.project.editControl.value = null;
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => WorkOrders_accessorial_operationcodes_by_projectId_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-accessorial_tasks_grid',
  templateUrl: './FootPrintManager.accessorial_tasks_grid.component.html'
})
export class FootPrintManager_accessorial_tasks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_accessorial_tasks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_accessorial_tasks_gridComponentInParams = { workOrderId: null, projectId: null, entityStatusId: null, orderId: null, warehouseId: null, shippingContainerId: null, showAllOpCodes: null, shipmentId: null };


  //#region Variables
  vars: { showAllOpCodes?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  onInit = new EventEmitter<void>();
  
  events = {
    onInit: this.onInit,
  }
  //#endregion

  headers = {
     owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       operation_code: new GridHeaderModel(new HeaderStyles(null, null), 'Operation code', true, false, null),       expected_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Expected qty', true, false, null),       actual_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Actual qty', false, false, null),       uom: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', true, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       billing_record: new GridHeaderModel(new HeaderStyles(null, null), 'Billing record', false, false, null),       invoice: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_accessorial_tasks_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('workOrderId') set $inParams_workOrderId(value: any) {
    this.inParams['workOrderId'] = value;
  }
  get $inParams_workOrderId(): any {
    return this.inParams['workOrderId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('entityStatusId') set $inParams_entityStatusId(value: any) {
    this.inParams['entityStatusId'] = value;
  }
  get $inParams_entityStatusId(): any {
    return this.inParams['entityStatusId'] ;
  }
  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('shippingContainerId') set $inParams_shippingContainerId(value: any) {
    this.inParams['shippingContainerId'] = value;
  }
  get $inParams_shippingContainerId(): any {
    return this.inParams['shippingContainerId'] ;
  }
  @Input('showAllOpCodes') set $inParams_showAllOpCodes(value: any) {
    this.inParams['showAllOpCodes'] = value;
  }
  get $inParams_showAllOpCodes(): any {
    return this.inParams['showAllOpCodes'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }

  topToolbar = {
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    show_all_operation_codes: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    auto_complete_on_creation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    show_all_operation_codes: new FieldModel(new CheckBoxModel(this.formGroup.controls['show_all_operation_codes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Show all operation codes', false),
    auto_complete_on_creation: new FieldModel(new CheckBoxModel(this.formGroup.controls['auto_complete_on_creation'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Auto complete on creation', false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Accessorials';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.entityStatusId)) {
        this.$missingRequiredInParams.push('entityStatusId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;

    (this.filters.show_all_operation_codes.control as CheckBoxModel).reset($grid.vars.showAllOpCodes);
    (this.filters.auto_complete_on_creation.control as CheckBoxModel).reset(false);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['show_all_operation_codes']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_show_all_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      workOrderId:  $grid.inParams.workOrderId ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shippingContainerId:  $grid.inParams.shippingContainerId ,
      shipmentId:  $grid.inParams.shipmentId ,
    };
    try {
    const data = await this.datasources.WorkOrders.ds_accessorial_tasks_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_accessorial_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_accessorial_tasks_gridComponentEntity) {
    const row = new FootPrintManager_accessorial_tasks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Hide owner and project columns if the projectId input is available
  if ($utils.isDefined($grid.inParams.projectId)) {
      $grid.headers.owner.hidden = true;
      $grid.headers.project.hidden = true;
  }
  
  
  // Disable top toolbar buttons
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.complete.control.readOnly = true;
  
  const statusId = $grid.inParams.entityStatusId;
  
  // Default show all operation codes behavior
  const showAllOpCodes = $grid.inParams.showAllOpCodes
  if ($utils.isDefined(showAllOpCodes) && showAllOpCodes == true) {
      $grid.vars.showAllOpCodes = true;
      $grid.filters.show_all_operation_codes.control.value = true;
  }
  else {
      $grid.vars.showAllOpCodes = false;
      $grid.filters.show_all_operation_codes.control.value = false;
  }
  
  // Work Order Context
  if ($utils.isDefined($grid.inParams.workOrderId)) {
      // Created
      if (statusId === 1) {
          $grid.headers.operation_code.required = true;
          $grid.headers.expected_amount.required = true;
          $grid.canEdit = true;
          $grid.bottomToolbar.addLine.control.readOnly = false;
  
  
      }
      // Released
      else if (statusId === 2) {
          $grid.headers.operation_code.required = true;
          $grid.headers.expected_amount.required = true;
          $grid.canEdit = true;
          $grid.bottomToolbar.addLine.control.readOnly = false;
  
      }
      // Cancelled
      else if (statusId === 8) {
  
          $grid.headers.operation_code.required = false;
          $grid.headers.expected_amount.required = false;
          $grid.canEdit = false;
          $grid.bottomToolbar.addLine.control.readOnly = true;
  
  
  
      }
      // Completed
      else {
  
          $grid.headers.operation_code.required = false;
          $grid.headers.expected_amount.required = false;
          $grid.canEdit = true;
          $grid.bottomToolbar.addLine.control.readOnly = true;
  
      }
  
  }
  // ShippingContainer Context
  else if ($utils.isDefined($grid.inParams.shippingContainerId)) {
      $grid.headers.operation_code.required = true;
      $grid.headers.expected_amount.required = true;
      $grid.canEdit = true;
      $grid.bottomToolbar.addLine.control.readOnly = false;
  
  }
  // Order Context
  else if ($utils.isDefined($grid.inParams.orderId)) {
      $grid.headers.operation_code.required = true;
      $grid.headers.expected_amount.required = true;
      $grid.canEdit = true;
      $grid.bottomToolbar.addLine.control.readOnly = false;
  
  }
  
  const element = document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0];
  if (element != null) {
      element.dispatchEvent(new CustomEvent('on_accessorial_tasks_grid_initialized'));
  }
  
  // Apply operations
  await $grid.on_apply_operations();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      $grid.topToolbar.complete.control.readOnly = false;
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.on_delete.control.readOnly = false;
  
      // Complete
      $grid.topToolbar.complete.control.readOnly = !(await checkAllCompletable());
  
      // Cancellation
      $grid.topToolbar.cancel.control.readOnly = !(await checkAllCancellable());
  
      // Deletion
      $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
  
  
  }
  else {
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  
  async function checkAllCompletable() {
      {
          let allow = $grid.selectedRows.length > 0;
  
          for (let row of $grid.selectedRows) {
  
              // Check if the task status is released
              if ($utils.isDefined(row.entity.StatusId) && row.entity.StatusId != 1) {
  
                  return false;
  
              }
          }
  
          return allow;
      }
  
  }
  async function checkAllDeletable() {
      {
          let allow = $grid.selectedRows.length > 0;
  
  
          for (let row of $grid.selectedRows) {
  
              // Check if the task status is completed
              if ($utils.isDefined(row.entity.StatusId) && row.entity.StatusId == 2) {
  
                  return false;
  
              }
          }
  
          return allow;
      }
  
  }
  
  async function checkAllCancellable() {
     {
          let allow = $grid.selectedRows.length > 0;
  
  
          for (let row of $grid.selectedRows) {
  
              // Check if the task status is already cancelled
              if ($utils.isDefined(row.entity.StatusId) && row.entity.StatusId == 3) {
  
                  return false;
  
              }
          }
  
          return allow;
      }
  
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Line Delete Error', 'No lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.WorkOrders.is_accessorial_task_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Line ${row.entity.OperationCode.Name.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete line errors';
          const errorMessage = `Line(s) ${failures.map(c => c.entity.OperationCode.Name.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Line(s) ${candidates.map(c => c.entity.OperationCode.Name.toString()).join(',')} - Once deleted, the line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some line(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          taskId: candidate.entity.Id
                      }
                      const result = await $flows.WorkOrders.delete_accessorial_task_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Line ${candidate.entity.OperationCode.Name.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All line(s) deleted';
                  const message = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All line(s) failed to delete';
                      const message = `Line(s) ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some lines could not be deleted';
                      const success = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} were deleted.`;
                      const errors = `The following line(s) could not be deleted: ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Line Cancel Error', 'No lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.WorkOrders.is_accessorial_task_cancelable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Line ${row.entity.OperationCode.Name.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Cancel line errors';
          const errorMessage = `Line(s) ${failures.map(c => c.entity.OperationCode.Name.toString()).join(',')} cannot be cancelled.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Line(s) ${candidates.map(c => c.entity.OperationCode.Name.toString()).join(',')} - Once cancelled, the line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some line(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          taskId: candidate.entity.Id
                      }
                      const result = await $flows.WorkOrders.cancel_accessorial_task_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Line ${candidate.entity.OperationCode.Name.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All line(s) cancelled';
                  const message = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} cancelled`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All line(s) failed to cancel';
                      const message = `Line(s) ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')} could not be cancelled`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some lines could not be cancelled';
                      const success = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} were cancelled.`;
                      const errors = `The following line(s) could not be cancelled: ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Line Complete Error', 'No lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.WorkOrders.is_accessorial_task_completable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Line ${row.entity.OperationCode.Name.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
  
  
      }
  
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Complete line errors';
          const errorMessage = `Line(s) ${failures.map(c => c.entity.OperationCode.Name.toString()).join(',')} cannot be completed.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Line(s) ${candidates.map(c => c.entity.OperationCode.Name.toString()).join(',')} - Once completed, the line(s) can be cancelled.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some line(s) cannot be completed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Complete the following line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          taskId: candidate.entity.Id,
                          billingRequest: true
                      }
                      const result = await $flows.WorkOrders.complete_accessorial_task_flow(flowParams);
  
                      processSuccess.push(candidate);
  
                      // Fire off thread to monitor for event completion
                      monitorEvent(candidate);
  
                      // Uncheck the row
                      candidate.selected = false;
  
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Line ${candidate.entity.OperationCode.Name.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All line(s) completed';
                  const message = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} completed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
  
                  for (const candidate of candidates) {
  
                      await candidate.refresh();
                      candidate.cells.billing_record.displayControl.text = 'Submitted to FootPrint events for processing....'
                  }
  
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All line(s) failed to complete';
                      const message = `Line(s) ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')} could not be completed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some lines could not be completed';
                      const success = `Line(s) ${processSuccess.map(c => c.entity.OperationCode.Name.toString()).join(',')} were completed.`;
                      const errors = `The following line(s) could not be completed: ${processFailures.map(c => c.entity.OperationCode.Name.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
                      for (const candidate of candidates) {
                          await candidate.refresh();
                          candidate.cells.billing_record.displayControl.text = 'Submitted to FootPrint events for processing....'
                      }
                  }
              }
          }
      }
  }
  
  
  /***********************************************
   * FUNCTIONS
  ************************************************/
  async function monitorEvent(myRow: any) {
      let checkIntervalSeconds = 10;
      let checkMaxDurationSeconds = 300;
      let index = 0;
      let isFinished = false;
  
      while (index * checkIntervalSeconds < checkMaxDurationSeconds && !isFinished) {
          await new Promise(resolve => setTimeout(resolve, checkIntervalSeconds * 1000));
  
          let eventDetails = (await $datasources.WorkOrders.ds_get_confirmed_accessorial_task_event_details.get({ taskId: myRow.entity.Id })).result;
  
          if ($utils.isDefined(eventDetails)) {
              // Is not in a 'Published' or 'Processing' status
              if (eventDetails.FootPrintEvent.StatusId !== 1 && eventDetails.FootPrintEvent.StatusId !== 5) {
                  isFinished = true;
                  await $grid.rows.find(r => r.entity.Id === myRow.entity.Id)?.refresh();
              }
          }
  
          index++;
      }
  }
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  on_show_all_changed(event = null) {
    return this.on_show_all_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_show_all_changedInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Default show all operation codes behavior
  
  if ($grid.filters.show_all_operation_codes.control.isChanged) {
      
      if ($grid.filters.show_all_operation_codes.control.value == true) {
          $grid.vars.showAllOpCodes = true;
      }
  
      else {
          $grid.vars.showAllOpCodes = false;
      }
  }
  
  }
  on_apply_operations(event = null) {
    return this.on_apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_apply_operationsInternal(
    $grid: FootPrintManager_accessorial_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if (await $operations.FootPrintManager.Enable_Accessorials_ShowAllOperations.isAuthorized()) {
      $grid.filters.show_all_operation_codes.hidden = false;
  }
  else {
      $grid.filters.show_all_operation_codes.hidden = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
