<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="edit" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="create_location" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_location_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.locations_empty_by_count_widget.hidden" class="widget-container">
            <Locations-locations_empty_by_count_widget
            #$widgets_locations_empty_by_count_widget 
              [warehouseId]="$widgets_locations_empty_by_count_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_locations_empty_by_count_widget')"
            >
            </Locations-locations_empty_by_count_widget>
          </div>
          <div *ngIf="!widgets.locations_by_count_widget.hidden" class="widget-container">
            <Locations-locations_by_count_widget
            #$widgets_locations_by_count_widget 
              [warehouseId]="$widgets_locations_by_count_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_locations_by_count_widget')"
            >
            </Locations-locations_by_count_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.locations.hidden" class="tab" data-cy="tab-locations">
              <h2 [className]="tabs.locations.active? 'active': ''" (click)="tabs.locations.activate()">{{tabs.locations.title}}</h2>
            </div>
            <div *ngIf="!tabs.locations_detail.hidden" class="tab" data-cy="tab-locations_detail">
              <h2 [className]="tabs.locations_detail.active? 'active': ''" (click)="tabs.locations_detail.activate()">{{tabs.locations_detail.title}}</h2>
            </div>
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.replenishments.hidden" class="tab" data-cy="tab-replenishments">
              <h2 [className]="tabs.replenishments.active? 'active': ''" (click)="tabs.replenishments.activate()">{{tabs.replenishments.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-locations_navigational_grid *ngIf="tabs.locations.active"
              #$tabs_locations
              [warehouseId]="$tabs_locations_locations_navigational_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_locations')">
              </FootPrintManager-locations_navigational_grid>
              <FootPrintManager-locations_grid *ngIf="tabs.locations_detail.active"
              #$tabs_locations_detail
              [warehouseId]="$tabs_locations_detail_locations_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_locations_detail')">
              </FootPrintManager-locations_grid>
              <FootPrintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [warehouseId]="$tabs_inventory_inventory_location_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_location_grid>
              <FootPrintManager-locations_replenishments_grid *ngIf="tabs.replenishments.active"
              #$tabs_replenishments
              [warehouseIds]="$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds"
              ($refreshEvent)="refresh(false, false, '$tabs_replenishments')">
              </FootPrintManager-locations_replenishments_grid>
              <FootPrintManager-contact_addresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [entity]="$tabs_contacts_contact_addresses_grid_inParams_entity"
              [entityId]="$tabs_contacts_contact_addresses_grid_inParams_entityId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-contact_addresses_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>