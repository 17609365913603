import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_orderful_pretzilla_940_mapService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise<{ orders?: any[] }> 
  {
    let url = `${environment.backendUrl}api/app/functions/orderful_pretzilla_940_map`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

