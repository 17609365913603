<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="Project_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Project_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_Name.displayControl.styles.style"
          [ngClass]="row.cells.Project_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_Owner_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_Owner_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Project_Owner_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_Owner_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_Owner_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_Owner_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_Owner_Name.displayControl.styles.style"
          [ngClass]="row.cells.Project_Owner_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_Owner_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_Owner_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="materials_material_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.materials_material_count.displayControl.styles.style"
          [ngClass]="row.cells.materials_material_count.displayControl.styles.classes"
          [matTooltip]="row.cells.materials_material_count.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.materials_material_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
