<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="AllocatedAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.AllocatedAmount.displayControl.styles.style"
          [ngClass]="row.cells.AllocatedAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.AllocatedAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.AllocatedAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AvailableAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.AvailableAmount.displayControl.styles.style"
          [ngClass]="row.cells.AvailableAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.AvailableAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.AvailableAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="InactiveAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.InactiveAmount.displayControl.styles.style"
          [ngClass]="row.cells.InactiveAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.InactiveAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.InactiveAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Lot_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Lot_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Lot_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Lot_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Lot_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLot_ExpirationDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLot_ExpirationDate.displayControl.styles.style"
          [ngClass]="row.cells.VendorLot_ExpirationDate.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLot_ExpirationDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLot_ExpirationDate.displayControl.text | formatText : row.cells.VendorLot_ExpirationDate.displayControl.formatType : row.cells.VendorLot_ExpirationDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLot_ManufactureDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLot_ManufactureDate.displayControl.styles.style"
          [ngClass]="row.cells.VendorLot_ManufactureDate.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLot_ManufactureDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLot_ManufactureDate.displayControl.text | formatText : row.cells.VendorLot_ManufactureDate.displayControl.formatType : row.cells.VendorLot_ManufactureDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Location_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Location_Name.displayControl.styles.style"
          [ngClass]="row.cells.Location_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Location_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Location_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Warehouse_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Warehouse_Name.displayControl.styles.style"
          [ngClass]="row.cells.Warehouse_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Warehouse_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Warehouse_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Packaging_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Packaging_Name.displayControl.styles.style"
          [ngClass]="row.cells.Packaging_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Packaging_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Packaging_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocate_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.allocate_quantity.displayControl.styles.style"
          [ngClass]="row.cells.allocate_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.allocate_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.allocate_quantity.displayControl.text | formatText : row.cells.allocate_quantity.displayControl.formatType : row.cells.allocate_quantity.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['allocate_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.allocate_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.allocate_quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.allocate_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.allocate_quantity.editControl.styles.style"
            [ngClass]="row.cells.allocate_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.allocate_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
