<div matAutocompleteOrigin
     [ngStyle]="styles?.style"
     [ngClass]="styles?.classes"
     class="datex-selectcontainer {{disabled ? 'readonly' : ''}}"
     #origin="matAutocompleteOrigin"
     data-cy="selectBox">
  <input #trigger
         class="select-input"
         type="text"
         spellcheck="false"
         matInput
         cdkTextareaAutosize
         [placeholder]="!hasSelectedItems ? placeholder : ''"
         [matAutocomplete]="auto1"
         [matAutocompleteConnectedTo]="origin"
         matAutocompleteDisabled="true"
         [disabled]="disabled"
         (keyup)="onKeyUp($event)"
         (keydown)="onKeyDown($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)"
         (click)="onClick($event)"
         panelClass="dropdown-below"
         data-cy="selectBox-input"
         [matTooltip]="tooltip"
         matTooltipClass="datex-control-tooltip">
  <div [hidden]="!hasSelectedItems || hasOpenedPanel || disabled"
       class="select-button clear"
       (click)="clearSelectedItem()"
       data-cy="selectBox-button-clear">
    <i class="icon icon-ic_fluent_dismiss_20_regular select-button-icon"></i>
  </div>
  <div [hidden]="disabled"
       (click)="togglePanel($event)"
       class="select-button"
       data-cy="selectBox-button-toggle">
    <i class="icon icon-ic_fluent_chevron_down_20_regular select-button-icon"></i>
  </div>
</div>
<mat-autocomplete #auto1="matAutocomplete"
                  (opened)="opened()"
                  (closed)="closed()"
                  [displayWith]="displayFn.bind(this)"
                  classList="datex-dropdownpanel"
                  class="datex-dropdownpanel">
  <ng-container *ngFor="let item of filteredOptions">
    <mat-option [value]="item"
                [disabled]="item.disabled"
                [matTooltip]="item?.name"
                matTooltipClass="datex-control-tooltip"
                (click)="optionClicked($event, item)"
                class="mat-option-multiple datex-dropdownoption"
                data-cy="selectBox-option">
      <div>
        <mat-pseudo-checkbox *ngIf="!item.disabled"
                             class="mat-option-pseudo-checkbox"
                             [state]="item.selected ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
        {{item ? item.name : undefined}}
      </div>
    </mat-option>
  </ng-container>
  <mat-option *ngIf="newItemLabel"
              (click)="onNewItemClicked()"
              class="dropdown-action"
              data-cy="selectBox-option-newItem">
    {{newItemLabel}}
  </mat-option>
</mat-autocomplete>