import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { DockAppointments_areas_dd_singleComponent } from './DockAppointments.areas_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_areas_dd_singleComponent),
  ],
  selector: 'FootPrintManager-dock_door_editor',
  templateUrl: './FootPrintManager.dock_door_editor.component.html'
})
export class FootPrintManager_dock_door_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { locationId: number } = { locationId: null };
  //#region Inputs
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, EligibleForAllocation?: boolean, IsPrimaryPick?: boolean, Name?: string, ParentId?: number, ShortName?: string, StatusId?: number, WarehouseId?: number, Status?: { Name?: string }, ChildLocationContainers?: { Id?: number, IsLoose?: boolean, Name?: string }[], ParentLocationContainer?: { Id?: number, Name?: string, TypeId?: number } };

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    short_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    area: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    inbound_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    outbound_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'ms-Icon ms-Icon--Completed12', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'ms-Icon ms-Icon--Cancel', null)
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Name', false),
    short_name: new FieldModel(new TextBoxModel(this.formGroup.controls['short_name'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Location', true),
    area: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['area'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Area', false),
    active: new FieldModel(new CheckBoxModel(this.formGroup.controls['active'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Enabled', false),
    inbound_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['inbound_only'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Inbound only', false),
    outbound_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['outbound_only'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Outbound only', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};


  //#region fields inParams
  cacheValueFor_$fields_area_selector_inParams_warehouseIds: number[];
  get $fields_area_selector_inParams_warehouseIds(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = [$editor.entity.WarehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_area_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_area_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_area_selector_inParams_warehouseIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    short_name: this.fields.short_name.control.valueChanges
    ,
    area: this.fields.area.control.valueChanges
    ,
    active: this.fields.active.control.valueChanges
    ,
    inbound_only: this.fields.inbound_only.control.valueChanges
    ,
    outbound_only: this.fields.outbound_only.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Dock Door';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      locationId:  $editor.inParams.locationId 
    };

    const data = await this.datasources.DockAppointments.ds_dock_door_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.short_name.control as TextBoxModel).reset($editor.entity.ChildLocationContainers[0]?.Name);
    (this.fields.active.control as CheckBoxModel).reset(($editor.entity.StatusId == 1) ? true : false);
    (this.fields.inbound_only.control as CheckBoxModel).reset($editor.entity.EligibleForAllocation);
    (this.fields.outbound_only.control as CheckBoxModel).reset($editor.entity.IsPrimaryPick);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .short_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .area
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .active
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .inbound_only
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_inbound_only_changed();
      });
    this.$formGroupFieldValidationObservables
      .outbound_only
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_outbound_only_changed();
      });
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $editor.fields.name.control.value,
      $editor.fields.short_name.control.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
      $shell.DockAppointments.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  else {
      try {
  
          if ($editor.fields.name.control.isChanged) {
              const locations = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
                  warehouseId: $editor.entity.WarehouseId,
                  name: $editor.fields.name.control.value
              })).result;
              if ($utils.isDefined(locations)) {
                  throw new Error('Duplicate dock door found, please select a different name.');
              }
          }
          if ($editor.fields.short_name.control.isChanged) {
              const locations = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
                  warehouseId: $editor.entity.WarehouseId,
                  name: $editor.fields.short_name.control.value
              })).result;
              if ($utils.isDefined(locations)) {
                  throw new Error('Duplicate dock door found, please select a different short name.');
              }
          }
  
  
          // Construct payload with any values that have changed
          const payload: any = {};
          const childPayload: any = {};
  
          //  Properties
          if ($editor.fields.name.control.isChanged) {
              payload.Name = $editor.fields.name.control.value;
  
          }
  
          if ($editor.fields.short_name.control.isChanged) {
              childPayload.Name = $editor.fields.short_name.control.value;
  
          }
          if ($editor.fields.active.control.isChanged) {
              payload.StatusId = ($editor.fields.active.control.value == true) ? 1 : 2; // Set to active if true else inactive
  
  
          }
          if ($editor.fields.inbound_only.control.isChanged) {
              payload.EligibleForAllocation = $editor.fields.inbound_only.control.value;
          }
          if ($editor.fields.outbound_only.control.isChanged) {
              payload.IsPrimaryPick = $editor.fields.outbound_only.control.value;
          }
          if ($editor.fields.area.control.isChanged) {
              payload.ParentId = $editor.fields.area.control.value;
          }
  
          // Make API call
  
          (await $flows.Utilities.crud_update_flow({
              entitySet: 'LocationContainers',
              id: $editor.entity.Id,
              entity: payload
          }));
  
          if ($utils.isDefined($editor.entity.ChildLocationContainers[0]?.Id)) {
              (await $flows.Utilities.crud_update_flow({
                  entitySet: 'LocationContainers',
                  id: $editor.entity.ChildLocationContainers[0].Id,
                  entity: childPayload
              }));
          }
          // Create missing child inventory location
          else {
              // Create child inventory location
  
              const nextChildId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LocationContainer' })).nextId;
              const childPayload = {
                  "Id": nextChildId,
                  "WarehouseId": $editor.entity.WarehouseId,
                  "ParentId": $editor.entity.Id,
                  "StatusId": 1,
                  "Depth": 1,
                  "Path": '/',
                  "Name": $editor.fields.short_name.control.value,
                  "TypeId": 3,
                  "IsLoose": false,
                  "EligibleForAllocation": false,
                  "IsPrimaryPick": false,
                  "Enabled": true,
                  // Default values
                  "AllowInventoryIfNotActive": true,
                  "ManufacturingLineFlag": false,
                  "RestrictToReplenishmentMaterials": false,
                  "PurgeAddedInventory": false,
                  "SkipCycleCount": false,
                  "EligibleForManufacturingFeedback": false,
                  "IsManufacturingStagingLocation": false,
                  "AllowMoveTaskCreation": false,
                  "DisableNonPreferredLocationAlertCreation": false,
                  "SkipValidationRules": true,
                  "BreakInventoryToBasePackaging": false,
                  "ConfirmInventoryDeletion": false,
                  "EligibleForScaleTicket": false,
                  "SkipAfterPickingCountBack": false,
                  "AutoSelectCountOnCountBack": false,
                  "MobileForceUserCountBack": false,
                  "IsPackageVerification": false,
                  "IsDynamicPrimaryPick": false
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'LocationContainers', entity: childPayload });
  
  
          }
  
          $editor.outParams.confirm = true;
          $editor.close();
  
  
  
  
      }
      catch (error) {
          $shell.DockAppointments.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  }
  on_inbound_only_changed(event = null) {
    return this.on_inbound_only_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inbound_only_changedInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($editor.fields.inbound_only.control.value) {
      $editor.fields.outbound_only.control.value = false
  }
  
  }
  on_outbound_only_changed(event = null) {
    return this.on_outbound_only_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_outbound_only_changedInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
   if ( $editor.fields.outbound_only.control.value ){
      $editor.fields.inbound_only.control.value = false;
   }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_dock_door_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const parentType = $editor.entity.ParentLocationContainer?.TypeId
  
  // Only display value if the parent is of type area
  if ($utils.isDefined(parentType) && parentType == 2){
      $editor.fields.area.control.displayText = $editor.entity.ParentLocationContainer.Name;
      $editor.fields.area.control.value = $editor.entity.ParentId;
  }
  }
  //#endregion private flows
}
