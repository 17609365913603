import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



import { Notifications_alerts_filters_gridComponent } from './Notifications.alerts_filters_grid.component';

interface INotifications_alerts_gridComponentEntity {
alert_id?: string, application_name?: string, alert_name?: string, level?: number, enabled?: boolean, to?: string, cc?: string, bcc?: string, subject?: string, pdf?: boolean, created_on?: string, created_by?: string, modified_on?: string, modified_by?: string}

interface INotifications_alerts_gridComponentInParams {
  application_name?: string}


class Notifications_alerts_gridComponentRowModel extends GridRowModel {
  grid: Notifications_alerts_gridComponent;
  entity: INotifications_alerts_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    application_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    alert_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    level_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    enabled_display: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    enabled_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    to_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    cc_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    bcc_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    subject_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    pdf_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pdf_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    application_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['application_name_edit'] as DatexFormControl, null, false, '', null)
      ),
    alert_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['alert_name_edit'] as DatexFormControl, null, false, '', null)
      ),
    level: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['level_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    enabled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['enabled_display'] as DatexFormControl, null, true, '', null)
,
      new CheckBoxModel(this.formGroup.controls['enabled_edit'] as DatexFormControl, null, false, '', null)
      ),
    to: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['to_edit'] as DatexFormControl, null, false, '', null)
      ),
    cc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['cc_edit'] as DatexFormControl, null, false, '', null)
      ),
    bcc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['bcc_edit'] as DatexFormControl, null, false, '', null)
      ),
    subject: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['subject_edit'] as DatexFormControl, null, false, '', null)
      ),
    pdf: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['pdf_display'] as DatexFormControl, null, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['pdf_edit'] as DatexFormControl, null, false, '', null)
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    modified_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    modified_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_Notifications_alerts_filters_grid_inParams_application_name(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.application_name;
    
    return expr;
  }


  get $rowExpand_Notifications_alerts_filters_grid_inParams_alert_id(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.alert_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['enabled_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_enable();
      });
  }

  async $initializeExisting(grid: Notifications_alerts_gridComponent, entity: INotifications_alerts_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.alert_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_alerts_gridComponent, entity?: INotifications_alerts_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.alert_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.alert_id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.application_name ,
      alert_name:  null ,
      level:  null ,
      enabled:  null ,
    };
    const data = await this.datasources.Notifications.ds_storage_alerts.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.application_name.displayControl as TextModel).text = $row.entity.application_name;
    (this.cells.application_name.editControl as TextBoxModel).reset($row.entity.application_name);
    (this.cells.alert_name.displayControl as TextModel).text = $row.entity.alert_name;
    (this.cells.alert_name.editControl as TextBoxModel).reset($row.entity.alert_name);
    (this.cells.level.displayControl as TextModel).text = $row.entity.level?.toString();
    (this.cells.level.editControl as NumberBoxModel).reset($row.entity.level);
    (this.cells.enabled.displayControl as CheckBoxModel).reset($row.entity.enabled);
    (this.cells.enabled.editControl as CheckBoxModel).reset($row.entity.enabled);
    (this.cells.to.displayControl as TextModel).text = $row.entity.to;
    (this.cells.to.editControl as TextBoxModel).reset($row.entity.to);
    (this.cells.cc.displayControl as TextModel).text = $row.entity.cc;
    (this.cells.cc.editControl as TextBoxModel).reset($row.entity.cc);
    (this.cells.bcc.displayControl as TextModel).text = $row.entity.bcc;
    (this.cells.bcc.editControl as TextBoxModel).reset($row.entity.bcc);
    (this.cells.subject.displayControl as TextModel).text = $row.entity.subject;
    (this.cells.subject.editControl as TextBoxModel).reset($row.entity.subject);
    (this.cells.pdf.displayControl as CheckBoxModel).reset($row.entity.pdf);
    (this.cells.pdf.editControl as CheckBoxModel).reset($row.entity.pdf);
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.created_on?.toString();
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.modified_on.displayControl as TextModel).text = $row.entity.modified_on?.toString();
    (this.cells.modified_by.displayControl as TextModel).text = $row.entity.modified_by;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_alerts_gridComponentRowModel,
  $grid: Notifications_alerts_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_save_existing_row');
  //O.Arias - 05/21/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.application_name.editControl.value) &&
      $utils.isDefined($row.cells.alert_name.editControl.value) &&
      $utils.isDefined($row.cells.subject.editControl.value) &&
      $utils.isDefined($row.cells.to.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Notifications.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  if (
      $row.cells.alert_name.editControl.isChanged ||
      $row.cells.bcc.editControl.isChanged ||
      $row.cells.cc.editControl.isChanged ||
      $row.cells.enabled.editControl.isChanged ||
      $row.cells.pdf.editControl.isChanged ||
      $row.cells.application_name.editControl.isChanged ||
      $row.cells.level.editControl.isChanged ||
      $row.cells.subject.editControl.isChanged ||
      $row.cells.to.editControl.isChanged
  ) {
  
      let alert_id: string = $row.entity?.alert_id ?? "";
      let application_name: string = $grid.inParams.application_name ?? "";
  
      if (application_name.toUpperCase() === "NULL") {
          application_name = "";
      };
  
      if (application_name === "") {
          application_name = $row.cells.application_name.editControl.value ?? "";
      };
  
      try {
  
          if (alert_id === "" && application_name === "") {
              throw new Error("You need to specify the integration to insert an alert!")
          };
  
          if (($row.cells.level.editControl.value ?? 0) < 3) {
              throw new Error("To prevent spam, the log level cannot be lesser than three (3)!")
          };
  
          if (($row.cells.alert_name.editControl.value ?? "") === "") {
              throw new Error("The alert name cannot be blank!")
          };
  
          if (($row.cells.subject.editControl.value ?? "") === "") {
              throw new Error("The subject cannot be blank!")
          };
  
          if (($row.cells.to.editControl.value ?? "") === "") {
              throw new Error("The to email addresses cannot be blank!")
          };
  
          let action: string = "Update";
  
          if (alert_id === "") {
              action = "Write";
          };
  
          let to: string = $row.cells.to.editControl.value ?? "";
          let cc: string = $row.cells.cc.editControl.value ?? "";
          let bcc: string = $row.cells.bcc.editControl.value ?? "";
  
          if (to !== "") {
              to = split_emails(to).join(';');
          };
  
          if (cc !== "") {
              cc = split_emails(cc).join(';');
          };
  
          if (bcc !== "") {
              bcc = split_emails(bcc).join(';');
          };
  
          await $flows.Notifications.storage_alerts({
              action: action,
              payload: {
                  alert_id: alert_id,
                  to: to,
                  cc: cc,
                  bcc: bcc,
                  alert_name: $row.cells.alert_name.editControl.value,
                  level: $row.cells.level.editControl.value,
                  subject: $row.cells.subject.editControl.value,
                  enabled: $row.cells.enabled.editControl.value,
                  pdf: $row.cells.pdf.editControl.value
              },
              application_name: application_name
          });
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.Notifications.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  
  function split_emails(emails: string) {
      const split_on: string[] = [';', ',', '"', '<', '>', ' ']
      let split_items: string[] = [emails];
      let split_final: string[] = [];
  
      for (let split of split_on) {
          for (let item of split_items) {
              split_final = split_final.concat(item.split(split));
          }
          split_items = JSON.parse(JSON.stringify(split_final));
          split_final = [];
      };
  
      split_final = split_items;
      split_final = split_final.filter(e => is_valid_email(e)); //Valid
      split_final = split_final.filter((value, index, array) => array.indexOf(value) === index); //Distinct
      return split_final;
  };
  
  function is_valid_email(email: string) {
      const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return email_regex.test(email);
  };
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Notifications_alerts_gridComponentRowModel,
  $grid: Notifications_alerts_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_row_data_loaded');
  //O.Arias - 3/15/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      };
      if ($utils.isDefined($row.entity.modified_on)) {
          $row.cells.modified_on.displayControl.text = $utils.date.format($row.entity.modified_on, format);
      };
  };
  }
  on_enable(event = null) {
    return this.on_enableInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_enableInternal(
    $row: Notifications_alerts_gridComponentRowModel,
  $grid: Notifications_alerts_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_enable');
  //O.Arias - 05/21/2024
  
  if ($row.cells.enabled.editControl.isChanged) {
  
      let alert_id: string = $row.entity?.alert_id ?? "";
  
      let application_name: string = $grid.inParams.application_name ?? "";
  
      if (application_name.toUpperCase() === "NULL") {
          application_name = "";
      };
  
      if (application_name === "") {
          application_name = $row.cells.application_name.editControl.value ?? "";
      };
  
      if (alert_id !== "" && application_name !== "") {
  
          try {
  
              let action: string = "Update";
  
              await $flows.Notifications.storage_alerts({
                  action: action,
                  payload: {
                      alert_id: alert_id,
                      enabled: $row.cells.enabled.editControl.value
                  },
                  application_name: application_name
              });
  
          } catch (error) {
              $shell.Notifications.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          };
      };
  
  };
  }
  on_pdf(event = null) {
    return this.on_pdfInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pdfInternal(
    $row: Notifications_alerts_gridComponentRowModel,
  $grid: Notifications_alerts_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_pdf');
  //O.Arias - 05/21/2024
  
  if ($row.cells.pdf.editControl.isChanged) {
  
      let alert_id: string = $row.entity?.alert_id ?? "";
  
      let application_name: string = $grid.inParams.application_name ?? "";
  
      if (application_name.toUpperCase() === "NULL") {
          application_name = "";
      };
  
      if (application_name === "") {
          application_name = $row.cells.application_name.editControl.value ?? "";
      };
  
      if (alert_id !== "" && application_name !== "") {
  
          try {
  
              let action: string = "Update";
  
              await $flows.Notifications.storage_alerts({
                  action: action,
                  payload: {
                      alert_id: alert_id,
                      pdf: $row.cells.pdf.editControl.value
                  },
                  application_name: application_name
              });
  
          } catch (error) {
              $shell.Notifications.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          };
      };
  
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_alerts_filters_gridComponent)
  ],
  selector: 'Notifications-alerts_grid',
  templateUrl: './Notifications.alerts_grid.component.html'
})
export class Notifications_alerts_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_alerts_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['set-width-table']);

  fullTextSearch: string;

  inParams: INotifications_alerts_gridComponentInParams = { application_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     application_name: new GridHeaderModel(new HeaderStyles(null, null), 'Application name', true, false, 200),       alert_name: new GridHeaderModel(new HeaderStyles(null, null), 'Alert name', true, false, 200),       level: new GridHeaderModel(new HeaderStyles(null, null), 'Level', true, false, 100),       enabled: new GridHeaderModel(new HeaderStyles(null, null), 'Enabled', true, false, 100),       to: new GridHeaderModel(new HeaderStyles(null, null), 'TO', true, false, 300),       cc: new GridHeaderModel(new HeaderStyles(null, null), 'CC', false, false, 300),       bcc: new GridHeaderModel(new HeaderStyles(null, null), 'BCC', false, false, 300),       subject: new GridHeaderModel(new HeaderStyles(null, null), 'Subject', true, false, 300),       pdf: new GridHeaderModel(new HeaderStyles(null, null), 'PDF', false, false, 100),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, 150),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, 150),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, 150),       modified_by: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, 150),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_alerts_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('application_name') set $inParams_application_name(value: any) {
    this.inParams['application_name'] = value;
  }
  get $inParams_application_name(): any {
    return this.inParams['application_name'] ;
  }

  topToolbar = {
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Alerts grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.application_name ,
      alert_name:  null ,
      level:  null ,
      enabled:  null ,
    };
    try {
    const data = await this.datasources.Notifications.ds_storage_alerts.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_alerts_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: INotifications_alerts_gridComponentEntity) {
    const row = new Notifications_alerts_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_alerts_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_init');
  //O.Arias - 03/18/2024
  
  $grid.title = 'Alerts Grid'
  
  let application_name: string = $grid.inParams.application_name ?? "";
  
  if (application_name.toUpperCase() === "NULL") {
      application_name = "";
  };
  
  if (application_name !== "") {
      $grid.headers.application_name.required = false;
      $grid.headers.application_name.hidden = true;
  };
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Notifications_alerts_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_delete_clicked');
  //O.Arias - 05/21/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Notifications.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Notifications.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  let filters = (await $flows.Notifications.storage_alerts_filters({ action: "Read", payload: { alert_id: entity.alert_id }, application_name: entity.application_name })).payload;
  
                  if (filters.length > 0) {
  
                      for (let filter of filters) {
                          await filter_delete({
                              "id": filter.id
                          }, filter.application_name);
                      };
                  };
  
                  await alert_delete({
                      alert_id: entity.alert_id
                  }, entity.application_name);
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.alert_id.toString())
              } else {
                  errors.push({
                      id: row.entity.alert_id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.Notifications.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
      };
  };
  
  $grid.refresh();
  
  const alert_schema = (await $flows.Notifications.storage_alerts({ action: "", application_name: "" })).payload;
  async function alert_delete(input: typeof alert_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts({ action: "Delete", payload: input, application_name: application_name });
  };
  
  const filter_schema = (await $flows.Notifications.storage_alerts_filters({ action: "", application_name: "" })).payload;
  async function filter_delete(input: typeof filter_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts_filters({ action: "Delete", payload: input, application_name: application_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_alerts_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_row_selected');
  //O.Arias - 03/18/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: Notifications_alerts_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_grid.on_export_flow');
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.alert_id);
          };
      };
  
      let notes = $grid.fullTextSearch;
      let application_name = $grid.inParams.application_name;
  
      let data = (await $datasources.Notifications.ds_storage_alerts.getList({
          application_name: application_name,
          fullTextSearch: notes
      }));
  
      if (data.totalCount > 0) {
  
          let result = data.result;
  
          if (ids.length > 0) {
              result = result.filter(r => ids.indexOf(r.alert_id) >= 0);
          };
  
          let alerts = result.map(r => {
              return {
                  "Application Name": r.application_name,
                  "Alert Name": r.alert_name,
                  "Level": r.level,
                  "Enabled": r.enabled,
                  "TO": r.to,
                  "CC": r.cc,
                  "BCC": r.bcc,
                  "Subject": r.subject,
                  "Created On (UTC)": r.created_on,
                  "Created By": r.created_by,
                  "Modified On (UTC)": r.modified_on,
                  "Modified By": r.modified_by
              }
          });
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(alerts);
          $utils.excel.book_append_sheet(wb, ws, "Alerts");
  
          let filters = (await $datasources.Notifications.ds_storage_alerts_filters.getList({
              application_name: application_name,
              fullTextSearch: notes
          }));
  
          if (filters.totalCount > 0) {
  
              let filert_result = filters.result;
  
              filert_result = filert_result.filter(f => result.map(r => r.alert_id).indexOf(f.alert_id) >= 0);
  
              let alert_filters = filert_result.map(f => {
                  return {
                      "Integration Name": f.application_name,
                      "Alert Name": result.find(r => r.alert_id === f.alert_id).alert_name,
                      "Name": f.filter_name,
                      "Value": f.filter_value
                  }
              });
  
              let af = $utils.excel.json_to_sheet(alert_filters);
              $utils.excel.book_append_sheet(wb, af, "Filters");
  
          };
  
      } else {
          $shell.Notifications.openInfoDialog('Error', 'The parameters did not return any records to export.')
      };
  } else {
      $shell.Notifications.openInfoDialog('Error', 'There are no records to export!')
  };
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'Notifications_alerts_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
