import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { LaborManagement_user_list_dd_singleComponent } from './LaborManagement.user_list_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LaborManagement_user_list_dd_singleComponent),
  ],
  selector: 'FootPrintManager-task_assignment_editor',
  templateUrl: './FootPrintManager.task_assignment_editor.component.html'
})
export class FootPrintManager_task_assignment_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { taskId: number } = { taskId: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { isConfirmed?: boolean } = { isConfirmed: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, Priority?: number, Status?: { Id?: number, Name?: string }, OperationCode?: { Id?: number, Name?: string, WorkClassOperationCodes?: { Id?: number, WorkClass?: { Name?: string } }[] }, Material?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Order?: { LookupCode?: string }, PickSlip?: { LookupCode?: string, Wave?: { Id?: number } }, ExpectedPackagedPack?: { Name?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, TaskAssignments?: { StatusId?: number, UserId?: string }[] };

  formGroup: FormGroup = new FormGroup({
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    assigned_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    operation_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    eligible_work_classes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    source_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    wave: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    )
  };

  fields = {
    priority: new FieldModel(new NumberBoxModel(this.formGroup.controls['priority'] as DatexFormControl, null, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Priority', false),
    assigned_to: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['assigned_to'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Assigned to', false),
    status: new FieldModel(new TextBoxModel(this.formGroup.controls['status'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Status', false),
    operation_code: new FieldModel(new TextBoxModel(this.formGroup.controls['operation_code'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Operation code', false),
    eligible_work_classes: new FieldModel(new TextBoxModel(this.formGroup.controls['eligible_work_classes'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Eligible work classes', false),
    created_on: new FieldModel(new TextBoxModel(this.formGroup.controls['created_on'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Created on', false),
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Material', false),
    vendor_lot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_lot'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false),
    lot: new FieldModel(new TextBoxModel(this.formGroup.controls['lot'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Lot', false),
    amount: new FieldModel(new TextBoxModel(this.formGroup.controls['amount'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Amount', false),
    source_location: new FieldModel(new TextBoxModel(this.formGroup.controls['source_location'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Location', false),
    license_plate: new FieldModel(new TextBoxModel(this.formGroup.controls['license_plate'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'License plate', false),
    order: new FieldModel(new TextBoxModel(this.formGroup.controls['order'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Order', false),
    wave: new FieldModel(new TextBoxModel(this.formGroup.controls['wave'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Wave', false),
  };

  fieldsets = {
  general: new FieldsetModel('General', true, false, true),
  details: new FieldsetModel('Details', false, false, true),
};


  //#region fields inParams
  cacheValueFor_$fields_assigned_to_selector_inParams_restrictToOperationCodes: number[];
  get $fields_assigned_to_selector_inParams_restrictToOperationCodes(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = [$editor.entity.OperationCode.Id];
    
    if(!isEqual(this.cacheValueFor_$fields_assigned_to_selector_inParams_restrictToOperationCodes, expr)) {
      this.cacheValueFor_$fields_assigned_to_selector_inParams_restrictToOperationCodes = expr;
    }
    return this.cacheValueFor_$fields_assigned_to_selector_inParams_restrictToOperationCodes;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    priority: this.fields.priority.control.valueChanges
    ,
    assigned_to: this.fields.assigned_to.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    operation_code: this.fields.operation_code.control.valueChanges
    ,
    eligible_work_classes: this.fields.eligible_work_classes.control.valueChanges
    ,
    created_on: this.fields.created_on.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    amount: this.fields.amount.control.valueChanges
    ,
    source_location: this.fields.source_location.control.valueChanges
    ,
    license_plate: this.fields.license_plate.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    wave: this.fields.wave.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Task';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      taskId:  $editor.inParams.taskId 
    };

    const data = await this.datasources.LaborManagement.ds_task_assignment_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.priority.control as NumberBoxModel).reset($editor.entity.Priority);
    (this.fields.assigned_to.control as SelectBoxModel).reset($editor.entity.TaskAssignments.find(ta => ta.StatusId === 1 || ta.StatusId === 2)?.UserId);
    (this.fields.status.control as TextBoxModel).reset($editor.entity.Status.Name);
    (this.fields.operation_code.control as TextBoxModel).reset($editor.entity.OperationCode.Name);
    (this.fields.eligible_work_classes.control as TextBoxModel).reset($editor.entity.OperationCode.WorkClassOperationCodes?.map(wcoc => wcoc?.WorkClass?.Name).join(', '));
    (this.fields.created_on.control as TextBoxModel).reset($editor.entity.CreatedSysDateTime);
    (this.fields.material.control as TextBoxModel).reset($editor.entity.Material?.LookupCode);
    (this.fields.vendor_lot.control as TextBoxModel).reset($editor.entity.VendorLot?.LookupCode);
    (this.fields.lot.control as TextBoxModel).reset($editor.entity.Lot?.LookupCode);
    (this.fields.amount.control as TextBoxModel).reset(`${$editor.entity.ExpectedPackagedAmount} ${$editor.entity.ExpectedPackagedPack?.Name}`);
    (this.fields.source_location.control as TextBoxModel).reset($editor.entity.ExpectedSourceLocation?.Name);
    (this.fields.license_plate.control as TextBoxModel).reset($editor.entity.ExpectedSourceLicensePlate?.LookupCode);
    (this.fields.order.control as TextBoxModel).reset($editor.entity.Order?.LookupCode);
    (this.fields.wave.control as TextBoxModel).reset($editor.entity.PickSlip?.Wave?.Id?.toString());

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .assigned_to
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .operation_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .eligible_work_classes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .source_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .license_plate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .wave
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_task_assignment_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.isConfirmed = false;
  
  $editor.fields.status.hidden = true;
  $editor.fields.operation_code.hidden = true;
  $editor.fields.eligible_work_classes.hidden = true;
  $editor.fields.created_on.hidden = true;
  $editor.fields.material.hidden = true;
  $editor.fields.vendor_lot.hidden = true;
  $editor.fields.lot.hidden = true;
  $editor.fields.amount.hidden = true;
  $editor.fields.source_location.hidden = true;
  $editor.fields.license_plate.hidden = true;
  $editor.fields.order.hidden = true;
  $editor.fields.wave.hidden = true;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_task_assignment_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // General
  if ($utils.isDefined($editor.entity.Status)) {
      $editor.fields.status.hidden = false;
  }
  if ($utils.isDefined($editor.entity.OperationCode?.Name)) {
      $editor.fields.operation_code.hidden = false;
  }
  if ($utils.isDefined($editor.entity.OperationCode?.WorkClassOperationCodes)) {
      $editor.fields.eligible_work_classes.hidden = false;
  }
  if ($utils.isDefined($editor.entity.CreatedSysDateTime)) {
      $editor.fields.created_on.hidden = false;
  }
  
  // Details
  let hasDetails = false;
  
  if ($utils.isDefined($editor.entity.Material)) {
      $editor.fields.material.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.VendorLot)) {
      $editor.fields.vendor_lot.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.Lot)) {
      $editor.fields.lot.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.ExpectedPackagedAmount) && $utils.isDefined($editor.entity.ExpectedPackagedPack?.Name)) {
      $editor.fields.amount.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.ExpectedSourceLocation)) {
      $editor.fields.source_location.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.ExpectedSourceLicensePlate)) {
      $editor.fields.license_plate.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.Order)) {
      $editor.fields.order.hidden = false;
      hasDetails = true;
  }
  if ($utils.isDefined($editor.entity.PickSlip?.Wave)) {
      $editor.fields.wave.hidden = false;
      hasDetails = true;
  }
  
  if (!hasDetails) { $editor.fieldsets.details.hidden = true; }
  
  // Format datetime
  $editor.fields.created_on.control.value = $utils.date.format($editor.entity.CreatedSysDateTime, `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '12 HOUR' ? 'LT' : 'H:MM'}`);
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_task_assignment_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      $editor.outParams.isConfirmed = true;
      
      if ($editor.fields.priority.control.isChanged) {
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: $editor.entity.Id,
              entity: {
                  Priority: $editor.fields.priority.control.value
              }
          });
      }
  
      if ($editor.fields.assigned_to.control.isChanged) {
          if ($utils.isDefined($editor.fields.assigned_to.control.value)) {
              await $flows.LaborManagement.assign_user_to_task_flow({ taskId: $editor.entity.Id, userId: $editor.fields.assigned_to.control.value });
          }
          else {
              await $flows.LaborManagement.unassign_task_flow({ taskId: $editor.entity.Id });
          }
      }
  
      $editor.close();
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.LaborManagement.openErrorDialog('Error saving task', targetError.message);
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_task_assignment_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.close();
  }
  //#endregion private flows
}
