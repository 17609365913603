import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Orderful.frontend.types'

@Injectable({ providedIn: 'root' })
export class Orderful_orderful_query_pollerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { output_details?: boolean, insert_transaction?: any }): Promise<{ success?: boolean, count?: number, messages?: string[], options?: any, url?: string, transactions?: any[], orders?: { id?: string, sender?: string, receiver?: string, transaction_type?: string, business_number?: string, validation_status?: string, delivery_status?: string, acknowledgement_status?: string, created_at?: string, message?: any }[] }> 
  {
    let url = `${environment.backendUrl}api/Orderful/functions/orderful_query_poller`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

