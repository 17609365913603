<signature-pad #signaturePad
               class="draw-box-signature-pad margin-bottom-5"
               [options]="signaturePadOptions"
               (drawEnd)="drawComplete($event)"
               [ariaReadOnly]="true"
               [matTooltip]="tooltip"
               matTooltipClass="datex-control-tooltip"></signature-pad>

<button type="button"
        (click)="clear()"
        [disabled]="isDisabled"
        class="datex-button secondary">Clear</button>