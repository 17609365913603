<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="Edit" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="add_to_wave" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_add_to_wave_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="process_wave" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_process_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="release_wave" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_release_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator2" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="cancel_wave" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_cancel_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="print" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.print_pickslip.readOnly"
                              [ngStyle]="tool.control.buttons.print_pickslip.styles.style"
                              [ngClass]="tool.control.buttons.print_pickslip.styles.classes"
                              [matTooltip]="tool.control.buttons.print_pickslip.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_print_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.print_pickslip.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_pickslip.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.print_pickslip.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.pick_slip_consolidated.readOnly"
                              [ngStyle]="tool.control.buttons.pick_slip_consolidated.styles.style"
                              [ngClass]="tool.control.buttons.pick_slip_consolidated.styles.classes"
                              [matTooltip]="tool.control.buttons.pick_slip_consolidated.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_print_consolidated_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.pick_slip_consolidated.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.pick_slip_consolidated.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.pick_slip_consolidated.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!filtersets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.header.collapsible }">
                    <div *ngIf="!filtersets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.header.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.header.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-is_batch_pick" *ngIf="!filters.is_batch_pick.hidden" 
                                  class="field-container standard {{filters.is_batch_pick.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.is_batch_pick.styles.style"
                                  [ngClass]="filters.is_batch_pick.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.is_batch_pick.label + (filters.is_batch_pick.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.is_batch_pick.label}}<span *ngIf="filters.is_batch_pick.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="is_batch_pick"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.is_batch_pick.control.styles.style"
                                            [ngClass]="filters.is_batch_pick.control.styles.classes"
                                            [matTooltip]="filters.is_batch_pick.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.is_batch_pick.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.is_batch_pick.invalid">
                                <ng-container *ngFor="let error of filters.is_batch_pick.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-staging_location" *ngIf="!filters.staging_location.hidden" 
                                  class="field-container standard {{filters.staging_location.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.staging_location.styles.style"
                                  [ngClass]="filters.staging_location.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.staging_location.label + (filters.staging_location.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.staging_location.label}}<span *ngIf="filters.staging_location.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-locations_dd_single 
                                  data-cy="selector"
                                  [type]="filters.staging_location.control.type"
                                  formControlName="staging_location"
                                  (displayTextChange)="filters.staging_location.control.displayText=$event"
                                  [placeholder]="filters.staging_location.control.placeholder"
                                  [styles]="filters.staging_location.control.styles"
                                  [tooltip]="filters.staging_location.control.tooltip"
                                [warehouseId]="$fields_staging_location_selector_inParams_warehouseId"
                                [typeId]="$fields_staging_location_selector_inParams_typeId"
                              >
                              </Locations-locations_dd_single>
                              <ng-container *ngIf="filters.staging_location.invalid">
                                <ng-container *ngFor="let error of filters.staging_location.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-priority" *ngIf="!filters.priority.hidden" 
                                  class="field-container standard {{filters.priority.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.priority.styles.style"
                                  [ngClass]="filters.priority.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.priority.label + (filters.priority.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.priority.label}}<span *ngIf="filters.priority.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Waves-wave_priorities_dd_single 
                                  data-cy="selector"
                                  [type]="filters.priority.control.type"
                                  formControlName="priority"
                                  (displayTextChange)="filters.priority.control.displayText=$event"
                                  [placeholder]="filters.priority.control.placeholder"
                                  [styles]="filters.priority.control.styles"
                                  [tooltip]="filters.priority.control.tooltip"
                              >
                              </Waves-wave_priorities_dd_single>
                              <ng-container *ngIf="filters.priority.invalid">
                                <ng-container *ngFor="let error of filters.priority.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-notes" *ngIf="!filters.notes.hidden" 
                                  class="field-container full {{filters.notes.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.notes.styles.style"
                                  [ngClass]="filters.notes.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.notes.label + (filters.notes.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.notes.label}}<span *ngIf="filters.notes.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.notes.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.notes.control.placeholder}}"
                                      [ngStyle]="filters.notes.control.styles.style"
                                      [ngClass]="filters.notes.control.styles.classes"
                                      [matTooltip]="filters.notes.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> </textarea>
                              <ng-container *ngIf="filters.notes.invalid">
                                <ng-container *ngFor="let error of filters.notes.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.order_count.hidden" class="widget-container">
            <Waves-order_count_widget
            #$widgets_order_count 
              [waveId]="$widgets_order_count_inParams_waveId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_count')"
            >
            </Waves-order_count_widget>
          </div>
          <div *ngIf="!widgets.pick_tasks_count.hidden" class="widget-container">
            <Waves-pick_tasks_widget
            #$widgets_pick_tasks_count 
              [waveId]="$widgets_pick_tasks_count_inParams_waveId"
            ($refreshEvent)="refresh(false, false, '$widgets_pick_tasks_count')"
            >
            </Waves-pick_tasks_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.assigned_orders.hidden" class="tab" data-cy="tab-assigned_orders">
              <h2 [className]="tabs.assigned_orders.active? 'active': ''" (click)="tabs.assigned_orders.activate()">{{tabs.assigned_orders.title}}</h2>
            </div>
            <div *ngIf="!tabs.manual_allocations.hidden" class="tab" data-cy="tab-manual_allocations">
              <h2 [className]="tabs.manual_allocations.active? 'active': ''" (click)="tabs.manual_allocations.activate()">{{tabs.manual_allocations.title}}</h2>
            </div>
            <div *ngIf="!tabs.pick_slips_grid.hidden" class="tab" data-cy="tab-pick_slips_grid">
              <h2 [className]="tabs.pick_slips_grid.active? 'active': ''" (click)="tabs.pick_slips_grid.activate()">{{tabs.pick_slips_grid.title}}</h2>
            </div>
            <div *ngIf="!tabs.pick_tasks.hidden" class="tab" data-cy="tab-pick_tasks">
              <h2 [className]="tabs.pick_tasks.active? 'active': ''" (click)="tabs.pick_tasks.activate()">{{tabs.pick_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-assigned_orders_on_wave_grid *ngIf="tabs.assigned_orders.active"
              #$tabs_assigned_orders
              [waveId]="$tabs_assigned_orders_assigned_orders_on_wave_grid_inParams_waveId"
              [waveStatusId]="$tabs_assigned_orders_assigned_orders_on_wave_grid_inParams_waveStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_assigned_orders')">
              </FootPrintManager-assigned_orders_on_wave_grid>
              <FootPrintManager-manual_allocations_grid *ngIf="tabs.manual_allocations.active"
              #$tabs_manual_allocations
              [waveId]="$tabs_manual_allocations_manual_allocations_grid_inParams_waveId"
              ($refreshEvent)="refresh(false, false, '$tabs_manual_allocations')">
              </FootPrintManager-manual_allocations_grid>
              <FootPrintManager-pick_slips_grid *ngIf="tabs.pick_slips_grid.active"
              #$tabs_pick_slips_grid
              [waveId]="$tabs_pick_slips_grid_pick_slips_grid_inParams_waveId"
              ($refreshEvent)="refresh(false, false, '$tabs_pick_slips_grid')">
              </FootPrintManager-pick_slips_grid>
              <FootPrintManager-sales_order_picking_grid *ngIf="tabs.pick_tasks.active"
              #$tabs_pick_tasks
              [waveId]="$tabs_pick_tasks_sales_order_picking_grid_inParams_waveId"
              [waveStatusId]="$tabs_pick_tasks_sales_order_picking_grid_inParams_waveStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_pick_tasks')">
              </FootPrintManager-sales_order_picking_grid>
              <FootPrintManager-sales_order_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [waveId]="$tabs_tasks_sales_order_tasks_grid_inParams_waveId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-sales_order_tasks_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>