<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-processing_strategy" *ngIf="!fields.processing_strategy.hidden" 
                            class="field-container double {{fields.processing_strategy.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.processing_strategy.styles.style"
                            [ngClass]="fields.processing_strategy.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.processing_strategy.label + (fields.processing_strategy.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.processing_strategy.label}}<span *ngIf="fields.processing_strategy.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SalesOrders-outbound_processing_strategies_dd_single 
                            data-cy="selector"
                            [type]="fields.processing_strategy.control.type"
                            formControlName="processing_strategy"
                            (displayTextChange)="fields.processing_strategy.control.displayText=$event"
                            [placeholder]="fields.processing_strategy.control.placeholder"
                            [styles]="fields.processing_strategy.control.styles"
                            [tooltip]="fields.processing_strategy.control.tooltip"
                        >
                        </SalesOrders-outbound_processing_strategies_dd_single>
                        <ng-container *ngIf="fields.processing_strategy.invalid">
                          <ng-container *ngFor="let error of fields.processing_strategy.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-hardcoded_processing_strategy" *ngIf="!fields.hardcoded_processing_strategy.hidden" 
                            class="field-container double {{fields.hardcoded_processing_strategy.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.hardcoded_processing_strategy.styles.style"
                            [ngClass]="fields.hardcoded_processing_strategy.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.hardcoded_processing_strategy.label + (fields.hardcoded_processing_strategy.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.hardcoded_processing_strategy.label}}<span *ngIf="fields.hardcoded_processing_strategy.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SalesOrders-outbound_processing_strategies_hardcoded_dd_single 
                            data-cy="selector"
                            [type]="fields.hardcoded_processing_strategy.control.type"
                            formControlName="hardcoded_processing_strategy"
                            (displayTextChange)="fields.hardcoded_processing_strategy.control.displayText=$event"
                            [placeholder]="fields.hardcoded_processing_strategy.control.placeholder"
                            [styles]="fields.hardcoded_processing_strategy.control.styles"
                            [tooltip]="fields.hardcoded_processing_strategy.control.tooltip"
                        >
                        </SalesOrders-outbound_processing_strategies_hardcoded_dd_single>
                        <ng-container *ngIf="fields.hardcoded_processing_strategy.invalid">
                          <ng-container *ngFor="let error of fields.hardcoded_processing_strategy.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cartonization_strategy" *ngIf="!fields.cartonization_strategy.hidden" 
                            class="field-container double {{fields.cartonization_strategy.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.cartonization_strategy.styles.style"
                            [ngClass]="fields.cartonization_strategy.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.cartonization_strategy.label + (fields.cartonization_strategy.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.cartonization_strategy.label}}<span *ngIf="fields.cartonization_strategy.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Cartonization-cartonization_strategies_dd_single 
                            data-cy="selector"
                            [type]="fields.cartonization_strategy.control.type"
                            formControlName="cartonization_strategy"
                            (displayTextChange)="fields.cartonization_strategy.control.displayText=$event"
                            [placeholder]="fields.cartonization_strategy.control.placeholder"
                            [styles]="fields.cartonization_strategy.control.styles"
                            [tooltip]="fields.cartonization_strategy.control.tooltip"
                        >
                        </Cartonization-cartonization_strategies_dd_single>
                        <ng-container *ngIf="fields.cartonization_strategy.invalid">
                          <ng-container *ngFor="let error of fields.cartonization_strategy.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expand_kit" *ngIf="!fields.expand_kit.hidden" 
                            class="field-container full {{fields.expand_kit.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expand_kit.styles.style"
                            [ngClass]="fields.expand_kit.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expand_kit.label + (fields.expand_kit.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expand_kit.label}}<span *ngIf="fields.expand_kit.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="expand_kit"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.expand_kit.control.styles.style"
                                      [ngClass]="fields.expand_kit.control.styles.classes"
                                      [matTooltip]="fields.expand_kit.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.expand_kit.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.expand_kit.invalid">
                          <ng-container *ngFor="let error of fields.expand_kit.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-wave_options"
                     *ngIf="!fieldsets.wave_options.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.wave_options.collapsible }">
                    <div *ngIf="!fieldsets.wave_options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.wave_options.toggle()">
                      <span class="fieldsetsTitle-text">Wave options</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.wave_options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.wave_options.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.wave_options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.wave_options.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_create_wave" *ngIf="!fields.is_create_wave.hidden" 
                            class="field-container full {{fields.is_create_wave.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_create_wave.styles.style"
                            [ngClass]="fields.is_create_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_create_wave.label + (fields.is_create_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_create_wave.label}}<span *ngIf="fields.is_create_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_create_wave"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_create_wave.control.styles.style"
                                      [ngClass]="fields.is_create_wave.control.styles.classes"
                                      [matTooltip]="fields.is_create_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_create_wave.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_create_wave.invalid">
                          <ng-container *ngFor="let error of fields.is_create_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_process_wave" *ngIf="!fields.is_process_wave.hidden" 
                            class="field-container standard {{fields.is_process_wave.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_process_wave.styles.style"
                            [ngClass]="fields.is_process_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_process_wave.label + (fields.is_process_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_process_wave.label}}<span *ngIf="fields.is_process_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="is_process_wave"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.is_process_wave.control.styles.style"
                                      [ngClass]="fields.is_process_wave.control.styles.classes"
                                      [matTooltip]="fields.is_process_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_process_wave.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.is_process_wave.invalid">
                          <ng-container *ngFor="let error of fields.is_process_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_create_manual_allocation_tasks" *ngIf="!fields.is_create_manual_allocation_tasks.hidden" 
                            class="field-container standard {{fields.is_create_manual_allocation_tasks.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_create_manual_allocation_tasks.styles.style"
                            [ngClass]="fields.is_create_manual_allocation_tasks.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_create_manual_allocation_tasks.label + (fields.is_create_manual_allocation_tasks.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_create_manual_allocation_tasks.label}}<span *ngIf="fields.is_create_manual_allocation_tasks.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="is_create_manual_allocation_tasks"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.is_create_manual_allocation_tasks.control.styles.style"
                                      [ngClass]="fields.is_create_manual_allocation_tasks.control.styles.classes"
                                      [matTooltip]="fields.is_create_manual_allocation_tasks.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_create_manual_allocation_tasks.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.is_create_manual_allocation_tasks.invalid">
                          <ng-container *ngFor="let error of fields.is_create_manual_allocation_tasks.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_release_wave" *ngIf="!fields.is_release_wave.hidden" 
                            class="field-container full {{fields.is_release_wave.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_release_wave.styles.style"
                            [ngClass]="fields.is_release_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_release_wave.label + (fields.is_release_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_release_wave.label}}<span *ngIf="fields.is_release_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="is_release_wave"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.is_release_wave.control.styles.style"
                                      [ngClass]="fields.is_release_wave.control.styles.classes"
                                      [matTooltip]="fields.is_release_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_release_wave.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.is_release_wave.invalid">
                          <ng-container *ngFor="let error of fields.is_release_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SalesOrders-wave_priorities_dd_single 
                            data-cy="selector"
                            [type]="fields.priority.control.type"
                            formControlName="priority"
                            (displayTextChange)="fields.priority.control.displayText=$event"
                            [placeholder]="fields.priority.control.placeholder"
                            [styles]="fields.priority.control.styles"
                            [tooltip]="fields.priority.control.tooltip"
                        >
                        </SalesOrders-wave_priorities_dd_single>
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-group_by_wave" *ngIf="!fields.group_by_wave.hidden" 
                            class="field-container standard {{fields.group_by_wave.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.group_by_wave.styles.style"
                            [ngClass]="fields.group_by_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.group_by_wave.label + (fields.group_by_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.group_by_wave.label}}<span *ngIf="fields.group_by_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="group_by_wave"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.group_by_wave.control.styles.style"
                                      [ngClass]="fields.group_by_wave.control.styles.classes"
                                      [matTooltip]="fields.group_by_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.group_by_wave.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.group_by_wave.invalid">
                          <ng-container *ngFor="let error of fields.group_by_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-abort_if_errors" *ngIf="!fields.abort_if_errors.hidden" 
                            class="field-container standard {{fields.abort_if_errors.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.abort_if_errors.styles.style"
                            [ngClass]="fields.abort_if_errors.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.abort_if_errors.label + (fields.abort_if_errors.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.abort_if_errors.label}}<span *ngIf="fields.abort_if_errors.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="abort_if_errors"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.abort_if_errors.control.styles.style"
                                      [ngClass]="fields.abort_if_errors.control.styles.classes"
                                      [matTooltip]="fields.abort_if_errors.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.abort_if_errors.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.abort_if_errors.invalid">
                          <ng-container *ngFor="let error of fields.abort_if_errors.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>