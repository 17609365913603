<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="process" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="complete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="unreceive" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_unreceive_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="appointment" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator2" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="revert" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_revert_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="on_delete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator1" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="attachments" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="on_print" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.receiving_report.readOnly"
                            [ngStyle]="tool.control.buttons.receiving_report.styles.style"
                            [ngClass]="tool.control.buttons.receiving_report.styles.classes"
                            [matTooltip]="tool.control.buttons.receiving_report.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_receiving_report_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.receiving_report.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.receiving_report.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.receiving_report.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.unloading_tally_report.readOnly"
                            [ngStyle]="tool.control.buttons.unloading_tally_report.styles.style"
                            [ngClass]="tool.control.buttons.unloading_tally_report.styles.classes"
                            [matTooltip]="tool.control.buttons.unloading_tally_report.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_unloading_tally_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.unloading_tally_report.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.unloading_tally_report.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.unloading_tally_report.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.purchase_order_report.readOnly"
                            [ngStyle]="tool.control.buttons.purchase_order_report.styles.style"
                            [ngClass]="tool.control.buttons.purchase_order_report.styles.classes"
                            [matTooltip]="tool.control.buttons.purchase_order_report.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_purchase_order_report($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.purchase_order_report.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.purchase_order_report.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.purchase_order_report.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.po_label.readOnly"
                            [ngStyle]="tool.control.buttons.po_label.styles.style"
                            [ngClass]="tool.control.buttons.po_label.styles.classes"
                            [matTooltip]="tool.control.buttons.po_label.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_po_labels_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.po_label.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.po_label.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.po_label.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
            <ng-template toolbarToolDef="options" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.discussions.readOnly"
                            [ngStyle]="tool.control.buttons.discussions.styles.style"
                            [ngClass]="tool.control.buttons.discussions.styles.classes"
                            [matTooltip]="tool.control.buttons.discussions.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_discussions_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.discussions.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.discussions.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.discussions.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.check_out.readOnly"
                            [ngStyle]="tool.control.buttons.check_out.styles.style"
                            [ngClass]="tool.control.buttons.check_out.styles.classes"
                            [matTooltip]="tool.control.buttons.check_out.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_driver_checkout_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.check_out.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.check_out.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.check_out.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.email_requests.readOnly"
                            [ngStyle]="tool.control.buttons.email_requests.styles.style"
                            [ngClass]="tool.control.buttons.email_requests.styles.classes"
                            [matTooltip]="tool.control.buttons.email_requests.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_email_requests_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.email_requests.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.email_requests.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.email_requests.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.pallet_transactions.readOnly"
                            [ngStyle]="tool.control.buttons.pallet_transactions.styles.style"
                            [ngClass]="tool.control.buttons.pallet_transactions.styles.classes"
                            [matTooltip]="tool.control.buttons.pallet_transactions.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_pallet_transactions_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.pallet_transactions.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.pallet_transactions.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.pallet_transactions.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.surveys.readOnly"
                            [ngStyle]="tool.control.buttons.surveys.styles.style"
                            [ngClass]="tool.control.buttons.surveys.styles.classes"
                            [matTooltip]="tool.control.buttons.surveys.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_surveys_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.surveys.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.surveys.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.surveys.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.temperature_readings.readOnly"
                            [ngStyle]="tool.control.buttons.temperature_readings.styles.style"
                            [ngClass]="tool.control.buttons.temperature_readings.styles.classes"
                            [matTooltip]="tool.control.buttons.temperature_readings.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_temperature_readings($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.temperature_readings.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.temperature_readings.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.temperature_readings.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.copy_order.readOnly"
                            [ngStyle]="tool.control.buttons.copy_order.styles.style"
                            [ngClass]="tool.control.buttons.copy_order.styles.classes"
                            [matTooltip]="tool.control.buttons.copy_order.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_copy_order_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.copy_order.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.copy_order.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.copy_order.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Order</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_class.label + (fields.order_class.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_class.label}}<span *ngIf="fields.order_class.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <PurchaseOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                            [tooltip]="fields.order_class.control.tooltip"
                        >
                        </PurchaseOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_status.label + (fields.order_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_status.label}}<span *ngIf="fields.order_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes"
                              [matTooltip]="fields.order_status.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-integration_information" *ngIf="!fields.integration_information.hidden" 
                            class="field-container full {{fields.integration_information.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.integration_information.styles.style"
                            [ngClass]="fields.integration_information.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.integration_information.label + (fields.integration_information.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.integration_information.label}}<span *ngIf="fields.integration_information.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.integration_information.control.readOnly"
                                [ngStyle]="fields.integration_information.control.styles.style"
                                [ngClass]="fields.integration_information.control.styles.classes"
                                [matTooltip]="fields.integration_information.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                        >
                          <div class="button-label">
                            <ng-container *ngIf="fields.integration_information.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.integration_information.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.integration_information.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.integration_information.invalid">
                          <ng-container *ngFor="let error of fields.integration_information.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-ship_from_selection" *ngIf="!fields.ship_from_selection.hidden" 
                            class="field-container standard {{fields.ship_from_selection.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from_selection.styles.style"
                            [ngClass]="fields.ship_from_selection.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_selection.label + (fields.ship_from_selection.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_selection.label}}<span *ngIf="fields.ship_from_selection.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_selection.control.readOnly"
                                [ngStyle]="fields.ship_from_selection.control.styles.style"
                                [ngClass]="fields.ship_from_selection.control.styles.classes"
                                [matTooltip]="fields.ship_from_selection.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_selection.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_selection.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_selection.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_selection.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_selection.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from_clear" *ngIf="!fields.ship_from_clear.hidden" 
                            class="field-container standard {{fields.ship_from_clear.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from_clear.styles.style"
                            [ngClass]="fields.ship_from_clear.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_clear.label + (fields.ship_from_clear.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_clear.label}}<span *ngIf="fields.ship_from_clear.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_clear.control.readOnly"
                                [ngStyle]="fields.ship_from_clear.control.styles.style"
                                [ngClass]="fields.ship_from_clear.control.styles.classes"
                                [matTooltip]="fields.ship_from_clear.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_cleared($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_clear.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_clear.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_clear.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_clear.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_clear.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from" *ngIf="!fields.ship_from.hidden" 
                            class="field-container double {{fields.ship_from.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_from.styles.style"
                            [ngClass]="fields.ship_from.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from.label + (fields.ship_from.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from.label}}<span *ngIf="fields.ship_from.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.ship_from.control.styles.style"
                              [ngClass]="fields.ship_from.control.styles.classes"
                              [matTooltip]="fields.ship_from.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.ship_from.control.text }}</div>
                        <ng-container *ngIf="fields.ship_from.invalid">
                          <ng-container *ngFor="let error of fields.ship_from.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_reference" *ngIf="!fields.vendor_reference.hidden" 
                            class="field-container standard {{fields.vendor_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendor_reference.styles.style"
                            [ngClass]="fields.vendor_reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_reference.label + (fields.vendor_reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_reference.label}}<span *ngIf="fields.vendor_reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendor_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.vendor_reference.control.styles.style"
                                [ngClass]="fields.vendor_reference.control.styles.classes"
                                [matTooltip]="fields.vendor_reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_date.label + (fields.expected_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes"
                            [tooltip]="fields.expected_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier.label + (fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier.label}}<span *ngIf="fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carriers_ownerscarriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                            [tooltip]="fields.carrier.control.tooltip"
                          [ownerId]="$fields_carrier_selector_inParams_ownerId"
                        >
                        </Carriers-carriers_ownerscarriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reference.label + (fields.reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reference.label}}<span *ngIf="fields.reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"
                                [matTooltip]="fields.reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer_number" *ngIf="!fields.trailer_number.hidden" 
                            class="field-container standard {{fields.trailer_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.trailer_number.styles.style"
                            [ngClass]="fields.trailer_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.trailer_number.label + (fields.trailer_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.trailer_number.label}}<span *ngIf="fields.trailer_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="trailer_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.trailer_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer_number.control.placeholder}}"
                                [ngStyle]="fields.trailer_number.control.styles.style"
                                [ngClass]="fields.trailer_number.control.styles.classes"
                                [matTooltip]="fields.trailer_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.trailer_number.invalid">
                          <ng-container *ngFor="let error of fields.trailer_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-seal_number" *ngIf="!fields.seal_number.hidden" 
                            class="field-container standard {{fields.seal_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.seal_number.styles.style"
                            [ngClass]="fields.seal_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.seal_number.label + (fields.seal_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.seal_number.label}}<span *ngIf="fields.seal_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="seal_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.seal_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.seal_number.control.placeholder}}"
                                [ngStyle]="fields.seal_number.control.styles.style"
                                [ngClass]="fields.seal_number.control.styles.classes"
                                [matTooltip]="fields.seal_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.seal_number.invalid">
                          <ng-container *ngFor="let error of fields.seal_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-custom_fields" *ngIf="!fields.custom_fields.hidden" 
                            class="field-container standard {{fields.custom_fields.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.custom_fields.styles.style"
                            [ngClass]="fields.custom_fields.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.custom_fields.label + (fields.custom_fields.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.custom_fields.label}}<span *ngIf="fields.custom_fields.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.custom_fields.control.readOnly"
                                [ngStyle]="fields.custom_fields.control.styles.style"
                                [ngClass]="fields.custom_fields.control.styles.classes"
                                [matTooltip]="fields.custom_fields.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_custom_fields_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.custom_fields.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.custom_fields.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.custom_fields.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.custom_fields.invalid">
                          <ng-container *ngFor="let error of fields.custom_fields.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.orderline_total_amount_widget.hidden" class="widget-container">
            <PurchaseOrders-orderline_total_amount_widget
            #$widgets_orderline_total_amount_widget 
              [orderId]="$widgets_orderline_total_amount_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_amount_widget')"
            >
            </PurchaseOrders-orderline_total_amount_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_received_widget.hidden" class="widget-container">
            <PurchaseOrders-orderline_total_received_widget
            #$widgets_orderline_total_received_widget 
              [orderId]="$widgets_orderline_total_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_received_widget')"
            >
            </PurchaseOrders-orderline_total_received_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_gross_received_widget.hidden" class="widget-container">
            <PurchaseOrders-orderline_total_gross_received_widget
            #$widgets_orderline_total_gross_received_widget 
              [orderId]="$widgets_orderline_total_gross_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_gross_received_widget')"
            >
            </PurchaseOrders-orderline_total_gross_received_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.purchase_order_lines.hidden" class="tab" data-cy="tab-purchase_order_lines">
              <h2 [className]="tabs.purchase_order_lines.active? 'active': ''" (click)="tabs.purchase_order_lines.activate()">{{tabs.purchase_order_lines.title}}</h2>
            </div>
            <div *ngIf="!tabs.receiving_tasks.hidden" class="tab" data-cy="tab-receiving_tasks">
              <h2 [className]="tabs.receiving_tasks.active? 'active': ''" (click)="tabs.receiving_tasks.activate()">{{tabs.receiving_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.received_inventory.hidden" class="tab" data-cy="tab-received_inventory">
              <h2 [className]="tabs.received_inventory.active? 'active': ''" (click)="tabs.received_inventory.activate()">{{tabs.received_inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-purchase_order_lines_grid *ngIf="tabs.purchase_order_lines.active"
              #$tabs_purchase_order_lines
              [orderId]="$tabs_purchase_order_lines_purchase_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_purchase_order_lines_purchase_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_purchase_order_lines_purchase_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_purchase_order_lines_purchase_order_lines_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_purchase_order_lines')">
              </FootPrintManager-purchase_order_lines_grid>
              <FootPrintManager-purchase_order_receiving_grid *ngIf="tabs.receiving_tasks.active"
              #$tabs_receiving_tasks
              [orderId]="$tabs_receiving_tasks_purchase_order_receiving_grid_inParams_orderId"
              [shipmentId]="$tabs_receiving_tasks_purchase_order_receiving_grid_inParams_shipmentId"
              [orderStatusId]="$tabs_receiving_tasks_purchase_order_receiving_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_receiving_tasks')">
              </FootPrintManager-purchase_order_receiving_grid>
              <FootPrintManager-purchase_order_received_inventory_grid *ngIf="tabs.received_inventory.active"
              #$tabs_received_inventory
              [orderId]="$tabs_received_inventory_purchase_order_received_inventory_grid_inParams_orderId"
              [shipmentId]="$tabs_received_inventory_purchase_order_received_inventory_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_received_inventory')">
              </FootPrintManager-purchase_order_received_inventory_grid>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  [shipmentId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_shipmentId"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
              <FootPrintManager-orderaddresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [orderId]="$tabs_contacts_orderaddresses_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-orderaddresses_grid>
              <FootPrintManager-purchase_order_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [orderId]="$tabs_tasks_purchase_order_tasks_grid_inParams_orderId"
              [shipmentId]="$tabs_tasks_purchase_order_tasks_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-purchase_order_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>