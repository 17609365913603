import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-bulk_receiving_row_input_form',
  templateUrl: './FootPrintManager.bulk_receiving_row_input_form.component.html'
})
export class FootPrintManager_bulk_receiving_row_input_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number, packagingId: number } = { materialId: null, packagingId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { palletCount?: number, fullPalletQuantity?: number } = { palletCount: null, fullPalletQuantity: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ palletCount?: number, fullPalletQuantity?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    pallet_count: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    full_pallet_default: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    pallet_count: new FieldModel(new NumberBoxModel(this.formGroup.controls['pallet_count'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Pallet count', true),
    full_pallet_default: new FieldModel(new NumberBoxModel(this.formGroup.controls['full_pallet_default'] as DatexFormControl, null, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Full pallet qty', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    pallet_count: this.fields.pallet_count.control.valueChanges
    ,
    full_pallet_default: this.fields.full_pallet_default.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Bulk Receiving';
  
    const $form = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .pallet_count
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .full_pallet_default
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: FootPrintManager_bulk_receiving_row_input_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $form.outParams.palletCount = $form.fields.pallet_count.control.value;
  $form.outParams.fullPalletQuantity = $form.fields.full_pallet_default.control.value;
  
  // Emit all outparams that have been set
  $form.events.outParamsChange.emit();
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_bulk_receiving_row_input_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const materials = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
      materialId: $form.inParams.materialId,
      packagingId: $form.inParams.packagingId
  })).result;
  
  if ($utils.isDefined(materials)) {
  
      const palletHigh = materials[0]?.PalletHigh;
      const palletTie = materials[0]?.PalletTie;
  
      if ($utils.isAllDefined(palletHigh, palletTie)) {
  
          $form.fields.full_pallet_default.control.value = palletHigh * palletTie
      }
  
  
  }
  }
  //#endregion private flows
}
