import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Catalogs_associate_material_catalog_by_project_flowService } from './Catalogs.flow.index';
import { Catalogs_copy_source_material_flowService } from './Catalogs.flow.index';
import { Catalogs_create_material_catalog_flowService } from './Catalogs.flow.index';
import { Catalogs_create_source_material_flowService } from './Catalogs.flow.index';
import { Catalogs_delete_material_catalog_flowService } from './Catalogs.flow.index';
import { Catalogs_is_material_catalog_deletable_flowService } from './Catalogs.flow.index';
import { Catalogs_unassociate_material_catalog_by_project_flowService } from './Catalogs.flow.index';

import { $frontendTypes } from './Catalogs.frontend.types'

@Injectable({ providedIn: 'root' })
export class Catalogs_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Catalogs: Catalogs_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _associate_material_catalog_by_project_flow: Catalogs_associate_material_catalog_by_project_flowService;
  public async associate_material_catalog_by_project_flow(inParams: { project_id: number, catalog_id: number }): Promise< { reasons?: string[] }> {
    if(!this._associate_material_catalog_by_project_flow) {
      this._associate_material_catalog_by_project_flow = this.injector.get(Catalogs_associate_material_catalog_by_project_flowService);
    }
    return this._associate_material_catalog_by_project_flow.run(inParams);
  }
   
   

   
 
  private _copy_source_material_flow: Catalogs_copy_source_material_flowService;
  public async copy_source_material_flow(inParams: { materialId?: number, targetProjectId?: number }): Promise< { nextMaterialId?: number, reason?: string }> {
    if(!this._copy_source_material_flow) {
      this._copy_source_material_flow = this.injector.get(Catalogs_copy_source_material_flowService);
    }
    return this._copy_source_material_flow.run(inParams);
  }
   
   

   
 
  private _create_material_catalog_flow: Catalogs_create_material_catalog_flowService;
  public async create_material_catalog_flow(inParams: { name: string, description: string }): Promise< { reasons?: string[] }> {
    if(!this._create_material_catalog_flow) {
      this._create_material_catalog_flow = this.injector.get(Catalogs_create_material_catalog_flowService);
    }
    return this._create_material_catalog_flow.run(inParams);
  }
   
   

   
 
  private _create_source_material_flow: Catalogs_create_source_material_flowService;
  public async create_source_material_flow(inParams: { catalogId: number, lookupcode: string, lotControlled: boolean, serialControlled: boolean, materialGroupId: number, description: string, name?: string, allocationStrategyId: number, isFixedWeight: boolean, isFixedLength: boolean, isFixedVolume: boolean, shelfLifeSpan: number, basePackagingId: number, netWeight?: number, shippingWeight?: number, weightUomId?: number, height?: number, length?: number, width?: number, dimensionUomId?: number, netVolume?: number, shippingVolume?: number, volumeUomId?: number, palletTie?: number, palletHigh?: number, upcCode?: string, quantityPrecisionScale?: number, storageCategoryRuleId?: number }): Promise< { reasons?: string[], materialId?: number }> {
    if(!this._create_source_material_flow) {
      this._create_source_material_flow = this.injector.get(Catalogs_create_source_material_flowService);
    }
    return this._create_source_material_flow.run(inParams);
  }
   
   

   
 
  private _delete_material_catalog_flow: Catalogs_delete_material_catalog_flowService;
  public async delete_material_catalog_flow(inParams: { catalog_id: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_material_catalog_flow) {
      this._delete_material_catalog_flow = this.injector.get(Catalogs_delete_material_catalog_flowService);
    }
    return this._delete_material_catalog_flow.run(inParams);
  }
   
   

   
 
  private _is_material_catalog_deletable_flow: Catalogs_is_material_catalog_deletable_flowService;
  public async is_material_catalog_deletable_flow(inParams: { catalog_id: number }): Promise< { reason?: string }> {
    if(!this._is_material_catalog_deletable_flow) {
      this._is_material_catalog_deletable_flow = this.injector.get(Catalogs_is_material_catalog_deletable_flowService);
    }
    return this._is_material_catalog_deletable_flow.run(inParams);
  }
   
   

   
 
  private _unassociate_material_catalog_by_project_flow: Catalogs_unassociate_material_catalog_by_project_flowService;
  public async unassociate_material_catalog_by_project_flow(inParams: { project_id: number, catalog_id: number }): Promise< { reasons?: string[] }> {
    if(!this._unassociate_material_catalog_by_project_flow) {
      this._unassociate_material_catalog_by_project_flow = this.injector.get(Catalogs_unassociate_material_catalog_by_project_flowService);
    }
    return this._unassociate_material_catalog_by_project_flow.run(inParams);
  }
   
   

   
}
