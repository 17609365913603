<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="on_delete" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Instructions-instruction_types_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
        [tooltip]="row.cells.type.editControl.tooltip"
      [typeIds]="row.$fields_type_selector_inParams_typeIds"
    >
    </Instructions-instruction_types_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="instructions">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.instructions.displayControl.styles.style"
          [ngClass]="row.cells.instructions.displayControl.styles.classes"
          [matTooltip]="row.cells.instructions.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.instructions.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['instructions_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.instructions.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.instructions.editControl.placeholder}}"
            [ngStyle]="row.cells.instructions.editControl.styles.style"
            [ngClass]="row.cells.instructions.editControl.styles.classes"
            [matTooltip]="row.cells.instructions.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="URL">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.URL.displayControl.styles.style"
          [ngClass]="row.cells.URL.displayControl.styles.classes"
          [matTooltip]="row.cells.URL.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.URL.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['URL_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.URL.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.URL.editControl.placeholder}}"
            [ngStyle]="row.cells.URL.editControl.styles.style"
            [ngClass]="row.cells.URL.editControl.styles.classes"
            [matTooltip]="row.cells.URL.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="enabled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['enabled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.enabled.displayControl.styles.style"
                  [ngClass]="row.cells.enabled.displayControl.styles.classes"
                  [matTooltip]="row.cells.enabled.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.enabled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['enabled_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.enabled.editControl.styles.style"
                  [ngClass]="row.cells.enabled.editControl.styles.classes"
                  [matTooltip]="row.cells.enabled.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.enabled.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_sys_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_sys_user.displayControl.styles.style"
          [ngClass]="row.cells.created_sys_user.displayControl.styles.classes"
          [matTooltip]="row.cells.created_sys_user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_sys_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_sys_date_time">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_sys_date_time.displayControl.styles.style"
          [ngClass]="row.cells.created_sys_date_time.displayControl.styles.classes"
          [matTooltip]="row.cells.created_sys_date_time.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_sys_date_time.displayControl.text | formatText : row.cells.created_sys_date_time.displayControl.formatType : row.cells.created_sys_date_time.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_sys_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_sys_user.displayControl.styles.style"
          [ngClass]="row.cells.modified_sys_user.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_sys_user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_sys_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_sys_date_time">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_sys_date_time.displayControl.styles.style"
          [ngClass]="row.cells.modified_sys_date_time.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_sys_date_time.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_sys_date_time.displayControl.text | formatText : row.cells.modified_sys_date_time.displayControl.formatType : row.cells.modified_sys_date_time.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
