import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_entity_user_defined_field_values_gridComponent } from './FootPrintManager.entity_user_defined_field_values_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_entity_user_defined_field_values_gridComponent),
  ],
  selector: 'app-custom_orders_udf_hub',
  templateUrl: './app.custom_orders_udf_hub.component.html'
})
export class app_custom_orders_udf_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { orderId?: number, shipmentId?: number } = { orderId: null, shipmentId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      order_udfs: new TabItemModel(
        this.rootTabGroup, 
        'Order custom fields', 
        ),
      shipment_udfs: new TabItemModel(
        this.rootTabGroup, 
        'Shipment custom fields', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityType(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Order';
      
      return expr;
    }
  
    cacheValueFor_$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys: { name?: string, value?: any }[];
    get $tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys(): { name?: string, value?: any }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [{ name: 'Id', value: $hub.inParams.orderId }];
      
      if(!isEqual(this.cacheValueFor_$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys, expr)) {
        this.cacheValueFor_$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys = expr;
      }
      return this.cacheValueFor_$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys;
    }
  
    get $tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityType(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Shipment';
      
      return expr;
    }
  
    cacheValueFor_$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys: { name?: string, value?: any }[];
    get $tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys(): { name?: string, value?: any }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [{ name: 'Id', value: $hub.inParams.shipmentId }];
      
      if(!isEqual(this.cacheValueFor_$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys, expr)) {
        this.cacheValueFor_$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys = expr;
      }
      return this.cacheValueFor_$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_order_udfs', { read: FootPrintManager_entity_user_defined_field_values_gridComponent }) $tabs_order_udfs: FootPrintManager_entity_user_defined_field_values_gridComponent;
      @ViewChild('$tabs_shipment_udfs', { read: FootPrintManager_entity_user_defined_field_values_gridComponent }) $tabs_shipment_udfs: FootPrintManager_entity_user_defined_field_values_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Custom fields`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `View order and shipment custom fields below`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: app_ShellService,
  private datasources: app_DatasourceService,
  private flows: app_FlowService,
  private reports: app_ReportService,
  private localization: app_LocalizationService,
  private operations: app_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.order_udfs,
      this.tabs.shipment_udfs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'custom_orders_udf_hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_order_udfs') {
      if (!isNil(this.$tabs_order_udfs) && !this.tabs.order_udfs.hidden) {
        this.$tabs_order_udfs.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_shipment_udfs') {
      if (!isNil(this.$tabs_shipment_udfs) && !this.tabs.shipment_udfs.hidden) {
        this.$tabs_shipment_udfs.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: app_custom_orders_udf_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $hub.title = 'Custom fields'
  }
  //#endregion private flows
}
