import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



import { FootPrintManager_material_components_gridComponent } from './FootPrintManager.material_components_grid.component';

interface IFootPrintManager_material_kits_gridComponentEntity {
component_count?: number, MaterialId?: number, PackagingId?: number}

interface IFootPrintManager_material_kits_gridComponentInParams {
  material_id: number, project_id: number}


class FootPrintManager_material_kits_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_material_kits_gridComponent;
  entity: IFootPrintManager_material_kits_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    packaging_name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    component_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    substitutions_included: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_FootPrintManager_material_components_grid_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.material_id;
    
    return expr;
  }


  get $rowExpand_FootPrintManager_material_components_grid_inParams_packaging_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.PackagingId;
    
    return expr;
  }


  get $rowExpand_FootPrintManager_material_components_grid_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }


  get $rowExpand_FootPrintManager_material_components_grid_inParams_suppress_new_row(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = true;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_material_kits_gridComponent, entity: IFootPrintManager_material_kits_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.MaterialId,this.entity.PackagingId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_material_kits_gridComponent, entity?: IFootPrintManager_material_kits_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.MaterialId,this.entity.PackagingId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'MaterialId',this.entity.MaterialId);
    set($resultKey, 'PackagingId',this.entity.PackagingId);
    const inParams = {
      $keys:[$resultKey],
      material_id:  $grid.inParams.material_id ,
    };
    const data = await this.datasources.FootPrintManager.ds_material_kits_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.component_count.displayControl as TextModel).text = $row.entity.component_count?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'packaging_name' : {
        this.on_packaging_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_material_kits_gridComponentRowModel,
  $grid: FootPrintManager_material_kits_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const material_id = $row.entity.MaterialId;
  const packaging_id = $row.entity.PackagingId;
  
  // Set packaging shortname as expand on the grid datasource is not available
  const packaging = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({
      unitId: packaging_id
  })).result;
  
  if ($utils.isDefined(packaging)) {
  
      $row.cells.packaging_name.displayControl.text = packaging[0].ShortName;
  }
  
  
  // Set the substitutions included cell
  
  const components = (await $datasources.Materials.ds_get_components_by_material_id_packaging_id.get({
      material_id: material_id,
      packaging_id: packaging_id
  })).result;
  
  if ($utils.isDefined(components)) {
  
      let substitutions_check = 'No'
      for (let component of components) {
  
          const component_material_id = component.ComponentMaterialId;
          const component_material_packaging_id = component.ComponentPackagedId;
  
          const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
              material_id: component_material_id,
              packaging_id: component_material_packaging_id
          })).result;
  
  
          if ($utils.isDefined(substitutions)) {
  
              substitutions_check = 'Yes';
              break;
          }
  
  
      }
      $row.cells.substitutions_included.displayControl.text = substitutions_check
  
  
  }
  
  else {
      $row.cells.substitutions_included.displayControl.text = 'No'
  }
  
  }
  on_packaging_clicked(event = null) {
    return this.on_packaging_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_clickedInternal(
    $row: FootPrintManager_material_kits_gridComponentRowModel,
  $grid: FootPrintManager_material_kits_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const packaging_id = $row.entity.PackagingId;
  const material_id = $row.entity.MaterialId;
  const project_id = $grid.inParams.project_id;
  
  if ($utils.isAllDefined(packaging_id, material_id, project_id)) {
  
  
      await $shell.FootPrintManager.openmaterial_kit_editorDialog({
          material_id: material_id,
          packaging_id: packaging_id,
          project_id: project_id
      }, "flyout", EModalSize.Large)
  }
  
  await $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_material_components_gridComponent)
  ],
  selector: 'FootPrintManager-material_kits_grid',
  templateUrl: './FootPrintManager.material_kits_grid.component.html'
})
export class FootPrintManager_material_kits_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_material_kits_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fitedWidth', ['compact','noscroll-table']);


  inParams: IFootPrintManager_material_kits_gridComponentInParams = { material_id: null, project_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     packaging_name: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       component_count: new GridHeaderModel(new HeaderStyles(null, null), 'Component count', false, false, null),       substitutions_included: new GridHeaderModel(new HeaderStyles(null, null), 'Substitutions included?', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_material_kits_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }

  topToolbar = {
      new_kit: new ToolModel(new ButtonModel('new_kit', new ButtonStyles(null, null), false, 'New kit', 'icon-ic_fluent_add_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Material kits';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      material_id:  $grid.inParams.material_id ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_material_kits_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_material_kits_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_material_kits_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  
  const selected_row_count = $grid.selectedRows.length;
  
  if ($utils.isDefined(selected_row_count) && selected_row_count > 0) {
  
  
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  
  
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_material_kits_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Initialize data
  
      const selected_rows = $grid.selectedRows;
  
      for (let selected_row of selected_rows) {
  
          const material_id = selected_row.entity.MaterialId;
          const material_packaging_id = selected_row.entity.PackagingId;
  
          const components = (await $datasources.Materials.ds_get_components_by_material_id_packaging_id.get({
              material_id: material_id,
              packaging_id: material_packaging_id
          })).result;
  
          if ($utils.isDefined(components)) {
  
              for (let component of components) {
  
                  const component_material_id = component.ComponentMaterialId;
                  const component_material_packaging_id = component.ComponentPackagedId;
                  const kit_id = component.Id;
  
                  const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
                      material_id: component_material_id,
                      packaging_id: component_material_packaging_id
                  })).result;
  
                  // Delete related substitutions
                  if ($utils.isDefined(substitutions)) {
  
                      for (let substitute of substitutions) {
  
                          let substitute_id = substitute.Id;
                          await $flows.Utilities.crud_delete_flow({ entitySet: 'MaterialSubstitutions', id: substitute_id });
                      }
                  }
  
                  // Delete component
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'Kits', id: kit_id });
  
              }
  
  
          }
  
  
      }
  
  
  
      $grid.refresh();
  
  }
  
  catch (error) {
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
  }
  }
  on_new_kit(event = null) {
    return this.on_new_kitInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_kitInternal(
    $grid: FootPrintManager_material_kits_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const material_id = $grid.inParams.material_id;
  const project_id = $grid.inParams.project_id;
  
  const packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: material_id })).result;
  
  // If there is only one packaging then auto select it
  if ($utils.isDefined(packagings)) {
  
      if (packagings.length == 1) {
  
          let packaging_id = packagings[0].PackagingId;
          await $shell.FootPrintManager.openmaterial_kit_editorDialog({
              material_id: material_id,
              project_id: project_id,
              packaging_id: packaging_id
          }, "flyout", EModalSize.Large)
      }
  
      else {
  
          let packaging_open_count = 0;
          let single_packaging_id = 0;
          for (let packaging of packagings) {
  
              const components = (await $datasources.Materials.ds_get_components_by_material_id_packaging_id.get({
                  material_id: material_id,
                  packaging_id: packaging.PackagingId
              })).result;
  
              if (!$utils.isDefined(components)) {
  
                  packaging_open_count += 1;
                  single_packaging_id = packaging.PackagingId
              }
  
  
          }
          if (single_packaging_id > 0 && packaging_open_count === 1) {
              await $shell.FootPrintManager.openmaterial_kit_editorDialog({
                  material_id: material_id,
                  project_id: project_id,
                  packaging_id: single_packaging_id
              }, "flyout", EModalSize.Large)
          }
          else {
  
               await $shell.FootPrintManager.openmaterial_kit_editorDialog({
                  material_id: material_id,
                  project_id: project_id
              }, "flyout", EModalSize.Large)
          }
      }
  
      await $grid.refresh();
  
  }
  else {
      throw new Error('Unable to extract a list of packagings for the given material.')
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_material_kits_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Check if the material already has kits configured for every packaging
  $grid.topToolbar.new_kit.control.readOnly = true;
  const material_id = $grid.inParams.material_id;
  
  const packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: material_id })).result;
  
  if ($utils.isDefined(packagings)) {
  
      for (let packaging of packagings) {
  
          let packaging_check = packaging.PackagingId;
          const components = (await $datasources.Materials.ds_get_components_by_material_id_packaging_id.get({
              material_id: material_id,
              packaging_id: packaging_check
          })).result;
          if (!$utils.isDefined(components)) {
  
              $grid.topToolbar.new_kit.control.readOnly = false;
              break;
          }
      }
  
  }
  
  
  
  
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
