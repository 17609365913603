import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Notifications_frequency_interval_dd_singleComponent } from './Notifications.frequency_interval_dd_single.component'
import { Notifications_weekly_frequency_dd_multiComponent } from './Notifications.weekly_frequency_dd_multi.component'
import { Notifications_monthly_frequency_dd_multiComponent } from './Notifications.monthly_frequency_dd_multi.component'
import { Notifications_time_frequency_dd_singleComponent } from './Notifications.time_frequency_dd_single.component'
import { Notifications_time_selector_dd_singleComponent } from './Notifications.time_selector_dd_single.component'
import { Notifications_time_meridiem_dd_singleComponent } from './Notifications.time_meridiem_dd_single.component'
import { Notifications_time_interval_dd_singleComponent } from './Notifications.time_interval_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_frequency_interval_dd_singleComponent),
    forwardRef(() => Notifications_weekly_frequency_dd_multiComponent),
    forwardRef(() => Notifications_monthly_frequency_dd_multiComponent),
    forwardRef(() => Notifications_time_frequency_dd_singleComponent),
    forwardRef(() => Notifications_time_selector_dd_singleComponent),
    forwardRef(() => Notifications_time_meridiem_dd_singleComponent),
    forwardRef(() => Notifications_time_interval_dd_singleComponent),
  ],
  selector: 'Notifications-schedule_frequency_form',
  templateUrl: './Notifications.schedule_frequency_form.component.html'
})
export class Notifications_schedule_frequency_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } } = { schedule: { dateOption: null, dateInterval: null, timeOption: null, timeInterval: null, timeOffset: null } };
  //#region Inputs
  @Input('schedule') set $inParams_schedule(v: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number }) {
    this.inParams.schedule = v;
  }
  get $inParams_schedule(): { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } {
    return this.inParams.schedule;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } } = { schedule: { dateOption: null, dateInterval: null, timeOption: null, timeInterval: null, timeOffset: null } };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    frequency_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    number_of_days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    weekly_days_of_week: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    monthly_day_of_month: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    time_frequency: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_selector: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_meridiem: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_interval: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_interval_options: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    frequency_interval: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['frequency_interval'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Frequency', true),
    number_of_days: new FieldModel(new TextBoxModel(this.formGroup.controls['number_of_days'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Repeat every (Days)', true),
    weekly_days_of_week: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weekly_days_of_week'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Days of week', true),
    monthly_day_of_month: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['monthly_day_of_month'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Days of month', true),
    time_frequency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_frequency'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
    time_selector: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_selector'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
    time_meridiem: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_meridiem'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
    time_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['time_interval'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), '', false),
    time_interval_options: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_interval_options'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
  daily_frequency: new FieldsetModel('Daily frequency', true, false, true),
  weekly_frequency: new FieldsetModel('Weekly frequency', true, false, true),
  monthly_frequency: new FieldsetModel('Monthly frequency', true, false, true),
  time_frequency: new FieldsetModel('Time frequency', false, false, true),
};

  //#region fields inParams
  get $fields_time_selector_selector_inParams_intervalMinutes(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 5;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    frequency_interval: this.fields.frequency_interval.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    number_of_days: this.fields.number_of_days.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    weekly_days_of_week: this.fields.weekly_days_of_week.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    monthly_day_of_month: this.fields.monthly_day_of_month.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    time_frequency: this.fields.time_frequency.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    time_selector: this.fields.time_selector.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    time_meridiem: this.fields.time_meridiem.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    time_interval: this.fields.time_interval.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
    time_interval_options: this.fields.time_interval_options.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.validate_form()))),
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Frequency';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.number_of_days.control as TextBoxModel).reset('1');
    (this.fields.time_frequency.control as SelectBoxModel).reset('Occurs at');
    (this.fields.time_selector.control as SelectBoxModel).reset('06:00');
    (this.fields.time_meridiem.control as SelectBoxModel).reset('AM');
    (this.fields.time_interval.control as NumberBoxModel).reset(5);
    (this.fields.time_interval_options.control as SelectBoxModel).reset('Minutes');

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .frequency_interval
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_frequency_changed();
      });
    this.$formGroupFieldValidationObservables
      .number_of_days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weekly_days_of_week
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .monthly_day_of_month
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_frequency
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_frequency_changed();
      });
    this.$formGroupFieldValidationObservables
      .time_selector
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_meridiem
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_interval
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_interval_changed();
      });
    this.$formGroupFieldValidationObservables
      .time_interval_options
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_frequency_changed(event = null) {
    return this.on_frequency_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_frequency_changedInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $form.set_state();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  $form.outParams.schedule = $form.inParams.schedule;
  
  if ($utils.isDefined($form.inParams.schedule)) {
      
      $form.fields.frequency_interval.control.value = $form.inParams.schedule.dateOption;
  
      switch ($form.fields.frequency_interval.control.value) {
          case 'DAILY': {
              $form.fields.number_of_days.control.value = $form.inParams.schedule.dateInterval;
              break;
          }
          case 'WEEKLY': {
              $form.fields.weekly_days_of_week.control.value = $form.inParams.schedule.dateInterval;
              break;
          }
          case 'MONTHLY': {
              $form.fields.monthly_day_of_month.control.value = $form.inParams.schedule.dateInterval;
              break;
          }
      }
  
      $form.fields.time_frequency.control.value = $form.inParams.schedule.timeOption;
  
      switch ($form.fields.time_frequency.control.value.trim().toUpperCase()) {
          case 'OCCURS AT': {
              $form.fields.time_selector.control.value = $form.inParams.schedule.timeInterval.split(' ')[0];
              $form.fields.time_meridiem.control.value = $form.inParams.schedule.timeInterval.split(' ')[1];
              break;
          }
          case 'OCCURS EVERY': {
              $form.fields.time_interval.control.value = $form.inParams.schedule.timeInterval.split(' ')[0];
              $form.fields.time_interval_options.control.value = $form.inParams.schedule.timeInterval.split(' ')[1];
              break;
          }
      }
  }
  
  await $form.set_state();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let option: any;
  switch ($form.fields.frequency_interval.control.value) {
      case 'DAILY': {
          option = $form.fields.number_of_days.control.value;
  
          break;
      }
      case 'WEEKLY': {
          let weekDaySortOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  
          option = $form.fields.weekly_days_of_week.control.value;
  
          option.sort((a, b) => weekDaySortOrder.indexOf(a) - weekDaySortOrder.indexOf(b));
  
          break;
      }
      case 'MONTHLY': {
          option = $form.fields.monthly_day_of_month.control.value;
          break;
      }
      default: {
          break;
      }
  }
  
  if ($utils.isDefined(option)) {
      $form.outParams.schedule = {
          dateOption: $form.fields.frequency_interval.control.value,
          dateInterval: option,
          timeOption: $form.fields.time_frequency.control.value,
          timeInterval: $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS AT' ? `${$form.fields.time_selector.control.value} ${$form.fields.time_meridiem.control.value}` :
              $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS EVERY' ? `${$form.fields.time_interval.control.value} ${$form.fields.time_interval_options.control.value}` : '',
          timeOffset: new Date().getTimezoneOffset()
      };
  }
  
  $form.close();
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  switch ($form.fields.frequency_interval.control.value) {
      case 'DAILY': {
          $form.fieldsets.daily_frequency.hidden = false;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.hidden = false;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = false;
          $form.fields.time_interval_options.hidden = false;
  
          await $form.on_time_frequency_changed();
  
          break;
      }
      case 'WEEKLY': {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = false;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.control.value = 'Occurs at';
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
          break;
      }
      case 'MONTHLY': {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = false;
  
          $form.fields.time_frequency.control.value = 'Occurs at';
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
  
          break;
      }
      default: {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = true;
          $form.fields.time_meridiem.hidden = true;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
  
          break;
      }
  }
  }
  on_time_frequency_changed(event = null) {
    return this.on_time_frequency_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_time_frequency_changedInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  switch ($form.fields.time_frequency.control.value.trim().toUpperCase()) {
      case 'OCCURS AT': {
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
  
          break;
      }
      case 'OCCURS EVERY': {
          $form.fields.time_interval.hidden = false;
          $form.fields.time_interval_options.hidden = false;
          $form.fields.time_selector.hidden = true;
          $form.fields.time_meridiem.hidden = true;
  
          break;
      }
  }
  }
  on_time_interval_changed(event = null) {
    return this.on_time_interval_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_time_interval_changedInternal(
    $form: Notifications_schedule_frequency_formComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($form.fields.time_interval.control.value < 5) {
      $form.fields.time_interval.control.value = 5;
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form() {
    const emptyResult = { frequency_interval:[],number_of_days:[],weekly_days_of_week:[],monthly_day_of_month:[],time_frequency:[],time_selector:[],time_meridiem:[],time_interval:[],time_interval_options:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $form: Notifications_schedule_frequency_formComponent,
    $validation:{
      fieldErrors: { frequency_interval: Pick<string[], 'push'>,number_of_days: Pick<string[], 'push'>,weekly_days_of_week: Pick<string[], 'push'>,monthly_day_of_month: Pick<string[], 'push'>,time_frequency: Pick<string[], 'push'>,time_selector: Pick<string[], 'push'>,time_meridiem: Pick<string[], 'push'>,time_interval: Pick<string[], 'push'>,time_interval_options: Pick<string[], 'push'>, }
    },
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
  ) {
    setToolbarVisibility();
  
  
  async function setToolbarVisibility() {
      await new Promise(result => setTimeout(result, 100));
      let isReadOnly = false;
      if (!$utils.isDefined($form.fields.frequency_interval.control.value)) {
          isReadOnly = true;
      }
  
      if ($form.fieldsets.daily_frequency.hidden && $form.fieldsets.weekly_frequency.hidden && $form.fields.monthly_day_of_month.hidden) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.daily_frequency.hidden && !$utils.isDefined($form.fields.number_of_days.control.value)) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.weekly_frequency.hidden && !$utils.isDefined($form.fields.weekly_days_of_week.control.value)) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.monthly_frequency.hidden && !$utils.isDefined($form.fields.monthly_day_of_month.control.value)) {
          isReadOnly = true;
      }
  
      $form.toolbar.confirm.control.readOnly = isReadOnly;
  }
    return $validation.fieldErrors as { frequency_interval:[],number_of_days:[],weekly_days_of_week:[],monthly_day_of_month:[],time_frequency:[],time_selector:[],time_meridiem:[],time_interval:[],time_interval_options:[], };
  }
  //#endregion validation flows
}
