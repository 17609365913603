import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_dock_appointments_dashboard_grid_empty_dataService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { from_date: string, to_date: string, warehouse_ids?: number[] }): 
  Promise<{ result: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_empty_data/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { from_date: string, to_date: string, warehouse_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_empty_data/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { from_date: string, to_date: string, warehouse_ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, Status?: { Name?: string }, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_empty_data/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
