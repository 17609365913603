import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { SerialNumbers_reason_codes_dd_singleComponent } from './SerialNumbers.reason_codes_dd_single.component'
import { SerialNumbers_serialnumber_statuses_dd_singleComponent } from './SerialNumbers.serialnumber_statuses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'
import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SerialNumbers_reason_codes_dd_singleComponent),
    forwardRef(() => SerialNumbers_serialnumber_statuses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
    forwardRef(() => Locations_licenseplate_statuses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-finalize_audit_form',
  templateUrl: './FootPrintManager.finalize_audit_form.component.html'
})
export class FootPrintManager_finalize_audit_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licensePlateId?: number, missingSerialNumberIds: number[], warehouseId: number, locationId?: number, shippingContainerId?: number, foundSerialNumberIds: number[] } = { licensePlateId: null, missingSerialNumberIds: [], warehouseId: null, locationId: null, shippingContainerId: null, foundSerialNumberIds: [] };
  //#region Inputs
  @Input('licensePlateId') set $inParams_licensePlateId(v: number) {
    this.inParams.licensePlateId = v;
  }
  get $inParams_licensePlateId(): number {
    return this.inParams.licensePlateId;
  }
  @Input('missingSerialNumberIds') set $inParams_missingSerialNumberIds(v: number[]) {
    this.inParams.missingSerialNumberIds = v;
  }
  get $inParams_missingSerialNumberIds(): number[] {
    return this.inParams.missingSerialNumberIds;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  @Input('foundSerialNumberIds') set $inParams_foundSerialNumberIds(v: number[]) {
    this.inParams.foundSerialNumberIds = v;
  }
  get $inParams_foundSerialNumberIds(): number[] {
    return this.inParams.foundSerialNumberIds;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { isConfirm: boolean, reasonCodeId?: number } = { isConfirm: null, reasonCodeId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ isConfirm: boolean, reasonCodeId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    reason_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    move_found_serials: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    missing_serials_message: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    change_missing_serial_statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    serial_status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    move_missing_serials: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    serial_target_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    serial_target_license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    license_plate_message: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    change_license_plate_status: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    license_plate_status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    move_license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    license_plate_target_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    reason_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reason code', false),
    move_found_serials: new FieldModel(new CheckBoxModel(this.formGroup.controls['move_found_serials'] as DatexFormControl, null, false, 'Move found serials onto license plate', null)
, new ControlContainerStyles(null, null), '', false),
    missing_serials_message: new FieldModel(new TextBoxModel(this.formGroup.controls['missing_serials_message'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), '', false),
    change_missing_serial_statuses: new FieldModel(new CheckBoxModel(this.formGroup.controls['change_missing_serial_statuses'] as DatexFormControl, null, false, 'Change status', null)
, new ControlContainerStyles(null, null), '', false),
    serial_status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['serial_status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
    move_missing_serials: new FieldModel(new CheckBoxModel(this.formGroup.controls['move_missing_serials'] as DatexFormControl, null, false, 'Move serials', null)
, new ControlContainerStyles(null, null), '', false),
    serial_target_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['serial_target_location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Location', false),
    serial_target_license_plate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['serial_target_license_plate'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'License plate', false),
    license_plate_message: new FieldModel(new TextBoxModel(this.formGroup.controls['license_plate_message'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), '', false),
    change_license_plate_status: new FieldModel(new CheckBoxModel(this.formGroup.controls['change_license_plate_status'] as DatexFormControl, null, false, 'License plate status', null)
, new ControlContainerStyles(null, null), '', false),
    license_plate_status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['license_plate_status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), '', false),
    move_license_plate: new FieldModel(new CheckBoxModel(this.formGroup.controls['move_license_plate'] as DatexFormControl, null, false, 'Move license plate', null)
, new ControlContainerStyles(null, null), '', false),
    license_plate_target_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['license_plate_target_location'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Location', false),
  };

  fieldsets = {
  top: new FieldsetModel('', true, false, true),
  missing_serial_numbers: new FieldsetModel('Missing serial numbers', false, true, true),
  license_plate: new FieldsetModel('License plate', false, true, true),
};

  //#region fields inParams
  get $fields_reason_code_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  get $fields_reason_code_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 46;
    
    return expr;
  }

  get $fields_serial_target_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.warehouseId;
    
    return expr;
  }

  get $fields_serial_target_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_serial_target_license_plate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.serial_target_location.control.value;
    
    return expr;
  }

  get $fields_serial_target_license_plate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_serial_target_license_plate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  get $fields_license_plate_target_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.warehouseId;
    
    return expr;
  }

  get $fields_license_plate_target_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    reason_code: this.fields.reason_code.control.valueChanges
    ,
    move_found_serials: this.fields.move_found_serials.control.valueChanges
    ,
    missing_serials_message: this.fields.missing_serials_message.control.valueChanges
    ,
    change_missing_serial_statuses: this.fields.change_missing_serial_statuses.control.valueChanges
    ,
    serial_status: this.fields.serial_status.control.valueChanges
    ,
    move_missing_serials: this.fields.move_missing_serials.control.valueChanges
    ,
    serial_target_location: this.fields.serial_target_location.control.valueChanges
    ,
    serial_target_license_plate: this.fields.serial_target_license_plate.control.valueChanges
    ,
    license_plate_message: this.fields.license_plate_message.control.valueChanges
    ,
    change_license_plate_status: this.fields.change_license_plate_status.control.valueChanges
    ,
    license_plate_status: this.fields.license_plate_status.control.valueChanges
    ,
    move_license_plate: this.fields.move_license_plate.control.valueChanges
    ,
    license_plate_target_location: this.fields.license_plate_target_location.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.missingSerialNumberIds)) {
        this.$missingRequiredInParams.push('missingSerialNumberIds');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
      if(isNil(this.inParams.foundSerialNumberIds)) {
        this.$missingRequiredInParams.push('foundSerialNumberIds');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Finalize audit';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.move_found_serials.control as CheckBoxModel).reset(true);
    
    (this.fields.change_missing_serial_statuses.control as CheckBoxModel).reset(false);
    (this.fields.move_missing_serials.control as CheckBoxModel).reset(false);
    
    (this.fields.change_license_plate_status.control as CheckBoxModel).reset(false);
    (this.fields.move_license_plate.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .move_found_serials
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .missing_serials_message
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .change_missing_serial_statuses
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_missing_serials_status_change();
      });
    this.$formGroupFieldValidationObservables
      .serial_status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .move_missing_serials
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_move_missing_serials_change();
      });
    this.$formGroupFieldValidationObservables
      .serial_target_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .serial_target_license_plate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .license_plate_message
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .change_license_plate_status
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_license_plate_status_change();
      });
    this.$formGroupFieldValidationObservables
      .license_plate_status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .move_license_plate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_move_license_plate_change();
      });
    this.$formGroupFieldValidationObservables
      .license_plate_target_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.isConfirm = false;
  
  // Check if missing serial numbers
  if ($form.inParams.missingSerialNumberIds.length > 0) {
      $form.fields.missing_serials_message.control.value = `There were ${$form.inParams.missingSerialNumberIds.length} serial numbers expected on the license plate that were not scanned.`
  } else {
      $form.fieldsets.missing_serial_numbers.hidden = true;
  }
  
  if ($form.inParams.foundSerialNumberIds.length > 0) {
      $form.fields.move_found_serials.control.label = `Move ${$form.inParams.foundSerialNumberIds.length} additional serials that were found on license plate?`
  }
  else {
      $form.fields.move_found_serials.hidden = true;
      $form.fields.move_found_serials.control.value = false;
  }
  
  // Check if any other errors
  $form.fields.license_plate_message.hidden = true;
  
  $form.fields.serial_status.hidden = true;
  $form.fields.serial_target_location.hidden = true;
  $form.fields.serial_target_license_plate.hidden = true;
  
  $form.fields.license_plate_status.hidden = true;
  $form.fields.license_plate_target_location.hidden = true;
  }
  on_change_missing_serials_status_change(event = null) {
    return this.on_change_missing_serials_status_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_missing_serials_status_changeInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.change_missing_serial_statuses.control.value) {
      $form.fields.serial_status.hidden = false;
      $form.fields.serial_status.control.value = null;
  }
  else {
      $form.fields.serial_status.hidden = true;
      $form.fields.serial_status.control.value = null;
  }
  }
  on_move_missing_serials_change(event = null) {
    return this.on_move_missing_serials_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_missing_serials_changeInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.move_missing_serials.control.value) {
      $form.fields.serial_target_location.hidden = false;
      $form.fields.serial_target_location.control.value = null;
  
      $form.fields.serial_target_license_plate.hidden = false;
      $form.fields.serial_target_license_plate.control.value = null;
  }
  else {
      $form.fields.serial_target_location.hidden = true;
      $form.fields.serial_target_location.control.value = null;
  
      $form.fields.serial_target_license_plate.hidden = true;
      $form.fields.serial_target_license_plate.control.value = null;
  }
  }
  on_change_license_plate_status_change(event = null) {
    return this.on_change_license_plate_status_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_license_plate_status_changeInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.change_license_plate_status.control.value) {
      $form.fields.license_plate_status.hidden = false;
      $form.fields.license_plate_status.control.value = null;
  }
  else {
      $form.fields.license_plate_status.hidden = true;
      $form.fields.license_plate_status.control.value = null;
  }
  }
  on_move_license_plate_change(event = null) {
    return this.on_move_license_plate_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_license_plate_changeInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.move_license_plate.control.value) {
      $form.fields.license_plate_target_location.hidden = false;
      $form.fields.license_plate_target_location.control.value = null;
  }
  else {
      $form.fields.license_plate_target_location.hidden = true;
      $form.fields.license_plate_target_location.control.value = null;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_finalize_audit_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let messages: { message: string, detail: string }[] = [];
  
  if ($form.valid) {
      $form.outParams.isConfirm = true;
  
  
  
      // Change license plate status
      if ($form.fields.change_license_plate_status.control.value) {
          let response = await $flows.Inventory.change_entity_status_flow({
              entityName: 'LicensePlate',
              entityId: $form.inParams.licensePlateId,
              targetStatusId: $form.fields.license_plate_status.control.value
          });
  
          if ($utils.isDefined(response.reasons)) {
              messages.push({ message: 'Failed to change license plate status', detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: response.reasons })).formattedValue });
          }
      }
  
      // Move license plate
      if ($form.fields.move_license_plate.control.value) {
          let response = await $flows.Inventory.complete_licenseplate_move_task_flow({
              licenseplateId: $form.inParams.licensePlateId,
              targetLocationId: $form.fields.license_plate_target_location.control.value,
              user: (await $flows.Utilities.get_username_flow({})).userName,
              notes: null,
              taskId: null,
              reasoncodeId: null
          });
  
          if ($utils.isDefined(response.reasons)) {
              messages.push({ message: 'Failed to move license plate', detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: response.reasons })).formattedValue });
          }
      }
  
      // Move found serial numbers
      if ($form.fields.move_found_serials.control.value) {
          let response = await $flows.SerialNumbers.move_serial_numbers_flow({
              serialNumberIds: $form.inParams.foundSerialNumberIds,
              targetLicensePlateId: $form.inParams.licensePlateId
          });
  
          if ($utils.isDefined(response.reasons)) {
              messages.push({ message: 'Failed to move found serial numbers onto license plate', detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: response.reasons })).formattedValue });
          }
      }
  
      // Move missing serial numbers
      if ($form.fields.move_missing_serials.control.value) {
          let response = await $flows.SerialNumbers.move_serial_numbers_flow({
              serialNumberIds: $form.inParams.missingSerialNumberIds,
              targetLicensePlateId: $form.fields.serial_target_license_plate.control.value
          });
          if ($utils.isDefined(response.reasons)) {
              messages.push({ message: 'Failed to move missing serial numbers', detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: response.reasons })).formattedValue });
          }
      }
  
      // Update missing serial number statuses
      if ($form.fields.change_missing_serial_statuses.control.value) {
          for (let serialId of $form.inParams.missingSerialNumberIds) {
              let response = await $flows.Inventory.change_entity_status_flow({
                  entityName: 'SerialNumber',
                  entityId: serialId,
                  targetStatusId: $form.fields.serial_status.control.value
              });
  
              if ($utils.isDefined(response.reasons)) {
                  messages.push({ message: `Failed to change missing serial numbers' statuses`, detail: (await $flows.Utilities.grammar_format_string_array_flow({ values: response.reasons })).formattedValue });
              }
          }
      }
  
      if ($utils.isDefined(messages)) {
          await $shell.FootPrintManager.openErrorDialog('Error finalizing audit', 'Please see details for more information.', null, 'Details', messages);
      }
      else {
          $form.close();
      }
  }
  }
  //#endregion private flows
}
