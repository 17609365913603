import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Inventory.frontend.types'

@Injectable({ providedIn: 'root' })
export class Inventory_get_inventory_licenseplate_snapshot_by_date_projectId_warehouseIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { captureDate: string, projectId?: number, warehouseId?: number, materialIds?: number[], fullTextSearch?: string, skip?: number, take?: number }): Promise<{ list?: { capture_date?: string, lot_id?: number, packaging_id?: number, warehouse_id?: number, total_packaged_amount?: number, total_base_amount?: number, project_id?: number, material_lookupcode?: string, packaging_shortname?: string, warehouse_name?: string, total_net_weight?: number, total_gross_weight?: number, weight_uom_id?: number, weight_uom_name?: string, lot_lookupcode?: string, material_id?: number, licenseplate_id?: number, licenseplate_lookupcode?: string, serial_numbers?: string, receive_date?: string, last_receive_date?: string }[], count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.captureDate)) {
      missingRequiredInParams.push('\'captureDate\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/functions/get_inventory_licenseplate_snapshot_by_date_projectId_warehouseId`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

