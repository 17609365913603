import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';

import { Orderful_ds_entity_import_requestsService } from './Orderful.datasource.index';
import { Orderful_ds_get_adjustment_taskIdsService } from './Orderful.datasource.index';
import { Orderful_ds_get_completed_shipmentsService } from './Orderful.datasource.index';
import { Orderful_ds_get_completed_shipments_without_transmissionsService } from './Orderful.datasource.index';
import { Orderful_ds_message_stagingService } from './Orderful.datasource.index';

@Injectable({ providedIn: 'root' })
export class Orderful_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
  public Orderful: Orderful_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_entity_import_requests: Orderful_ds_entity_import_requestsService;
  public get ds_entity_import_requests(): Orderful_ds_entity_import_requestsService {
    if(!this._ds_entity_import_requests) {
      this._ds_entity_import_requests = this.injector.get(Orderful_ds_entity_import_requestsService);
    }
    return this._ds_entity_import_requests;
  }
  private _ds_get_adjustment_taskIds: Orderful_ds_get_adjustment_taskIdsService;
  public get ds_get_adjustment_taskIds(): Orderful_ds_get_adjustment_taskIdsService {
    if(!this._ds_get_adjustment_taskIds) {
      this._ds_get_adjustment_taskIds = this.injector.get(Orderful_ds_get_adjustment_taskIdsService);
    }
    return this._ds_get_adjustment_taskIds;
  }
  private _ds_get_completed_shipments: Orderful_ds_get_completed_shipmentsService;
  public get ds_get_completed_shipments(): Orderful_ds_get_completed_shipmentsService {
    if(!this._ds_get_completed_shipments) {
      this._ds_get_completed_shipments = this.injector.get(Orderful_ds_get_completed_shipmentsService);
    }
    return this._ds_get_completed_shipments;
  }
  private _ds_get_completed_shipments_without_transmissions: Orderful_ds_get_completed_shipments_without_transmissionsService;
  public get ds_get_completed_shipments_without_transmissions(): Orderful_ds_get_completed_shipments_without_transmissionsService {
    if(!this._ds_get_completed_shipments_without_transmissions) {
      this._ds_get_completed_shipments_without_transmissions = this.injector.get(Orderful_ds_get_completed_shipments_without_transmissionsService);
    }
    return this._ds_get_completed_shipments_without_transmissions;
  }
  private _ds_message_staging: Orderful_ds_message_stagingService;
  public get ds_message_staging(): Orderful_ds_message_stagingService {
    if(!this._ds_message_staging) {
      this._ds_message_staging = this.injector.get(Orderful_ds_message_stagingService);
    }
    return this._ds_message_staging;
  }
}

