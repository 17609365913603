import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_inventory_report_by_projectServiceInParams {
  projectId: number}

interface Iapp_custom_inventory_report_by_projectServiceData {
  Header1?: { result?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, NetWeight?: number, WeightUomId?: number, Location?: { Name?: string }, Status?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, MaterialGroup?: { Name?: string }, Project?: { Id?: number, LookupCode?: string } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Packaged?: { Name?: string }, converted_license_plate_weight?: { convertedWeight?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_inventory_report_by_projectService extends ReportBaseService<Iapp_custom_inventory_report_by_projectServiceInParams, Iapp_custom_inventory_report_by_projectServiceData> {

  protected reportReferenceName = 'custom_inventory_report_by_project';
  protected appReferenceName = 'app';

  constructor(
    utils: UtilsService,
    private datasources: app_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_inventory_report_by_projectServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_inventory_report_by_projectServiceInParams): Promise<Iapp_custom_inventory_report_by_projectServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: Iapp_custom_inventory_report_by_projectServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_inventory_report_by_projectServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      
      const dsData = await this.datasources.app.custom_ds_inventory_report_by_project.get(dsParams);
      
      data.Header1 = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
