import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_bol_shipping_content_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { order_id: number }): Promise<{ result?: { result?: { vendor_lot?: string, gross?: number, net?: number, item?: string, description?: string, quantity?: number, lot?: string, display_system_lot_on_bol?: boolean }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_shipping_content_flow/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
