<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup1</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-material_packaging" *ngIf="!fields.material_packaging.hidden" 
                            class="field-container standard {{fields.material_packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_packaging.styles.style"
                            [ngClass]="fields.material_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material_packaging.label + (fields.material_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material_packaging.label}}<span *ngIf="fields.material_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.material_packaging.control.type"
                            formControlName="material_packaging"
                            (displayTextChange)="fields.material_packaging.control.displayText=$event"
                            [placeholder]="fields.material_packaging.control.placeholder"
                            [styles]="fields.material_packaging.control.styles"
                            [tooltip]="fields.material_packaging.control.tooltip"
                          [materialId]="$fields_material_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.material_packaging.invalid">
                          <ng-container *ngFor="let error of fields.material_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.substitutions.hidden" class="tab" data-cy="tab-substitutions">
              <h2 [className]="tabs.substitutions.active? 'active': ''" (click)="tabs.substitutions.activate()">{{tabs.substitutions.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-material_substitutions_grid *ngIf="tabs.substitutions.active"
              #$tabs_substitutions
              [material_id]="$tabs_substitutions_material_substitutions_grid_inParams_material_id"
              [packaging_id]="$tabs_substitutions_material_substitutions_grid_inParams_packaging_id"
              [project_id]="$tabs_substitutions_material_substitutions_grid_inParams_project_id"
              ($refreshEvent)="refresh(false, false, '$tabs_substitutions')">
              </FootPrintManager-material_substitutions_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>