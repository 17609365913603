<div class="dataview"
     [ngStyle]="{'grid-template-columns': getContainerGridTemplateColumns() , 'display':'grid'}">
  <div class="scheduled-events">
    <div class="summary-container">
      <div class="data-results-summary">
        <span class="results-count">{{totalItemsText}}</span>
        <ng-container *ngIf="selection && hasSelectedEvents()">
          <div class="results-selected">
            <div matTooltip="{{ selectedEventsCount }} selected"
                 matTooltipClass="datex-control-tooltip"
                 class="datex-text">({{ selectedEventsCount }} selected)</div>
            <button (click)="allSelected = false; allSelectedChanged()"
                    class="datex-button">
              <div class="button-label">
                <div class="button-icon"><i class="icon icon-ic_fluent_dismiss_20_regular"></i></div>
                <div class="button-text"></div>
              </div>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="dataview-tools">
      <ng-content select="[topToolbar]"></ng-content>
    </div>
    <div class="dataview-filters">
      <ng-content select="[filters]"></ng-content>
    </div>

    <div *ngIf="ready && pagesCount > 1"
         class="cal-pagination">
      <div class="cal-page-left">
        <a (click)="goToPage(currentPage - 1)"
           class="cal-last-link"
           [ngClass]="{'disabled' : currentPage == 1}">
          <i class="icon icon-ic_fluent_chevron_left_20_regular"></i>
          <span>Previous doors</span>
        </a>
      </div>
      <div class="cal-page-right">
        <a (click)="goToPage(currentPage + 1)"
           class="cal-next-link"
           [ngClass]="{'disabled' : currentPage == pagesCount}">
          <span>Next doors</span>
          <i class="icon icon-ic_fluent_chevron_right_20_regular"></i>
        </a>
        <span class="cal-page-details">
          Showing doors
          {{currentPageFrom}}
          -
          {{currentPageTo}} of {{columnsTotalCount}}
        </span>
      </div>
    </div>
    <div *ngIf="ready"
         class="calendar-day-view"
         [ngClass]="{'no-pages' : pagesCount <= 1}">
      <div class="grid-container"
           [ngClass]="containerClass"
           data-cy="calendar-column">
        <div class="cal-week-view"
             role="grid">
          <div *ngIf="!viewDate"
               class="cal-time-events">
            Please provide current date
          </div>
          <!-- column headers (start after top left cell) -->
          <div class="cal-day-headers"
               role="row">
            <div class="cal-timeheader">
              <mat-checkbox *ngIf="selection"
                            [(ngModel)]="allSelected"
                            (ngModelChange)="allSelectedChanged()"
                            [indeterminate]="allSelected === undefined"
                            color="primary"
                            class="datex-checkbox"></mat-checkbox>
            </div>
            <div class="cal-header"
                 *ngFor="let column of columns; trackBy: trackByViewColumn"
                 tabindex="0"
                 role="columnheader">
              <ng-template [ngTemplateOutlet]="headerTemplate"
                           [ngTemplateOutletContext]="{column: column}">
              </ng-template>
            </div>
          </div>
          <!-- hours column on left and grid for events -->
          <div *ngIf="view && viewDate"
               class="cal-time-events">
            <!-- hours column on left-->
            <!-- for view.hourColumns[0].hours -->
            <div class="cal-time-label-column"
                 #hourColumn
                 *ngIf="view.hourColumns.length > 0">
              <div *ngFor="
                let hour of view.hourColumns[0].hours;
                trackBy: trackByHour;
                let odd = odd
              "
                   class="cal-hour"
                   [class.cal-hour-odd]="odd">
                <datex-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                                                       [style.height.px]="hourSegmentHeight"
                                                       [segment]="segment"
                                                       [segmentHeight]="hourSegmentHeight"
                                                       [locale]="locale"
                                                       [customTemplate]="hourSegmentTemplate"
                                                       [isTimeLabel]="true">
                </datex-calendar-week-view-hour-segment>
              </div>
            </div>
            <!-- grid for events -->
            <div class="cal-day-columns"
                 #dayColumns>
              <!-- columns and column cells-->
              <!-- for view.hourColumns -->
              <div class="cal-day-column"
                   *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn;index as columnIndex ">
                <datex-calendar-week-view-current-time-marker [columnDate]="column.date"
                                                              [dayStartHour]="dayStartHour"
                                                              [dayStartMinute]="dayStartMinute"
                                                              [dayEndHour]="dayEndHour"
                                                              [dayEndMinute]="dayEndMinute"
                                                              [hourSegments]="hourSegments"
                                                              [hourSegmentHeight]="hourSegmentHeight"
                                                              [customTemplate]="currentTimeMarkerTemplate">
                </datex-calendar-week-view-current-time-marker>
                <!-- events -->
                <!-- for events in column -->
                <div *ngFor="let timeEvent of column.events; trackBy: trackByWeekTimeEvent"
                     #event
                     class="cal-event-container"
                     [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
                     [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
                     [ngClass]="timeEvent.event.cssClass"
                     [hidden]="timeEvent.height === 0 && timeEvent.width === 0"
                     [style.top.px]="timeEvent.top"
                     [style.height.px]="timeEvent.height"
                     [style.left.%]="timeEvent.left"
                     [style.width.%]="timeEvent.width"
                     [class.cal-draggable]="timeEvent.event.draggable"
                     [draggable]="timeEvent.event.draggable"
                     (dragstart)="onEventDragStart($event, timeEvent.event)"
                     (dragend)="onEventDragEnd($event)">
                  <!-- event -->
                  <div class="full-size"
                       role="application">
                    <ng-template [ngTemplateOutlet]="eventTemplate"
                                 [ngTemplateOutletContext]="{
                                    timeEvent: timeEvent,
                                    event: timeEvent.event,
                                    column: column
                                  }">
                    </ng-template>

                  </div>
                </div>

                <!-- for hours in column -->
                <div *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd"
                     class="cal-hour"
                     [class.cal-hour-odd]="odd">
                  <datex-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                                                         [style.height.px]="hourSegmentHeight"
                                                         [segment]="segment"
                                                         [segmentHeight]="hourSegmentHeight"
                                                         [locale]="locale"
                                                         [customTemplate]="hourSegmentTemplate"
                                                         (click)="onHourSegmentClicked(segment.date, columnIndex, $event)"
                                                         (dragenter)="onSegmentDragEnter($event)"
                                                         (dragover)="onSegmentDragOver($event)"
                                                         (dragleave)="onSegmentDragLeave($event)"
                                                         (drop)="onEventDroppedInHourSegment($event, segment, columnIndex)">
                  </datex-calendar-week-view-hour-segment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasUnscheduledEvents"
       class="unscheduled-container">
    <div class="unscheduled-events">
      <div class="unscheduled-header">
        <div class="unscheduled-title">{{unscheduledEventsTitle}}</div>
        <div>
          <span class="unscheduled-count">{{totalUnscheduledEventsText}}</span>
        </div>
        <div class="unscheduled-search">
          <ng-content select="[unscheduledEventsToolbar]"></ng-content>
        </div>
      </div>
      <div class="unscheduled-content">
        <div *ngIf="ready"
             class="card-list"
             [style.min-height.px]="hourSegmentHeight"
             (dragenter)="onUnscheduledDragEnter($event)"
             (dragover)="onUnscheduledDragOver($event)"
             (dragleave)="onUnscheduledDragLeave($event)"
             (drop)="onEventDroppedInUnscheduled($event)">
          <div class="local-event-container full-width"
               *ngFor="let event of unscheduledEvents;"
               [style.height.px]="hourSegmentHeight - 8*2"
               [class.cal-draggable]="event.draggable"
               [draggable]="event.draggable"
               (dragstart)="onEventDragStart($event, event)"
               (dragend)="onEventDragEnd($event)">
            <ng-template [ngTemplateOutlet]="unscheduledEventTemplate"
                         [ngTemplateOutletContext]="{
                                        event: event
                                      }">
            </ng-template>
          </div>
        </div>
        <div *ngIf="hasMoreUnscheduledEvents()"
             class="unscheduled-footer">
          <button (click)="onLoadMoreUnscheduledEventsClick()"
                  class="datex-button secondary load-more">
            <div class="button-label">
              <div class="button-icon"><i class="icon icon-ic_fluent_arrow_clockwise_20_regular"></i></div>
              <div class="button-text">Load more</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>