import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { EndeavorLabs_dg_get_pick_weightService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_check_for_annotation_updateService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_export_to_fileService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_get_ownerid_from_shipmentidService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_get_shipment_contents_by_shipment_id_customService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_get_uom_by_shipmentService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_list_shipmentsService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_load_timeService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_pick_timeService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_date_filterService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_flow_orderService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_flow_reportService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_gridService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_max_loadService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_max_ship_timeService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_min_loadService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_min_ship_timeService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_prediction_order_filterService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_predictions_orderService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_predictions_rawService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_shipment_casesService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_shipment_detailsService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_shipment_listService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_task_predictionsService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_ds_warehouseService } from './EndeavorLabs.datasource.index';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public EndeavorLabs: EndeavorLabs_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _dg_get_pick_weight: EndeavorLabs_dg_get_pick_weightService;
  public get dg_get_pick_weight(): EndeavorLabs_dg_get_pick_weightService {
    if(!this._dg_get_pick_weight) {
      this._dg_get_pick_weight = this.injector.get(EndeavorLabs_dg_get_pick_weightService);
    }
    return this._dg_get_pick_weight;
  }
  private _ds_check_for_annotation_update: EndeavorLabs_ds_check_for_annotation_updateService;
  public get ds_check_for_annotation_update(): EndeavorLabs_ds_check_for_annotation_updateService {
    if(!this._ds_check_for_annotation_update) {
      this._ds_check_for_annotation_update = this.injector.get(EndeavorLabs_ds_check_for_annotation_updateService);
    }
    return this._ds_check_for_annotation_update;
  }
  private _ds_export_to_file: EndeavorLabs_ds_export_to_fileService;
  public get ds_export_to_file(): EndeavorLabs_ds_export_to_fileService {
    if(!this._ds_export_to_file) {
      this._ds_export_to_file = this.injector.get(EndeavorLabs_ds_export_to_fileService);
    }
    return this._ds_export_to_file;
  }
  private _ds_get_ownerid_from_shipmentid: EndeavorLabs_ds_get_ownerid_from_shipmentidService;
  public get ds_get_ownerid_from_shipmentid(): EndeavorLabs_ds_get_ownerid_from_shipmentidService {
    if(!this._ds_get_ownerid_from_shipmentid) {
      this._ds_get_ownerid_from_shipmentid = this.injector.get(EndeavorLabs_ds_get_ownerid_from_shipmentidService);
    }
    return this._ds_get_ownerid_from_shipmentid;
  }
  private _ds_get_shipment_contents_by_shipment_id_custom: EndeavorLabs_ds_get_shipment_contents_by_shipment_id_customService;
  public get ds_get_shipment_contents_by_shipment_id_custom(): EndeavorLabs_ds_get_shipment_contents_by_shipment_id_customService {
    if(!this._ds_get_shipment_contents_by_shipment_id_custom) {
      this._ds_get_shipment_contents_by_shipment_id_custom = this.injector.get(EndeavorLabs_ds_get_shipment_contents_by_shipment_id_customService);
    }
    return this._ds_get_shipment_contents_by_shipment_id_custom;
  }
  private _ds_get_uom_by_shipment: EndeavorLabs_ds_get_uom_by_shipmentService;
  public get ds_get_uom_by_shipment(): EndeavorLabs_ds_get_uom_by_shipmentService {
    if(!this._ds_get_uom_by_shipment) {
      this._ds_get_uom_by_shipment = this.injector.get(EndeavorLabs_ds_get_uom_by_shipmentService);
    }
    return this._ds_get_uom_by_shipment;
  }
  private _ds_list_shipments: EndeavorLabs_ds_list_shipmentsService;
  public get ds_list_shipments(): EndeavorLabs_ds_list_shipmentsService {
    if(!this._ds_list_shipments) {
      this._ds_list_shipments = this.injector.get(EndeavorLabs_ds_list_shipmentsService);
    }
    return this._ds_list_shipments;
  }
  private _ds_load_time: EndeavorLabs_ds_load_timeService;
  public get ds_load_time(): EndeavorLabs_ds_load_timeService {
    if(!this._ds_load_time) {
      this._ds_load_time = this.injector.get(EndeavorLabs_ds_load_timeService);
    }
    return this._ds_load_time;
  }
  private _ds_pick_time: EndeavorLabs_ds_pick_timeService;
  public get ds_pick_time(): EndeavorLabs_ds_pick_timeService {
    if(!this._ds_pick_time) {
      this._ds_pick_time = this.injector.get(EndeavorLabs_ds_pick_timeService);
    }
    return this._ds_pick_time;
  }
  private _ds_prediction_date_filter: EndeavorLabs_ds_prediction_date_filterService;
  public get ds_prediction_date_filter(): EndeavorLabs_ds_prediction_date_filterService {
    if(!this._ds_prediction_date_filter) {
      this._ds_prediction_date_filter = this.injector.get(EndeavorLabs_ds_prediction_date_filterService);
    }
    return this._ds_prediction_date_filter;
  }
  private _ds_prediction_flow_order: EndeavorLabs_ds_prediction_flow_orderService;
  public get ds_prediction_flow_order(): EndeavorLabs_ds_prediction_flow_orderService {
    if(!this._ds_prediction_flow_order) {
      this._ds_prediction_flow_order = this.injector.get(EndeavorLabs_ds_prediction_flow_orderService);
    }
    return this._ds_prediction_flow_order;
  }
  private _ds_prediction_flow_report: EndeavorLabs_ds_prediction_flow_reportService;
  public get ds_prediction_flow_report(): EndeavorLabs_ds_prediction_flow_reportService {
    if(!this._ds_prediction_flow_report) {
      this._ds_prediction_flow_report = this.injector.get(EndeavorLabs_ds_prediction_flow_reportService);
    }
    return this._ds_prediction_flow_report;
  }
  private _ds_prediction_grid: EndeavorLabs_ds_prediction_gridService;
  public get ds_prediction_grid(): EndeavorLabs_ds_prediction_gridService {
    if(!this._ds_prediction_grid) {
      this._ds_prediction_grid = this.injector.get(EndeavorLabs_ds_prediction_gridService);
    }
    return this._ds_prediction_grid;
  }
  private _ds_prediction_max_load: EndeavorLabs_ds_prediction_max_loadService;
  public get ds_prediction_max_load(): EndeavorLabs_ds_prediction_max_loadService {
    if(!this._ds_prediction_max_load) {
      this._ds_prediction_max_load = this.injector.get(EndeavorLabs_ds_prediction_max_loadService);
    }
    return this._ds_prediction_max_load;
  }
  private _ds_prediction_max_ship_time: EndeavorLabs_ds_prediction_max_ship_timeService;
  public get ds_prediction_max_ship_time(): EndeavorLabs_ds_prediction_max_ship_timeService {
    if(!this._ds_prediction_max_ship_time) {
      this._ds_prediction_max_ship_time = this.injector.get(EndeavorLabs_ds_prediction_max_ship_timeService);
    }
    return this._ds_prediction_max_ship_time;
  }
  private _ds_prediction_min_load: EndeavorLabs_ds_prediction_min_loadService;
  public get ds_prediction_min_load(): EndeavorLabs_ds_prediction_min_loadService {
    if(!this._ds_prediction_min_load) {
      this._ds_prediction_min_load = this.injector.get(EndeavorLabs_ds_prediction_min_loadService);
    }
    return this._ds_prediction_min_load;
  }
  private _ds_prediction_min_ship_time: EndeavorLabs_ds_prediction_min_ship_timeService;
  public get ds_prediction_min_ship_time(): EndeavorLabs_ds_prediction_min_ship_timeService {
    if(!this._ds_prediction_min_ship_time) {
      this._ds_prediction_min_ship_time = this.injector.get(EndeavorLabs_ds_prediction_min_ship_timeService);
    }
    return this._ds_prediction_min_ship_time;
  }
  private _ds_prediction_order_filter: EndeavorLabs_ds_prediction_order_filterService;
  public get ds_prediction_order_filter(): EndeavorLabs_ds_prediction_order_filterService {
    if(!this._ds_prediction_order_filter) {
      this._ds_prediction_order_filter = this.injector.get(EndeavorLabs_ds_prediction_order_filterService);
    }
    return this._ds_prediction_order_filter;
  }
  private _ds_predictions_order: EndeavorLabs_ds_predictions_orderService;
  public get ds_predictions_order(): EndeavorLabs_ds_predictions_orderService {
    if(!this._ds_predictions_order) {
      this._ds_predictions_order = this.injector.get(EndeavorLabs_ds_predictions_orderService);
    }
    return this._ds_predictions_order;
  }
  private _ds_predictions_raw: EndeavorLabs_ds_predictions_rawService;
  public get ds_predictions_raw(): EndeavorLabs_ds_predictions_rawService {
    if(!this._ds_predictions_raw) {
      this._ds_predictions_raw = this.injector.get(EndeavorLabs_ds_predictions_rawService);
    }
    return this._ds_predictions_raw;
  }
  private _ds_shipment_cases: EndeavorLabs_ds_shipment_casesService;
  public get ds_shipment_cases(): EndeavorLabs_ds_shipment_casesService {
    if(!this._ds_shipment_cases) {
      this._ds_shipment_cases = this.injector.get(EndeavorLabs_ds_shipment_casesService);
    }
    return this._ds_shipment_cases;
  }
  private _ds_shipment_details: EndeavorLabs_ds_shipment_detailsService;
  public get ds_shipment_details(): EndeavorLabs_ds_shipment_detailsService {
    if(!this._ds_shipment_details) {
      this._ds_shipment_details = this.injector.get(EndeavorLabs_ds_shipment_detailsService);
    }
    return this._ds_shipment_details;
  }
  private _ds_shipment_list: EndeavorLabs_ds_shipment_listService;
  public get ds_shipment_list(): EndeavorLabs_ds_shipment_listService {
    if(!this._ds_shipment_list) {
      this._ds_shipment_list = this.injector.get(EndeavorLabs_ds_shipment_listService);
    }
    return this._ds_shipment_list;
  }
  private _ds_task_predictions: EndeavorLabs_ds_task_predictionsService;
  public get ds_task_predictions(): EndeavorLabs_ds_task_predictionsService {
    if(!this._ds_task_predictions) {
      this._ds_task_predictions = this.injector.get(EndeavorLabs_ds_task_predictionsService);
    }
    return this._ds_task_predictions;
  }
  private _ds_warehouse: EndeavorLabs_ds_warehouseService;
  public get ds_warehouse(): EndeavorLabs_ds_warehouseService {
    if(!this._ds_warehouse) {
      this._ds_warehouse = this.injector.get(EndeavorLabs_ds_warehouseService);
    }
    return this._ds_warehouse;
  }
}

