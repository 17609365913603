<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="reset_to_accruing" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_reset_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="clean_transactions" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_clean($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Invoices-transaction_statuses_dd_single 
            data-cy="selector"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
            [tooltip]="filters.status.control.tooltip"
        >
        </Invoices-transaction_statuses_dd_single>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="instructionName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.instructionName.displayControl.styles.style"
          [ngClass]="row.cells.instructionName.displayControl.styles.classes"
          [matTooltip]="row.cells.instructionName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.instructionName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="instructionId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.instructionId.displayControl.styles.style"
          [ngClass]="row.cells.instructionId.displayControl.styles.classes"
          [matTooltip]="row.cells.instructionId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.instructionId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="projectId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.projectId.displayControl.styles.style"
          [ngClass]="row.cells.projectId.displayControl.styles.classes"
          [matTooltip]="row.cells.projectId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.projectId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingContractId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billingContractId.displayControl.styles.style"
          [ngClass]="row.cells.billingContractId.displayControl.styles.classes"
          [matTooltip]="row.cells.billingContractId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billingContractId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="invoiceId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.invoiceId.displayControl.styles.style"
          [ngClass]="row.cells.invoiceId.displayControl.styles.classes"
          [matTooltip]="row.cells.invoiceId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.invoiceId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipmentId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipmentId.displayControl.styles.style"
          [ngClass]="row.cells.shipmentId.displayControl.styles.classes"
          [matTooltip]="row.cells.shipmentId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipmentId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouseId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouseId.displayControl.styles.style"
          [ngClass]="row.cells.warehouseId.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouseId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouseId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingTaskId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billingTaskId.displayControl.styles.style"
          [ngClass]="row.cells.billingTaskId.displayControl.styles.classes"
          [matTooltip]="row.cells.billingTaskId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billingTaskId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="process">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.process.displayControl.styles.style"
          [ngClass]="row.cells.process.displayControl.styles.classes"
          [matTooltip]="row.cells.process.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.process.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingAggregationStrategyIds">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billingAggregationStrategyIds.displayControl.styles.style"
          [ngClass]="row.cells.billingAggregationStrategyIds.displayControl.styles.classes"
          [matTooltip]="row.cells.billingAggregationStrategyIds.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billingAggregationStrategyIds.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transactionId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.transactionId.displayControl.styles.style"
          [ngClass]="row.cells.transactionId.displayControl.styles.classes"
          [matTooltip]="row.cells.transactionId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.transactionId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="requestId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.requestId.displayControl.styles.style"
          [ngClass]="row.cells.requestId.displayControl.styles.classes"
          [matTooltip]="row.cells.requestId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.requestId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="createdOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.createdOn.displayControl.styles.style"
          [ngClass]="row.cells.createdOn.displayControl.styles.classes"
          [matTooltip]="row.cells.createdOn.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.createdOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modifiedOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modifiedOn.displayControl.styles.style"
          [ngClass]="row.cells.modifiedOn.displayControl.styles.classes"
          [matTooltip]="row.cells.modifiedOn.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modifiedOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
