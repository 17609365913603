<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template toolbarToolDef="auto_email_execute_main" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_auto_email_execute_main_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.transactions.hidden" class="tab" data-cy="tab-transactions">
              <h2 [className]="tabs.transactions.active? 'active': ''" (click)="tabs.transactions.activate()">{{tabs.transactions.title}}</h2>
            </div>
            <div *ngIf="!tabs.logs.hidden" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
            <div *ngIf="!tabs.rule_data.hidden" class="tab" data-cy="tab-rule_data">
              <h2 [className]="tabs.rule_data.active? 'active': ''" (click)="tabs.rule_data.activate()">{{tabs.rule_data.title}}</h2>
            </div>
          </div>
        
              <Invoices-invoicing_transactions_grid *ngIf="tabs.transactions.active"
              #$tabs_transactions
              ($refreshEvent)="refresh(false, false, '$tabs_transactions')">
              </Invoices-invoicing_transactions_grid>
              <Invoices-invoicing_logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </Invoices-invoicing_logs_grid>
              <ng-container
                *ngIf="tabs.rule_data.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.rule_data.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.rule_data.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.rule_data.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <Invoices-invoicing_rule_template_exceptions_grid
                   *ngIf="tabs.rule_data.tabs.exceptions.active"
                  #$tabs_rule_data_exceptions
                  ($refreshEvent)="refresh(false, false, '$tabs_rule_data_exceptions')">
                  </Invoices-invoicing_rule_template_exceptions_grid>
                  <Invoices-priorities_grid
                   *ngIf="tabs.rule_data.tabs.priorities.active"
                  #$tabs_rule_data_priorities
                  ($refreshEvent)="refresh(false, false, '$tabs_rule_data_priorities')">
                  </Invoices-priorities_grid>
              </ng-container>
        </div>

      </ng-container>
    </div>
  </div>
</div>