<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-serial_lookup" *ngIf="!filters.serial_lookup.hidden" 
            class="field-container standard {{filters.serial_lookup.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.serial_lookup.styles.style"
            [ngClass]="filters.serial_lookup.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.serial_lookup.label + (filters.serial_lookup.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.serial_lookup.label}}<span *ngIf="filters.serial_lookup.required" class="required-asterisk">*</span>
          </label>
        </div>
        <input data-cy="textBox" formControlName="serial_lookup"
                matInput
                autocomplete="off"
                spellcheck="false"
                class="datex-textbox {{filters.serial_lookup.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.serial_lookup.control.placeholder}}"
                [ngStyle]="filters.serial_lookup.control.styles.style"
                [ngClass]="filters.serial_lookup.control.styles.classes"
                [matTooltip]="filters.serial_lookup.control.tooltip"
                matTooltipClass="datex-control-tooltip"> 
        <ng-container *ngIf="filters.serial_lookup.invalid">
          <ng-container *ngFor="let error of filters.serial_lookup.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available.displayControl.styles.style"
          [ngClass]="row.cells.available.displayControl.styles.classes"
          [matTooltip]="row.cells.available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.material_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes"
          [matTooltip]="row.cells.expiration_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes"
          [matTooltip]="row.cells.manufacture_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes"
          [matTooltip]="row.cells.material_description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
