import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryCounts_ShellService } from './InventoryCounts.shell.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { InventoryCounts_LocalizationService } from './InventoryCounts.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryCounts.frontend.types'
import { $frontendTypes as $types} from './InventoryCounts.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryCounts-inventorycounts_library_home',
  templateUrl: './InventoryCounts.inventorycounts_library_home.component.html'
})
export class InventoryCounts_inventorycounts_library_homeComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    contents: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    location_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      create_inventory_count: new ToolModel(new ButtonModel('create_inventory_count', new ButtonStyles(null, null), false, 'Create inventory count', '', null)
    ),
      get_location_filter_xml: new ToolModel(new ButtonModel('get_location_filter_xml', new ButtonStyles(null, null), false, 'Get location filter xml', '', null)
    )
  };

  fields = {
    description: new FieldModel(new LabelModel(null, null, null)
, new ControlContainerStyles(null, null), 'Description', false),
    contents: new FieldModel(new TextBoxModel(this.formGroup.controls['contents'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Contents', false),
    warehouse_id: new FieldModel(new TextBoxModel(this.formGroup.controls['warehouse_id'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Warehouse id', false),
    location_id: new FieldModel(new TextBoxModel(this.formGroup.controls['location_id'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Location id', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Inventory counts library', false, true, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    contents: this.fields.contents.control.valueChanges
    ,
    warehouse_id: this.fields.warehouse_id.control.valueChanges
    ,
    location_id: this.fields.location_id.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: InventoryCounts_ShellService,
private datasources: InventoryCounts_DatasourceService,
private flows: InventoryCounts_FlowService,
private reports: InventoryCounts_ReportService,
private localization: InventoryCounts_LocalizationService,
private operations: InventoryCounts_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.description.control as LabelModel).label = `Library to access FootPrint InventoryCounts, includes various grids, editors and forms to create, view and manipulate inventory counts and their associated entities.`;
    
    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .contents
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .location_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_create_inventory_count_clicked(event = null) {
    return this.on_create_inventory_count_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_count_clickedInternal(
    $form: InventoryCounts_inventorycounts_library_homeComponent,
  
    $shell: InventoryCounts_ShellService,
    $datasources: InventoryCounts_DatasourceService,
    $flows: InventoryCounts_FlowService,
    $reports: InventoryCounts_ReportService,
    $settings: SettingsValuesService,
    $operations: InventoryCounts_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: InventoryCounts_LocalizationService,
    $event: any
  ) {
    this.logger.log('InventoryCounts', 'inventorycounts_library_home.on_create_inventory_count_clicked');
  await $flows.InventoryCounts.create_inventory_count_flow({ warehouseId: $form.fields.warehouse_id.control.value });
  }
  on_update_location_filter_clicked(event = null) {
    return this.on_update_location_filter_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_location_filter_clickedInternal(
    $form: InventoryCounts_inventorycounts_library_homeComponent,
  
    $shell: InventoryCounts_ShellService,
    $datasources: InventoryCounts_DatasourceService,
    $flows: InventoryCounts_FlowService,
    $reports: InventoryCounts_ReportService,
    $settings: SettingsValuesService,
    $operations: InventoryCounts_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: InventoryCounts_LocalizationService,
    $event: any
  ) {
    this.logger.log('InventoryCounts', 'inventorycounts_library_home.on_update_location_filter_clicked');
  let result = (await $flows.InventoryCounts.get_location_filter_xml_string_flow({ locationIds: [$form.fields.location_id.control.value] }));
  
  await $shell.InventoryCounts.openErrorDialog('Location filter', null, null, 'Details', [
      { message: 'XML', detail: result.locationFilterXmlString },
      { message: 'Reasons', detail: result.errors.join(', ') },
  ]);
  }
  //#endregion private flows
}
