import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_inventory_licenseplate_gridComponent } from './FootPrintManager.inventory_licenseplate_grid.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { Inventory_licenseplate_total_packaged_amount_widgetComponent } from './Inventory.licenseplate_total_packaged_amount_widget.component';
import { Inventory_licenseplate_total_gross_weight_widgetComponent } from './Inventory.licenseplate_total_gross_weight_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_licenseplate_gridComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_serialnumbers_gridComponent),
    forwardRef(() => Inventory_licenseplate_total_packaged_amount_widgetComponent),
    forwardRef(() => Inventory_licenseplate_total_gross_weight_widgetComponent),
  ],
  selector: 'FootPrintManager-single_licenseplate_hub',
  templateUrl: './FootPrintManager.single_licenseplate_hub.component.html'
})
export class FootPrintManager_single_licenseplate_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateId: number } = { licenseplateId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string, hubLicensePlateIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, 'Create inventory', 'icon-ic_fluent_add_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_clipboard_bullet_list_ltr_20_regular', null)
    )
  };

  actionbar = {
      custom_fields: new ToolModel(new ButtonModel('custom_fields', new ButtonStyles(null, null), false, 'Custom fields', 'icon-ic_fluent_edit_20_regular', null)
    ),
      audit: new ToolModel(new ButtonModel('audit', new ButtonStyles(null, null), false, 'Audit license plate', 'icon-ic_fluent_clipboard_checkmark_20_regular', null)
    )
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
      serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_inventory_inventory_licenseplate_grid_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds: number[];
    get $tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.hubLicensePlateIds;
      
      if(!isEqual(this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds, expr)) {
        this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds = expr;
      }
      return this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory', { read: FootPrintManager_inventory_licenseplate_gridComponent }) $tabs_inventory: FootPrintManager_inventory_licenseplate_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_serialnumbers', { read: FootPrintManager_serialnumbers_gridComponent }) $tabs_serialnumbers: FootPrintManager_serialnumbers_gridComponent;
    //#endregion tabs children
    widgets = {
      licenseplate_total_packaged_amount_widget: new WidgetModel(),
      licenseplate_total_gross_weight_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_licenseplate_total_packaged_amount_widget_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    get $widgets_licenseplate_total_gross_weight_widget_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_licenseplate_total_packaged_amount_widget', { read:  Inventory_licenseplate_total_packaged_amount_widgetComponent }) $widgets_licenseplate_total_packaged_amount_widget: Inventory_licenseplate_total_packaged_amount_widgetComponent;
      @ViewChild('$widgets_licenseplate_total_gross_weight_widget', { read:  Inventory_licenseplate_total_gross_weight_widgetComponent }) $widgets_licenseplate_total_gross_weight_widget: Inventory_licenseplate_total_gross_weight_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
      this.tabs.tasks,
      this.tabs.serialnumbers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Licenseplate Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_licenseplate_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_licenseplate_total_packaged_amount_widget) && !this.widgets.licenseplate_total_packaged_amount_widget.hidden) {
        this.$widgets_licenseplate_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_licenseplate_total_gross_weight_widget') {
      if (!isNil(this.$widgets_licenseplate_total_gross_weight_widget) && !this.widgets.licenseplate_total_gross_weight_widget.hidden) {
        this.$widgets_licenseplate_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_serialnumbers') {
      if (!isNil(this.$tabs_serialnumbers) && !this.tabs.serialnumbers.hidden) {
        this.$tabs_serialnumbers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      licenseplateId: $hub.inParams.licenseplateId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $hub.inParams.licenseplateId })).result;
  let licensePlateIds: number[] = [];
  if ($utils.isDefined(licenseplate)) {
  
      $hub.title = `Licenseplate ${licenseplate.LookupCode}`;
  
      const title = `${licenseplate.LookupCode} Status ${licenseplate.Status.Name}  Location ${licenseplate.Location.Name}  Warehouse ${licenseplate.Warehouse.Name}`;
      const description = `Type ${licenseplate.Type.Name}  Archived ${licenseplate.Archived}  `;
      $hub.vars.hubDescription = description;
      $hub.vars.hubTitle = title;
      licensePlateIds.push(licenseplate?.Id);
      $hub.vars.hubLicensePlateIds = licensePlateIds;
  
      
  
  }
  
  // Hide serialnumbers tab if no serialnumbers exists
  const serialnumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_various_top1.get({ licenseplateId: $hub.inParams.licenseplateId })).result;
  if ($utils.isDefined(serialnumber)) {
  
      $hub.tabs.serialnumbers.hidden = false;
  }
  else {
      $hub.tabs.serialnumbers.hidden = true;
  }
  
  //Hide Create Inventory button for any License plate Type that is NOT ivnentory or Loose. 
  if(licenseplate.TypeId !== 1 && licenseplate.TypeId !== 3){
      $hub.toolbar.create_inventory.hidden = true;
  }
  
  $hub.toolbar.create_inventory.hidden = (await $operations.FootPrintManager.Disable_Inventory_Creation.isAuthorized());
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openlicenseplate_editorDialog({licenseplateId: $hub.inParams.licenseplateId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
    if (userConfirmed) {
  
        await $hub.on_init();
        await $hub.refresh();
    }
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'LicensePlate', ids: [$hub.inParams.licenseplateId] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'LicensePlate', entityKeys: [{name: 'Id', value: $hub.inParams.licenseplateId}]}, 'modal');
  }
  on_audit_clicked(event = null) {
    return this.on_audit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_audit_clickedInternal(
    $hub: FootPrintManager_single_licenseplate_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openaudit_serial_numbers_hub({ licensePlateId: $hub.inParams.licenseplateId });
  }
  //#endregion private flows
}
