import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { LoadContainers_load_containers_library_homeComponent } from './LoadContainers.load_containers_library_home.component';
import { LoadContainers_carrier_service_types_singleComponent } from './LoadContainers.carrier_service_types_single.component';
import { LoadContainers_carriers_singleComponent } from './LoadContainers.carriers_single.component';
import { LoadContainers_container_sizes_singleComponent } from './LoadContainers.container_sizes_single.component';
import { LoadContainers_container_types_singleComponent } from './LoadContainers.container_types_single.component';
import { LoadContainers_load_container_date_types_singleComponent } from './LoadContainers.load_container_date_types_single.component';
import { LoadContainers_load_container_statuses_singleComponent } from './LoadContainers.load_container_statuses_single.component';
import { LoadContainers_order_date_types_singleComponent } from './LoadContainers.order_date_types_single.component';
import { LoadContainers_order_types_singleComponent } from './LoadContainers.order_types_single.component';
import { LoadContainers_shipment_priorities_singleComponent } from './LoadContainers.shipment_priorities_single.component';
import { LoadContainers_unassigned_orders_singleComponent } from './LoadContainers.unassigned_orders_single.component';
import { LoadContainers_carrier_service_types_multiComponent } from './LoadContainers.carrier_service_types_multi.component';
import { LoadContainers_carriers_multiComponent } from './LoadContainers.carriers_multi.component';
import { LoadContainers_container_sizes_multiComponent } from './LoadContainers.container_sizes_multi.component';
import { LoadContainers_container_types_multiComponent } from './LoadContainers.container_types_multi.component';
import { LoadContainers_load_container_date_types_multiComponent } from './LoadContainers.load_container_date_types_multi.component';
import { LoadContainers_load_container_statuses_multiComponent } from './LoadContainers.load_container_statuses_multi.component';
import { LoadContainers_order_date_types_multiComponent } from './LoadContainers.order_date_types_multi.component';
import { LoadContainers_order_types_multiComponent } from './LoadContainers.order_types_multi.component';
import { LoadContainers_shipment_priorities_multiComponent } from './LoadContainers.shipment_priorities_multi.component';
import { LoadContainers_unassigned_orders_multiComponent } from './LoadContainers.unassigned_orders_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public LoadContainers: LoadContainers_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openload_containers_library_home(replaceCurrentView?: boolean) {
    this.logger.log('LoadContainers', 'load_containers_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'LoadContainers_load_containers_library_home',
        component: LoadContainers_load_containers_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openload_containers_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('LoadContainers', 'load_containers_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LoadContainers_load_containers_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'LoadContainers_load_containers_library_home') {
      this.logger.log('LoadContainers', 'load_containers_library_home');
      const title = 'Home';
      const component = LoadContainers_load_containers_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_carrier_service_types_single') {
      const title = 'Carrier Service Types';
      const component = LoadContainers_carrier_service_types_singleComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_carriers_single') {
      const title = 'Carriers';
      const component = LoadContainers_carriers_singleComponent;
      const inParams:{ carrierId?: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_container_sizes_single') {
      this.logger.log('LoadContainers', 'container_sizes_single');
      const title = 'Container Sizes';
      const component = LoadContainers_container_sizes_singleComponent;
      const inParams:{ containerSizeId?: number } = { containerSizeId: null };
      if (!isNil(params.get('containerSizeId'))) {
        const paramValueString = params.get('containerSizeId');
        inParams.containerSizeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_container_types_single') {
      const title = 'Container Types';
      const component = LoadContainers_container_types_singleComponent;
      const inParams:{ containerTypeId?: number } = { containerTypeId: null };
      if (!isNil(params.get('containerTypeId'))) {
        const paramValueString = params.get('containerTypeId');
        inParams.containerTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_load_container_date_types_single') {
      const title = 'Load Container Date Types';
      const component = LoadContainers_load_container_date_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_load_container_statuses_single') {
      const title = 'Load Container Statuses';
      const component = LoadContainers_load_container_statuses_singleComponent;
      const inParams:{ excludedStatusId?: number } = { excludedStatusId: null };
      if (!isNil(params.get('excludedStatusId'))) {
        const paramValueString = params.get('excludedStatusId');
        inParams.excludedStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_order_date_types_single') {
      const title = 'Order Date Types';
      const component = LoadContainers_order_date_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_order_types_single') {
      const title = 'Order Types';
      const component = LoadContainers_order_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_shipment_priorities_single') {
      const title = 'Shipment Priorities';
      const component = LoadContainers_shipment_priorities_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_unassigned_orders_single') {
      this.logger.log('LoadContainers', 'unassigned_orders_single');
      const title = 'Unassigned Orders';
      const component = LoadContainers_unassigned_orders_singleComponent;
      const inParams:{ projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, statusIds?: number[], warehouseId?: number, ownerId?: number } = { projectId: null, orderTypeId: null, fromDate: null, toDate: null, dateType: null, statusIds: [], warehouseId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('dateType'))) {
        const paramValueString = params.get('dateType');
        // TODO: date
        inParams.dateType = paramValueString;
              }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_carrier_service_types_multi') {
      const title = 'Carrier Service Types';
      const component = LoadContainers_carrier_service_types_multiComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_carriers_multi') {
      const title = 'Carriers';
      const component = LoadContainers_carriers_multiComponent;
      const inParams:{ carrierId?: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_container_sizes_multi') {
      this.logger.log('LoadContainers', 'container_sizes_multi');
      const title = 'Container Sizes';
      const component = LoadContainers_container_sizes_multiComponent;
      const inParams:{ containerSizeId?: number } = { containerSizeId: null };
      if (!isNil(params.get('containerSizeId'))) {
        const paramValueString = params.get('containerSizeId');
        inParams.containerSizeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_container_types_multi') {
      const title = 'Container Types';
      const component = LoadContainers_container_types_multiComponent;
      const inParams:{ containerTypeId?: number } = { containerTypeId: null };
      if (!isNil(params.get('containerTypeId'))) {
        const paramValueString = params.get('containerTypeId');
        inParams.containerTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_load_container_date_types_multi') {
      const title = 'Load Container Date Types';
      const component = LoadContainers_load_container_date_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_load_container_statuses_multi') {
      const title = 'Load Container Statuses';
      const component = LoadContainers_load_container_statuses_multiComponent;
      const inParams:{ excludedStatusId?: number } = { excludedStatusId: null };
      if (!isNil(params.get('excludedStatusId'))) {
        const paramValueString = params.get('excludedStatusId');
        inParams.excludedStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_order_date_types_multi') {
      const title = 'Order Date Types';
      const component = LoadContainers_order_date_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_order_types_multi') {
      const title = 'Order Types';
      const component = LoadContainers_order_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_shipment_priorities_multi') {
      const title = 'Shipment Priorities';
      const component = LoadContainers_shipment_priorities_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LoadContainers_unassigned_orders_multi') {
      this.logger.log('LoadContainers', 'unassigned_orders_multi');
      const title = 'Unassigned Orders';
      const component = LoadContainers_unassigned_orders_multiComponent;
      const inParams:{ projectId?: number, orderTypeId?: number, fromDate?: string, toDate?: string, dateType?: string, statusIds?: number[], warehouseId?: number, ownerId?: number } = { projectId: null, orderTypeId: null, fromDate: null, toDate: null, dateType: null, statusIds: [], warehouseId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('dateType'))) {
        const paramValueString = params.get('dateType');
        // TODO: date
        inParams.dateType = paramValueString;
              }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
