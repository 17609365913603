import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { app_custom_dock_appointment_check_in_options_formComponent } from './app.custom_dock_appointment_check_in_options_form.component';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_dock_appointment_editorComponent } from './app.custom_dock_appointment_editor.component';
import { app_custom_sales_order_editorComponent } from './app.custom_sales_order_editor.component';
import { app_custom_activity_gridComponent } from './app.custom_activity_grid.component';
import { app_custom_dock_appointments_gridComponent } from './app.custom_dock_appointments_grid.component';
import { app_custom_dock_status_gridComponent } from './app.custom_dock_status_grid.component';
import { app_custom_inventory_licenseplate_gridComponent } from './app.custom_inventory_licenseplate_grid.component';
import { app_custom_inventory_location_gridComponent } from './app.custom_inventory_location_grid.component';
import { app_custom_inventory_lot_gridComponent } from './app.custom_inventory_lot_grid.component';
import { app_custom_inventory_material_gridComponent } from './app.custom_inventory_material_grid.component';
import { app_custom_sales_order_lines_gridComponent } from './app.custom_sales_order_lines_grid.component';
import { app_custom_activity_hubComponent } from './app.custom_activity_hub.component';
import { app_custom_dock_appointments_hubComponent } from './app.custom_dock_appointments_hub.component';
import { app_custom_orders_udf_hubComponent } from './app.custom_orders_udf_hub.component';
import { app_custom_outbound_orders_hubComponent } from './app.custom_outbound_orders_hub.component';
import { app_custom_single_location_hubComponent } from './app.custom_single_location_hub.component';
import { app_TestOrderReportComponent } from './app.TestOrderReport.component';
import { app_custom_bol_reportComponent } from './app.custom_bol_report.component';
import { app_custom_inventory_reportComponent } from './app.custom_inventory_report.component';
import { app_custom_inventory_report_by_projectComponent } from './app.custom_inventory_report_by_project.component';
import { app_custom_load_diagram_reportComponent } from './app.custom_load_diagram_report.component';
import { app_custom_task_date_types_dd_singleComponent } from './app.custom_task_date_types_dd_single.component';
import { app_custom_task_date_types_dd_multiComponent } from './app.custom_task_date_types_dd_multi.component';
import { app_custom_order_total_picked_gross_widgetComponent } from './app.custom_order_total_picked_gross_widget.component';
import { app_custom_order_total_picked_net_widgetComponent } from './app.custom_order_total_picked_net_widget.component';
import { app_custom_order_total_units_widgetComponent } from './app.custom_order_total_units_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Attachments_ShellService } from './Attachments.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { EntityImport_ShellService } from './EntityImport.shell.service';
import { ExcelMaterialImport_ShellService } from './ExcelMaterialImport.shell.service';
import { Instructions_ShellService } from './Instructions.shell.service';
import { LoadContainers_ShellService } from './LoadContainers.shell.service';
import { Surveys_ShellService } from './Surveys.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { ExcelLocationImport_ShellService } from './ExcelLocationImport.shell.service';
import { EndeavorLabs_ShellService } from './EndeavorLabs.shell.service';
import { SerialNumbers_ShellService } from './SerialNumbers.shell.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Discussions_ShellService } from './Discussions.shell.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { ExcelInventoryImport_ShellService } from './ExcelInventoryImport.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { Waves_ShellService } from './Waves.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';
import { Orderful_ShellService } from './Orderful.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public Attachments: Attachments_ShellService,
    public Carriers: Carriers_ShellService,
    public EntityImport: EntityImport_ShellService,
    public ExcelMaterialImport: ExcelMaterialImport_ShellService,
    public Instructions: Instructions_ShellService,
    public LoadContainers: LoadContainers_ShellService,
    public Surveys: Surveys_ShellService,
    public Lots: Lots_ShellService,
    public ExcelLocationImport: ExcelLocationImport_ShellService,
    public EndeavorLabs: EndeavorLabs_ShellService,
    public SerialNumbers: SerialNumbers_ShellService,
    public AsnOrders: AsnOrders_ShellService,
    public Owners: Owners_ShellService,
    public Discussions: Discussions_ShellService,
    public ExcelOrderImport: ExcelOrderImport_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public BarTender: BarTender_ShellService,
    public Locations: Locations_ShellService,
    public Notifications: Notifications_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Inventory: Inventory_ShellService,
    public ExcelInventoryImport: ExcelInventoryImport_ShellService,
    public Invoices: Invoices_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public Reports: Reports_ShellService,
    public Materials: Materials_ShellService,
    public Waves: Waves_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
    public Orderful: Orderful_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opencustom_dock_appointment_check_in_options_form(inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_dock_appointment_check_in_options_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Check In',
        referenceName: 'custom_dock_appointment_check_in_options_form',
        component: app_custom_dock_appointment_check_in_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_check_in_options_formDialog(
    inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, scheduledDoorId?: number, assignedDoorId?: number, driverName?: string, driverLicense?: string, vehicleLicense?: string }> {
    this.logger.log('app', 'custom_dock_appointment_check_in_options_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointment_check_in_options_formComponent,
      'Dock Appointment Check In',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_dock_appointment_editor(inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_dock_appointment_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Editor',
        referenceName: 'custom_dock_appointment_editor',
        component: app_custom_dock_appointment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_editorDialog(
    inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_dock_appointment_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointment_editorComponent,
      'Dock Appointment Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'custom_sales_order_editor',
        component: app_custom_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_activity_grid(inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Activity Grid',
        referenceName: 'custom_activity_grid',
        component: app_custom_activity_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_activity_gridDialog(
    inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_activity_gridComponent,
      'Custom Activity Grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointments_grid(inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_dock_appointments_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Dock appointments',
        referenceName: 'custom_dock_appointments_grid',
        component: app_custom_dock_appointments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointments_gridDialog(
    inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_dock_appointments_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointments_gridComponent,
      'Dock appointments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_status_grid(inParams:{ warehouse_ids?: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Dock Status Grid',
        referenceName: 'custom_dock_status_grid',
        component: app_custom_dock_status_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_status_gridDialog(
    inParams:{ warehouse_ids?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_status_gridComponent,
      'Dock Status Grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_licenseplate_grid(inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_licenseplate_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by lp',
        referenceName: 'custom_inventory_licenseplate_grid',
        component: app_custom_inventory_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_licenseplate_gridDialog(
    inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_licenseplate_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_licenseplate_gridComponent,
      'Inventory by lp',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_location_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_location_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by location',
        referenceName: 'custom_inventory_location_grid',
        component: app_custom_inventory_location_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_location_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_location_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_location_gridComponent,
      'Inventory by location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_lot_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_lot_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by lot',
        referenceName: 'custom_inventory_lot_grid',
        component: app_custom_inventory_lot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_lot_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_lot_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_lot_gridComponent,
      'Inventory by lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_material_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_material_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by material',
        referenceName: 'custom_inventory_material_grid',
        component: app_custom_inventory_material_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_material_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_material_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_material_gridComponent,
      'Inventory by material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'custom_sales_order_lines_grid',
        component: app_custom_sales_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_activity_hub(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_activity_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Activity Hub',
        referenceName: 'custom_activity_hub',
        component: app_custom_activity_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_activity_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_activity_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_activity_hubComponent,
      'Activity Hub',
      mode,
      dialogSize
    )
  }
  public opencustom_dock_appointments_hub(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_dock_appointments_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Dock Appointments Hub',
        referenceName: 'custom_dock_appointments_hub',
        component: app_custom_dock_appointments_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_dock_appointments_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_dock_appointments_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_dock_appointments_hubComponent,
      'Custom Dock Appointments Hub',
      mode,
      dialogSize
    )
  }
  public opencustom_orders_udf_hub(inParams:{ orderId?: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_orders_udf_hub',
        referenceName: 'custom_orders_udf_hub',
        component: app_custom_orders_udf_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_orders_udf_hubDialog(
    inParams:{ orderId?: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_orders_udf_hubComponent,
      'custom_orders_udf_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_orders_hub(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_outbound_orders_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Outbound Orders Hub',
        referenceName: 'custom_outbound_orders_hub',
        component: app_custom_outbound_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_outbound_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_outbound_orders_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_outbound_orders_hubComponent,
      'Outbound Orders Hub',
      mode,
      dialogSize
    )
  }
  public opencustom_single_location_hub(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_single_location_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Single Location Hub',
        referenceName: 'custom_single_location_hub',
        component: app_custom_single_location_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_single_location_hubDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_single_location_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_single_location_hubComponent,
      'Custom Single Location Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openTestOrderReport(replaceCurrentView?: boolean) {
    this.logger.log('app', 'TestOrderReport');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Test Report',
        referenceName: 'TestOrderReport',
        component: app_TestOrderReportComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openTestOrderReportDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'TestOrderReport');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_TestOrderReportComponent,
      'Test Report',
      mode,
      dialogSize
    )
  }
  public opencustom_bol_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_bol_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_bol_report',
        referenceName: 'custom_bol_report',
        component: app_custom_bol_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_bol_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_bol_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_bol_reportComponent,
      'custom_bol_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_report(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_report',
        referenceName: 'custom_inventory_report',
        component: app_custom_inventory_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_reportDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_inventory_reportComponent,
      'custom_inventory_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_report_by_project(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_report_by_project',
        referenceName: 'custom_inventory_report_by_project',
        component: app_custom_inventory_report_by_projectComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_report_by_projectDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_inventory_report_by_projectComponent,
      'custom_inventory_report_by_project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_load_diagram_report(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_load_diagram_report',
        referenceName: 'custom_load_diagram_report',
        component: app_custom_load_diagram_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_load_diagram_reportDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_load_diagram_reportComponent,
      'custom_load_diagram_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'custom_dock_appointment_check_in_options_form') {
      this.logger.log('app', 'custom_dock_appointment_check_in_options_form');
      const title = 'Dock Appointment Check In';
      const component = app_custom_dock_appointment_check_in_options_formComponent;
      const inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string } = { title: null, dockAppointmentId: null, warehouseId: null, scheduledDockDoorId: null, appointmentType: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('scheduledDockDoorId'))) {
        const paramValueString = params.get('scheduledDockDoorId');
        inParams.scheduledDockDoorId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointment_editor') {
      this.logger.log('app', 'custom_dock_appointment_editor');
      const title = 'Dock Appointment Editor';
      const component = app_custom_dock_appointment_editorComponent;
      const inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number, shipmentId?: number } = { dockAppointmentId: null, orderId: null, loadContainerId: null, shipmentId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_editor') {
      this.logger.log('app', 'custom_sales_order_editor');
      const title = 'Edit Sales Order';
      const component = app_custom_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_activity_grid') {
      const title = 'Custom Activity Grid';
      const component = app_custom_activity_gridComponent;
      const inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean } = { project_ids: [], owner_ids: [], warehouse_ids: [], operation_codes: [], status_ids: [], location_id: null, seven_days_before_flag: null };
      if (!isNil(params.get('project_ids'))) {
        const paramValueString = params.get('project_ids');
        inParams.project_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('owner_ids'))) {
        const paramValueString = params.get('owner_ids');
        inParams.owner_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operation_codes'))) {
        const paramValueString = params.get('operation_codes');
        inParams.operation_codes = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('status_ids'))) {
        const paramValueString = params.get('status_ids');
        inParams.status_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('seven_days_before_flag'))) {
        const paramValueString = params.get('seven_days_before_flag');
        inParams.seven_days_before_flag = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointments_grid') {
      this.logger.log('app', 'custom_dock_appointments_grid');
      const title = 'Dock appointments';
      const component = app_custom_dock_appointments_gridComponent;
      const inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], fromDate: null, toDate: null, dockDoorIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('dockDoorIds'))) {
        const paramValueString = params.get('dockDoorIds');
        inParams.dockDoorIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_status_grid') {
      const title = 'Dock Status Grid';
      const component = app_custom_dock_status_gridComponent;
      const inParams:{ warehouse_ids?: number[] } = { warehouse_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_licenseplate_grid') {
      this.logger.log('app', 'custom_inventory_licenseplate_grid');
      const title = 'Inventory by lp';
      const component = app_custom_inventory_licenseplate_gridComponent;
      const inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_location_grid') {
      this.logger.log('app', 'custom_inventory_location_grid');
      const title = 'Inventory by location';
      const component = app_custom_inventory_location_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string } = { ownerId: null, projectId: null, warehouseId: null, locationId: null, materialIds: [], lotId: null, zonePath: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('zonePath'))) {
        const paramValueString = params.get('zonePath');
        // TODO: date
        inParams.zonePath = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_lot_grid') {
      this.logger.log('app', 'custom_inventory_lot_grid');
      const title = 'Inventory by lot';
      const component = app_custom_inventory_lot_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_material_grid') {
      this.logger.log('app', 'custom_inventory_material_grid');
      const title = 'Inventory by material';
      const component = app_custom_inventory_material_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_lines_grid') {
      const title = 'Order lines';
      const component = app_custom_sales_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null, warehouseId: null, orderState: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderState'))) {
        const paramValueString = params.get('orderState');
        inParams.orderState = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_activity_hub') {
      this.logger.log('app', 'custom_activity_hub');
      const title = 'Activity Hub';
      const component = app_custom_activity_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_dock_appointments_hub') {
      this.logger.log('app', 'custom_dock_appointments_hub');
      const title = 'Custom Dock Appointments Hub';
      const component = app_custom_dock_appointments_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_orders_udf_hub') {
      const title = 'custom_orders_udf_hub';
      const component = app_custom_orders_udf_hubComponent;
      const inParams:{ orderId?: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orders_hub') {
      this.logger.log('app', 'custom_outbound_orders_hub');
      const title = 'Outbound Orders Hub';
      const component = app_custom_outbound_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_single_location_hub') {
      this.logger.log('app', 'custom_single_location_hub');
      const title = 'Custom Single Location Hub';
      const component = app_custom_single_location_hubComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TestOrderReport') {
      this.logger.log('app', 'TestOrderReport');
      const title = 'Test Report';
      const component = app_TestOrderReportComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_bol_report') {
      this.logger.log('app', 'custom_bol_report');
      const title = 'custom_bol_report';
      const component = app_custom_bol_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_report') {
      this.logger.log('app', 'custom_inventory_report');
      const title = 'custom_inventory_report';
      const component = app_custom_inventory_reportComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_report_by_project') {
      const title = 'custom_inventory_report_by_project';
      const component = app_custom_inventory_report_by_projectComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_load_diagram_report') {
      const title = 'custom_load_diagram_report';
      const component = app_custom_load_diagram_reportComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_task_date_types_dd_single') {
      const title = 'custom_task_date_types_dd';
      const component = app_custom_task_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_task_date_types_dd_multi') {
      const title = 'custom_task_date_types_dd';
      const component = app_custom_task_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_total_picked_gross_widget') {
      const title = 'Total gross picked';
      const component = app_custom_order_total_picked_gross_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_total_picked_net_widget') {
      const title = 'Total net picked';
      const component = app_custom_order_total_picked_net_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_total_units_widget') {
      const title = 'Total amount';
      const component = app_custom_order_total_units_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Attachments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EntityImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelMaterialImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Instructions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LoadContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Surveys.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelLocationImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EndeavorLabs.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SerialNumbers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.AsnOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelOrderImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.BarTender.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelInventoryImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Waves.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Orderful.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
