<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="manage" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.manage_configurations.readOnly"
                              [ngStyle]="tool.control.buttons.manage_configurations.styles.style"
                              [ngClass]="tool.control.buttons.manage_configurations.styles.classes"
                              [matTooltip]="tool.control.buttons.manage_configurations.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_manage_configurations_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.manage_configurations.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.manage_configurations.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.manage_configurations.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-location" *ngIf="!filters.location.hidden" 
                                  class="field-container standard {{filters.location.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.location.styles.style"
                                  [ngClass]="filters.location.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.location.label + (filters.location.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.location.label}}<span *ngIf="filters.location.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <PackVerification-verification_locations_dd_single 
                                  data-cy="selector"
                                  [type]="filters.location.control.type"
                                  formControlName="location"
                                  (displayTextChange)="filters.location.control.displayText=$event"
                                  [placeholder]="filters.location.control.placeholder"
                                  [styles]="filters.location.control.styles"
                                  [tooltip]="filters.location.control.tooltip"
                                [warehouseId]="$fields_location_selector_inParams_warehouseId"
                              >
                              </PackVerification-verification_locations_dd_single>
                              <ng-container *ngIf="filters.location.invalid">
                                <ng-container *ngFor="let error of filters.location.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-container_code" *ngIf="!filters.container_code.hidden" 
                                  class="field-container full {{filters.container_code.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.container_code.styles.style"
                                  [ngClass]="filters.container_code.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.container_code.label + (filters.container_code.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.container_code.label}}<span *ngIf="filters.container_code.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="container_code"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.container_code.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.container_code.control.placeholder}}"
                                      [ngStyle]="filters.container_code.control.styles.style"
                                      [ngClass]="filters.container_code.control.styles.classes"
                                      [matTooltip]="filters.container_code.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.container_code.invalid">
                                <ng-container *ngFor="let error of filters.container_code.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.shipping_containers_to_verify.hidden" class="widget-container">
            <PackVerification-shipping_containers_to_verify_widget
            #$widgets_shipping_containers_to_verify 
              [warehouseId]="$widgets_shipping_containers_to_verify_inParams_warehouseId"
              [locationId]="$widgets_shipping_containers_to_verify_inParams_locationId"
            ($refreshEvent)="refresh(false, false, '$widgets_shipping_containers_to_verify')"
            >
            </PackVerification-shipping_containers_to_verify_widget>
          </div>
          <div *ngIf="!widgets.shipping_containers_failed_verification.hidden" class="widget-container">
            <PackVerification-shipping_containers_failed_verification_widget
            #$widgets_shipping_containers_failed_verification 
              [warehouseId]="$widgets_shipping_containers_failed_verification_inParams_warehouseId"
              [locationId]="$widgets_shipping_containers_failed_verification_inParams_locationId"
            ($refreshEvent)="refresh(false, false, '$widgets_shipping_containers_failed_verification')"
            >
            </PackVerification-shipping_containers_failed_verification_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.containers_to_verify.hidden" class="tab" data-cy="tab-containers_to_verify">
              <h2 [className]="tabs.containers_to_verify.active? 'active': ''" (click)="tabs.containers_to_verify.activate()">{{tabs.containers_to_verify.title}}</h2>
            </div>
            <div *ngIf="!tabs.failed_containers.hidden" class="tab" data-cy="tab-failed_containers">
              <h2 [className]="tabs.failed_containers.active? 'active': ''" (click)="tabs.failed_containers.activate()">{{tabs.failed_containers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-shipping_containers_to_verify_grid *ngIf="tabs.containers_to_verify.active"
              #$tabs_containers_to_verify
              [warehouseId]="$tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_warehouseId"
              [locationId]="$tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_locationId"
              [shippingContainerCode]="$tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_shippingContainerCode"
              [isFailed]="$tabs_containers_to_verify_shipping_containers_to_verify_grid_inParams_isFailed"
              (refocus)="set_focus($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_containers_to_verify')">
              </FootPrintManager-shipping_containers_to_verify_grid>
              <FootPrintManager-shipping_containers_to_verify_grid *ngIf="tabs.failed_containers.active"
              #$tabs_failed_containers
              [warehouseId]="$tabs_failed_containers_shipping_containers_to_verify_grid_inParams_warehouseId"
              [locationId]="$tabs_failed_containers_shipping_containers_to_verify_grid_inParams_locationId"
              [shippingContainerCode]="$tabs_failed_containers_shipping_containers_to_verify_grid_inParams_shippingContainerCode"
              [isFailed]="$tabs_failed_containers_shipping_containers_to_verify_grid_inParams_isFailed"
              (refocus)="set_focus($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_failed_containers')">
              </FootPrintManager-shipping_containers_to_verify_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>