import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Catalogs_catalogs_library_homeComponent } from './Catalogs.catalogs_library_home.component';
import { Catalogs_material_catalogs_dd_singleComponent } from './Catalogs.material_catalogs_dd_single.component';
import { Catalogs_project_subscribers_dd_singleComponent } from './Catalogs.project_subscribers_dd_single.component';
import { Catalogs_material_catalogs_dd_multiComponent } from './Catalogs.material_catalogs_dd_multi.component';
import { Catalogs_project_subscribers_dd_multiComponent } from './Catalogs.project_subscribers_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Catalogs_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Catalogs: Catalogs_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opencatalogs_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Catalogs', 'catalogs_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Catalogs_catalogs_library_home',
        component: Catalogs_catalogs_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencatalogs_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Catalogs', 'catalogs_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Catalogs_catalogs_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Catalogs_catalogs_library_home') {
      this.logger.log('Catalogs', 'catalogs_library_home');
      const title = 'Home';
      const component = Catalogs_catalogs_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Catalogs_material_catalogs_dd_single') {
      const title = 'Material catalogs';
      const component = Catalogs_material_catalogs_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Catalogs_project_subscribers_dd_single') {
      const title = 'Project subscribers dropdown';
      const component = Catalogs_project_subscribers_dd_singleComponent;
      const inParams:{ statusId?: number, catalog_id: number } = { statusId: null, catalog_id: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('catalog_id'))) {
        const paramValueString = params.get('catalog_id');
        inParams.catalog_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Catalogs_material_catalogs_dd_multi') {
      const title = 'Material catalogs';
      const component = Catalogs_material_catalogs_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Catalogs_project_subscribers_dd_multi') {
      const title = 'Project subscribers dropdown';
      const component = Catalogs_project_subscribers_dd_multiComponent;
      const inParams:{ statusId?: number, catalog_id: number } = { statusId: null, catalog_id: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('catalog_id'))) {
        const paramValueString = params.get('catalog_id');
        inParams.catalog_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
