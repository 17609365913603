import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IInvoices_commodity_price_flat_gridComponentEntity {
value1?: number, value2?: number, value3?: number, value4?: number, value5?: number, date?: string, date_number?: number}

interface IInvoices_commodity_price_flat_gridComponentInParams {
  names: string[], title?: string}


class Invoices_commodity_price_flat_gridComponentRowModel extends GridRowModel {
  grid: Invoices_commodity_price_flat_gridComponent;
  entity: IInvoices_commodity_price_flat_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    value1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    value2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    value3: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    value4: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    value5: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_commodity_price_flat_gridComponent, entity: IInvoices_commodity_price_flat_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.date_number].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_commodity_price_flat_gridComponent, entity?: IInvoices_commodity_price_flat_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.date_number].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.date_number;
    const inParams = {
      $keys:[$resultKey],
      names:  $grid.inParams.names ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Invoices.ds_commodity_price_flat_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.value1.displayControl as TextModel).text = $row.entity.value1?.toLocaleString();
    (this.cells.value2.displayControl as TextModel).text = $row.entity.value2?.toLocaleString();
    (this.cells.value3.displayControl as TextModel).text = $row.entity.value3?.toLocaleString();
    (this.cells.value4.displayControl as TextModel).text = $row.entity.value4?.toLocaleString();
    (this.cells.value5.displayControl as TextModel).text = $row.entity.value5?.toLocaleString();
    (this.cells.date.displayControl as TextModel).text = $row.entity.date?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Invoices_commodity_price_flat_gridComponentRowModel,
  $grid: Invoices_commodity_price_flat_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'commodity_price_flat_grid.on_row_data_loaded');
  //O.Arias - 07/26/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.date)) {
          $row.cells.date.displayControl.text = $utils.date.format($row.entity.date, format);
      }
  }
  
  let value_id: number = 0;
  
  for (let name of $grid.inParams.names) {
  
      value_id++;
  
      if (value_id > 5) { break } //Supporting 5 columns for now
  
      if ($utils.isDefined($row.entity[`value${value_id}`])) {
          let num = $row.entity[`value${value_id}`];
          $row.cells[`value${value_id}`].displayControl.text = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-commodity_price_flat_grid',
  templateUrl: './Invoices.commodity_price_flat_grid.component.html'
})
export class Invoices_commodity_price_flat_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_commodity_price_flat_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_commodity_price_flat_gridComponentInParams = { names: [], title: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     value1: new GridHeaderModel(new HeaderStyles(null, null), 'Value1', false, false, null),       value2: new GridHeaderModel(new HeaderStyles(null, null), 'Value2', false, false, null),       value3: new GridHeaderModel(new HeaderStyles(null, null), 'Value3', false, false, null),       value4: new GridHeaderModel(new HeaderStyles(null, null), 'Value4', false, false, null),       value5: new GridHeaderModel(new HeaderStyles(null, null), 'Value5', false, false, null),       date: new GridHeaderModel(new HeaderStyles(null, null), 'Date', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_commodity_price_flat_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('names') set $inParams_names(value: any) {
    this.inParams['names'] = value;
  }
  get $inParams_names(): any {
    return this.inParams['names'] ;
  }
  @Input('title') set $inParams_title(value: any) {
    this.inParams['title'] = value;
  }
  get $inParams_title(): any {
    return this.inParams['title'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'From', false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'To', false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'commodity_price_flat_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.names)) {
        this.$missingRequiredInParams.push('names');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      names:  $grid.inParams.names ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Invoices.ds_commodity_price_flat_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_commodity_price_flat_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_commodity_price_flat_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'commodity_price_flat_grid.on_init');
  //O.Arias - 07/26/2024
  
  //Default for the dates
  
  let date = $utils.date.now();
  
  let new_date = new Date(date);
  new_date.setUTCHours(0, 0, 0, 0);
  date = new_date.toISOString();
  
  $grid.filters.date_from.control.value = $utils.date.subtract(30, "day", date);
  $grid.filters.date_to.control.value = $utils.date.add(1, "day", date);
  
  $grid.headers.value1.hidden = true;
  $grid.headers.value2.hidden = true;
  $grid.headers.value3.hidden = true;
  $grid.headers.value4.hidden = true;
  $grid.headers.value5.hidden = true;
  
  let value_id: number = 0;
  
  for (let name of $grid.inParams.names) {
  
      value_id++;
  
      if (value_id > 5) { break } //Supporting 5 columns for now
  
      $grid.headers[`value${value_id}`].hidden = false;
      $grid.headers[`value${value_id}`].name = name.slice(0, 1).toUpperCase().concat(name.slice(1, name.length).toLowerCase());
  }
  
  $grid.title = $grid.inParams.title ?? "Commodity price flat grid"
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: Invoices_commodity_price_flat_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'commodity_price_flat_grid.on_export_flow');
  
  const settingsExcelExportLimit = 5000;
  const serverMaxAllowedRecordsLimit: number = 5000;// could be changed base on what each request to the server can handle.
  const concurrentLimit = 5;//max concurrent requests
  const timeout = 500;//use it only if you want to set a delay between requests...
  // Initialize
  var workBook = $event;
  var recordsToExport = [];
  var fullDataSet: any[] = [];
  
  
  //parameters to be passed to dataset api call
  const baseParams = {
      names: $grid.inParams.names,
      date_from: $grid.filters.date_from.control.value,
      date_to: $grid.filters.date_to.control.value,
      full_tex_search: $grid.fullTextSearch
  }
  
  //api call
  const dataApiCall = async (baseParams: any) => {
      return await $datasources.Invoices.ds_commodity_price_flat_grid.getList(baseParams);
  };
  
  //records count api call
  const countApiCall = async (baseParams: any) => {
      const params = { ...baseParams, $top: 0 };
      return await $datasources.Invoices.ds_commodity_price_flat_grid.getList(params);
  };
  
  //api call execution
  async function processApiCall(apiCall: (params: any) => Promise<any>, baseParams: any = {}, offset: number = 0, limit: number = 0): Promise<any> {
      const defaultParams = { $skip: offset, $top: limit };
      // Merge base, default, and additional params
      const params = { ...baseParams, ...defaultParams };
      return await apiCall(params);
  };
  
  //main funcion to handle concurrency in reagard of server and request limits
  async function processControlledApiRequests(totalRecords: number, requestLimit: number, serverLimit: number): Promise<any[]> {
      const totalBatches = Math.ceil(Math.min(totalRecords, serverLimit) / requestLimit);
  
      const result: any[] = [];
      const fetchPromiseQueue: Promise<any[]>[] = [];
  
      for (let i = 0; i < totalBatches; i++) {
          const offset = i * requestLimit;
          const limit = Math.min(requestLimit, serverLimit - (i * requestLimit));
  
          fetchPromiseQueue.push(processApiCall(dataApiCall, baseParams, offset, limit));
  
          //process promiseQueue once reached the limit then clear queue for the next loop round...
          if (fetchPromiseQueue.length === concurrentLimit) {
              result.push(...await Promise.all(fetchPromiseQueue));
              fetchPromiseQueue.length = 0;
          }
      }
  
      // Process any remaining requests after the loop
      if (fetchPromiseQueue.length) result.push(...await Promise.all(fetchPromiseQueue));
  
      return result;
  }
  
  
  try {
  
      if ($grid.selectedRows.length > 0) {
          // Compile all data into JSON
          for (let row of $grid.selectedRows) {
  
              recordsToExport.push({
                  "Gold": row.entity.value1?.toLocaleString(),
                  "Silver": row.entity.value2?.toLocaleString(),
                  "Platinum": row.entity.value3?.toLocaleString(),
                  "Paladium": row.entity.value4?.toLocaleString(),
                  "Date": row.entity.date?.toString()
  
              });
          }
          var workSheet = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, workSheet, "CommodityPriceList");
      }
      else {
  
          const { totalCount: requestedRecordsCount } = await countApiCall(baseParams);
  
          if (requestedRecordsCount > settingsExcelExportLimit) {
              await $shell.Invoices.openInfoDialog(`Export limitation of ${settingsExcelExportLimit} exceeded`, `Excel will only export ${settingsExcelExportLimit} records`);
          }
  
          const responses = await processControlledApiRequests(requestedRecordsCount, serverMaxAllowedRecordsLimit, settingsExcelExportLimit);
          responses.forEach(apiResponse => {
              fullDataSet.push(...apiResponse.result);
          });
          // Compile all data into JSON
          for (let record of fullDataSet) {
              recordsToExport.push({
  
                  "Gold": record.value1?.toLocaleString(),
                  "Silver": record.value2?.toLocaleString(),
                  "Platinum": record.value3?.toLocaleString(),
                  "Paladium": record.value4?.toLocaleString(),
                  "Date": record.date?.toString()
              });
          }
          var ws = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, ws, "CommodityPriceList");
  
      }
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'Invoices_commodity_price_flat_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
