<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="charge_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.charge_type.displayControl.styles.style"
          [ngClass]="row.cells.charge_type.displayControl.styles.classes"
          [matTooltip]="row.cells.charge_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.charge_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="charge_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.charge_amount.displayControl.styles.style"
          [ngClass]="row.cells.charge_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.charge_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.charge_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_range_from">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_range_from.displayControl.styles.style"
          [ngClass]="row.cells.pallet_range_from.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_range_from.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_range_from.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_range_to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_range_to.displayControl.styles.style"
          [ngClass]="row.cells.pallet_range_to.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_range_to.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_range_to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
