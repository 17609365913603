import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EasyPost_ShellService } from './EasyPost.shell.service';
import { EasyPost_OperationService } from './EasyPost.operation.service';
import { EasyPost_DatasourceService } from './EasyPost.datasource.index';
import { EasyPost_FlowService } from './EasyPost.flow.index';
import { EasyPost_ReportService } from './EasyPost.report.index';
import { EasyPost_LocalizationService } from './EasyPost.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EasyPost.frontend.types'
import { $frontendTypes as $types} from './EasyPost.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EasyPost-create_address_form',
  templateUrl: './EasyPost.create_address_form.component.html'
})
export class EasyPost_create_address_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    street1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    street2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    city: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    state: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    zip: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    country: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    company: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    phone: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    verify: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    verify_strict: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(null, null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    )
  };

  fields = {
    street1: new FieldModel(new TextBoxModel(this.formGroup.controls['street1'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Street1', false),
    street2: new FieldModel(new TextBoxModel(this.formGroup.controls['street2'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Street2', false),
    city: new FieldModel(new TextBoxModel(this.formGroup.controls['city'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'City', false),
    state: new FieldModel(new TextBoxModel(this.formGroup.controls['state'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'State', false),
    zip: new FieldModel(new TextBoxModel(this.formGroup.controls['zip'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Zip', false),
    country: new FieldModel(new TextBoxModel(this.formGroup.controls['country'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Country', false),
    company: new FieldModel(new TextBoxModel(this.formGroup.controls['company'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Company', false),
    phone: new FieldModel(new TextBoxModel(this.formGroup.controls['phone'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Phone', false),
    verify: new FieldModel(new CheckBoxModel(this.formGroup.controls['verify'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Verify', false),
    verify_strict: new FieldModel(new CheckBoxModel(this.formGroup.controls['verify_strict'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Verify strict', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Address', false, false, true),
  newGroup2: new FieldsetModel('Other', false, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    street1: this.fields.street1.control.valueChanges
    ,
    street2: this.fields.street2.control.valueChanges
    ,
    city: this.fields.city.control.valueChanges
    ,
    state: this.fields.state.control.valueChanges
    ,
    zip: this.fields.zip.control.valueChanges
    ,
    country: this.fields.country.control.valueChanges
    ,
    company: this.fields.company.control.valueChanges
    ,
    phone: this.fields.phone.control.valueChanges
    ,
    verify: this.fields.verify.control.valueChanges
    ,
    verify_strict: this.fields.verify_strict.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EasyPost_ShellService,
private datasources: EasyPost_DatasourceService,
private flows: EasyPost_FlowService,
private reports: EasyPost_ReportService,
private localization: EasyPost_LocalizationService,
private operations: EasyPost_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'create_address_form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    
    
    
    
    (this.fields.verify.control as CheckBoxModel).reset(false);
    (this.fields.verify_strict.control as CheckBoxModel).reset(false);


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .street1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .street2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .city
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .state
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .zip
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .country
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .company
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .phone
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .verify
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .verify_strict
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: EasyPost_create_address_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
    this.logger.log('EasyPost', 'create_address_form.on_confirm_clicked');
  
  
  var easyPostKey = $settings.EasyPost.EasyPostKey?.toString();
  
  let response = (await $flows.EasyPost.create_address_flow({
      address: {
          street1: $form.fields.street1.control.value,
          street2: $form.fields.street2.control.value,
          city: $form.fields.city.control.value,
          state: $form.fields.state.control.value,
          zip: $form.fields.zip.control.value,
          country: $form.fields.country.control.value,
          phone: $form.fields.phone.control.value,
          company: $form.fields.company.control.value
  
      },
      verify: $form.fields.verify.control.value,
      verify_strict: $form.fields.verify_strict.control.value,
      easyPostKey: easyPostKey
  }));
  
  if ($utils.isDefined(response?.address_id)) {
      $shell.EasyPost.openInfoDialog('New Address Created', `AddressId: [${response.address_id}]`);
  }
  else if ($utils.isDefined(response?.errors)) {
      $shell.EasyPost.openErrorDialog('Error(s) During Address Creation', response.errors.join(' | '));
  }
  }
  //#endregion private flows
}
