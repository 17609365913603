<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="edit" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="create_material" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_material_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="create_inventory" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_inventory_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator2" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="on_delete" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_delete($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator3" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="print" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.print_inventory_by_project_report.readOnly"
                              [ngStyle]="tool.control.buttons.print_inventory_by_project_report.styles.style"
                              [ngClass]="tool.control.buttons.print_inventory_by_project_report.styles.classes"
                              [matTooltip]="tool.control.buttons.print_inventory_by_project_report.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_print_inventory_by_project_report_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.print_inventory_by_project_report.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_inventory_by_project_report.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.print_inventory_by_project_report.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
              <ng-template toolbarToolDef="options" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.auto_email_rules.readOnly"
                              [ngStyle]="tool.control.buttons.auto_email_rules.styles.style"
                              [ngClass]="tool.control.buttons.auto_email_rules.styles.classes"
                              [matTooltip]="tool.control.buttons.auto_email_rules.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_email_rules_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.auto_email_rules.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.auto_email_rules.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.auto_email_rules.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.surveys.readOnly"
                              [ngStyle]="tool.control.buttons.surveys.styles.style"
                              [ngClass]="tool.control.buttons.surveys.styles.classes"
                              [matTooltip]="tool.control.buttons.surveys.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_surveys_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.surveys.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.surveys.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.surveys.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.auto_invoicing_rules.readOnly"
                              [ngStyle]="tool.control.buttons.auto_invoicing_rules.styles.style"
                              [ngClass]="tool.control.buttons.auto_invoicing_rules.styles.classes"
                              [matTooltip]="tool.control.buttons.auto_invoicing_rules.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_auto_invoicing_rules_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.auto_invoicing_rules.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.auto_invoicing_rules.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.auto_invoicing_rules.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template toolbarToolDef="ship_from_addresses" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_ship_from_addresses_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
                  <ng-template toolbarToolDef="ship_to_addresses" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_ship_to_addresses_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
                  <ng-template toolbarToolDef="assign_containers_button" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_assignment_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
                  <ng-template toolbarToolDef="custom_fields" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_custom_fields_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.materials_by_count_widget.hidden" class="widget-container">
            <Materials-materials_by_count_widget
            #$widgets_materials_by_count_widget 
              [projectId]="$widgets_materials_by_count_widget_inParams_projectId"
            ($refreshEvent)="refresh(false, false, '$widgets_materials_by_count_widget')"
            >
            </Materials-materials_by_count_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [projectId]="$widgets_total_packaged_amount_widget_inParams_projectId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [projectId]="$widgets_total_gross_weight_widget_inParams_projectId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.materials.hidden" class="tab" data-cy="tab-materials">
              <h2 [className]="tabs.materials.active? 'active': ''" (click)="tabs.materials.activate()">{{tabs.materials.title}}</h2>
            </div>
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.instructions.hidden" class="tab" data-cy="tab-instructions">
              <h2 [className]="tabs.instructions.active? 'active': ''" (click)="tabs.instructions.activate()">{{tabs.instructions.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
            <div *ngIf="!tabs.invoices.hidden" class="tab" data-cy="tab-invoices">
              <h2 [className]="tabs.invoices.active? 'active': ''" (click)="tabs.invoices.activate()">{{tabs.invoices.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-materials_by_base_packaging_grid *ngIf="tabs.materials.active"
              #$tabs_materials
              [projectId]="$tabs_materials_materials_by_base_packaging_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_materials')">
              </FootPrintManager-materials_by_base_packaging_grid>
              <ng-container
                *ngIf="tabs.inventory.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.inventory.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.inventory.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.inventory.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-inventory_location_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_location.active"
                  #$tabs_inventory_inventory_by_location
                  [projectId]="$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_projectId"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_location')">
                  </FootPrintManager-inventory_location_grid>
                  <FootPrintManager-licenseplate_list
                   *ngIf="tabs.inventory.tabs.licenseplates.active"
                  #$tabs_inventory_licenseplates
                  [projectId]="$tabs_inventory_licenseplates_licenseplate_list_inParams_projectId"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_licenseplates')">
                  </FootPrintManager-licenseplate_list>
                  <FootPrintManager-serialnumbers_grid
                   *ngIf="tabs.inventory.tabs.serialnumbers.active"
                  #$tabs_inventory_serialnumbers
                  [projectId]="$tabs_inventory_serialnumbers_serialnumbers_grid_inParams_projectId"
                  [archived]="$tabs_inventory_serialnumbers_serialnumbers_grid_inParams_archived"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_serialnumbers')">
                  </FootPrintManager-serialnumbers_grid>
              </ng-container>
              <ng-container
                *ngIf="tabs.contacts.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.contacts.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.contacts.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.contacts.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-contact_addresses_grid
                   *ngIf="tabs.contacts.tabs.addresses.active"
                  #$tabs_contacts_addresses
                  [entity]="$tabs_contacts_addresses_contact_addresses_grid_inParams_entity"
                  [entityId]="$tabs_contacts_addresses_contact_addresses_grid_inParams_entityId"
                  ($refreshEvent)="refresh(false, false, '$tabs_contacts_addresses')">
                  </FootPrintManager-contact_addresses_grid>
                  <FootPrintManager-contact_addresses_grid
                   *ngIf="tabs.contacts.tabs.importer.active"
                  #$tabs_contacts_importer
                  [entity]="$tabs_contacts_importer_contact_addresses_grid_inParams_entity"
                  [entityId]="$tabs_contacts_importer_contact_addresses_grid_inParams_entityId"
                  [typeIds]="$tabs_contacts_importer_contact_addresses_grid_inParams_typeIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_contacts_importer')">
                  </FootPrintManager-contact_addresses_grid>
              </ng-container>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [projectId]="$tabs_tasks_inventory_tasks_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-instructions_grid *ngIf="tabs.instructions.active"
              #$tabs_instructions
              [entityName]="$tabs_instructions_instructions_grid_inParams_entityName"
              [enabled]="$tabs_instructions_instructions_grid_inParams_enabled"
              [entityKey1]="$tabs_instructions_instructions_grid_inParams_entityKey1"
              ($refreshEvent)="refresh(false, false, '$tabs_instructions')">
              </FootPrintManager-instructions_grid>
              <FootPrintManager-billing_contracts_grid *ngIf="tabs.billing.active"
              #$tabs_billing
              [ownerId]="$tabs_billing_billing_contracts_grid_inParams_ownerId"
              [projectId]="$tabs_billing_billing_contracts_grid_inParams_projectId"
              [statusIds]="$tabs_billing_billing_contracts_grid_inParams_statusIds"
              ($refreshEvent)="refresh(false, false, '$tabs_billing')">
              </FootPrintManager-billing_contracts_grid>
              <FootPrintManager-invoices_grid *ngIf="tabs.invoices.active"
              #$tabs_invoices
              [ownerId]="$tabs_invoices_invoices_grid_inParams_ownerId"
              [projectId]="$tabs_invoices_invoices_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoices')">
              </FootPrintManager-invoices_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>