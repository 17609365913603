<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="manifest_ready_shipments" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_manifest_ready_shipments_button_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="print_packing_lists" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="print_packing_lists($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="manifestcount" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference.displayControl.styles.style"
          [ngClass]="row.cells.reference.displayControl.styles.classes"
          [matTooltip]="row.cells.reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Carrier_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Carrier_Name.displayControl.styles.style"
          [ngClass]="row.cells.Carrier_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Carrier_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Carrier_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CarrierServiceType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CarrierServiceType_Name.displayControl.styles.style"
          [ngClass]="row.cells.CarrierServiceType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.CarrierServiceType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CarrierServiceType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_container_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_container_count.displayControl.styles.style"
          [ngClass]="row.cells.shipping_container_count.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_container_count.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_container_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="account_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.account_number.displayControl.styles.style"
          [ngClass]="row.cells.account_number.displayControl.styles.classes"
          [matTooltip]="row.cells.account_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.account_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse_address">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse_address.displayControl.styles.style"
          [ngClass]="row.cells.warehouse_address.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse_address.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse_address.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_address">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_address.displayControl.styles.style"
          [ngClass]="row.cells.shipping_address.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_address.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_address.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="tracking">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.tracking.displayControl.styles.style"
          [ngClass]="row.cells.tracking.displayControl.styles.classes"
          [matTooltip]="row.cells.tracking.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.tracking.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="printjob">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.printjob.displayControl.styles.style"
          [ngClass]="row.cells.printjob.displayControl.styles.classes"
          [matTooltip]="row.cells.printjob.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.printjob.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="label">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.label.displayControl.styles.style"
          [ngClass]="row.cells.label.displayControl.styles.classes"
          [matTooltip]="row.cells.label.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.label.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="invoice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.invoice.displayControl.styles.style"
          [ngClass]="row.cells.invoice.displayControl.styles.classes"
          [matTooltip]="row.cells.invoice.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.invoice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
