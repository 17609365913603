import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Usersnap_widgetformComponent } from './Usersnap.widgetform.component';


@Injectable({ providedIn: 'root' })
export class Usersnap_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Usersnap: Usersnap_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openwidgetform(replaceCurrentView?: boolean) {
    this.logger.log('Usersnap', 'widgetform');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'widgetform',
        referenceName: 'Usersnap_widgetform',
        component: Usersnap_widgetformComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwidgetformDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Usersnap', 'widgetform');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Usersnap_widgetformComponent,
      'widgetform',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Usersnap_widgetform') {
      this.logger.log('Usersnap', 'widgetform');
      const title = 'widgetform';
      const component = Usersnap_widgetformComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
