<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-location"
                     *ngIf="!fieldsets.location.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.location.collapsible }">
                    <div *ngIf="!fieldsets.location.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.location.toggle()">
                      <span class="fieldsetsTitle-text">Location</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.location.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.location.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.location.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.location.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-location" *ngIf="!fields.location.hidden" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.location.label + (fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.location.label}}<span *ngIf="fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                            [tooltip]="fields.location.control.tooltip"
                          [warehouseId]="$fields_location_selector_inParams_warehouseId"
                          [typeId]="$fields_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-licenseplate"
                     *ngIf="!fieldsets.licenseplate.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.licenseplate.collapsible }">
                    <div *ngIf="!fieldsets.licenseplate.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.licenseplate.toggle()">
                      <span class="fieldsetsTitle-text">License plate</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.licenseplate.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.licenseplate.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.licenseplate.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.licenseplate.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_new_licenseplate" *ngIf="!fields.is_new_licenseplate.hidden" 
                            class="field-container standard {{fields.is_new_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_new_licenseplate.styles.style"
                            [ngClass]="fields.is_new_licenseplate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_new_licenseplate.label + (fields.is_new_licenseplate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_new_licenseplate.label}}<span *ngIf="fields.is_new_licenseplate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_new_licenseplate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_new_licenseplate.control.styles.style"
                                      [ngClass]="fields.is_new_licenseplate.control.styles.classes"
                                      [matTooltip]="fields.is_new_licenseplate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_new_licenseplate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_new_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.is_new_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-existing_licenseplate" *ngIf="!fields.existing_licenseplate.hidden" 
                            class="field-container standard {{fields.existing_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.existing_licenseplate.styles.style"
                            [ngClass]="fields.existing_licenseplate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.existing_licenseplate.label + (fields.existing_licenseplate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.existing_licenseplate.label}}<span *ngIf="fields.existing_licenseplate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.existing_licenseplate.control.type"
                            formControlName="existing_licenseplate"
                            (displayTextChange)="fields.existing_licenseplate.control.displayText=$event"
                            [placeholder]="fields.existing_licenseplate.control.placeholder"
                            [styles]="fields.existing_licenseplate.control.styles"
                            [tooltip]="fields.existing_licenseplate.control.tooltip"
                          [locationId]="$fields_existing_licenseplate_selector_inParams_locationId"
                          [typeId]="$fields_existing_licenseplate_selector_inParams_typeId"
                          [archived]="$fields_existing_licenseplate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.existing_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.existing_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_licenseplate" *ngIf="!fields.new_licenseplate.hidden" 
                            class="field-container standard {{fields.new_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.new_licenseplate.styles.style"
                            [ngClass]="fields.new_licenseplate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.new_licenseplate.label + (fields.new_licenseplate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.new_licenseplate.label}}<span *ngIf="fields.new_licenseplate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="new_licenseplate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.new_licenseplate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.new_licenseplate.control.placeholder}}"
                                [ngStyle]="fields.new_licenseplate.control.styles.style"
                                [ngClass]="fields.new_licenseplate.control.styles.classes"
                                [matTooltip]="fields.new_licenseplate.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.new_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.new_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>