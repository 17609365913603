import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Orderful.frontend.types'

@Injectable({ providedIn: 'root' })
export class Orderful_orderful_controlService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { action?: string, payload?: { id?: string, type?: string, identifier?: string, direction?: string, count?: number, created_date?: string, modified_date?: string, search?: string, integration_name?: string }, skip?: number, take?: number, option?: string }): Promise<{ payload?: { id?: string, type?: string, identifier?: string, direction?: string, count?: number, created_date?: string, modified_date?: string, integration_name?: string }[], control_number?: number }> 
  {
    let url = `${environment.backendUrl}api/Orderful/functions/orderful_control`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

