<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes"
          [matTooltip]="row.cells.name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.name.editControl.placeholder}}"
            [ngStyle]="row.cells.name.editControl.styles.style"
            [ngClass]="row.cells.name.editControl.styles.classes"
            [matTooltip]="row.cells.name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="option">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.option.displayControl.styles.style"
          [ngClass]="row.cells.option.displayControl.styles.classes"
          [matTooltip]="row.cells.option.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.option.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <DockAppointments-dock_appointment_type_options_dd_single 
        data-cy="selector"
        [type]="row.cells.option.editControl.type"
        [formControl]="row.formGroup.controls['option_edit']"
        (displayTextChange)="row.cells.option.editControl.displayText=$event"
        [placeholder]="row.cells.option.editControl.placeholder"
        [styles]="row.cells.option.editControl.styles"
        [tooltip]="row.cells.option.editControl.tooltip"
    >
    </DockAppointments-dock_appointment_type_options_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
