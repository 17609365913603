import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_load_container_unassigned_orders_gridComponent } from './FootPrintManager.load_container_unassigned_orders_grid.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { PurchaseOrders_orderstatuses_dd_multiComponent } from './PurchaseOrders.orderstatuses_dd_multi.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { LoadContainers_order_types_singleComponent } from './LoadContainers.order_types_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_load_container_unassigned_orders_gridComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => PurchaseOrders_orderstatuses_dd_multiComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => LoadContainers_order_types_singleComponent),
  ],
  selector: 'FootPrintManager-load_container_add_orders',
  templateUrl: './FootPrintManager.load_container_add_orders.component.html'
})
export class FootPrintManager_load_container_add_ordersComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { loadContainerId?: number, warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number } = { loadContainerId: null, warehouseId: null, orderTypeId: null, projectId: null, ownerId: null };
  //#region Inputs
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('orderTypeId') set $inParams_orderTypeId(v: number) {
    this.inParams.orderTypeId = v;
  }
  get $inParams_orderTypeId(): number {
    return this.inParams.orderTypeId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { edit?: boolean, warehouseId?: number } = { };
  //#endregion
  entity: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, InYardDate?: string, LastOnsiteDate?: string, LastPierDate?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, Priority?: number, ReasonCodeId?: number, SealIdentifier?: string, TrailerNumber?: string, Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string }, ContainerType?: { Id?: number, Name?: string }, OrderType?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, YardLocation?: { Id?: number, Name?: string, WarehouseId?: number }, DockAppointmentsLoadContainersLookup?: { DockAppointmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledLocation?: { Name?: string } } }[] };

  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save_container: new ToolModel(new ButtonModel('save_container', new ButtonStyles(null, null), false, 'Done', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    )
  };

  fields = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false),
    statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['statuses'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Statuses', false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false),
    order_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_type'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Order type', false),
  };

  fieldsets = {
  newGroup2: new FieldsetModel('Search parameters', false, true, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Unassigned orders', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_ownerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.owner.control.value;
      
      return expr;
    }
  
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds: number[];
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.statuses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds;
    }
  
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_orderTypeId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.order_type.control.value;
      
      return expr;
    }
  
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_loadContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.loadContainerId;
      
      return expr;
    }
  
    get $tabs_orders_load_container_unassigned_orders_grid_inParams_lookupCode(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.LookupCode;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: FootPrintManager_load_container_unassigned_orders_gridComponent }) $tabs_orders: FootPrintManager_load_container_unassigned_orders_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    statuses: this.fields.statuses.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    order_type: this.fields.order_type.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Load Container Add Orders';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      loadContainerId:  $editor.inParams.loadContainerId 
    };

    const data = await this.datasources.LoadContainers.ds_load_container_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.inParams.warehouseId);
    (this.fields.statuses.control as SelectBoxModel).reset([1,2]);
    (this.fields.owner.control as SelectBoxModel).reset($editor.inParams.ownerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.inParams.projectId);
    (this.fields.order_type.control as SelectBoxModel).reset($editor.inParams.orderTypeId);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_orders') {
        if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden) {
          this.$tabs_orders.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .statuses
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/21/2023
  
  if ($utils.isDefined($editor.inParams.warehouseId)) {
      $editor.fields.warehouse.control.readOnly = true;
  } else {
      $editor.fields.warehouse.control.readOnly = false;
  };
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.order_type.hidden = true
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_add_orders.on_edit_clicked');
  //O.Arias - 07/17/2023
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/17/2023
  
  try {
  
          //Set the fields to read only, but not hidden
          $editor.toolbar.save_container.control.readOnly = true;
  
          //Build the contract payload
          let payload: any = {};
          payload.LookupCode = $editor.entity.LookupCode;
          await $flows.Utilities.crud_update_flow({ entitySet: 'LoadContainers', id: $editor.entity.Id, entity: payload });
  
          $editor.outParams.confirm = true;
          await $editor.close();
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Load Container ${$editor.entity.LookupCode} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Update load container error', 'An error occurred during updating of the load container', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  }
  on_grid_output(event = null) {
    return this.on_grid_outputInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_outputInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_add_orders.on_grid_output');
  //O.Arias - 07/17/2023
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/14/2023
  
  // Clear out the project selection
  $editor.fields.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $editor: FootPrintManager_load_container_add_ordersComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'load_container_add_orders.on_project_change');
  //O.Arias - 07/14/2023
  
  if (!$utils.isDefined($editor.fields.owner.control.value)) {
  
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $editor.fields.project.control.value })).result
  
      if ($utils.isDefined(project)) {
  
          $editor.fields.owner.control.value = project[0].OwnerId;
  
      };
  };
  }
  //#endregion private flows
}
