import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Discussions_discussions_gridComponent } from './Discussions.discussions_grid.component';
import { Discussions_discussions_listComponent } from './Discussions.discussions_list.component';
import { Discussions_discussion_cardComponent } from './Discussions.discussion_card.component';


@Injectable({ providedIn: 'root' })
export class Discussions_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Discussions: Discussions_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opendiscussions_grid(inParams:{ entity_name?: string, entity_id?: string, title?: string, application_id?: string, reference?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Discussions', 'discussions_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Discussions grid',
        referenceName: 'Discussions_discussions_grid',
        component: Discussions_discussions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendiscussions_gridDialog(
    inParams:{ entity_name?: string, entity_id?: string, title?: string, application_id?: string, reference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Discussions', 'discussions_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Discussions_discussions_gridComponent,
      'Discussions grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendiscussions_list(inParams:{ entity_name?: string, entity_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Discussions',
        referenceName: 'Discussions_discussions_list',
        component: Discussions_discussions_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendiscussions_listDialog(
    inParams:{ entity_name?: string, entity_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Discussions_discussions_listComponent,
      'Discussions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Discussions_discussions_grid') {
      this.logger.log('Discussions', 'discussions_grid');
      const title = 'Discussions grid';
      const component = Discussions_discussions_gridComponent;
      const inParams:{ entity_name?: string, entity_id?: string, title?: string, application_id?: string, reference?: string } = { entity_name: null, entity_id: null, title: null, application_id: null, reference: null };
      if (!isNil(params.get('entity_name'))) {
        const paramValueString = params.get('entity_name');
        // TODO: date
        inParams.entity_name = paramValueString;
              }
      if (!isNil(params.get('entity_id'))) {
        const paramValueString = params.get('entity_id');
        // TODO: date
        inParams.entity_id = paramValueString;
              }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('application_id'))) {
        const paramValueString = params.get('application_id');
        // TODO: date
        inParams.application_id = paramValueString;
              }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Discussions_discussions_list') {
      const title = 'Discussions';
      const component = Discussions_discussions_listComponent;
      const inParams:{ entity_name?: string, entity_id?: string } = { entity_name: null, entity_id: null };
      if (!isNil(params.get('entity_name'))) {
        const paramValueString = params.get('entity_name');
        // TODO: date
        inParams.entity_name = paramValueString;
              }
      if (!isNil(params.get('entity_id'))) {
        const paramValueString = params.get('entity_id');
        // TODO: date
        inParams.entity_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Discussions_discussion_card') {
      const title = 'discussion_card';
      const component = Discussions_discussion_cardComponent;
      const inParams:{ entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string, id?: string } = { entity_name: null, entity_id: null, reference: null, discussion: null, created_by: null, created_on: null, read_on: null, alerted_on: null, application_id: null, id: null };
      if (!isNil(params.get('entity_name'))) {
        const paramValueString = params.get('entity_name');
        // TODO: date
        inParams.entity_name = paramValueString;
              }
      if (!isNil(params.get('entity_id'))) {
        const paramValueString = params.get('entity_id');
        // TODO: date
        inParams.entity_id = paramValueString;
              }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('discussion'))) {
        const paramValueString = params.get('discussion');
        // TODO: date
        inParams.discussion = paramValueString;
              }
      if (!isNil(params.get('created_by'))) {
        const paramValueString = params.get('created_by');
        // TODO: date
        inParams.created_by = paramValueString;
              }
      if (!isNil(params.get('created_on'))) {
        const paramValueString = params.get('created_on');
        // TODO: date
        inParams.created_on = paramValueString;
              }
      if (!isNil(params.get('read_on'))) {
        const paramValueString = params.get('read_on');
        // TODO: date
        inParams.read_on = paramValueString;
              }
      if (!isNil(params.get('alerted_on'))) {
        const paramValueString = params.get('alerted_on');
        // TODO: date
        inParams.alerted_on = paramValueString;
              }
      if (!isNil(params.get('application_id'))) {
        const paramValueString = params.get('application_id');
        // TODO: date
        inParams.application_id = paramValueString;
              }
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        // TODO: date
        inParams.id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
