import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  forwardRef,
  HostListener
} from '@angular/core';

import { MatDatepicker } from '@angular/material/datepicker';
import { MatDateFormats } from '@angular/material/core';

import {
  NgxMatDatetimepicker,
  NgxMatDateFormats
} from '@angular-material-components/datetime-picker';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { isNil, trim } from 'lodash-es';

import * as moment_ from 'moment';
import { Subscription } from 'rxjs';
const moment = moment_;

@Component({
  selector: 'app-datebox',
  templateUrl: './datebox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateboxComponent),
      multi: true
    }
  ]
})
export class DateboxComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @ViewChild('picker') picker: MatDatepicker<moment_.Moment> | NgxMatDatetimepicker<moment_.Moment>;

  @Input() mode = 'datetime';
  @Input() format = 'l, LT';
  @Input() placeholder = '';
  @Input() tooltip = '';

  @HostListener('focus')
  onFocus() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  formattedValue = null;
  _innerValue: any;
  disabled: boolean;
  hasSelectedDate = false;
  hasOpenedPicker = false;
  private openedStreamSubscription: Subscription;
  private closedStreamSubscription: Subscription;

  constructor(
    private _elementRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    if (this.format) {
      // workaround until https://github.com/angular/components/issues/8355 is implemented
      Promise.resolve(null).then(() => {
        const dateFormats =
          JSON.parse(JSON.stringify((this.picker.datepickerInput as any)._dateFormats)) as MatDateFormats | NgxMatDateFormats;
        dateFormats.parse.dateInput = this.format;
        dateFormats.display.dateInput = this.format;

        const datepickerInput = this.picker.datepickerInput as any;
        datepickerInput._dateFormats = dateFormats;
        // Reformat the input base on datepickerInput._onBlur()
        if (datepickerInput.value) {
          datepickerInput._formatValue(datepickerInput.value);
        }
      });
    }
    this.openedStreamSubscription = this.picker.openedStream.subscribe(() => {
      this.hasOpenedPicker = true;
    });

    this.closedStreamSubscription = this.picker.closedStream.subscribe(() => {
      this.hasOpenedPicker = false;
    });
  }

  ngOnDestroy(): void {
    if (this.openedStreamSubscription) {
      this.openedStreamSubscription.unsubscribe();
    }
    if (this.closedStreamSubscription) {
      this.closedStreamSubscription.unsubscribe();
    }
  }

  // note: there are a lot of hacks in the current implementation when working with dates
  // essentially, we want to work with dates without the time zone (offset), but all tools 
  // and frameworks that we use are built in the opposite manner, hence
  // we keep the zone as if it was UTC (+00.00 or Z) everywhere, but the datetime part
  // reflects the current (locale) datetime - we try avoiding all conversions between locale and utc
  // also, we want to work with those dates as strings and not as Date or Moment instances
  onValueChanged(val: moment_.Moment) {
    const compareVal = this.isDefinedTrimmed(this.value) ? moment.utc(this.value) : null;
    if ((compareVal && val && !compareVal.isSame(val)) || (!compareVal && val) || (compareVal && !val)) {
      let parsedValue = null;
      if (val && val.isValid()) {
        parsedValue = val.toISOString();
      }
      this.value = parsedValue;
    }
  }

  onUserInputChanged(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;
    if (this.value === null) {
      event.target.value = '';
    }
  }

  onFocusOut() {
    this.onValueChanged((this.picker.datepickerInput as any).value);
  }

  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.onValueChanged((this.picker.datepickerInput as any).value);
    }
  }

  isDefinedTrimmed(value) {
    if (typeof value === 'string') {
      value = trim(value);
    }
    const result = !isNil(value) && value !== '';
    return result;
  }

  onChange: any = () => { };
  onTouch: any = () => { };

  get value() {
    return this._innerValue;
  }

  set value(val) {
    this._innerValue = val;
    this.onChange(val);
    this.onTouch(val);
    this.hasSelectedDate = !!val;
  }

  writeValue(value: any): void {
    this._innerValue = value;
    this.hasSelectedDate = !!value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  clearSelectedDate() {
    this.value = null;
  }
}
