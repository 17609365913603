import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { WorkOrders_work_order_open_tasks_count_widgetComponent } from './WorkOrders.work_order_open_tasks_count_widget.component';
import { WorkOrders_work_order_completed_tasks_count_wdigetComponent } from './WorkOrders.work_order_completed_tasks_count_wdiget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => WorkOrders_work_order_open_tasks_count_widgetComponent),
    forwardRef(() => WorkOrders_work_order_completed_tasks_count_wdigetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-work_order_editor',
  templateUrl: './FootPrintManager.work_order_editor.component.html'
})
export class FootPrintManager_work_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { workOrderId: number } = { workOrderId: null };
  //#region Inputs
  @Input('workOrderId') set $inParams_workOrderId(v: number) {
    this.inParams.workOrderId = v;
  }
  get $inParams_workOrderId(): number {
    return this.inParams.workOrderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number } = { };
  //#endregion
  entity: { Id?: number, CreatedSysDateTime?: string, Description?: string, ExpectedDate?: string, LookupCode?: string, Notes?: string, ProjectId?: number, ReferenceNumber?: string, StatusId?: number, WarehouseId?: number, Status?: { DisplayName?: string }, Tasks?: { Id?: number }[], Project?: { OwnerId?: number } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reference_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      release: new ToolModel(new ButtonModel('release', new ButtonStyles(null, null), true, 'Release', 'ms-Icon ms-Icon--Process', null)
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular', null)
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Work order code', true),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', true),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Status', false),
    reference_number: new FieldModel(new TextBoxModel(this.formGroup.controls['reference_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Reference', false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Expected date', false),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Work order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      accessorial_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      billing_records: new TabItemModel(
        this.rootTabGroup, 
        'Billing records', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_accessorial_tasks_accessorial_tasks_grid_inParams_workOrderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.workOrderId;
      
      return expr;
    }
  
    get $tabs_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.WarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_billing_records_billing_records_grid_inParams_workOrderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.workOrderId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
      @ViewChild('$tabs_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_records: FootPrintManager_billing_records_gridComponent;
    //#endregion tabs children
    widgets = {
      work_order_open_tasks_count_widget: new WidgetModel(),
      work_order_completed_tasks_count_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_work_order_open_tasks_count_widget_inParams_workOrderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.workOrderId;
      
      return expr;
    }
  
    get $widgets_work_order_completed_tasks_count_widget_inParams_workOrderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.workOrderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_work_order_open_tasks_count_widget', { read:  WorkOrders_work_order_open_tasks_count_widgetComponent }) $widgets_work_order_open_tasks_count_widget: WorkOrders_work_order_open_tasks_count_widgetComponent;
      @ViewChild('$widgets_work_order_completed_tasks_count_widget', { read:  WorkOrders_work_order_completed_tasks_count_wdigetComponent }) $widgets_work_order_completed_tasks_count_widget: WorkOrders_work_order_completed_tasks_count_wdigetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    reference_number: this.fields.reference_number.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.accessorial_tasks,
      this.tabs.billing_records,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.workOrderId)) {
        this.$missingRequiredInParams.push('workOrderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Work Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      workOrderId:  $editor.inParams.workOrderId 
    };

    const data = await this.datasources.WorkOrders.ds_work_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.DisplayName;
    (this.fields.reference_number.control as TextBoxModel).reset($editor.entity.ReferenceNumber);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.ExpectedDate);
    (this.fields.description.control as TextBoxModel).reset($editor.entity.Description);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_work_order_open_tasks_count_widget') {
      if (!isNil(this.$widgets_work_order_open_tasks_count_widget) && !this.widgets.work_order_open_tasks_count_widget.hidden) {
        this.$widgets_work_order_open_tasks_count_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_work_order_completed_tasks_count_widget') {
      if (!isNil(this.$widgets_work_order_completed_tasks_count_widget) && !this.widgets.work_order_completed_tasks_count_widget.hidden) {
        this.$widgets_work_order_completed_tasks_count_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_accessorial_tasks') {
        if (!isNil(this.$tabs_accessorial_tasks) && !this.tabs.accessorial_tasks.hidden) {
          this.$tabs_accessorial_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_records') {
        if (!isNil(this.$tabs_billing_records) && !this.tabs.billing_records.hidden) {
          this.$tabs_billing_records.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.WorkOrders.is_workorder_deletable_flow({ workOrderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Work Order cannot be deleted.', reason);
      return;
  } else {
  
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Work Order', `Are you sure you want to delete work order  ${$editor.entity.LookupCode} and attempt to delete all associated lines?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              var confirmReason = '';
              const result = await $flows.WorkOrders.work_order_delete_header_with_lines_flow({ workOrderId: $editor.entity.Id });
              if ($utils.isDefined(result.reason)){
              confirmReason = result.reason;
              }
  
              if ($utils.isDefined(confirmReason)) {
                  await $shell.FootPrintManager.openErrorDialog('Work Order cannot be fully deleted.', confirmReason);
                  await $editor.refresh();
                  return;
              }
              else {
  
                  const title = 'Delete Work Order';
                  const message = `Work Order ${$editor.entity.LookupCode} deleted.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.close();
                  await $editor.tabs.accessorial_tasks.activate();
              }
  
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Work Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Work Order deletion error', 'An error occurred during the deletion of the work order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.WorkOrders.is_workorder_cancelable_flow({ workOrderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Work Order cannot be cancelled.', reason);
      return;
  } else {
  
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel Work Order', `Are you sure you want to cancel work order  ${$editor.entity.LookupCode} and attempt to cancel all associated lines?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              var confirmReason = '';
              const result = await $flows.WorkOrders.work_order_cancel_header_with_lines_flow({ workOrderId: $editor.entity.Id });
              if ($utils.isDefined(result.reason)) {
                  confirmReason = result.reason;
              }
  
              if ($utils.isDefined(confirmReason)) {
                  await $shell.FootPrintManager.openErrorDialog('Work Order cannot be fully cancelled.', confirmReason);
                  await $editor.refresh();
                  return;
              }
              else {
  
                  const title = 'Cancel Work Order';
                  const message = `Work Order ${$editor.entity.LookupCode} cancelled.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.accessorial_tasks.activate();
              }
  
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Work Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Work Order cancelation error', 'An error occurred during the cancelation of the work order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  
  
  
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.WorkOrders.is_workorder_completable_flow({ workOrderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Work Order cannot be completed.', reason);
      return;
  } else {
  
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Work Order', `Are you sure you want to complete work order  ${$editor.entity.LookupCode} and confirm all associated lines?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              var confirmReason = '';
              const result = await $flows.WorkOrders.work_order_complete_header_with_lines_flow({ workOrderId: $editor.entity.Id });
              if ($utils.isDefined(result.reason)){
              confirmReason = result.reason;
              }
  
              if ($utils.isDefined(confirmReason)) {
                  await $shell.FootPrintManager.openErrorDialog('Work Order cannot be fully completed.', confirmReason);
                  await $editor.refresh();
                  return;
              }
              else {
  
                  const title = 'Complete Work Order';
                  const message = `Work Order ${$editor.entity.LookupCode} completed.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
                  await $editor.tabs.accessorial_tasks.activate();
              }
  
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Work Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Work Order complete error', 'An error occurred during the completion of the work order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Shipment created if not found in the on_init flow
  
  
  // Set editor title
  $editor.title = `Work Order ${$editor.entity.LookupCode}`;
  
  
  
  // created status
  if ($editor.entity.StatusId === 1) {
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
      $editor.toolbar.on_delete.hidden = false;
      $editor.toolbar.on_delete.control.readOnly = false;
  
      // Reset process button in case the editor is refreshed back to a created status
      $editor.toolbar.release.hidden = false;
      $editor.toolbar.release.control.readOnly = false;
      $editor.toolbar.release.control.icon = "icon-ic_fluent_arrow_clockwise_dashes_20_regular";
  
  
      $editor.toolbar.complete.hidden = true;
     
  
      // disable owner project if order lines exist
      const hasOrderLines = $editor.entity.Tasks.length > 0;
      $editor.fields.owner.control.readOnly = hasOrderLines;
      $editor.fields.project.control.readOnly = hasOrderLines;
  
      $editor.fields.lookupcode.control.readOnly = true;
  
  
  
  } else if ($editor.entity.StatusId === 2) {
      // released status
      $editor.toolbar.release.hidden = true;
  
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
  
      $editor.toolbar.on_delete.hidden = true;
  
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.complete.control.readOnly = false;
  
      $editor.fields.lookupcode.control.readOnly = false;
  
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.warehouse.control.readOnly = true;
      $editor.fields.lookupcode.control.readOnly = true;
  
  
  
  } else {
      $editor.toolbar.release.hidden = true;
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.on_delete.hidden = true;
  
  
      $editor.fields.lookupcode.control.readOnly = true;
  
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.warehouse.control.readOnly = true;
      $editor.fields.lookupcode.control.readOnly = true;
  
  
  
  }
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $editor.fields.expected_date.control.format = dateFormat;
  
  
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_release_clicked(event = null) {
    return this.on_release_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const lines = (await $datasources.WorkOrders.ds_get_planned_tasks_by_workorderId.get({ workOrderId: $editor.entity.Id })).result;
  if (!$utils.isDefined(lines)) {
      throw new Error('Work Order must have at least one line to release.')
  }
  
  const result = await $flows.WorkOrders.is_workorder_releasable_flow({ workOrderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Work Order cannot be released.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Release Work Order', `Are you sure you want to release work order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              $editor.toolbar.release.control.readOnly = true;
              $editor.toolbar.release.control.icon = "icon datex-default-spinner";
              $editor.toolbar.cancel.control.readOnly = true;
              $editor.toolbar.on_delete.control.readOnly = true;
  
              var confirmReason = '';
              const result = await $flows.WorkOrders.work_order_release_header_with_lines_flow({ workOrderId: $editor.entity.Id });
              if ($utils.isDefined(result.reason)){
              confirmReason = result.reason;
              }
  
  
              if ($utils.isDefined(confirmReason)) {
                  await $shell.FootPrintManager.openErrorDialog('Work Order cannot be fully released.', confirmReason);
                  $editor.toolbar.release.control.styles.resetStyle();
                  $editor.toolbar.release.control.icon = "icon ms-Icon ms-Icon--Process";
                  $editor.toolbar.release.control.label = 'Process';
  
  
                  $editor.toolbar.release.control.readOnly = false;
                  $editor.toolbar.cancel.control.readOnly = false;
                  $editor.toolbar.on_delete.control.readOnly = false;
                  $editor.refresh();
                  return;
              }
              else {
                  const title = 'Release Work Order';
                  const message = `Work Order ${$editor.entity.LookupCode} released.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  $editor.toolbar.release.control.icon = "icon ms-Icon ms-Icon--Process";
                  await $editor.refresh();
                  await $editor.tabs.accessorial_tasks.activate();
              }
  
          } catch (error) {
              $editor.toolbar.release.control.styles.resetStyle();
              $editor.toolbar.release.control.icon = "icon ms-Icon ms-Icon--Process";
              $editor.toolbar.release.control.label = 'Release';
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Work Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Work Order releasing error', 'An error occurred during releasing of the work order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      payload.WarehouseId = $editor.fields.warehouse.control.value;
  }
  
  if ($editor.fields.expected_date.control.isChanged) {
      payload.ExpectedDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  if ($editor.fields.reference_number.control.isChanged) {
      payload.ReferenceNumber = $editor.fields.reference_number.control.value;
  }
  if ($editor.fields.description.control.isChanged) {
      payload.Description = $editor.fields.description.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'WorkOrders', id: $editor.entity.Id, entity: payload });
  
  
  
  
  await $editor.refresh();
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  
  
      await $shell.WorkOrders.openwork_order_reportDialog({ workOrderId: $editor.inParams.workOrderId });
  
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_work_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'WorkOrder', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  //#endregion private flows
}
