import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IInvoices_invoicing_template_rule_selection_gridComponentEntity {
instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, priority?: number, isTemplateRule?: boolean, priorityId?: string}

interface IInvoices_invoicing_template_rule_selection_gridComponentInParams {
  exclude_rule_ids: string[]}

interface IInvoices_invoicing_template_rule_selection_gridComponentOutParams {
  rule_ids?: string[]}

class Invoices_invoicing_template_rule_selection_gridComponentRowModel extends GridRowModel {
  grid: Invoices_invoicing_template_rule_selection_gridComponent;
  entity: IInvoices_invoicing_template_rule_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    active_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    use_existing_invoice_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    active: new GridCellModel(
      new CellStyles(['center'], null),
      new CheckBoxModel(this.formGroup.controls['active_display'] as DatexFormControl, null, false, '', null)
,
null
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    term: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    tax_schedule: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    group_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    use_existing_invoice: new GridCellModel(
      new CellStyles(['center'], null),
      new CheckBoxModel(this.formGroup.controls['use_existing_invoice_display'] as DatexFormControl, null, false, '', null)
,
null
      ),
    billing_aggregation_strategies: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_invoicing_template_rule_selection_gridComponent, entity: IInvoices_invoicing_template_rule_selection_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.instructionId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_invoicing_template_rule_selection_gridComponent, entity?: IInvoices_invoicing_template_rule_selection_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.instructionId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.instructionId;
    const inParams = {
      $keys:[$resultKey],
      search:  null ,
      billingContractId:  null ,
      projectId:  0 ,
      active:  null ,
      excludeRuleIds:  $grid.inParams.exclude_rule_ids ,
    };
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.instructionName;
    (this.cells.active.displayControl as CheckBoxModel).reset($row.entity.active);
    (this.cells.type.displayControl as TextModel).text = $row.entity.instructionType;
    (this.cells.group_by.displayControl as TextModel).text = $row.entity.oneInvoicePerShipment ? 'Shipment' : $row.entity.oneInvoicePerWarehouse ? 'Warehouse' : 'Rule';
    (this.cells.use_existing_invoice.displayControl as CheckBoxModel).reset($row.entity.useExistingInvoice);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-invoicing_template_rule_selection_grid',
  templateUrl: './Invoices.invoicing_template_rule_selection_grid.component.html'
})
export class Invoices_invoicing_template_rule_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_invoicing_template_rule_selection_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_invoicing_template_rule_selection_gridComponentInParams = { exclude_rule_ids: [] };

  outParams: IInvoices_invoicing_template_rule_selection_gridComponentOutParams = { rule_ids: [] };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ rule_ids?: string[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       active: new GridHeaderModel(new HeaderStyles(['center'], null), 'Active', false, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, null),       term: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice term', false, false, null),       tax_schedule: new GridHeaderModel(new HeaderStyles(null, null), 'Tax schedule', false, false, null),       group_by: new GridHeaderModel(new HeaderStyles(null, null), 'Group billing records by', false, false, null),       use_existing_invoice: new GridHeaderModel(new HeaderStyles(['center'], null), 'Add billing records to existing invoices', false, false, null),       billing_aggregation_strategies: new GridHeaderModel(new HeaderStyles(null, null), 'Billing aggregation strategies', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_invoicing_template_rule_selection_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('exclude_rule_ids') set $inParams_exclude_rule_ids(value: any) {
    this.inParams['exclude_rule_ids'] = value;
  }
  get $inParams_exclude_rule_ids(): any {
    return this.inParams['exclude_rule_ids'] ;
  }

  topToolbar = {
      add_rules: new ToolModel(new ButtonModel('add_rules', new ButtonStyles(['primary'], null), false, 'Add rules', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Template rules';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.exclude_rule_ids)) {
        this.$missingRequiredInParams.push('exclude_rule_ids');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      search:  null ,
      billingContractId:  null ,
      projectId:  0 ,
      active:  null ,
      excludeRuleIds:  $grid.inParams.exclude_rule_ids ,
    };
    try {
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_invoicing_template_rule_selection_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Invoices_invoicing_template_rule_selection_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const p_terms = $datasources.Invoices.ds_invoice_terms_dd.get({});
  const p_taxes = $datasources.Invoices.ds_tax_schedules_dd.get({});
  const p_billing_aggregation = $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({});
  
  const [{ result: terms }, { result: taxes }, { result: billing_aggregation }] = await Promise.all([p_terms, p_taxes, p_billing_aggregation]);
  
  for (let row of $grid.rows) {
      let bs_ids = row.entity.billingAggregationStrategyIds ?? [];
  
      if (bs_ids.length > 0) {
  
          let bs_names = billing_aggregation.filter(bs => bs_ids.indexOf(bs.Id) >= 0)?.map(bs => bs.EnumName).join(', ') ?? "";
  
          if (bs_names.length > 50) {
              bs_names = bs_names.slice(0, 47)?.trim()
              bs_names = `${bs_names}...`
          }
          row.cells.billing_aggregation_strategies.displayControl.text = bs_names
      }
  
      row.cells.term.displayControl.text = terms.find(t => t.Id === row.entity.termId)?.Name;
      row.cells.tax_schedule.displayControl.text = taxes.find(t => t.Id === row.entity.taxScheduleId)?.Label;
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_invoicing_template_rule_selection_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $grid.outParams.rule_ids = [];
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Invoices_invoicing_template_rule_selection_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.add_rules.control.readOnly = $grid.selectedRows.length === 0;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $grid: Invoices_invoicing_template_rule_selection_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $grid.outParams.rule_ids = $grid.selectedRows.map(row => row.entity.instructionId);
  $grid.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: Invoices_invoicing_template_rule_selection_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $grid.close();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
