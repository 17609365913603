<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="process_wave" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_process_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="release_wave" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_release_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="cancel_wave" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cancel_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator2" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="print_wave" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_print_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-from_date" *ngIf="!filters.from_date.hidden" 
            class="field-container standard {{filters.from_date.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.from_date.styles.style"
            [ngClass]="filters.from_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.from_date.label + (filters.from_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.from_date.label}}<span *ngIf="filters.from_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="from_date"
            [format]="filters.from_date.control.format"
            [mode]="filters.from_date.control.mode"
            [ngStyle]="filters.from_date.control.styles.style"
            [ngClass]="filters.from_date.control.styles.classes"
            [tooltip]="filters.from_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.from_date.invalid">
          <ng-container *ngFor="let error of filters.from_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-to_date" *ngIf="!filters.to_date.hidden" 
            class="field-container standard {{filters.to_date.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.to_date.styles.style"
            [ngClass]="filters.to_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.to_date.label + (filters.to_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.to_date.label}}<span *ngIf="filters.to_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="to_date"
            [format]="filters.to_date.control.format"
            [mode]="filters.to_date.control.mode"
            [ngStyle]="filters.to_date.control.styles.style"
            [ngClass]="filters.to_date.control.styles.classes"
            [tooltip]="filters.to_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.to_date.invalid">
          <ng-container *ngFor="let error of filters.to_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_type.label + (filters.date_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_type.label}}<span *ngIf="filters.date_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Waves-wave_date_types_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
            [tooltip]="filters.date_type.control.tooltip"
        >
        </Waves-wave_date_types_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Shipment_Wave_Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_Id.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manual_allocations">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.manual_allocations.displayControl.styles.style"
          [ngClass]="row.cells.manual_allocations.displayControl.styles.classes"
          [matTooltip]="row.cells.manual_allocations.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.manual_allocations.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_Description.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Description.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_Description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_Description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Status_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_Status_Name.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Status_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_Status_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_Status_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Priority_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_Priority_Name.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Priority_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_Priority_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_Priority_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.text | formatText : row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.formatType : row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment_Wave_CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_CreatedSysUser.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment_Wave_CreatedSysUser.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment_Wave_CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="released_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.released_on.displayControl.styles.style"
          [ngClass]="row.cells.released_on.displayControl.styles.classes"
          [matTooltip]="row.cells.released_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.released_on.displayControl.text | formatText : row.cells.released_on.displayControl.formatType : row.cells.released_on.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_batch_pick">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_batch_pick_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_batch_pick.displayControl.styles.style"
                  [ngClass]="row.cells.is_batch_pick.displayControl.styles.classes"
                  [matTooltip]="row.cells.is_batch_pick.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_batch_pick.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="staging_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.staging_location.displayControl.styles.style"
          [ngClass]="row.cells.staging_location.displayControl.styles.classes"
          [matTooltip]="row.cells.staging_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.staging_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-pick_waves_expanded_grid
    [WaveId]="row.$rowExpand_FootPrintManager_pick_waves_expanded_grid_inParams_WaveId"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-pick_waves_expanded_grid>
</ng-template>

</datex-grid>
