import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'


interface IFootPrintManager_transload_outbound_shipments_gridComponentEntity {
OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BillOfLading?: string, BookingNumber?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadContainerId?: number, LookupCode?: string, Notes?: string, PickupDate?: string, ReferenceNumber?: string, StatusId?: number, TrailerId?: string, Carrier?: { ShortName?: string }, CarrierServiceType?: { ShortName?: string }, Status?: { Name?: string }, LicensePlateShipmentAssociation?: { LicensePlateId?: number, LicensePlate?: { Id?: number, ChildLicensePlates?: { Id?: number }[] } }[], Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } }, LoadContainer?: { LookupCode?: string } }, Order?: { Id?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, VendorReference?: string, Project?: { OwnerId?: number }, Status?: { Name?: string } }}

interface IFootPrintManager_transload_outbound_shipments_gridComponentInParams {
  orderId: number}


class FootPrintManager_transload_outbound_shipments_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_transload_outbound_shipments_gridComponent;
  entity: IFootPrintManager_transload_outbound_shipments_gridComponentEntity;

  vars: { stateId?: number, dockAppointmentId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    pickup_date_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    available_date_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    expected_date_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    bill_of_lading_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    reference_number_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    owner_reference_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendor_reference_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    booking_number_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    broker_reference_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    trailer_id_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    carrier_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    carrier_service_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    licenseplate_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    load_container: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    appointment: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    pickup_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new DateBoxModel(this.formGroup.controls['pickup_date_edit'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
      ),
    available_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new DateBoxModel(this.formGroup.controls['available_date_edit'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
      ),
    expected_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new DateBoxModel(this.formGroup.controls['expected_date_edit'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
      ),
    bill_of_lading: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['bill_of_lading_edit'] as DatexFormControl, null, false, '', null)
      ),
    reference_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['reference_number_edit'] as DatexFormControl, null, false, '', null)
      ),
    owner_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['owner_reference_edit'] as DatexFormControl, null, false, '', null)
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['vendor_reference_edit'] as DatexFormControl, null, false, '', null)
      ),
    booking_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['booking_number_edit'] as DatexFormControl, null, false, '', null)
      ),
    broker_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['broker_reference_edit'] as DatexFormControl, null, false, '', null)
      ),
    trailer_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['trailer_id_edit'] as DatexFormControl, null, false, '', null)
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['carrier_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    carrier_service: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['carrier_service_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '', null)
      ),
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.Order?.Project?.OwnerId;
    
    return expr;
  }
  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.carrier.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_transload_outbound_shipments_gridComponent, entity: IFootPrintManager_transload_outbound_shipments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.ShipmentId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_transload_outbound_shipments_gridComponent, entity?: IFootPrintManager_transload_outbound_shipments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.OrderId,this.entity.ShipmentId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'ShipmentId',this.entity.ShipmentId);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.datasources.FootPrintManager.ds_transload_outbound_shipments_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.Shipment?.LookupCode;
    (this.cells.load_container.displayControl as TextModel).text = $row.entity.Shipment?.LoadContainer?.LookupCode;
    (this.cells.pickup_date.editControl as DateBoxModel).reset($row.entity.Shipment?.PickupDate);
    (this.cells.available_date.editControl as DateBoxModel).reset($row.entity.Shipment?.AvailableDate);
    (this.cells.expected_date.editControl as DateBoxModel).reset($row.entity.Shipment?.ExpectedDate);
    (this.cells.bill_of_lading.displayControl as TextModel).text = $row.entity.Shipment?.BillOfLading;
    (this.cells.bill_of_lading.editControl as TextBoxModel).reset($row.entity.Shipment?.BillOfLading);
    (this.cells.reference_number.displayControl as TextModel).text = $row.entity.Shipment?.ReferenceNumber;
    (this.cells.reference_number.editControl as TextBoxModel).reset($row.entity.Shipment?.ReferenceNumber);
    (this.cells.owner_reference.displayControl as TextModel).text = $row.entity.Order?.OwnerReference;
    (this.cells.owner_reference.editControl as TextBoxModel).reset($row.entity.Order?.OwnerReference);
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.Order?.VendorReference;
    (this.cells.vendor_reference.editControl as TextBoxModel).reset($row.entity.Order?.VendorReference);
    (this.cells.booking_number.displayControl as TextModel).text = $row.entity.Shipment?.BookingNumber;
    (this.cells.booking_number.editControl as TextBoxModel).reset($row.entity.Shipment?.BookingNumber);
    (this.cells.broker_reference.displayControl as TextModel).text = $row.entity.Shipment?.BrokerReference;
    (this.cells.broker_reference.editControl as TextBoxModel).reset($row.entity.Shipment?.BrokerReference);
    (this.cells.trailer_id.displayControl as TextModel).text = $row.entity.Shipment?.TrailerId;
    (this.cells.trailer_id.editControl as TextBoxModel).reset($row.entity.Shipment?.TrailerId);
    (this.cells.carrier.displayControl as TextModel).text = $row.entity.Shipment?.Carrier?.ShortName;
    (this.cells.carrier.editControl as SelectBoxModel).reset($row.entity.Shipment?.CarrierId);
    (this.cells.carrier_service.displayControl as TextModel).text = $row.entity.Shipment?.CarrierServiceType?.ShortName;
    (this.cells.carrier_service.editControl as SelectBoxModel).reset($row.entity.Shipment?.CarrierServiceTypeId);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Shipment?.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Shipment?.Notes);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'load_container' : {
        this.on_load_container_clicked();
        break;
      }
      case 'appointment' : {
        this.on_appointment_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_row_data_loaded');
  var shipmentId = $row.entity.ShipmentId;
  
  // Get licenseplate count from the composite parent
  const licenseplates = (await $datasources.TransloadOrders.ds_get_licenseplate_count_by_composite_shipment.get({
      shipment_id: shipmentId
  })).result;
  
  if ($utils.isDefined(licenseplates) && licenseplates[0]?.LicensePlate?.ChildLicensePlates?.length > 0) {
  
      const count = licenseplates[0]?.LicensePlate?.ChildLicensePlates?.length ?? 0;
      $row.cells.licenseplate_count.displayControl.text = count.toString();
  }
  // Get the count when the children have been unchained
  else {
      const licenseplates = (await $datasources.TransloadOrders.ds_get_licenseplate_count_by_children_shipment.get({ shipment_id: shipmentId })).result;
      if ($utils.isDefined(licenseplates)) {
  
          const count = licenseplates.total_lp_count ?? 0;
          $row.cells.licenseplate_count.displayControl.text = count.toString();
      }
  
  }
  
  
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  if ($utils.isDefined($row.entity.Shipment.AvailableDate)) {
      $row.cells.available_date.displayControl.text = $utils.date.format($row.entity.Shipment.AvailableDate, format);
  }
  
  if ($utils.isDefined($row.entity.Shipment.ExpectedDate)) {
      $row.cells.expected_date.displayControl.text = $utils.date.format($row.entity.Shipment.ExpectedDate, format);
  }
  
  if ($utils.isDefined($row.entity.Shipment.PickupDate)) {
      $row.cells.pickup_date.displayControl.text = $utils.date.format($row.entity.Shipment.PickupDate, format);
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_save_existing_row');
  
  
  const payload: any = {};
  const order_payload: any = {};
  
  if ($row.cells.available_date.editControl.isChanged) {
      payload.AvailableDate = $row.cells.available_date.editControl.value;
  }
  if ($row.cells.bill_of_lading.editControl.isChanged) {
      payload.BillOfLading = $row.cells.bill_of_lading.editControl.value;
  }
  
  if ($row.cells.booking_number.editControl.isChanged) {
      payload.BookingNumber = $row.cells.booking_number.editControl.value;
  }
  if ($row.cells.broker_reference.editControl.isChanged) {
      payload.BrokerReference = $row.cells.broker_reference.editControl.value;
  }
  if ($row.cells.reference_number.editControl.isChanged) {
      payload.ReferenceNumber = $row.cells.reference_number.editControl.value;
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  if ($row.cells.carrier.editControl.isChanged) {
      payload.CarrierId = $row.cells.carrier.editControl.value;
  }
  
  if ($row.cells.carrier_service.editControl.isChanged) {
      payload.CarrierServiceTypeId = $row.cells.carrier_service.editControl.value;
  }
  
  if ($row.cells.trailer_id.editControl.isChanged) {
      payload.TrailerId = $row.cells.trailer_id.editControl.value;
  }
  if ($row.cells.expected_date.editControl.isChanged) {
      payload.ExpectedDate = $row.cells.expected_date.editControl.value;
  }
  if ($row.cells.pickup_date.editControl.isChanged) {
      payload.PickupDate = $row.cells.pickup_date.editControl.value;
  }
  if ($row.cells.owner_reference.editControl.isChanged) {
      order_payload.OwnerReference = $row.cells.owner_reference.editControl.value;
  }
  if ($row.cells.vendor_reference.editControl.isChanged) {
      order_payload.VendorReference = $row.cells.vendor_reference.editControl.value;
  }
  
  
  
  
  try {
  
  
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Shipments',
          id: $row.entity.ShipmentId,
          entity: payload
      });
  
      if ($utils.isDefined(order_payload)) {
  
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Orders',
              id: $row.entity.OrderId,
              entity: order_payload
          });
      }
  
  
      await $row.refresh();
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  on_load_container_clicked(event = null) {
    return this.on_load_container_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_load_container_clickedInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_load_container_clicked');
  
  
  
  const load_container_id = $row.entity.Shipment?.LoadContainerId;
  
  if ($utils.isDefined(load_container_id)){
  
      await $shell.FootPrintManager.openload_container_editor({loadContainerId: load_container_id})
  }
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_appointment_clicked');
  //O.Arias - 08/17/2023
  
  let dock_appointment_id = $row.vars.dockAppointmentId;
  
  if ($utils.isDefined(dock_appointment_id)) {
  
      if ($utils.isDefined($row.entity.Shipment?.ExpectedWarehouseId)) {
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
              dockAppointmentId: dock_appointment_id,
              shipmentId: $row.entity.ShipmentId
          });
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
  
      }
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
     }
  
  }
  else {
      
      if ($utils.isDefined($row.entity.Shipment?.ExpectedWarehouseId)) {
          const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
          const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
  
              warehouseId: [$row.entity.Shipment?.ExpectedWarehouseId],
              lookupcode: nextAppointmentId.toString(),
              scheduledArrival: $utils.isDefined($row.entity.Shipment?.PickupDate) ? $row.entity.Shipment?.PickupDate : $utils.date.now(),
              scheduledDeparture: $utils.isDefined($row.entity.Shipment?.PickupDate) ? $utils.date.add(1, 'hour', $row.entity.Shipment?.PickupDate) : $utils.date.add(1, 'hour', $utils.date.now()),
              typeId: 2,
              ownerId: $row.entity.Order.Project.OwnerId,
              projectId: $row.entity.Order.ProjectId,
              carrierId: $row.entity.Shipment?.CarrierId,
              shipmentId: $row.entity.ShipmentId
          });
  
          if ($utils.isDefined(dialogResult)) {
              const userConfirm = dialogResult.confirm;
              if (userConfirm) {
                  $grid.refresh();
              }
          }
      }
  
      else {
          throw new Error('Unable to determined preferred warehouse from the selected order.')
      }
  
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'FootPrintManager-transload_outbound_shipments_grid',
  templateUrl: './FootPrintManager.transload_outbound_shipments_grid.component.html'
})
export class FootPrintManager_transload_outbound_shipments_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_transload_outbound_shipments_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootPrintManager_transload_outbound_shipments_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       licenseplate_count: new GridHeaderModel(new HeaderStyles(null, null), 'LP count', false, false, null),       load_container: new GridHeaderModel(new HeaderStyles(null, null), 'Load container', false, false, null),       appointment: new GridHeaderModel(new HeaderStyles(null, null), 'Appointment', false, false, null),       pickup_date: new GridHeaderModel(new HeaderStyles(null, null), 'Pickup date', false, false, null),       available_date: new GridHeaderModel(new HeaderStyles(null, null), 'Available date', false, false, null),       expected_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expected date', false, false, null),       bill_of_lading: new GridHeaderModel(new HeaderStyles(null, null), 'Bill of lading', false, false, null),       reference_number: new GridHeaderModel(new HeaderStyles(null, null), 'Reference number', false, false, null),       owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       booking_number: new GridHeaderModel(new HeaderStyles(null, null), 'Booking number', false, false, null),       broker_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Broker reference', false, false, null),       trailer_id: new GridHeaderModel(new HeaderStyles(null, null), 'Trailer ID', false, false, null),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier', false, false, null),       carrier_service: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier service', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_transload_outbound_shipments_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      create_shipment: new ToolModel(new ButtonModel('create_shipment', new ButtonStyles(null, null), false, 'New outbound', 'icon-ic_fluent_add_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cross_dock: new ToolModel(new ButtonModel('cross_dock', new ButtonStyles(null, null), false, 'Prepare cross-dock', 'icon-ic_fluent_skip_forward_tab_20_regular', null)
    ),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, 'Auto load', 'icon-datex-Load', null)
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, 'Surveys', 'ms-Icon ms-Icon--Questionnaire', null)
    ),
      print: new ToolModel(new SplitButtonModel<{ license_plate_labels: ButtonModel, print_bill_of_lading: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('license_plate_labels', new ButtonStyles(null, null), false, 'License plate labels', ''),
          new ButtonModel('print_bill_of_lading', new ButtonStyles(null, null), false, 'Bill of lading', '')
        ])
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Transload outbound shipments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_transload_outbound_shipments_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_transload_outbound_shipments_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_outbound_shipment(event = null) {
    return this.on_new_outbound_shipmentInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_outbound_shipmentInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_new_outbound_shipment');
  
  
  // Get order details
  const order = (await $datasources.TransloadOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  // Get warehouse default receiving location
  var defaultReceivingLocationId;
  const warehouse = (await $datasources.PurchaseOrders.ds_get_warehouse_by_warehouseId.get({ warehouseId: order.PreferredWarehouseId })).result;
  if ($utils.isDefined(warehouse)) {
      if ($utils.isDefined(warehouse.DefaultReceivingLocationId)) {
          defaultReceivingLocationId = warehouse.DefaultReceivingLocationId;
      } else {
    
          throw new Error('Missing a default receiving location on the preferred warehouse.');
      }
  } else {
     
      throw new Error('Unable to determine warehouse from inbound order.');
  }
  
  
  if ($utils.isDefined(order)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const updated_bol_seed = (await $flows.SalesOrders.update_bill_of_lading_seed({})).next_bol_seed;
  
      // create shipment order lookup
      const payload = {
          "OrderId": $grid.inParams.orderId,
          "Shipment": {
              "Id": nextShipmentId,
              "LookupCode": nextShipmentId.toString(),
              "CarrierId": order.PreferredCarrierId,
              "ExpectedWarehouseId": order.PreferredWarehouseId,
              "BillOfLading": updated_bol_seed?.toString(),
              "Cartonized": false,
              "ConfirmSeal": false,
              "StatusId": 1,
              "Transhipment": false,
              "TypeId": 2
          }
      }
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
      // Create the composite license plate and the licenseplate shipment association
      const nextLicensePlateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
      const associationPayload = {
          "ShipmentId": nextShipmentId,
          "LicensePlate": {
              "Id": nextLicensePlateId,
              "LookupCode": `COMP1 for SH${nextShipmentId.toString()}`,
              "WarehouseId": order.PreferredWarehouseId,
              "LocationId": defaultReceivingLocationId,
              "StatusId": 1,
              "Archived": false,
              "TypeId": 2
          }
      }
      await $flows.Utilities.crud_create_flow({ entitySet: 'LicensePlatesShipmentsAssociation', entity: associationPayload });
  
      $grid.refresh();
  
  }
  else {
      throw new Error('Unable to determine outbound order details.')
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_row_selected');
  let order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  const outbound_shipment_state = $grid.selectedRows[0]?.vars.stateId ?? 0;
  // { stateId: 1, stateName: 'Empty' },
  // { stateId: 3, stateName: 'Ready to process wave' },
  // { stateId: 4, stateName: 'Ready to cross dock' },
  // { stateId: 5, stateName: 'Ready to cross dock' },
  // { stateId: 6, stateName: 'Cross dock in progress' },
  // { stateId: 7, stateName: 'Ready to load' },
  // { stateId: 8, stateName: 'Ready to complete' },
  // { stateId: 9, stateName: 'Ready to cancel' },
  // { stateId: 10, stateName: 'Loading in progress' },
  // { stateId: 11, stateName: 'Completed' },
  // { stateId: 12, stateName: 'Cancelled' }
  
  
  const hasOneSelectedRows = $grid.selectedRows.length === 1;
  if (hasOneSelectedRows) {
      $grid.topToolbar.surveys.control.readOnly = false;
  }
  else {
      $grid.topToolbar.surveys.control.readOnly = true;
  }
  
  // Not completed or cancelled
  if (order.OrderStatusId !== 4 && order.OrderStatusId !== 8) {
  
      $grid.topToolbar.create_shipment.control.readOnly = false;
  
  
  
      if (hasOneSelectedRows) {
  
          $grid.topToolbar.print.control.readOnly = false;
          const shipment_status = $grid.selectedRows[0].entity.Shipment?.StatusId;
          const childrenlength = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlate?.ChildLicensePlates?.length;
          const childrenCount = $utils.isDefined(childrenlength) ? childrenlength : 0;
  
          // Created status
          if (shipment_status === 1) {
  
              $grid.topToolbar.auto_load.control.readOnly = true;
              $grid.topToolbar.complete.control.readOnly = true;
  
              if (childrenCount === 0) {
                  $grid.topToolbar.on_delete.control.readOnly = false;
                  $grid.topToolbar.cross_dock.control.readOnly = true;
              } else {
                  $grid.topToolbar.cross_dock.control.readOnly = false;
              }
          }
          // Executing status
          else if (shipment_status === 4) {
  
              $grid.topToolbar.cross_dock.control.readOnly = true;
  
              if (outbound_shipment_state === 7 || outbound_shipment_state === 10) {
                  $grid.topToolbar.auto_load.control.readOnly = false;
              }
              else {
                  $grid.topToolbar.auto_load.control.readOnly = true;
              }
  
              if (outbound_shipment_state === 8) {
                  $grid.topToolbar.complete.control.readOnly = false;
              }
              else {
                  $grid.topToolbar.complete.control.readOnly = true;
              }
  
              $grid.topToolbar.on_delete.control.readOnly = true;
          }
          else {
              $grid.topToolbar.cross_dock.control.readOnly = true;
              $grid.topToolbar.auto_load.control.readOnly = true;
              $grid.topToolbar.complete.control.readOnly = true
              $grid.topToolbar.on_delete.control.readOnly = true;
          }
  
      }
      else {
          $grid.topToolbar.cross_dock.control.readOnly = true;
          $grid.topToolbar.auto_load.control.readOnly = true;
          $grid.topToolbar.complete.control.readOnly = true;
          $grid.topToolbar.on_delete.control.readOnly = true;
  
          $grid.topToolbar.print.control.readOnly = true;
  
  
      }
  
  } else {
      $grid.topToolbar.create_shipment.control.readOnly = true;
      $grid.topToolbar.cross_dock.control.readOnly = true;
      $grid.topToolbar.auto_load.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
  
      $grid.topToolbar.print.control.readOnly = true;
  }
  
  
  }
  on_cross_dock(event = null) {
    return this.on_cross_dockInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cross_dockInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_cross_dock');
  
  const shipment_id = $grid.selectedRows[0].entity.ShipmentId;
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog(
      `Prepare shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode} for cross-dock?`,
      `Are you sure you want to prepare shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode} for cross-dock? You will not be able to add any more LPs to this shipment.`, 'Yes', 'No');
  if (confirmed) {
      const licenseplate_id = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlateId;
  
      if ($utils.isDefined(licenseplate_id)) {
          const result = await $flows.TransloadOrders.pick_transload_composite_licenseplate_flow({ licenseplate_id: licenseplate_id });
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              await $shell.FootPrintManager.openErrorDialog('Shipment cannot be cross-docked.', reason);
  
          }
          else {
              const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} cross-docked.`;
  
              // Crud insert into the shipment Content Lookup table
  
              const inventories = (await $datasources.TransloadOrders.ds_get_shipped_transload_inventory_by_shipment_id.get({
                  shipment_id: shipment_id
              })).result;
              if ($utils.isDefined(inventories)) {
  
  
                  for (let inventory of inventories) {
  
                      let shipment_line_id = null;
                      let lot_id = inventory.LotId;
                      let packaging_id = inventory.PackagedId;
                      let licenseplate_id = inventory.LicensePlateId;
                      let packaged_amount = inventory.PackagedAmount;
  
                      const shipment_line = (await $datasources.TransloadOrders.ds_get_shipment_lines_by_shipment_id.get({
                          shipment_id: shipment_id,
                          lot_id: lot_id,
                          packaging_id: packaging_id
                      })).result;
  
                      if ($utils.isDefined(shipment_line)) {
  
                          shipment_line_id = shipment_line[0].Id;
                      }
  
                      if ($utils.isAllDefined(shipment_line_id, lot_id, packaging_id, packaged_amount, licenseplate_id)) {
  
  
  
                          const payload = {
                              "ShipmentLineId": shipment_line_id,
                              "LicensePlateId": licenseplate_id,
                              "LotId": lot_id,
                              "PackagedId": packaging_id,
                              "PackagedAmount": packaged_amount
  
                          }
  
                          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentsContentsLookup', entity: payload });
  
  
                      }
                  }
  
              }
  
  
  
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
              await $grid.refresh();
          }
  
      }
      else {
  
          throw new Error('Unable to determine composite licenseplate for the given outbound shipment.')
      }
  
  }
  }
  on_complete(event = null) {
    return this.on_completeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_completeInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_complete');
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Complete', `Are you sure you want to complete shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode}?`, 'Yes', 'No');
  if (confirmed) {
  
      const shipment_id = $grid.selectedRows[0].entity.ShipmentId;
      const order_id = $grid.selectedRows[0].entity.OrderId
  
      if ($utils.isDefined(shipment_id)) {
          const result = await $flows.TransloadOrders.complete_outbound_transload_shipment_flow({
              shipment_id: shipment_id,
              order_id: order_id,
              keep_parent_order_open: true
          });
  
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              await $shell.FootPrintManager.openErrorDialog('Shipment cannot be completed.', reason);
  
          }
          else {
              const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} completed`;
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
              await $grid.refresh();
          }
  
      }
      else {
  
          throw new Error('Unable to determine the shipment from the selected row.')
      }
  
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_grid_data_loaded');
  const order_data = $grid.rows;
  const outbound_shipments = $grid.rows.map(row => row.entity.ShipmentId)
  
  const orders = order_data.map(o => {
      return {
          orderId: o.entity.OrderId,
          orderStatusId: o.entity.Order?.OrderStatusId,
          orderStatus: o.entity.Order?.Status?.Name,
          shipmentId: o.entity.ShipmentId,
          shipmentStatusId: o.entity.Shipment?.StatusId,
          waveId: o.entity.Shipment?.Wave?.Id,
          waveStatus: o.entity.Shipment?.Wave?.Status?.Name,
          waveStatusId: o.entity.Shipment?.Wave?.StatusId
      }
  });
  
  const order_states = (await $flows.TransloadOrders.get_transload_shipment_state({ orders: orders })).states;
  
  const dock_appointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_shipmentIds.get({ shipmentIds: outbound_shipments })).result;
  
  const state_array = order_states.map(row => row.shipmentId);
  const dock_array = dock_appointments.map(row => row.ItemEntityId);
  
  for (let row of order_data) {
  
      let orderIndex = -1;
      let shipmentId = row.entity.ShipmentId;
      let orderStatusId = row.entity.Order?.OrderStatusId;
  
      let stateId: number = orderStatusId;
      let stateName: string = row.entity.Shipment?.Status?.Name;
  
      orderIndex = state_array.indexOf(shipmentId)
      if (orderIndex > -1) {
          stateId = order_states[orderIndex].stateId;
          stateName = order_states[orderIndex].stateName;
      };
  
      row.vars.stateId = stateId;
      row.cells.state.displayControl.text = stateName;
  
      // Apply appointment text logic
      orderIndex = dock_array.indexOf(shipmentId)
      if (orderIndex >= 0) {
  
          let dock_appointment_item = dock_appointments[orderIndex];
  
          if ($utils.isDefined(dock_appointment_item.DockAppointmentId)) {
  
              let dock_appointment_id = dock_appointment_item.DockAppointmentId;
              let dock_appointment = dock_appointment_item.DockAppointment;
  
              row.vars.dockAppointmentId = dock_appointment_id;
  
              let scheduled_arrival = dock_appointment_item.convertedScheduledArrival?.convertedDate;
              let scheduled_location = $utils.isDefinedTrimmed(dock_appointment?.ScheduledLocation?.ShortName) ? dock_appointment?.ScheduledLocation?.ShortName : dock_appointment?.ScheduledLocation?.Name;
              let assigned_location = $utils.isDefinedTrimmed(dock_appointment?.AssignedLocation?.ShortName) ? dock_appointment?.AssignedLocation?.ShortName : dock_appointment?.AssignedLocation?.Name;
              let dock_door = $utils.isDefinedTrimmed(assigned_location) ? assigned_location : ($utils.isDefinedTrimmed(scheduled_location) ? scheduled_location : 'Unassigned');
  
              row.cells.appointment.displayControl.text = (await $flows.DockAppointments.get_formatted_dock_appointment({
                  dockAppointmentId: dock_appointment_id,
                  optimalInputs: {
                      scheduledLocationName: dock_door,
                      assignedLocationName: dock_door,
                      statusId: dock_appointment?.StatusId,
                      lookupCode: dock_appointment?.LookupCode,
                      scheduledArrival: scheduled_arrival
                  }
              })).formattedDockAppointment;
          }
          else {
              row.cells.appointment.displayControl.text = '  +'
          };
      }
      else {
          row.cells.appointment.displayControl.text = '  +'
      };
  
  };
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_auto_load_clicked');
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Auto load', `Are you sure you want to auto load shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode}?`, 'Yes', 'No');
  if (confirmed) {
  
      const result = await $flows.SalesOrders.is_sales_order_loadable_flow({
          orderId: $grid.selectedRows[0].entity.OrderId,
          shipmentId: $grid.selectedRows[0].entity.ShipmentId
      });
  
      const reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.FootPrintManager.openErrorDialog('Order cannot be loaded.', reason);
          return;
      } else {
  
          var warehouseId = $grid.selectedRows[0].entity.Shipment?.ActualWarehouseId
          if (!$utils.isDefined(warehouseId)) {
  
              $shell.FootPrintManager.openErrorDialog('Shipment Auto Load Error', 'Unable to determine warehouse from the selected shipment.');
              return;
          }
  
          // Get assigned or scheduled dock door from dock appointment if it exists
          const dockappointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_shipmentId.get({ 
              shipmentId: $grid.selectedRows[0]?.entity.ShipmentId })).result;
  
          if ($utils.isDefined(dockappointment)) {
  
              const doorId = dockappointment[0]?.DockAppointment?.AssignedLocation?.Id ?? dockappointment[0]?.DockAppointment?.ScheduledLocation?.Id;
  
              if ($utils.isDefined(doorId)) {
                  const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({ locationId: doorId })).result;
                  if ($utils.isDefined(dockDoor)) {
                      // Get child location of type inventory
                      var locationId = dockDoor.ChildLocationContainers[0]?.Id
                  }
  
              }
          }
  
  
          const dialogResult = await $shell.FootPrintManager.openauto_load_options_formDialog({
              warehouseId: warehouseId,
              loadLocationId: locationId
          });
  
          var userConfirmed = dialogResult.confirm;
          var loadLocation = dialogResult.loadLocation;
  
          if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
              return;
          }
          if (userConfirmed) {
              try {
  
                  const result = await $flows.SalesOrders.auto_load_sales_order_flow({
                      orderId: $grid.selectedRows[0].entity.OrderId,
                      shipmentId: $grid.selectedRows[0].entity.ShipmentId,
                      loadLocation: loadLocation
                  });
                  const reason = result.reason;
  
                  if ($utils.isDefined(reason)) {
                      await $shell.FootPrintManager.openErrorDialog('Shipment cannot be fully loaded.', reason);
                      return;
                  }
                  else {
                      const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} loaded`;
                      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                      await $grid.refresh();
  
                  }
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} - ${errorMessage}`;
                  await $shell.FootPrintManager.openErrorDialog('Load shipment error', 'An error occurred during the loading of the shipment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
  
          }
  
      }
  
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_delete_clicked');
  try {
      // Initialize data
      const shipmentId = $grid.selectedRows[0].entity.ShipmentId;
      const orderId = $grid.selectedRows[0].entity.OrderId;
      const licenseplateId = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlateId;
  
      // Delete shipment order lookup
      await $flows.Utilities.crud_delete_dual_pk_flow({
          entitySet: 'ShipmentOrderLookup',
          pk1String: 'ShipmentId',
          pk1: shipmentId,
          pk2String: 'OrderId',
          pk2: orderId
      });
  
      // Delete licenseplate shipment association
      await $flows.Utilities.crud_delete_dual_pk_flow({
          entitySet: 'LicensePlatesShipmentsAssociation',
          pk1String: 'ShipmentId',
          pk1: shipmentId,
          pk2String: 'LicensePlateId',
          pk2: licenseplateId
      });
  
      // Delete shipment
      await $flows.Utilities.crud_delete_flow({ entitySet: 'Shipments', id: shipmentId });
  
      // Delete licenseplate
      await $flows.Utilities.crud_delete_flow({ entitySet: 'LicensePlates', id: licenseplateId });
  
      const title = `Shipment ${shipmentId.toString()} deleted`;
      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
      await $grid.refresh();
  
      $grid.refresh();
  
  }
  
  catch (error) {
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
  }
  }
  on_so_print_labels(event = null) {
    return this.on_so_print_labelsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_so_print_labelsInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_so_print_labels');
  
  
  
  await $shell.FootPrintManager.openprint_order_labels_formDialog({ 
      orderId: $grid.selectedRows[0].entity.OrderId ,
      context:"so",
      shipmentId: $grid.selectedRows[0].entity.ShipmentId });
  }
  on_bill_of_lading(event = null) {
    return this.on_bill_of_ladingInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_bill_of_ladingInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_bill_of_lading');
  
  
  
  
  
  await $shell.SalesOrders.openoutbound_bill_of_lading_reportDialog({ 
      orderId: $grid.selectedRows[0]?.entity.OrderId,
      shipmentId: $grid.selectedRows[0]?.entity.ShipmentId});
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_init');
  
  // Apply operations
  if (await $operations.FootPrintManager.Disable_TransLoads_AutoLoading.isAuthorized()){
      $grid.topToolbar.auto_load.hidden = true;
  }
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'transload_outbound_shipments_grid.on_surveys_clicked');
  
  if ($utils.isDefined($grid.selectedRows[0].entity.ShipmentId)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$grid.selectedRows[0].entity.ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
