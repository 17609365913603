import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Returns_ShellService } from './Returns.shell.service';
import { Returns_OperationService } from './Returns.operation.service';
import { Returns_DatasourceService } from './Returns.datasource.index';
import { Returns_FlowService } from './Returns.flow.index';
import { Returns_ReportService } from './Returns.report.index';
import { Returns_LocalizationService } from './Returns.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Returns.frontend.types'
import { $frontendTypes as $types} from './Returns.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Returns_shipping_container_return_states_dd_multiComponent } from './Returns.shipping_container_return_states_dd_multi.component'


interface IReturns_return_shipping_containers_gridComponentEntity {
Id?: number, LookupCode?: string, Shipment?: { Id?: number, OrderLookups?: { CreatedSysDateTime?: string, Order?: { LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } } }[] }, LicensePlates?: { Id?: number, Archived?: boolean, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string } }[]}

interface IReturns_return_shipping_containers_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[]}


class Returns_return_shipping_containers_gridComponentRowModel extends GridRowModel {
  grid: Returns_return_shipping_containers_gridComponent;
  entity: IReturns_return_shipping_containers_gridComponentEntity;


  options: { blind_receive: ButtonModel } = {
    blind_receive: new ButtonModel('blind_receive', new ButtonStyles(null, null), false, 'Blind receive', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    container_code: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    order_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Returns_ShellService,
private datasources: Returns_DatasourceService,
private flows: Returns_FlowService,
private reports: Returns_ReportService,
private localization: Returns_LocalizationService,
private operations: Returns_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Returns_return_shipping_containers_gridComponent, entity: IReturns_return_shipping_containers_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Returns_return_shipping_containers_gridComponent, entity?: IReturns_return_shipping_containers_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      orderStatusIds:  $grid.inParams.orderStatusIds ,
      reference:  null ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      isSourceShippingContainer:  null ,
      statusIds:  $grid.filters.return_states.control.value ,
    };
    const data = await this.datasources.Returns.ds_return_shipping_containers_grid_testing.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.container_code.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.LicensePlates[0]?.Location?.Name;
    (this.cells.order_code.displayControl as TextModel).text = $row.entity.Shipment?.OrderLookups[0]?.Order?.LookupCode;
    (this.cells.owner_code.displayControl as TextModel).text = $row.entity.Shipment?.OrderLookups[0]?.Order?.Project?.Owner?.LookupCode;
    (this.cells.project_code.displayControl as TextModel).text = $row.entity.Shipment?.OrderLookups[0]?.Order?.Project?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'container_code' : {
        this.on_container_code_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Returns_return_shipping_containers_gridComponentRowModel,
  $grid: Returns_return_shipping_containers_gridComponent, 
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_row_data_loaded');
  
  }
  on_container_code_clicked(event = null) {
    return this.on_container_code_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_code_clickedInternal(
    $row: Returns_return_shipping_containers_gridComponentRowModel,
  $grid: Returns_return_shipping_containers_gridComponent, 
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_container_code_clicked');
  //$shell.FootPrintManager.openshipping_container_editor({ shippingContainerId: $row.entity.Id });
  }
  blind_receive(event = null) {
    return this.blind_receiveInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async blind_receiveInternal(
    $row: Returns_return_shipping_containers_gridComponentRowModel,
  $grid: Returns_return_shipping_containers_gridComponent, 
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.blind_receive');
  //$shell.FootPrintManager.openblind_return_shipping_container_hub({ shippingContainerId: $row.entity.Id });
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Returns_shipping_container_return_states_dd_multiComponent),
  ],
  selector: 'Returns-return_shipping_containers_grid',
  templateUrl: './Returns.return_shipping_containers_grid.component.html'
})
export class Returns_return_shipping_containers_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IReturns_return_shipping_containers_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IReturns_return_shipping_containers_gridComponentInParams = { ownerId: null, projectId: null, warehouseIds: [], orderStatusIds: [], orderClassIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     container_code: new GridHeaderModel(new HeaderStyles(null, null), 'Container', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       order_code: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       owner_code: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project_code: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Returns_return_shipping_containers_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('orderStatusIds') set $inParams_orderStatusIds(value: any) {
    this.inParams['orderStatusIds'] = value;
  }
  get $inParams_orderStatusIds(): any {
    return this.inParams['orderStatusIds'] ;
  }
  @Input('orderClassIds') set $inParams_orderClassIds(value: any) {
    this.inParams['orderClassIds'] = value;
  }
  get $inParams_orderClassIds(): any {
    return this.inParams['orderClassIds'] ;
  }

  topToolbar = {
      blind_receive: new ToolModel(new ButtonModel('blind_receive', new ButtonStyles(null, null), true, 'Blind receive', 'icon-datex-Blind-Receiving', null)
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    return_states: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    return_states: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['return_states'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'State', false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Returns_ShellService,
    private datasources: Returns_DatasourceService,
    private flows: Returns_FlowService,
    private reports: Returns_ReportService,
    private localization: Returns_LocalizationService,
    private operations: Returns_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Return shipping containers';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;

    (this.filters.return_states.control as SelectBoxModel).reset([0,1]);

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['return_states']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_return_states_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      orderStatusIds:  $grid.inParams.orderStatusIds ,
      reference:  null ,
      orderClassIds:  $grid.inParams.orderClassIds ,
      isSourceShippingContainer:  null ,
      statusIds:  $grid.filters.return_states.control.value ,
    };
    try {
    const data = await this.datasources.Returns.ds_return_shipping_containers_grid_testing.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Returns_return_shipping_containers_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_return_states_changed(event = null) {
    return this.on_return_states_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_return_states_changedInternal(
    $grid: Returns_return_shipping_containers_gridComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_return_states_changed');
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: Returns_return_shipping_containers_gridComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_data_loaded');
  let existingTasks = (await $datasources.Returns.ds_get_tasks_by_shippingContainerIds.get({ shippingContainerIds: $grid.rows.map(row => row.entity.Id) })).result;
  
  for (let row of $grid.rows) {
      // Container state
      if (row.entity.LicensePlates.every(lp => lp.Archived)) {
          row.cells.state.displayControl.text = 'Closed';
      }
      else {
          let rowCompletedTasks = existingTasks.find(t => t.ActualTargetShippingContainerId === row.entity.Id || t.ActualSourceShippingContainerId === row.entity.Id);
  
          if ($utils.isDefined(rowCompletedTasks)) {
              row.cells.state.displayControl.text = 'In progress';
          }
          else {
              row.cells.state.displayControl.text = 'Open';
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Returns_return_shipping_containers_gridComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_row_selected');
  if ($grid.selectedRows.length === 1 ) {
      $grid.topToolbar.blind_receive.control.readOnly = false;
  }
  else {
      $grid.topToolbar.blind_receive.control.readOnly = true;
  }
  
  }
  on_blind_receive_clicked(event = null) {
    return this.on_blind_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_blind_receive_clickedInternal(
    $grid: Returns_return_shipping_containers_gridComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.logger.log('Returns', 'return_shipping_containers_grid.on_blind_receive_clicked');
  try {
      if ($grid.selectedRows.length > 1) {
          throw new Error(`Blind receiving can only be done for one shipping container at a time.`);
      }
  
      //$shell.FootPrintManager.openblind_return_shipping_container_hub({ shippingContainerId: $grid.selectedRows[0]?.entity?.Id });
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      //await $shell.FootPrintManager.openErrorDialog('Error blind receiving', error.message);
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
