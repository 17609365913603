<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="create_rule" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_create_rule_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="edit_rule" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_edit_rule_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="delete_rule" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_rule_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="copy" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_copy_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator2" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="execute" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_execute_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="request_history" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_request_history_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator3" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="field_selector" let-tool>
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_enabled.readOnly"
                          [ngStyle]="tool.control.buttons.fs_enabled.styles.style"
                          [ngClass]="tool.control.buttons.fs_enabled.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_enabled.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_enabled.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_enabled.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_enabled.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_rule_id.readOnly"
                          [ngStyle]="tool.control.buttons.fs_rule_id.styles.style"
                          [ngClass]="tool.control.buttons.fs_rule_id.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_rule_id.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_rule_id.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_rule_id.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_rule_id.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_owner.readOnly"
                          [ngStyle]="tool.control.buttons.fs_owner.styles.style"
                          [ngClass]="tool.control.buttons.fs_owner.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_owner.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_owner.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_owner.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_owner.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_project.readOnly"
                          [ngStyle]="tool.control.buttons.fs_project.styles.style"
                          [ngClass]="tool.control.buttons.fs_project.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_project.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_project.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_project.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_project.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_context.readOnly"
                          [ngStyle]="tool.control.buttons.fs_context.styles.style"
                          [ngClass]="tool.control.buttons.fs_context.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_context.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_context.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_context.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_context.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_frequency.readOnly"
                          [ngStyle]="tool.control.buttons.fs_frequency.styles.style"
                          [ngClass]="tool.control.buttons.fs_frequency.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_frequency.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_frequency.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_frequency.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_frequency.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_last_executed_on.readOnly"
                          [ngStyle]="tool.control.buttons.fs_last_executed_on.styles.style"
                          [ngClass]="tool.control.buttons.fs_last_executed_on.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_last_executed_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_last_executed_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_last_executed_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_last_executed_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_to.readOnly"
                          [ngStyle]="tool.control.buttons.fs_to.styles.style"
                          [ngClass]="tool.control.buttons.fs_to.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_to.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_to.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_to.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_to.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_cc.readOnly"
                          [ngStyle]="tool.control.buttons.fs_cc.styles.style"
                          [ngClass]="tool.control.buttons.fs_cc.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_cc.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_cc.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_cc.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_cc.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_bcc.readOnly"
                          [ngStyle]="tool.control.buttons.fs_bcc.styles.style"
                          [ngClass]="tool.control.buttons.fs_bcc.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_bcc.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_bcc.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_bcc.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_bcc.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_subject.readOnly"
                          [ngStyle]="tool.control.buttons.fs_subject.styles.style"
                          [ngClass]="tool.control.buttons.fs_subject.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_subject.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_subject.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_subject.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_subject.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_body.readOnly"
                          [ngStyle]="tool.control.buttons.fs_body.styles.style"
                          [ngClass]="tool.control.buttons.fs_body.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_body.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_body.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_body.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_body.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_notes.readOnly"
                          [ngStyle]="tool.control.buttons.fs_notes.styles.style"
                          [ngClass]="tool.control.buttons.fs_notes.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_notes.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_notes.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_notes.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_notes.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_created_on.readOnly"
                          [ngStyle]="tool.control.buttons.fs_created_on.styles.style"
                          [ngClass]="tool.control.buttons.fs_created_on.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_created_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_created_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_created_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_created_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.fs_modified_on.readOnly"
                          [ngStyle]="tool.control.buttons.fs_modified_on.styles.style"
                          [ngClass]="tool.control.buttons.fs_modified_on.styles.classes"
                          [matTooltip]="tool.control.buttons.fs_modified_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_select_change($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.fs_modified_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.fs_modified_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.fs_modified_on.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="is_enabled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['is_enabled_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.is_enabled.displayControl.styles.style"
                  [ngClass]="row.cells.is_enabled.displayControl.styles.classes"
                  [matTooltip]="row.cells.is_enabled.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.is_enabled.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="rule_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.rule_id.displayControl.styles.style"
          [ngClass]="row.cells.rule_id.displayControl.styles.classes"
          [matTooltip]="row.cells.rule_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.rule_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes"
          [matTooltip]="row.cells.owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="context">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.context.displayControl.styles.style"
          [ngClass]="row.cells.context.displayControl.styles.classes"
          [matTooltip]="row.cells.context.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.context.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="frequency">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.frequency.displayControl.styles.style"
          [ngClass]="row.cells.frequency.displayControl.styles.classes"
          [matTooltip]="row.cells.frequency.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.frequency.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_executed_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_executed_on.displayControl.styles.style"
          [ngClass]="row.cells.last_executed_on.displayControl.styles.classes"
          [matTooltip]="row.cells.last_executed_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_executed_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.to.displayControl.styles.style"
          [ngClass]="row.cells.to.displayControl.styles.classes"
          [matTooltip]="row.cells.to.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.cc.displayControl.styles.style"
          [ngClass]="row.cells.cc.displayControl.styles.classes"
          [matTooltip]="row.cells.cc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.cc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bcc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.bcc.displayControl.styles.style"
          [ngClass]="row.cells.bcc.displayControl.styles.classes"
          [matTooltip]="row.cells.bcc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.bcc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="subject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.subject.displayControl.styles.style"
          [ngClass]="row.cells.subject.displayControl.styles.classes"
          [matTooltip]="row.cells.subject.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.subject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="body">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.body.displayControl.styles.style"
          [ngClass]="row.cells.body.displayControl.styles.classes"
          [matTooltip]="row.cells.body.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.body.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
