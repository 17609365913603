import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_bulk_receiving_row_input_formComponent } from './FootPrintManager.bulk_receiving_row_input_form.component';
import { FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent } from './FootPrintManager.purchase_order_bulk_receiving_by_taskId_grid.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => FootPrintManager_bulk_receiving_row_input_formComponent),
    forwardRef(() => FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent),
  ],
  selector: 'FootPrintManager-purchase_order_bulk_receiving_by_taskId_wizard',
  templateUrl: './FootPrintManager.purchase_order_bulk_receiving_by_taskId_wizard.component.html'
})
export class FootPrintManager_purchase_order_bulk_receiving_by_taskId_wizardComponent extends BaseComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { taskId: number, materialId: number, packagingId: number } = { taskId: null, materialId: null, packagingId: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Bulk Receiving';
  
    const $wizard = this;
    const $utils = this.utils;


    this.initialized = true;
  }

  steps: {
    step1?: {
      outParams?: { palletCount?: number, fullPalletQuantity?: number }
    }
    step2?: {
      outParams?: {  }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.utils;

      this._stepConfigs = [
      {
          id: 'step1',
          title: 'Pallet Count Input',
          component: FootPrintManager_bulk_receiving_row_input_formComponent,
          inParamsFunc: ($index?: number) => {
            return {
              materialId: $wizard.inParams.materialId,
              packagingId: $wizard.inParams.packagingId,
            }
          },
          next: 'step2',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.steps.step1.outParams?.palletCount);
          },
      },
      {
          id: 'step2',
          title: 'Bulk Receiving',
          component: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              taskId: $wizard.inParams.taskId,
              palletCount: $wizard?.steps?.step1?.outParams?.palletCount,
              fullPalletQuantity: $wizard?.steps?.step1?.outParams?.fullPalletQuantity,
            }
          },
          nextButtonLabel: 'Finish',
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.utils;


    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
}
