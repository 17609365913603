import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Inventory.frontend.types'
import { $frontendTypes as $types} from './Inventory.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Inventory_owners_dd_singleComponent } from './Inventory.owners_dd_single.component'
import { Inventory_projects_dd_singleComponent } from './Inventory.projects_dd_single.component'
import { Inventory_warehouse_dd_singleComponent } from './Inventory.warehouse_dd_single.component'
import { Inventory_inventory_snapshot_levels_dd_singleComponent } from './Inventory.inventory_snapshot_levels_dd_single.component'


interface IInventory_inventory_snapshot_rules_gridComponentEntity {
id?: string, ownerId?: number, projectId?: number, warehouseId?: number, snapshotLevel?: string, ownerLookupcode?: string, projectLookupcode?: string, warehouseName?: string}



class Inventory_inventory_snapshot_rules_gridComponentRowModel extends GridRowModel {
  grid: Inventory_inventory_snapshot_rules_gridComponent;
  entity: IInventory_inventory_snapshot_rules_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    owner_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    project_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    warehouse_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    snapshot_level_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['owner_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['project_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['warehouse_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    snapshot_level: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['snapshot_level_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
  }

  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.project.editControl.value;
    
    return expr;
  }
  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.owner.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Inventory_ShellService,
private datasources: Inventory_DatasourceService,
private flows: Inventory_FlowService,
private reports: Inventory_ReportService,
private localization: Inventory_LocalizationService,
private operations: Inventory_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Inventory_inventory_snapshot_rules_gridComponent, entity: IInventory_inventory_snapshot_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Inventory_inventory_snapshot_rules_gridComponent, entity?: IInventory_inventory_snapshot_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  null ,
    };
    const data = await this.datasources.Inventory.ds_inventory_snapshot_rules_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.ownerLookupcode;
    (this.cells.owner.editControl as SelectBoxModel).reset($row.entity.ownerId);
    (this.cells.project.displayControl as TextModel).text = $row.entity.projectLookupcode;
    (this.cells.project.editControl as SelectBoxModel).reset($row.entity.projectId);
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.warehouseName;
    (this.cells.warehouse.editControl as SelectBoxModel).reset($row.entity.warehouseId);
    (this.cells.snapshot_level.displayControl as TextModel).text = $row.entity.snapshotLevel;
    (this.cells.snapshot_level.editControl as SelectBoxModel).reset($row.entity.snapshotLevel);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: Inventory_inventory_snapshot_rules_gridComponentRowModel,
  $grid: Inventory_inventory_snapshot_rules_gridComponent, 
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  
  let ownerId = $row.cells.owner.editControl.value;
  let projectId = $row.cells.project.editControl.value;
  let warehouseId = $row.cells.warehouse.editControl.value;
  let snapshotLevel = $row.cells.snapshot_level.editControl.value;
  
  if ($utils.isAllDefined(ownerId, projectId, warehouseId, snapshotLevel)) {
  
      try {
          let project = (await $datasources.Inventory.ds_get_project_by_projectId.get({ projectId: projectId })).result;
          let warehouse = (await $datasources.Inventory.ds_get_warehouse_by_warehouseId.get({ warehouseId: warehouseId })).result;
  
          if ($utils.isAllDefined(project.Id, warehouse.Id)) {
  
              await $flows.Inventory.insert_inventory_snapshot_rule_flow({
                  ownerId: ownerId,
                  projectId: projectId,
                  warehouseId: warehouseId,
                  snapshotLevel: snapshotLevel,
                  ownerLookupcode: project.Owner?.LookupCode,
                  projectLookupcode: project.LookupCode,
                  warehouseName: warehouse.Name
              })
  
  
              $grid.refresh();
          }
          else {
               $shell.Inventory.openErrorDialog('Inventory snapshot rule', 'Unable to determine project and or warehouse.')
               $grid.refresh();
          }
  
      }
      catch (error) {
          $shell.Inventory.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  
  
  }
  else {
       $shell.Inventory.openErrorDialog('Inventory snapshot rule', 'Please enter in data into all fields.')
       throw 'Please enter in data into all fields.';
  }
  
  
  
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Inventory_inventory_snapshot_rules_gridComponentRowModel,
  $grid: Inventory_inventory_snapshot_rules_gridComponent, 
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  
  let ownerId = $row.entity.ownerId;
  if ($utils.isDefined(ownerId)) {
      $row.cells.owner.editControl.readOnly = true;
      $row.cells.project.editControl.readOnly = true;
      $row.cells.warehouse.editControl.readOnly = true;
      $row.cells.snapshot_level.editControl.readOnly = true;
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: Inventory_inventory_snapshot_rules_gridComponentRowModel,
  $grid: Inventory_inventory_snapshot_rules_gridComponent, 
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  $row.cells.owner.editControl.readOnly = false;
  $row.cells.project.editControl.readOnly = false;
  $row.cells.warehouse.editControl.readOnly = false;
  $row.cells.snapshot_level.editControl.readOnly = false;
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_owners_dd_singleComponent),
    forwardRef(() => Inventory_projects_dd_singleComponent),
    forwardRef(() => Inventory_warehouse_dd_singleComponent),
    forwardRef(() => Inventory_inventory_snapshot_levels_dd_singleComponent),
  ],
  selector: 'Inventory-inventory_snapshot_rules_grid',
  templateUrl: './Inventory.inventory_snapshot_rules_grid.component.html'
})
export class Inventory_inventory_snapshot_rules_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInventory_inventory_snapshot_rules_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);




  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       snapshot_level: new GridHeaderModel(new HeaderStyles(null, null), 'Snapshot level', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Inventory_inventory_snapshot_rules_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(null, null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Inventory_ShellService,
    private datasources: Inventory_DatasourceService,
    private flows: Inventory_FlowService,
    private reports: Inventory_ReportService,
    private localization: Inventory_LocalizationService,
    private operations: Inventory_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inventory snapshot rules';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  null ,
    };
    try {
    const data = await this.datasources.Inventory.ds_inventory_snapshot_rules_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Inventory_inventory_snapshot_rules_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IInventory_inventory_snapshot_rules_gridComponentEntity) {
    const row = new Inventory_inventory_snapshot_rules_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Inventory_inventory_snapshot_rules_gridComponent,
  
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Inventory_inventory_snapshot_rules_gridComponent,
  
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  
  const selected_rows = $grid.selectedRows;
  let errors: { rule_name: string, id: string, errorMessages: string[] }[] = [];
  let resubmitted_records = [];
  
  
  if (selected_rows.length === 0) {
      $shell.Inventory.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Inventory.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                await $flows.Inventory.remove_inventory_snapshot_rule_flow({ id: row.entity.id });
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  resubmitted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      rule_name: row.entity.id.toString(), id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
  
              await $shell.Inventory.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Rule Id: ${error.rule_name}` })))
          };
      };
  };
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Inventory_inventory_snapshot_rules_gridComponent,
  
    $shell: Inventory_ShellService,
    $datasources: Inventory_DatasourceService,
    $flows: Inventory_FlowService,
    $reports: Inventory_ReportService,
    $settings: SettingsValuesService,
    $operations: Inventory_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Inventory_LocalizationService,
    $event: any
  ) {
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
