import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Invoices_freight_billing_charge_types_selector_singleComponent } from './Invoices.freight_billing_charge_types_selector_single.component'


interface IInvoices_freight_billing_details_editor_gridComponentEntity {
header_id?: string, freight_header_lookupcode?: string, charge_type?: string, charge_amount?: number, pallet_range_from?: number, pallet_range_to?: number, created_date?: string, date_number?: number, order?: number}

interface IInvoices_freight_billing_details_editor_gridComponentInParams {
  lookupcode?: string}


class Invoices_freight_billing_details_editor_gridComponentRowModel extends GridRowModel {
  grid: Invoices_freight_billing_details_editor_gridComponent;
  entity: IInvoices_freight_billing_details_editor_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    charge_type_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    charge_amount_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pallet_range_from_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pallet_range_to_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    charge_type: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['charge_type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    charge_amount: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['charge_amount_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    pallet_range_from: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['pallet_range_from_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    pallet_range_to: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['pallet_range_to_edit'] as DatexFormControl, null, false, '', '', null)
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_freight_billing_details_editor_gridComponent, entity: IInvoices_freight_billing_details_editor_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.header_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_freight_billing_details_editor_gridComponent, entity?: IInvoices_freight_billing_details_editor_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.header_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.header_id;
    const inParams = {
      $keys:[$resultKey],
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.inParams.lookupcode ,
    };
    const data = await this.datasources.Invoices.ds_freight_billing_details.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.charge_type.displayControl as TextModel).text = $row.entity?.charge_type;
    (this.cells.charge_type.editControl as SelectBoxModel).reset($row.selected.valueOf);
    (this.cells.charge_amount.displayControl as TextModel).text = "$" + $row.entity.charge_amount?.toString();
    (this.cells.charge_amount.editControl as NumberBoxModel).reset($row.entity.charge_amount);
    (this.cells.pallet_range_from.displayControl as TextModel).text = $row.entity.pallet_range_from?.toString();
    (this.cells.pallet_range_from.editControl as NumberBoxModel).reset($row.entity.pallet_range_from);
    (this.cells.pallet_range_to.displayControl as TextModel).text = $row.entity.pallet_range_to?.toString();
    (this.cells.pallet_range_to.editControl as NumberBoxModel).reset($row.entity.pallet_range_to);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_saved_clicked();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_saved_clicked(event = null) {
    return this.on_saved_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saved_clickedInternal(
    $row: Invoices_freight_billing_details_editor_gridComponentRowModel,
  $grid: Invoices_freight_billing_details_editor_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_saved_clicked');
  let payload: any = {};
  
  if ($utils.isDefined($grid.inParams.lookupcode)) {
      payload.freight_header_lookupcode = $utils.odata.formatValue($grid.inParams.lookupcode);
  }
  
  if ($utils.isDefined($row.cells.charge_type.editControl.value)) {
      payload.charge_type = $row.cells.charge_type?.editControl.value;
  }
  
  if ($utils.isDefined($row.cells.charge_amount.editControl.value)) {
      payload.charge_amount = $row.cells.charge_amount?.editControl.value;
  }
  
  if ($utils.isDefined($row.cells.pallet_range_from.editControl.value)) {
      payload.pallet_range_from = $row.cells.pallet_range_from?.editControl.value;
  }
  
  if ($utils.isDefined($row.cells.pallet_range_to.editControl.value)) {
      payload.pallet_range_to = $row.cells.pallet_range_to?.editControl.value;
  }
  
  let min_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Min"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let max_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Max"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let pallet_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Pallet"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let flat_rate_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Flat rate"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let fuel_surcharge_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Fuel surcharge"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  
  let max_amount: number = payload.charge_amount;
  let min_amount: number = payload.charge_amount;
  let validationException = false;
  let palletRangeException = false;
  
  
  if ($utils.isDefined(max_validate)) {
      let i: number = 0;
      while (i < max_validate.count) {
          if (max_validate.payload[i].charge_amount < min_amount) {
              max_amount = max_validate.payload[i].charge_amount;
          }
          i++;
      }
  }
  
  if ($utils.isDefined(min_validate)) {
      let i: number = 0;
      while (i < min_validate.count) {
          if (min_validate.payload[i].charge_amount < min_amount) {
              min_amount = min_validate.payload[i].charge_amount;
          }
          i++;
      }
  }
  
  if ($utils.isDefined(pallet_validate) && payload.charge_type === "Pallet") {
      if (payload.pallet_range_to <= payload.pallet_range_from) {
          const title = 'Pallet range error';
          const message = `The "Pallet range from" value must be less than the "Pallet range to" value. Please adjust the range`;
          $shell.Utilities.openErrorDialog(title, message);
          validationException = true;
      }
  }
  
  if ($utils.isDefined(pallet_validate)) {
      let i: number = 0;
      while (i < pallet_validate.count) {
          if (pallet_validate.payload[i].pallet_range_to >= payload.pallet_range_from && pallet_validate.payload[i].pallet_range_from <= payload.pallet_range_to) {
              palletRangeException = true;
              break;
          }
          i++;
      }
  }
  
  if (payload.charge_type === "Pallet") {
      if (palletRangeException === true) {
          const title = 'Pallet range error';
          const message = `The Pallet range you entered already exist. Please Choose a different range.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(flat_rate_validate)) {
      if (flat_rate_validate.count > 0 && payload.charge_type === "Flat rate") {
          const title = `Charge type already exist`;
          const message = `With existing flat rate details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(fuel_surcharge_validate)) {
      if (fuel_surcharge_validate.count > 0 && payload.charge_type === "Fuel surcharge") {
          const title = `Charge type already exist`;
          const message = `With existing fuel surcharge details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(max_validate)) {
      if (max_validate.count > 0 && payload.charge_type === "Max") {
          const title = `Charge type already exist`;
          const message = `With existing max details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(min_validate)) {
      if (min_validate.count > 0 && payload.charge_type === "Min") {
          const title = `Charge type already exist`;
          const message = `With existing min details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if (payload.charge_type === "Max") {
      if (min_amount > payload.charge_amount) {
          const title = 'Freight charge creation error';
          const message = `Max amount should not be lower than the min amount.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if (payload.charge_type === "Min") {
      if (max_amount < payload.charge_amount) {
          const title = 'Freight charge creation error';
          const message = `Min amount should not be greater than the max amount.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  try {
      if (validationException != true) {
          await $flows.Invoices.freight_billing_details_storage({
              payload: [{
                  freight_header_lookupcode: payload.freight_header_lookupcode,
                  charge_type: payload.charge_type,
                  charge_amount: payload.charge_amount,
                  pallet_range_from: payload.pallet_range_from,
                  pallet_range_to: payload.pallet_range_to
              }],
              action: "Write"
          })
          const title = `Freight charge ${payload.charge_type}`;
          const message = `Added`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
      }
      else if (validationException != true && payload.charge_type === "Pallet") {
          await $flows.Invoices.freight_billing_details_storage({
              payload: [{
                  freight_header_lookupcode: payload.freight_header_lookupcode,
                  charge_type: payload.charge_type,
                  charge_amount: payload.charge_amount
              }],
              action: "Write"
          })
          const title = `Freight charge ${payload.charge_type}`;
          const message = `Added`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
      }
  
  } catch (error) {
      $shell.Invoices.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  $row.refresh();
  $grid.refresh();
  
  
  
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Invoices_freight_billing_details_editor_gridComponentRowModel,
  $grid: Invoices_freight_billing_details_editor_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_save_existing_row');
  let payload: any = {};
  
  if ($row.cells.charge_type?.editControl.isChanged) {
      payload.charge_type = $row.cells.charge_type?.editControl.value;
  }
  if (!$utils.isDefined(payload.charge_type)) {
      payload.charge_type = $row.entity.charge_type;
  }
  
  if ($row.cells.charge_amount?.editControl.isChanged) {
      payload.charge_amount = $row.cells.charge_amount?.editControl.value;
  }
  if (!$utils.isDefined(payload.charge_amount)) {
      payload.charge_amount = $row.entity.charge_amount;
  }
  
  if ($row.cells.pallet_range_from?.editControl.isChanged) {
      payload.pallet_range_from = $row.cells.pallet_range_from?.editControl.value;
  }
  if (!$utils.isDefined(payload.pallet_range_from)) {
      payload.pallet_range_from = $row.entity.pallet_range_from;
  }
  
  if ($row.cells.pallet_range_to?.editControl.isChanged) {
      payload.pallet_range_to = $row.cells.pallet_range_to?.editControl.value;
  }
  if (!$utils.isDefined(payload.pallet_range_to)) {
      payload.pallet_range_to = $row.entity.pallet_range_to;
  }
  
  if ($utils.isDefined($row.entity.header_id)) {
      payload.header_id = $row.entity.header_id;
  }
  
  
  let min_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Min"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let max_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Max"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let pallet_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Pallet"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let flat_rate_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Flat rate"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  let fuel_surcharge_validate = (await $flows.Invoices.freight_billing_details_storage({
      payload: [{
          charge_type: "Fuel surcharge"
      }],
      full_text_search: $grid.inParams.lookupcode,
      action: `Read`
  }));
  
  
  let max_amount: number = payload.charge_amount;
  let min_amount: number = payload.charge_amount;
  let validationException = false;
  let palletRangeException = false;
  
  
  if ($utils.isDefined(max_validate)) {
      let i: number = 0;
      while (i < max_validate.count) {
          if (max_validate.payload[i].charge_amount < min_amount) {
              max_amount = max_validate.payload[i].charge_amount;
          }
          i++;
      }
  }
  
  if ($utils.isDefined(min_validate)) {
      let i: number = 0;
      while (i < min_validate.count) {
          if (min_validate.payload[i].charge_amount < min_amount) {
              min_amount = min_validate.payload[i].charge_amount;
          }
          i++;
      }
  }
  
  if ($utils.isDefined(min_validate)) {
      if (min_validate.count > 0 && payload.charge_type === "Min") {
          const title = `Charge type already exist`;
          const message = `With existing min details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if (payload.charge_type === "Min") {
      if (max_amount < payload.charge_amount) {
          const title = `Freight charge creation error`;
          const message = `Min amount should not be greater than the max amount.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(max_validate)) {
      if (max_validate.count > 0 && payload.charge_type === "Max") {
          const title = `Charge type already exist`;
          const message = `With existing max details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if (payload.charge_type === "Max") {
      if (min_amount > payload.charge_amount) {
          const title = `Freight charge creation error`;
          const message = `Max amount should not be lower than the min amount.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(pallet_validate) && payload.charge_type === "Pallet") {
      if (payload.pallet_range_to <= payload.pallet_range_from) {
          const title = `Pallet range error`;
          const message = `Pallet range from must be greated than pallet range to.`;
          $shell.Utilities.openErrorDialog(title, message);
          validationException = true;
      }
  }
  
  if ($utils.isDefined(pallet_validate) && payload.charge_type === "Pallet") {
      let i: number = 0;
      while (i < pallet_validate.count) {
          if (pallet_validate.payload[i].pallet_range_to >= payload.pallet_range_from
              && pallet_validate.payload[i].pallet_range_from <= payload.pallet_range_to
              && pallet_validate.payload[i].header_id != payload.header_id) {
  
              palletRangeException = true;
  
              break;
          }
          i++;
      }
  }
  
  if (payload.charge_type === "Pallet") {
      if (palletRangeException === true) {
          const title = 'Pallet range already exist';
          const message = `The pallet range you entered already exist. Please choose a difference range.`;
          $shell.Utilities.openErrorDialog(title, message);
          validationException = true;
      }
  }
  
  if ($utils.isDefined(flat_rate_validate)) {
      if (flat_rate_validate.count > 0 && payload.charge_type === "Flat rate") {
          const title = `Charge type already exist`;
          const message = `With existing flat rate details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  if ($utils.isDefined(fuel_surcharge_validate)) {
      if (fuel_surcharge_validate.count > 0 && payload.charge_type === "Fuel surcharge") {
          const title = `Charge type already exist`;
          const message = `With existing fuel surcharge details row.`;
          $shell.Utilities.openErrorDialog(title, message);
  
          validationException = true;
      }
  }
  
  try {
      if (validationException != true) {
          await $flows.Invoices.freight_billing_details_storage({
              payload: [{
                  header_id: payload.header_id,
                  charge_type: payload.charge_type,
                  charge_amount: payload.charge_amount,
                  pallet_range_from: payload.pallet_range_from,
                  pallet_range_to: payload.pallet_range_to
              }],
              action: "Update"
          })
          const title = `Freight charge ${payload.charge_type}`;
          const message = `Updated`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
      }
      else if (validationException != true && payload.charge_type === "Pallet") {
          await $flows.Invoices.freight_billing_details_storage({
              payload: [{
                  header_id: payload.header_id,
                  charge_type: payload.charge_type,
                  charge_amount: payload.charge_amount
              }],
              action: "Update"
          })
          const title = `Freight charge ${payload.charge_type}`;
          const message = `Updated`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
      }
      $row.refresh();
      $grid.refresh();
  } catch (error) {
      $shell.Invoices.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  
  }
  on_charge_type_changed(event = null) {
    return this.on_charge_type_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_charge_type_changedInternal(
    $row: Invoices_freight_billing_details_editor_gridComponentRowModel,
  $grid: Invoices_freight_billing_details_editor_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_charge_type_changed');
  const charge_type = $row.cells.charge_type.editControl.value
  
  if ($utils.isDefined(charge_type)) {
  
      if (charge_type != "Pallet") {
          
          $row.entity.pallet_range_from = null;
          $row.entity.pallet_range_to = null;
  
          $row.refresh();
      }
  
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_freight_billing_charge_types_selector_singleComponent),
  ],
  selector: 'Invoices-freight_billing_details_editor_grid',
  templateUrl: './Invoices.freight_billing_details_editor_grid.component.html'
})
export class Invoices_freight_billing_details_editor_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_freight_billing_details_editor_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['default','set-width-table']);

  fullTextSearch: string;

  inParams: IInvoices_freight_billing_details_editor_gridComponentInParams = { lookupcode: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     charge_type: new GridHeaderModel(new HeaderStyles(['left'], null), 'Charge type', false, false, 150),       charge_amount: new GridHeaderModel(new HeaderStyles(['left'], null), 'Charge amount', false, false, 120),       pallet_range_from: new GridHeaderModel(new HeaderStyles(['left'], null), 'Pallet range from', false, false, 120),       pallet_range_to: new GridHeaderModel(new HeaderStyles(['left'], null), 'Pallet range to', false, false, 120),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_freight_billing_details_editor_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('lookupcode') set $inParams_lookupcode(value: any) {
    this.inParams['lookupcode'] = value;
  }
  get $inParams_lookupcode(): any {
    return this.inParams['lookupcode'] ;
  }

  topToolbar = {
      delete_record: new ToolModel(new ButtonModel('delete_record', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'freight_billing_details_editor_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.inParams.lookupcode ,
    };
    try {
    const data = await this.datasources.Invoices.ds_freight_billing_details.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_freight_billing_details_editor_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IInvoices_freight_billing_details_editor_gridComponentEntity) {
    const row = new Invoices_freight_billing_details_editor_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Invoices_freight_billing_details_editor_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_delete_clicked');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Utilities.openErrorDialog('Delete Freigth charge Error', 'No Freigth charge selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
          // Placeholder to check if the rule can be deleted.
          candidates.push(row);
  
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Freigth charge delete errors';
          const errorMessage = `Freigth charge(s) cannot be deleted`;
          const errorList = errors;
          await $shell.Utilities.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
  
          const confirmCandidates = `Once deleted, cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Freigth charge(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Utilities.openConfirmationDialog(title, message);
          } else {
              const title = `Delete charge type ${candidates.map(c => c.entity.charge_type.toString()).join(',')}?`;
              const message = confirmCandidates;
              confirm = await $shell.Utilities.openConfirmationDialog(title, message, 'Delete charge');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          payload: [{
                              header_id: candidate.entity.header_id
                          }],
                          action: "Delete"
                      }
  
                      const result = await $flows.Invoices.freight_billing_details_storage(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Freigth charge ${candidate.entity.charge_type.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = `Freigth charge(s) ${processSuccess.map(c => c.entity.charge_type.toString()).join(',')}`;
                  const message = `Deleted`;
                  $shell.Utilities.openToaster(title,message,EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All Freigth charge(s) failed to delete';
                      const message = `Freigth charge(s) could not be deleted`;
                      await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Freigth charge(s) could not be deleted';
                      const success = `Freigth charge(s) ${processSuccess.map(c => c.entity.charge_type.toString()).join(',')} were deleted.`;
                      const errors = `Freigth charge(s) could not be deleted: ${processFailures.map(c => c.entity.charge_type.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_freight_billing_details_editor_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_init');
  //Disable delete button
  $grid.topToolbar.delete_record.control.readOnly = true;
  await $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Invoices_freight_billing_details_editor_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'freight_billing_details_editor_grid.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
  
      $grid.topToolbar.delete_record.control.readOnly = false;
  
  }
  else {
  
      $grid.topToolbar.delete_record.control.readOnly = true;
      
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
