<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="add_row" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_add_row_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="grid_options" let-tool>
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div mat-menu-item class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.select_fields.readOnly"
                          [ngStyle]="tool.control.buttons.select_fields.styles.style"
                          [ngClass]="tool.control.buttons.select_fields.styles.classes"
                          [matTooltip]="tool.control.buttons.select_fields.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_select_fields_clicked($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.select_fields.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.select_fields.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.select_fields.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="delete_row" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_row_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Returns-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
        [tooltip]="row.cells.project.editControl.tooltip"
      [statusId]="row.$fields_project_selector_inParams_statusId"
    >
    </Returns-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.material.editControl.type"
        [formControl]="row.formGroup.controls['material_edit']"
        (displayTextChange)="row.cells.material.editControl.displayText=$event"
        [placeholder]="row.cells.material.editControl.placeholder"
        [styles]="row.cells.material.editControl.styles"
        [tooltip]="row.cells.material.editControl.tooltip"
      [projectId]="row.$fields_material_selector_inParams_projectId"
    >
    </Materials-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendor_lot_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.vendor_lot.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendor_lot.editControl.placeholder}}"
            [ngStyle]="row.cells.vendor_lot.editControl.styles.style"
            [ngClass]="row.cells.vendor_lot.editControl.styles.classes"
            [matTooltip]="row.cells.vendor_lot.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lot_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.lot.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lot.editControl.placeholder}}"
            [ngStyle]="row.cells.lot.editControl.styles.style"
            [ngClass]="row.cells.lot.editControl.styles.classes"
            [matTooltip]="row.cells.lot.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes"
          [matTooltip]="row.cells.expiration_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['expiration_date_edit']"
        [format]="row.cells.expiration_date.editControl.format"
        [mode]="row.cells.expiration_date.editControl.mode"
        [ngStyle]="row.cells.expiration_date.editControl.styles.style"
        [ngClass]="row.cells.expiration_date.editControl.styles.classes"
        [tooltip]="row.cells.expiration_date.editControl.tooltip">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot_status.displayControl.styles.style"
          [ngClass]="row.cells.lot_status.displayControl.styles.classes"
          [matTooltip]="row.cells.lot_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Lots-lot_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.lot_status.editControl.type"
        [formControl]="row.formGroup.controls['lot_status_edit']"
        (displayTextChange)="row.cells.lot_status.editControl.displayText=$event"
        [placeholder]="row.cells.lot_status.editControl.placeholder"
        [styles]="row.cells.lot_status.editControl.styles"
        [tooltip]="row.cells.lot_status.editControl.tooltip"
    >
    </Lots-lot_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.actual_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['actual_packaged_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.actual_packaged_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.actual_packaged_amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.actual_packaged_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.actual_packaged_amount.editControl.styles.style"
            [ngClass]="row.cells.actual_packaged_amount.editControl.styles.classes"
            [matTooltip]="row.cells.actual_packaged_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_packaging.displayControl.styles.style"
          [ngClass]="row.cells.actual_packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Returns-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.actual_packaging.editControl.type"
        [formControl]="row.formGroup.controls['actual_packaging_edit']"
        (displayTextChange)="row.cells.actual_packaging.editControl.displayText=$event"
        [placeholder]="row.cells.actual_packaging.editControl.placeholder"
        [styles]="row.cells.actual_packaging.editControl.styles"
        [tooltip]="row.cells.actual_packaging.editControl.tooltip"
      [materialId]="row.$fields_actual_packaging_selector_inParams_materialId"
    >
    </Returns-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serial_number_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serial_number_code.displayControl.styles.style"
          [ngClass]="row.cells.serial_number_code.displayControl.styles.classes"
          [matTooltip]="row.cells.serial_number_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serial_number_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['serial_number_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.serial_number_code.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.serial_number_code.editControl.placeholder}}"
            [ngStyle]="row.cells.serial_number_code.editControl.styles.style"
            [ngClass]="row.cells.serial_number_code.editControl.styles.classes"
            [matTooltip]="row.cells.serial_number_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="fill_percentage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.fill_percentage.displayControl.styles.style"
          [ngClass]="row.cells.fill_percentage.displayControl.styles.classes"
          [matTooltip]="row.cells.fill_percentage.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.fill_percentage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['fill_percentage_edit']"
            matInput
            numberBox
            [format]="row.cells.fill_percentage.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.fill_percentage.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.fill_percentage.editControl.placeholder}}"
            [ngStyle]="row.cells.fill_percentage.editControl.styles.style"
            [ngClass]="row.cells.fill_percentage.editControl.styles.classes"
            [matTooltip]="row.cells.fill_percentage.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="condition">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.condition.displayControl.styles.style"
          [ngClass]="row.cells.condition.displayControl.styles.classes"
          [matTooltip]="row.cells.condition.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.condition.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Returns-condition_dd_single 
        data-cy="selector"
        [type]="row.cells.condition.editControl.type"
        [formControl]="row.formGroup.controls['condition_edit']"
        (displayTextChange)="row.cells.condition.editControl.displayText=$event"
        [placeholder]="row.cells.condition.editControl.placeholder"
        [styles]="row.cells.condition.editControl.styles"
        [tooltip]="row.cells.condition.editControl.tooltip"
    >
    </Returns-condition_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="disposition">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.disposition.displayControl.styles.style"
          [ngClass]="row.cells.disposition.displayControl.styles.classes"
          [matTooltip]="row.cells.disposition.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.disposition.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['disposition_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.disposition.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.disposition.editControl.placeholder}}"
            [ngStyle]="row.cells.disposition.editControl.styles.style"
            [ngClass]="row.cells.disposition.editControl.styles.classes"
            [matTooltip]="row.cells.disposition.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_target_license_plate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_target_license_plate.displayControl.styles.style"
          [ngClass]="row.cells.actual_target_license_plate.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_target_license_plate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_target_license_plate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['actual_target_license_plate_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.actual_target_license_plate.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.actual_target_license_plate.editControl.placeholder}}"
            [ngStyle]="row.cells.actual_target_license_plate.editControl.styles.style"
            [ngClass]="row.cells.actual_target_license_plate.editControl.styles.classes"
            [matTooltip]="row.cells.actual_target_license_plate.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_code.displayControl.styles.style"
          [ngClass]="row.cells.order_code.displayControl.styles.classes"
          [matTooltip]="row.cells.order_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
