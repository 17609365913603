<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualInventoryAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualInventoryAmount.displayControl.styles.style"
          [ngClass]="row.cells.ActualInventoryAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualInventoryAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualInventoryAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualPackagedAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualPackagedAmount.displayControl.styles.style"
          [ngClass]="row.cells.ActualPackagedAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualPackagedAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualPackagedAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualPackagedPackId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualPackagedPackId.displayControl.styles.style"
          [ngClass]="row.cells.ActualPackagedPackId.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualPackagedPackId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualPackagedPackId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualSourceLicensePlateId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualSourceLicensePlateId.displayControl.styles.style"
          [ngClass]="row.cells.ActualSourceLicensePlateId.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualSourceLicensePlateId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualSourceLicensePlateId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualSourceLocationId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualSourceLocationId.displayControl.styles.style"
          [ngClass]="row.cells.ActualSourceLocationId.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualSourceLocationId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualSourceLocationId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualTargetLicensePlateId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualTargetLicensePlateId.displayControl.styles.style"
          [ngClass]="row.cells.ActualTargetLicensePlateId.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualTargetLicensePlateId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualTargetLicensePlateId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ActualTargetLocationId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ActualTargetLocationId.displayControl.styles.style"
          [ngClass]="row.cells.ActualTargetLocationId.displayControl.styles.classes"
          [matTooltip]="row.cells.ActualTargetLocationId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ActualTargetLocationId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CompletedDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CompletedDateTime.displayControl.styles.style"
          [ngClass]="row.cells.CompletedDateTime.displayControl.styles.classes"
          [matTooltip]="row.cells.CompletedDateTime.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CompletedDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DimensionsUomId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.DimensionsUomId.displayControl.styles.style"
          [ngClass]="row.cells.DimensionsUomId.displayControl.styles.classes"
          [matTooltip]="row.cells.DimensionsUomId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.DimensionsUomId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Employee.displayControl.styles.style"
          [ngClass]="row.cells.Employee.displayControl.styles.classes"
          [matTooltip]="row.cells.Employee.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="GrossVolume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.GrossVolume.displayControl.styles.style"
          [ngClass]="row.cells.GrossVolume.displayControl.styles.classes"
          [matTooltip]="row.cells.GrossVolume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.GrossVolume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="GrossWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.GrossWeight.displayControl.styles.style"
          [ngClass]="row.cells.GrossWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.GrossWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.GrossWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Height.displayControl.styles.style"
          [ngClass]="row.cells.Height.displayControl.styles.classes"
          [matTooltip]="row.cells.Height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Length.displayControl.styles.style"
          [ngClass]="row.cells.Length.displayControl.styles.classes"
          [matTooltip]="row.cells.Length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LotId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LotId.displayControl.styles.style"
          [ngClass]="row.cells.LotId.displayControl.styles.classes"
          [matTooltip]="row.cells.LotId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LotId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.MaterialId.displayControl.styles.style"
          [ngClass]="row.cells.MaterialId.displayControl.styles.classes"
          [matTooltip]="row.cells.MaterialId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.MaterialId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NetVolume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.NetVolume.displayControl.styles.style"
          [ngClass]="row.cells.NetVolume.displayControl.styles.classes"
          [matTooltip]="row.cells.NetVolume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.NetVolume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NetWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.NetWeight.displayControl.styles.style"
          [ngClass]="row.cells.NetWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.NetWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.NetWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes"
          [matTooltip]="row.cells.Notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OperationCodeId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OperationCodeId.displayControl.styles.style"
          [ngClass]="row.cells.OperationCodeId.displayControl.styles.classes"
          [matTooltip]="row.cells.OperationCodeId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OperationCodeId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ProjectId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ProjectId.displayControl.styles.style"
          [ngClass]="row.cells.ProjectId.displayControl.styles.classes"
          [matTooltip]="row.cells.ProjectId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ProjectId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReasonCodeId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReasonCodeId.displayControl.styles.style"
          [ngClass]="row.cells.ReasonCodeId.displayControl.styles.classes"
          [matTooltip]="row.cells.ReasonCodeId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReasonCodeId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SerialNumberId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SerialNumberId.displayControl.styles.style"
          [ngClass]="row.cells.SerialNumberId.displayControl.styles.classes"
          [matTooltip]="row.cells.SerialNumberId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SerialNumberId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLotId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLotId.displayControl.styles.style"
          [ngClass]="row.cells.VendorLotId.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLotId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLotId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VolumeUomId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VolumeUomId.displayControl.styles.style"
          [ngClass]="row.cells.VolumeUomId.displayControl.styles.classes"
          [matTooltip]="row.cells.VolumeUomId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VolumeUomId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="WarehouseId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.WarehouseId.displayControl.styles.style"
          [ngClass]="row.cells.WarehouseId.displayControl.styles.classes"
          [matTooltip]="row.cells.WarehouseId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.WarehouseId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="WeightUomId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.WeightUomId.displayControl.styles.style"
          [ngClass]="row.cells.WeightUomId.displayControl.styles.classes"
          [matTooltip]="row.cells.WeightUomId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.WeightUomId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Width.displayControl.styles.style"
          [ngClass]="row.cells.Width.displayControl.styles.classes"
          [matTooltip]="row.cells.Width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReasonCode_Label">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReasonCode_Label.displayControl.styles.style"
          [ngClass]="row.cells.ReasonCode_Label.displayControl.styles.classes"
          [matTooltip]="row.cells.ReasonCode_Label.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReasonCode_Label.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReasonCode_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReasonCode_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.ReasonCode_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.ReasonCode_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReasonCode_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReasonCode_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReasonCode_Name.displayControl.styles.style"
          [ngClass]="row.cells.ReasonCode_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.ReasonCode_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReasonCode_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Project_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project_Name.displayControl.styles.style"
          [ngClass]="row.cells.Project_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Project_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OperationCode_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OperationCode_Name.displayControl.styles.style"
          [ngClass]="row.cells.OperationCode_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.OperationCode_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OperationCode_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OperationCode_OperationTypeId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OperationCode_OperationTypeId.displayControl.styles.style"
          [ngClass]="row.cells.OperationCode_OperationTypeId.displayControl.styles.classes"
          [matTooltip]="row.cells.OperationCode_OperationTypeId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OperationCode_OperationTypeId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OperationCode_OperationType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OperationCode_OperationType_Name.displayControl.styles.style"
          [ngClass]="row.cells.OperationCode_OperationType_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.OperationCode_OperationType_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OperationCode_OperationType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VolumeUOM_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VolumeUOM_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.VolumeUOM_Short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.VolumeUOM_Short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VolumeUOM_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="WeightUOM_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.WeightUOM_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.WeightUOM_Short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.WeightUOM_Short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.WeightUOM_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
