import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { Addresses_OperationService } from './Addresses.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Carriers_OperationService } from './Carriers.operation.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { ExcelMaterialImport_OperationService } from './ExcelMaterialImport.operation.service';
import { Instructions_OperationService } from './Instructions.operation.service';
import { LoadContainers_OperationService } from './LoadContainers.operation.service';
import { Surveys_OperationService } from './Surveys.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { ExcelLocationImport_OperationService } from './ExcelLocationImport.operation.service';
import { EndeavorLabs_OperationService } from './EndeavorLabs.operation.service';
import { SerialNumbers_OperationService } from './SerialNumbers.operation.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Discussions_OperationService } from './Discussions.operation.service';
import { ExcelOrderImport_OperationService } from './ExcelOrderImport.operation.service';
import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { Locations_OperationService } from './Locations.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { ExcelInventoryImport_OperationService } from './ExcelInventoryImport.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { Waves_OperationService } from './Waves.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { Orderful_OperationService } from './Orderful.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Addresses = this.injector.get(Addresses_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Carriers = this.injector.get(Carriers_OperationService);
    this.EntityImport = this.injector.get(EntityImport_OperationService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_OperationService);
    this.Instructions = this.injector.get(Instructions_OperationService);
    this.LoadContainers = this.injector.get(LoadContainers_OperationService);
    this.Surveys = this.injector.get(Surveys_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_OperationService);
    this.EndeavorLabs = this.injector.get(EndeavorLabs_OperationService);
    this.SerialNumbers = this.injector.get(SerialNumbers_OperationService);
    this.AsnOrders = this.injector.get(AsnOrders_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.Discussions = this.injector.get(Discussions_OperationService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_OperationService);
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.BarTender = this.injector.get(BarTender_OperationService);
    this.Locations = this.injector.get(Locations_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.DockAppointments = this.injector.get(DockAppointments_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.Waves = this.injector.get(Waves_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.FootPrintManager = this.injector.get(FootPrintManager_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
    this.Orderful = this.injector.get(Orderful_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public Addresses: Addresses_OperationService;
    public Attachments: Attachments_OperationService;
    public Carriers: Carriers_OperationService;
    public EntityImport: EntityImport_OperationService;
    public ExcelMaterialImport: ExcelMaterialImport_OperationService;
    public Instructions: Instructions_OperationService;
    public LoadContainers: LoadContainers_OperationService;
    public Surveys: Surveys_OperationService;
    public Lots: Lots_OperationService;
    public ExcelLocationImport: ExcelLocationImport_OperationService;
    public EndeavorLabs: EndeavorLabs_OperationService;
    public SerialNumbers: SerialNumbers_OperationService;
    public AsnOrders: AsnOrders_OperationService;
    public Owners: Owners_OperationService;
    public Discussions: Discussions_OperationService;
    public ExcelOrderImport: ExcelOrderImport_OperationService;
    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public BarTender: BarTender_OperationService;
    public Locations: Locations_OperationService;
    public Notifications: Notifications_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public Inventory: Inventory_OperationService;
    public ExcelInventoryImport: ExcelInventoryImport_OperationService;
    public Invoices: Invoices_OperationService;
    public DockAppointments: DockAppointments_OperationService;
    public Reports: Reports_OperationService;
    public Materials: Materials_OperationService;
    public Waves: Waves_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public FootPrintManager: FootPrintManager_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
    public Orderful: Orderful_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
