<ng-container *ngIf="!type || type === selectorTypeEnums.dropdown">
  <app-dropdown-single *ngIf="!multiple"
                       #selector
                       [(ngModel)]="value"
                       [disabled]="disabled"
                       [placeholder]="placeholder"
                       [styles]="styles"
                       [displayWith]="displayWithFn"
                       [options]="optionsFn"
                       [newItemLabel]="newItemLabel"
                       [tooltip]="tooltip"
                       (newItemClick)="newItemClick.emit()">
  </app-dropdown-single>

  <app-dropdown-multi *ngIf="multiple"
                      #selector
                      [(ngModel)]="value"
                      [disabled]="disabled"
                      [placeholder]="placeholder"
                      [styles]="styles"
                      [displayWith]="displayWithFn"
                      [options]="optionsFn"
                      [newItemLabel]="newItemLabel"
                      [tooltip]="tooltip"
                      (newItemClick)="newItemClick.emit()">
  </app-dropdown-multi>
</ng-container>


<ng-container *ngIf="type === selectorTypeEnums.chips">
  <mat-chip-list #selector
                 [(ngModel)]="value"
                 [multiple]="multiple"
                 [disabled]="disabled"
                 [ngStyle]="styles?.style"
                 [ngClass]="styles?.classes"
                 class="datex-chip-selector"
                 data-cy="chip-list"
                 [matTooltip]="tooltip"
                 matTooltipClass="datex-control-tooltip">
    <mat-chip *ngFor="let item of options"
              #chip="matChip"
              [value]="item.key"
              [disabled]="item.disabled"
              [matTooltip]="item?.name"
              matTooltipClass="datex-control-tooltip"
              (click)="(!disabled && !item.disabled) ? chip.toggleSelected(true) : null"
              data-cy="chip">
      <span class="selector-option-text">{{item?.name}}</span></mat-chip>
    <mat-chip *ngIf="newItemLabel && !disabled"
              #newItem
              [selectable]="false"
              (click)="newItemClick.emit();newItem.blur()"
              class="new-item-chip"
              data-cy="chip-newItem">
      {{newItemLabel}}
    </mat-chip>
    <span *ngIf="message"
          class="margin-left-10 vertical-align-bottom">{{message}}</span>
    <!--The click on mat-chip is because chips in Material v14 have only keyboard interaction and receive focus on click,
      hence this is a hack, which can be removed when we update to Material v15 and use mat-chip-listbox instead of mat-chip-list-->
  </mat-chip-list>
</ng-container>

<ng-container *ngIf="type === selectorTypeEnums.radioButtonsCheckboxes">
  <mat-radio-group *ngIf="!multiple"
                   #selector
                   [(ngModel)]="value"
                   [disabled]="disabled"
                   [ngStyle]="styles?.style"
                   [ngClass]="styles?.classes"
                   class="radio-group datex-radio-group"
                   data-cy="radio-group"
                   [matTooltip]="tooltip"
                   matTooltipClass="datex-control-tooltip">
    <mat-radio-button *ngFor="let item of options"
                      [value]="item.key"
                      [disabled]="item.disabled"
                      [matTooltip]="item?.name"
                      matTooltipClass="datex-control-tooltip"
                      tabIndex="0"
                      data-cy="radio-button"><span class="selector-option-text">{{item?.name}}</span></mat-radio-button>
    <span *ngIf="message"
          class="margin-left-10 vertical-align-bottom">{{message}}</span>
  </mat-radio-group>

  <mat-selection-list *ngIf="multiple"
                      #selector
                      [(ngModel)]="value"
                      [disabled]="disabled"
                      [disableRipple]="true"
                      [ngStyle]="styles?.style"
                      [ngClass]="styles?.classes"
                      class="checkbox-group datex-checkbox-group"
                      data-cy="checkbox-group"
                      [matTooltip]="tooltip"
                      matTooltipClass="datex-control-tooltip">
    <mat-list-option *ngFor="let item of options"
                     [value]="item.key"
                     [disabled]="item.disabled"
                     [matTooltip]="item?.name"
                     matTooltipClass="datex-control-tooltip"
                     checkboxPosition="before"
                     data-cy="checkbox-option">
      <span class="selector-option-text">{{item?.name}}</span>
    </mat-list-option>
    <div *ngIf="message"
         class="margin-left-10">{{message}}</div>
  </mat-selection-list>
</ng-container>