<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="run_import" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_run_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="line_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="refresh_grid" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="delete_row" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="purge_table" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_purge_data($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator3" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="view_logs" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_view_logs($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-import_status" *ngIf="!filters.import_status.hidden" 
            class="field-container standard {{filters.import_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.import_status.styles.style"
            [ngClass]="filters.import_status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.import_status.label + (filters.import_status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.import_status.label}}<span *ngIf="filters.import_status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <ExcelInventoryImport-import_statuses_dd_single 
            data-cy="selector"
            [type]="filters.import_status.control.type"
            formControlName="import_status"
            (displayTextChange)="filters.import_status.control.displayText=$event"
            [placeholder]="filters.import_status.control.placeholder"
            [styles]="filters.import_status.control.styles"
            [tooltip]="filters.import_status.control.tooltip"
        >
        </ExcelInventoryImport-import_statuses_dd_single>
        <ng-container *ngIf="filters.import_status.invalid">
          <ng-container *ngFor="let error of filters.import_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-validation_only" *ngIf="!filters.validation_only.hidden" 
            class="field-container standard {{filters.validation_only.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.validation_only.styles.style"
            [ngClass]="filters.validation_only.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.validation_only.label + (filters.validation_only.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.validation_only.label}}<span *ngIf="filters.validation_only.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="validation_only"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.validation_only.control.styles.style"
                      [ngClass]="filters.validation_only.control.styles.classes"
                      [matTooltip]="filters.validation_only.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.validation_only.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.validation_only.invalid">
          <ng-container *ngFor="let error of filters.validation_only.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-ready" *ngIf="!filters.ready.hidden" 
            class="field-container standard {{filters.ready.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.ready.styles.style"
            [ngClass]="filters.ready.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.ready.label + (filters.ready.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.ready.label}}<span *ngIf="filters.ready.required" class="required-asterisk">*</span>
          </label>
        </div>
        <div data-cy="text" class="datex-text" 
              [ngStyle]="filters.ready.control.styles.style"
              [ngClass]="filters.ready.control.styles.classes"
              [matTooltip]="filters.ready.control.tooltip"
              matTooltipClass="datex-control-tooltip">{{filters.ready.control.text }}</div>
        <ng-container *ngIf="filters.ready.invalid">
          <ng-container *ngFor="let error of filters.ready.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-completed" *ngIf="!filters.completed.hidden" 
            class="field-container standard {{filters.completed.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.completed.styles.style"
            [ngClass]="filters.completed.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.completed.label + (filters.completed.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.completed.label}}<span *ngIf="filters.completed.required" class="required-asterisk">*</span>
          </label>
        </div>
        <div data-cy="text" class="datex-text" 
              [ngStyle]="filters.completed.control.styles.style"
              [ngClass]="filters.completed.control.styles.classes"
              [matTooltip]="filters.completed.control.tooltip"
              matTooltipClass="datex-control-tooltip">{{filters.completed.control.text }}</div>
        <ng-container *ngIf="filters.completed.invalid">
          <ng-container *ngFor="let error of filters.completed.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-errors" *ngIf="!filters.errors.hidden" 
            class="field-container standard {{filters.errors.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.errors.styles.style"
            [ngClass]="filters.errors.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.errors.label + (filters.errors.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.errors.label}}<span *ngIf="filters.errors.required" class="required-asterisk">*</span>
          </label>
        </div>
        <div data-cy="text" class="datex-text" 
              [ngStyle]="filters.errors.control.styles.style"
              [ngClass]="filters.errors.control.styles.classes"
              [matTooltip]="filters.errors.control.tooltip"
              matTooltipClass="datex-control-tooltip">{{filters.errors.control.text }}</div>
        <ng-container *ngIf="filters.errors.invalid">
          <ng-container *ngFor="let error of filters.errors.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ImportStatus.displayControl.styles.style"
          [ngClass]="row.cells.ImportStatus.displayControl.styles.classes"
          [matTooltip]="row.cells.ImportStatus.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ImportStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportCreatedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ImportCreatedDate.displayControl.styles.style"
          [ngClass]="row.cells.ImportCreatedDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ImportCreatedDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ImportCreatedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportCompletedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ImportCompletedDate.displayControl.styles.style"
          [ngClass]="row.cells.ImportCompletedDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ImportCompletedDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ImportCompletedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportErrorMessage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ImportErrorMessage.displayControl.styles.style"
          [ngClass]="row.cells.ImportErrorMessage.displayControl.styles.classes"
          [matTooltip]="row.cells.ImportErrorMessage.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ImportErrorMessage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Owner.displayControl.styles.style"
          [ngClass]="row.cells.Owner.displayControl.styles.classes"
          [matTooltip]="row.cells.Owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project.displayControl.styles.style"
          [ngClass]="row.cells.Project.displayControl.styles.classes"
          [matTooltip]="row.cells.Project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Warehouse.displayControl.styles.style"
          [ngClass]="row.cells.Warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.Warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Location.displayControl.styles.style"
          [ngClass]="row.cells.Location.displayControl.styles.classes"
          [matTooltip]="row.cells.Location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LicensePlate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LicensePlate.displayControl.styles.style"
          [ngClass]="row.cells.LicensePlate.displayControl.styles.classes"
          [matTooltip]="row.cells.LicensePlate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LicensePlate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LicensePlateStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LicensePlateStatus.displayControl.styles.style"
          [ngClass]="row.cells.LicensePlateStatus.displayControl.styles.classes"
          [matTooltip]="row.cells.LicensePlateStatus.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LicensePlateStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Material.displayControl.styles.style"
          [ngClass]="row.cells.Material.displayControl.styles.classes"
          [matTooltip]="row.cells.Material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Lot.displayControl.styles.style"
          [ngClass]="row.cells.Lot.displayControl.styles.classes"
          [matTooltip]="row.cells.Lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LotStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LotStatus.displayControl.styles.style"
          [ngClass]="row.cells.LotStatus.displayControl.styles.classes"
          [matTooltip]="row.cells.LotStatus.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LotStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLot.displayControl.styles.style"
          [ngClass]="row.cells.VendorLot.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Vat">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Vat.displayControl.styles.style"
          [ngClass]="row.cells.Vat.displayControl.styles.classes"
          [matTooltip]="row.cells.Vat.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Vat.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Packaging.displayControl.styles.style"
          [ngClass]="row.cells.Packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.Packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PackagedAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PackagedAmount.displayControl.styles.style"
          [ngClass]="row.cells.PackagedAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.PackagedAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PackagedAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SerialNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SerialNumber.displayControl.styles.style"
          [ngClass]="row.cells.SerialNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.SerialNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SerialNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NetWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.NetWeight.displayControl.styles.style"
          [ngClass]="row.cells.NetWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.NetWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.NetWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="GrossWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.GrossWeight.displayControl.styles.style"
          [ngClass]="row.cells.GrossWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.GrossWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.GrossWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="WeightUOM">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.WeightUOM.displayControl.styles.style"
          [ngClass]="row.cells.WeightUOM.displayControl.styles.classes"
          [matTooltip]="row.cells.WeightUOM.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.WeightUOM.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReasonCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReasonCode.displayControl.styles.style"
          [ngClass]="row.cells.ReasonCode.displayControl.styles.classes"
          [matTooltip]="row.cells.ReasonCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReasonCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ManufactureDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ManufactureDate.displayControl.styles.style"
          [ngClass]="row.cells.ManufactureDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ManufactureDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ManufactureDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ExpirationDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ExpirationDate.displayControl.styles.style"
          [ngClass]="row.cells.ExpirationDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ExpirationDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ExpirationDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReceiveDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReceiveDate.displayControl.styles.style"
          [ngClass]="row.cells.ReceiveDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ReceiveDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReceiveDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainerTypeName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ContainerTypeName.displayControl.styles.style"
          [ngClass]="row.cells.ContainerTypeName.displayControl.styles.classes"
          [matTooltip]="row.cells.ContainerTypeName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ContainerTypeName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ContainerTypeWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ContainerTypeWeight.displayControl.styles.style"
          [ngClass]="row.cells.ContainerTypeWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.ContainerTypeWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ContainerTypeWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF01Entity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF01Entity.displayControl.styles.style"
          [ngClass]="row.cells.UDF01Entity.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF01Entity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF01Entity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF01Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF01Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF01Name.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF01Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF01Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF01Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF01Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF01Value.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF01Value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF01Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF02Entity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF02Entity.displayControl.styles.style"
          [ngClass]="row.cells.UDF02Entity.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF02Entity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF02Entity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF02Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF02Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF02Name.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF02Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF02Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF02Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF02Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF02Value.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF02Value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF02Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF03Entity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF03Entity.displayControl.styles.style"
          [ngClass]="row.cells.UDF03Entity.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF03Entity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF03Entity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF03Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF03Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF03Name.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF03Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF03Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF03Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF03Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF03Value.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF03Value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF03Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF04Entity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF04Entity.displayControl.styles.style"
          [ngClass]="row.cells.UDF04Entity.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF04Entity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF04Entity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF04Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF04Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF04Name.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF04Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF04Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF04Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF04Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF04Value.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF04Value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF04Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF05Entity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF05Entity.displayControl.styles.style"
          [ngClass]="row.cells.UDF05Entity.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF05Entity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF05Entity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF05Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF05Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF05Name.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF05Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF05Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF05Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UDF05Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF05Value.displayControl.styles.classes"
          [matTooltip]="row.cells.UDF05Value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UDF05Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
