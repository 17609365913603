import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Materials_materials_library_homeComponent } from './Materials.materials_library_home.component';
import { Materials_allocation_strategy_dd_singleComponent } from './Materials.allocation_strategy_dd_single.component';
import { Materials_decimal_precision_singleComponent } from './Materials.decimal_precision_single.component';
import { Materials_material_groups_dd_singleComponent } from './Materials.material_groups_dd_single.component';
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component';
import { Materials_material_statuses_dd_singleComponent } from './Materials.material_statuses_dd_single.component';
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component';
import { Materials_materials_by_project_excl_material_dd_singleComponent } from './Materials.materials_by_project_excl_material_dd_single.component';
import { Materials_materials_by_project_with_description_dd_singleComponent } from './Materials.materials_by_project_with_description_dd_single.component';
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component';
import { Materials_packages_excl_materials_dd_singleComponent } from './Materials.packages_excl_materials_dd_single.component';
import { Materials_storage_category_rules_dd_singleComponent } from './Materials.storage_category_rules_dd_single.component';
import { Materials_allocation_strategy_dd_multiComponent } from './Materials.allocation_strategy_dd_multi.component';
import { Materials_decimal_precision_multiComponent } from './Materials.decimal_precision_multi.component';
import { Materials_material_groups_dd_multiComponent } from './Materials.material_groups_dd_multi.component';
import { Materials_material_packagings_dd_multiComponent } from './Materials.material_packagings_dd_multi.component';
import { Materials_material_statuses_dd_multiComponent } from './Materials.material_statuses_dd_multi.component';
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component';
import { Materials_materials_by_project_excl_material_dd_multiComponent } from './Materials.materials_by_project_excl_material_dd_multi.component';
import { Materials_materials_by_project_with_description_dd_multiComponent } from './Materials.materials_by_project_with_description_dd_multi.component';
import { Materials_measurement_units_dd_multiComponent } from './Materials.measurement_units_dd_multi.component';
import { Materials_packages_excl_materials_dd_multiComponent } from './Materials.packages_excl_materials_dd_multi.component';
import { Materials_storage_category_rules_dd_multiComponent } from './Materials.storage_category_rules_dd_multi.component';
import { Materials_materials_by_count_widgetComponent } from './Materials.materials_by_count_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Materials_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Materials: Materials_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openmaterials_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Materials', 'materials_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Materials_materials_library_home',
        component: Materials_materials_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmaterials_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Materials', 'materials_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Materials_materials_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Materials_materials_library_home') {
      this.logger.log('Materials', 'materials_library_home');
      const title = 'Home';
      const component = Materials_materials_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_allocation_strategy_dd_single') {
      const title = 'Allocation Strategy dropdown';
      const component = Materials_allocation_strategy_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_decimal_precision_single') {
      const title = 'Decimal Precision';
      const component = Materials_decimal_precision_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_groups_dd_single') {
      const title = 'Material Groups dropdown';
      const component = Materials_material_groups_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_packagings_dd_single') {
      const title = 'Material Packagings dropdown';
      const component = Materials_material_packagings_dd_singleComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_statuses_dd_single') {
      const title = 'Material Statuses dropdown';
      const component = Materials_material_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_dd_single') {
      const title = 'Project Materials dropdown';
      const component = Materials_materials_by_project_dd_singleComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_excl_material_dd_single') {
      const title = 'Project Materials with exclusion dropdown';
      const component = Materials_materials_by_project_excl_material_dd_singleComponent;
      const inParams:{ project_id: number, excluded_material_id?: number } = { project_id: null, excluded_material_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excluded_material_id'))) {
        const paramValueString = params.get('excluded_material_id');
        inParams.excluded_material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_with_description_dd_single') {
      this.logger.log('Materials', 'materials_by_project_with_description_dd_single');
      const title = 'Project Materials with description dropdown';
      const component = Materials_materials_by_project_with_description_dd_singleComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_measurement_units_dd_single') {
      const title = 'Measurement Units dropdown';
      const component = Materials_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_packages_excl_materials_dd_single') {
      const title = 'Packagings that are not configured for a given material.';
      const component = Materials_packages_excl_materials_dd_singleComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_storage_category_rules_dd_single') {
      const title = 'storage_category_rules_dd';
      const component = Materials_storage_category_rules_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_allocation_strategy_dd_multi') {
      const title = 'Allocation Strategy dropdown';
      const component = Materials_allocation_strategy_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_decimal_precision_multi') {
      const title = 'Decimal Precision';
      const component = Materials_decimal_precision_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_groups_dd_multi') {
      const title = 'Material Groups dropdown';
      const component = Materials_material_groups_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_packagings_dd_multi') {
      const title = 'Material Packagings dropdown';
      const component = Materials_material_packagings_dd_multiComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_material_statuses_dd_multi') {
      const title = 'Material Statuses dropdown';
      const component = Materials_material_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_dd_multi') {
      const title = 'Project Materials dropdown';
      const component = Materials_materials_by_project_dd_multiComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_excl_material_dd_multi') {
      const title = 'Project Materials with exclusion dropdown';
      const component = Materials_materials_by_project_excl_material_dd_multiComponent;
      const inParams:{ project_id: number, excluded_material_id?: number } = { project_id: null, excluded_material_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excluded_material_id'))) {
        const paramValueString = params.get('excluded_material_id');
        inParams.excluded_material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_project_with_description_dd_multi') {
      this.logger.log('Materials', 'materials_by_project_with_description_dd_multi');
      const title = 'Project Materials with description dropdown';
      const component = Materials_materials_by_project_with_description_dd_multiComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_measurement_units_dd_multi') {
      const title = 'Measurement Units dropdown';
      const component = Materials_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_packages_excl_materials_dd_multi') {
      const title = 'Packagings that are not configured for a given material.';
      const component = Materials_packages_excl_materials_dd_multiComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_storage_category_rules_dd_multi') {
      const title = 'storage_category_rules_dd';
      const component = Materials_storage_category_rules_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Materials_materials_by_count_widget') {
      const title = 'Total materials';
      const component = Materials_materials_by_count_widgetComponent;
      const inParams:{ ownerId?: number, projectId?: number } = { ownerId: null, projectId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
