<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="edit" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="set_edit_mode($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="save" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator1" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="delete_rule" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator2" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="options" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.transactions.readOnly"
                            [ngStyle]="tool.control.buttons.transactions.styles.style"
                            [ngClass]="tool.control.buttons.transactions.styles.classes"
                            [matTooltip]="tool.control.buttons.transactions.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_transactions_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.transactions.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.transactions.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.transactions.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.logs.readOnly"
                            [ngStyle]="tool.control.buttons.logs.styles.style"
                            [ngClass]="tool.control.buttons.logs.styles.classes"
                            [matTooltip]="tool.control.buttons.logs.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_logs_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.logs.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.logs.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.logs.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-active" *ngIf="!fields.active.hidden" 
                            class="field-container full {{fields.active.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.active.styles.style"
                            [ngClass]="fields.active.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.active.label + (fields.active.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.active.label}}<span *ngIf="fields.active.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="active"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.active.control.styles.style"
                                      [ngClass]="fields.active.control.styles.classes"
                                      [matTooltip]="fields.active.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.active.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.active.invalid">
                          <ng-container *ngFor="let error of fields.active.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-create_new_invoice" *ngIf="!fields.create_new_invoice.hidden" 
                            class="field-container full {{fields.create_new_invoice.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.create_new_invoice.styles.style"
                            [ngClass]="fields.create_new_invoice.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.create_new_invoice.label + (fields.create_new_invoice.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.create_new_invoice.label}}<span *ngIf="fields.create_new_invoice.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="create_new_invoice"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.create_new_invoice.control.styles.style"
                                      [ngClass]="fields.create_new_invoice.control.styles.classes"
                                      [matTooltip]="fields.create_new_invoice.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.create_new_invoice.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.create_new_invoice.invalid">
                          <ng-container *ngFor="let error of fields.create_new_invoice.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container full {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.name.label + (fields.name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.name.label}}<span *ngIf="fields.name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"
                                [matTooltip]="fields.name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-terms" *ngIf="!fields.terms.hidden" 
                            class="field-container standard {{fields.terms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.terms.styles.style"
                            [ngClass]="fields.terms.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.terms.label + (fields.terms.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.terms.label}}<span *ngIf="fields.terms.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-invoice_terms_dd_single 
                            data-cy="selector"
                            [type]="fields.terms.control.type"
                            formControlName="terms"
                            (displayTextChange)="fields.terms.control.displayText=$event"
                            [placeholder]="fields.terms.control.placeholder"
                            [styles]="fields.terms.control.styles"
                            [tooltip]="fields.terms.control.tooltip"
                        >
                        </Invoices-invoice_terms_dd_single>
                        <ng-container *ngIf="fields.terms.invalid">
                          <ng-container *ngFor="let error of fields.terms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tax_schedule" *ngIf="!fields.tax_schedule.hidden" 
                            class="field-container standard {{fields.tax_schedule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tax_schedule.styles.style"
                            [ngClass]="fields.tax_schedule.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.tax_schedule.label + (fields.tax_schedule.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.tax_schedule.label}}<span *ngIf="fields.tax_schedule.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-tax_schedules_dd_single 
                            data-cy="selector"
                            [type]="fields.tax_schedule.control.type"
                            formControlName="tax_schedule"
                            (displayTextChange)="fields.tax_schedule.control.displayText=$event"
                            [placeholder]="fields.tax_schedule.control.placeholder"
                            [styles]="fields.tax_schedule.control.styles"
                            [tooltip]="fields.tax_schedule.control.tooltip"
                        >
                        </Invoices-tax_schedules_dd_single>
                        <ng-container *ngIf="fields.tax_schedule.invalid">
                          <ng-container *ngFor="let error of fields.tax_schedule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_aggregation_strategies" *ngIf="!fields.billing_aggregation_strategies.hidden" 
                            class="field-container full {{fields.billing_aggregation_strategies.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_aggregation_strategies.styles.style"
                            [ngClass]="fields.billing_aggregation_strategies.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.billing_aggregation_strategies.label + (fields.billing_aggregation_strategies.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.billing_aggregation_strategies.label}}<span *ngIf="fields.billing_aggregation_strategies.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-billing_aggregation_strategies_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.billing_aggregation_strategies.control.type"
                            formControlName="billing_aggregation_strategies"
                            (displayTextChange)="fields.billing_aggregation_strategies.control.displayText=$event"
                            [placeholder]="fields.billing_aggregation_strategies.control.placeholder"
                            [styles]="fields.billing_aggregation_strategies.control.styles"
                            [tooltip]="fields.billing_aggregation_strategies.control.tooltip"
                          [id]="$fields_billing_aggregation_strategies_selector_inParams_id"
                        >
                        </Invoices-billing_aggregation_strategies_dd_multi>
                        <ng-container *ngIf="fields.billing_aggregation_strategies.invalid">
                          <ng-container *ngFor="let error of fields.billing_aggregation_strategies.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-group_by" *ngIf="!fields.group_by.hidden" 
                            class="field-container double {{fields.group_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.group_by.styles.style"
                            [ngClass]="fields.group_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.group_by.label + (fields.group_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.group_by.label}}<span *ngIf="fields.group_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-auto_invoicing_rule_group_by_options_single 
                            data-cy="selector"
                            [type]="fields.group_by.control.type"
                            formControlName="group_by"
                            (displayTextChange)="fields.group_by.control.displayText=$event"
                            [placeholder]="fields.group_by.control.placeholder"
                            [styles]="fields.group_by.control.styles"
                            [tooltip]="fields.group_by.control.tooltip"
                          [exclude]="$fields_group_by_selector_inParams_exclude"
                        >
                        </Invoices-auto_invoicing_rule_group_by_options_single>
                        <ng-container *ngIf="fields.group_by.invalid">
                          <ng-container *ngFor="let error of fields.group_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>