import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-licenseplate_content_card',
  templateUrl: './FootPrintManager.licenseplate_content_card.component.html'
})
export class FootPrintManager_licenseplate_content_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['attention','border-left']);

  inParams: { licenseplateId: number, lotId: number, packagingId: number } = { licenseplateId: null, lotId: null, packagingId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  vars: { header?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    quantity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        quantity: new FieldModel(new TextBoxModel(this.formGroupContent.controls['quantity'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), '', false),
        vendor_lot: new FieldModel(new TextBoxModel(this.formGroupContent.controls['vendor_lot'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false),
        lot: new FieldModel(new TextBoxModel(this.formGroupContent.controls['lot'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Lot', false),
    },
    fieldsets: {
  newGroup1: new FieldsetModel('Fields', true, false, true),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.header;
    return expr;
  }






  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;

    (this.content.fields.quantity.control as TextBoxModel).reset(`[Quantity]`);
    
    


    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: FootPrintManager_licenseplate_content_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'licenseplate_content_card.on_init');
  try {
      let content = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed.get({
          licenseplateId: $card.inParams.licenseplateId,
          lotId: $card.inParams.lotId,
          packagingId: $card.inParams.packagingId
      })).result[0];
  
      if (!$utils.isDefined(content)) {
          throw new Error(`Failed to retrieve license plate content using IDs for license plate ${$card.inParams.licenseplateId}, lot ${$card.inParams.lotId}, and packaging ${$card.inParams.packagingId}`);
      }
  
      $card.vars.header = content.Lot.Material.LookupCode;
      $card.content.fields.quantity.control.value = `${content.PackagedAmount} ${content.Packaged.Name}`;
      $card.content.fields.vendor_lot.control.value = content.Lot.VendorLot.LookupCode;
      $card.content.fields.lot.control.value = content.Lot.LookupCode;
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Failed to initialize license plate content card', targetError.message);
  }
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
