import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_bol_reportService } from './Reports.report.index';
import { Reports_custom_dock_appointment_reportService } from './Reports.report.index';
import { Reports_custom_pick_slip_by_orderId_reportService } from './Reports.report.index';
import { Reports_custom_pick_slip_by_wave_id_reportService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_bol_report: Reports_custom_bol_reportService;
  public get custom_bol_report(): Reports_custom_bol_reportService {
    if(!this._custom_bol_report) {
      this._custom_bol_report = this.injector.get(Reports_custom_bol_reportService);
    }
    return this._custom_bol_report;
  }
  private _custom_dock_appointment_report: Reports_custom_dock_appointment_reportService;
  public get custom_dock_appointment_report(): Reports_custom_dock_appointment_reportService {
    if(!this._custom_dock_appointment_report) {
      this._custom_dock_appointment_report = this.injector.get(Reports_custom_dock_appointment_reportService);
    }
    return this._custom_dock_appointment_report;
  }
  private _custom_pick_slip_by_orderId_report: Reports_custom_pick_slip_by_orderId_reportService;
  public get custom_pick_slip_by_orderId_report(): Reports_custom_pick_slip_by_orderId_reportService {
    if(!this._custom_pick_slip_by_orderId_report) {
      this._custom_pick_slip_by_orderId_report = this.injector.get(Reports_custom_pick_slip_by_orderId_reportService);
    }
    return this._custom_pick_slip_by_orderId_report;
  }
  private _custom_pick_slip_by_wave_id_report: Reports_custom_pick_slip_by_wave_id_reportService;
  public get custom_pick_slip_by_wave_id_report(): Reports_custom_pick_slip_by_wave_id_reportService {
    if(!this._custom_pick_slip_by_wave_id_report) {
      this._custom_pick_slip_by_wave_id_report = this.injector.get(Reports_custom_pick_slip_by_wave_id_reportService);
    }
    return this._custom_pick_slip_by_wave_id_report;
  }
}

