import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_dock_appointment_flow_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { dock_appointment_id: number }): Promise<{ result?: { dockAppointment?: { Id?: number, AssignOn?: string, CancelledOn?: string, CheckedInOn?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledDeparture?: string, VehicleLicense?: string, ScheduledLocation_Name?: string, ScheduledCarrier_Name?: string, ScheduledOwner_Name?: string, ScheduledProject_Name?: string, Status_Name?: string, AssignedLocation_Name?: string, Warehouse_Name?: string, Warehouse_TimeZoneId?: string, Type_Name?: string, Order_Notes?: string, convertedScheduledArrival__ConvertedDate?: string, convertedScheduledDeparture_ConvertedDate?: string, convertedCheckInOn_ConvertedDate?: string, convertedAssignedOn_ConvertedDate?: string, convertedInProcessOn_ConvertedDate?: string, convertedCompletedOn_ConvertedDate?: string, convertedCancelledOn_ConvertedDate?: string } } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.dock_appointment_id)) {
      missingRequiredInParams.push('\'dock_appointment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_dock_appointment_flow_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
