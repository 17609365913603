<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="manage_dock_doors" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_manage_dock_door_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelMaterialImport-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </ExcelMaterialImport-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelMaterialImport-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </ExcelMaterialImport-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-carrier" *ngIf="!filters.carrier.hidden" 
                                  class="field-container standard {{filters.carrier.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.carrier.styles.style"
                                  [ngClass]="filters.carrier.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.carrier.label + (filters.carrier.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.carrier.label}}<span *ngIf="filters.carrier.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Carriers-carriers_dd_single 
                                  data-cy="selector"
                                  [type]="filters.carrier.control.type"
                                  formControlName="carrier"
                                  (displayTextChange)="filters.carrier.control.displayText=$event"
                                  [placeholder]="filters.carrier.control.placeholder"
                                  [styles]="filters.carrier.control.styles"
                                  [tooltip]="filters.carrier.control.tooltip"
                              >
                              </Carriers-carriers_dd_single>
                              <ng-container *ngIf="filters.carrier.invalid">
                                <ng-container *ngFor="let error of filters.carrier.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-type" *ngIf="!filters.type.hidden" 
                                  class="field-container double {{filters.type.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.type.styles.style"
                                  [ngClass]="filters.type.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.type.label + (filters.type.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.type.label}}<span *ngIf="filters.type.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <DockAppointments-dock_appointment_types_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.type.control.type"
                                  formControlName="type"
                                  (displayTextChange)="filters.type.control.displayText=$event"
                                  [placeholder]="filters.type.control.placeholder"
                                  [styles]="filters.type.control.styles"
                                  [tooltip]="filters.type.control.tooltip"
                              >
                              </DockAppointments-dock_appointment_types_dd_multi>
                              <ng-container *ngIf="filters.type.invalid">
                                <ng-container *ngFor="let error of filters.type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container double {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <DockAppointments-dock_appointment_statuses_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                                  [tooltip]="filters.status.control.tooltip"
                                [statusIds]="$fields_status_selector_inParams_statusIds"
                              >
                              </DockAppointments-dock_appointment_statuses_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_appt_checked_in_by_date_widget.hidden" class="widget-container">
            <DockAppointments-total_appt_checked_in_by_date_widget
            #$widgets_total_appt_checked_in_by_date_widget 
              [typeIds]="$widgets_total_appt_checked_in_by_date_widget_inParams_typeIds"
              [ownerId]="$widgets_total_appt_checked_in_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_total_appt_checked_in_by_date_widget_inParams_projectId"
              [carrierId]="$widgets_total_appt_checked_in_by_date_widget_inParams_carrierId"
              [statusIds]="$widgets_total_appt_checked_in_by_date_widget_inParams_statusIds"
              [warehouseIds]="$widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds"
              [timeZoneDates]="$widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates"
            ($refreshEvent)="refresh(false, false, '$widgets_total_appt_checked_in_by_date_widget')"
            >
            </DockAppointments-total_appt_checked_in_by_date_widget>
          </div>
          <div *ngIf="!widgets.total_appt_not_checked_in_by_date_widget.hidden" class="widget-container">
            <DockAppointments-total_appt_not_checked_in_by_date_widget
            #$widgets_total_appt_not_checked_in_by_date_widget 
              [typeIds]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds"
              [ownerId]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_projectId"
              [carrierId]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_carrierId"
              [statusIds]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds"
              [warehouseIds]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds"
              [timeZoneDates]="$widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates"
            ($refreshEvent)="refresh(false, false, '$widgets_total_appt_not_checked_in_by_date_widget')"
            >
            </DockAppointments-total_appt_not_checked_in_by_date_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.dock_appointments_calendar.hidden" class="tab" data-cy="tab-dock_appointments_calendar">
              <h2 [className]="tabs.dock_appointments_calendar.active? 'active': ''" (click)="tabs.dock_appointments_calendar.activate()">{{tabs.dock_appointments_calendar.title}}</h2>
            </div>
            <div *ngIf="!tabs.appointment_list.hidden" class="tab" data-cy="tab-appointment_list">
              <h2 [className]="tabs.appointment_list.active? 'active': ''" (click)="tabs.appointment_list.activate()">{{tabs.appointment_list.title}}</h2>
            </div>
            <div *ngIf="!tabs.dock_appointment_dashboard.hidden" class="tab" data-cy="tab-dock_appointment_dashboard">
              <h2 [className]="tabs.dock_appointment_dashboard.active? 'active': ''" (click)="tabs.dock_appointment_dashboard.activate()">{{tabs.dock_appointment_dashboard.title}}</h2>
            </div>
            <div *ngIf="!tabs.dock_status.hidden" class="tab" data-cy="tab-dock_status">
              <h2 [className]="tabs.dock_status.active? 'active': ''" (click)="tabs.dock_status.activate()">{{tabs.dock_status.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-dock_appointments_calendar *ngIf="tabs.dock_appointments_calendar.active"
              #$tabs_dock_appointments_calendar
              [warehouseIds]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds"
              [typeIds]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds"
              [ownerId]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_ownerId"
              [projectId]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_projectId"
              [carrierId]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_carrierId"
              [statusIds]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds"
              [dockDoorIds]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds"
              [viewDate]="$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_viewDate"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_appointments_calendar')">
              </FootPrintManager-dock_appointments_calendar>
              <FootPrintManager-dock_appointments_grid *ngIf="tabs.appointment_list.active"
              #$tabs_appointment_list
              [typeIds]="$tabs_appointment_list_dock_appointments_grid_inParams_typeIds"
              [ownerId]="$tabs_appointment_list_dock_appointments_grid_inParams_ownerId"
              [projectId]="$tabs_appointment_list_dock_appointments_grid_inParams_projectId"
              [carrierId]="$tabs_appointment_list_dock_appointments_grid_inParams_carrierId"
              [statusIds]="$tabs_appointment_list_dock_appointments_grid_inParams_statusIds"
              [warehouseIds]="$tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds"
              [fromDate]="$tabs_appointment_list_dock_appointments_grid_inParams_fromDate"
              [toDate]="$tabs_appointment_list_dock_appointments_grid_inParams_toDate"
              [dockDoorIds]="$tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds"
              ($refreshEvent)="refresh(false, false, '$tabs_appointment_list')">
              </FootPrintManager-dock_appointments_grid>
              <FootPrintManager-dock_appointments_dashboard_grid *ngIf="tabs.dock_appointment_dashboard.active"
              #$tabs_dock_appointment_dashboard
              [ownerId]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_ownerId"
              [projectId]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_projectId"
              [warehouseIds]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds"
              [typeIds]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds"
              [statusIds]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds"
              [fromDate]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_fromDate"
              [toDate]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_toDate"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_appointment_dashboard')">
              </FootPrintManager-dock_appointments_dashboard_grid>
              <app-custom_dock_status_grid *ngIf="tabs.dock_status.active"
              #$tabs_dock_status
              [warehouse_ids]="$tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_status')">
              </app-custom_dock_status_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>