<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="adjust_inventory" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_adjust_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="move_inventory" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_move_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="transfer_inventory" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_transfer_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-vendorlot" *ngIf="!filters.vendorlot.hidden" 
            class="field-container standard {{filters.vendorlot.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.vendorlot.styles.style"
            [ngClass]="filters.vendorlot.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.vendorlot.label + (filters.vendorlot.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.vendorlot.label}}<span *ngIf="filters.vendorlot.required" class="required-asterisk">*</span>
          </label>
        </div>
        <input data-cy="textBox" formControlName="vendorlot"
                matInput
                autocomplete="off"
                spellcheck="false"
                class="datex-textbox {{filters.vendorlot.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.vendorlot.control.placeholder}}"
                [ngStyle]="filters.vendorlot.control.styles.style"
                [ngClass]="filters.vendorlot.control.styles.classes"
                [matTooltip]="filters.vendorlot.control.tooltip"
                matTooltipClass="datex-control-tooltip"> 
        <ng-container *ngIf="filters.vendorlot.invalid">
          <ng-container *ngFor="let error of filters.vendorlot.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-licenseplate_status" *ngIf="!filters.licenseplate_status.hidden" 
            class="field-container standard {{filters.licenseplate_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.licenseplate_status.styles.style"
            [ngClass]="filters.licenseplate_status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.licenseplate_status.label + (filters.licenseplate_status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.licenseplate_status.label}}<span *ngIf="filters.licenseplate_status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Locations-licenseplate_statuses_dd_single 
            data-cy="selector"
            [type]="filters.licenseplate_status.control.type"
            formControlName="licenseplate_status"
            (displayTextChange)="filters.licenseplate_status.control.displayText=$event"
            [placeholder]="filters.licenseplate_status.control.placeholder"
            [styles]="filters.licenseplate_status.control.styles"
            [tooltip]="filters.licenseplate_status.control.tooltip"
        >
        </Locations-licenseplate_statuses_dd_single>
        <ng-container *ngIf="filters.licenseplate_status.invalid">
          <ng-container *ngFor="let error of filters.licenseplate_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-lot_status" *ngIf="!filters.lot_status.hidden" 
            class="field-container standard {{filters.lot_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.lot_status.styles.style"
            [ngClass]="filters.lot_status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.lot_status.label + (filters.lot_status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.lot_status.label}}<span *ngIf="filters.lot_status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Lots-lot_statuses_dd_single 
            data-cy="selector"
            [type]="filters.lot_status.control.type"
            formControlName="lot_status"
            (displayTextChange)="filters.lot_status.control.displayText=$event"
            [placeholder]="filters.lot_status.control.placeholder"
            [styles]="filters.lot_status.control.styles"
            [tooltip]="filters.lot_status.control.tooltip"
        >
        </Lots-lot_statuses_dd_single>
        <ng-container *ngIf="filters.lot_status.invalid">
          <ng-container *ngFor="let error of filters.lot_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="parent_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.parent_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.parent_licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.parent_licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.parent_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes"
          [matTooltip]="row.cells.location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.total_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.total_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.total_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.total_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.available_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.available_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="inbound_order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.inbound_order.displayControl.styles.style"
          [ngClass]="row.cells.inbound_order.displayControl.styles.classes"
          [matTooltip]="row.cells.inbound_order.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.inbound_order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes"
          [matTooltip]="row.cells.expiration_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes"
          [matTooltip]="row.cells.manufacture_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="received_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.received_date.displayControl.styles.style"
          [ngClass]="row.cells.received_date.displayControl.styles.classes"
          [matTooltip]="row.cells.received_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.received_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes"
          [matTooltip]="row.cells.material_description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
