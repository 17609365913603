<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="new_matrix" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_new_matrix_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters_group"
                     *ngIf="!filtersets.filters_group.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters_group.collapsible }">
                    <div *ngIf="!filtersets.filters_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters_group.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters_group.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse_filter" *ngIf="!filters.warehouse_filter.hidden" 
                                  class="field-container standard {{filters.warehouse_filter.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse_filter.styles.style"
                                  [ngClass]="filters.warehouse_filter.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse_filter.label + (filters.warehouse_filter.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse_filter.label}}<span *ngIf="filters.warehouse_filter.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Invoices-freight_billing_warehouse_selector_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse_filter.control.type"
                                  formControlName="warehouse_filter"
                                  (displayTextChange)="filters.warehouse_filter.control.displayText=$event"
                                  [placeholder]="filters.warehouse_filter.control.placeholder"
                                  [styles]="filters.warehouse_filter.control.styles"
                                  [tooltip]="filters.warehouse_filter.control.tooltip"
                              >
                              </Invoices-freight_billing_warehouse_selector_single>
                              <ng-container *ngIf="filters.warehouse_filter.invalid">
                                <ng-container *ngFor="let error of filters.warehouse_filter.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-owner_filter" *ngIf="!filters.owner_filter.hidden" 
                                  class="field-container standard {{filters.owner_filter.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner_filter.styles.style"
                                  [ngClass]="filters.owner_filter.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner_filter.label + (filters.owner_filter.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner_filter.label}}<span *ngIf="filters.owner_filter.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Invoices-freight_billing_owner_selector_single 
                                  data-cy="selector"
                                  [type]="filters.owner_filter.control.type"
                                  formControlName="owner_filter"
                                  (displayTextChange)="filters.owner_filter.control.displayText=$event"
                                  [placeholder]="filters.owner_filter.control.placeholder"
                                  [styles]="filters.owner_filter.control.styles"
                                  [tooltip]="filters.owner_filter.control.tooltip"
                              >
                              </Invoices-freight_billing_owner_selector_single>
                              <ng-container *ngIf="filters.owner_filter.invalid">
                                <ng-container *ngFor="let error of filters.owner_filter.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project_filter" *ngIf="!filters.project_filter.hidden" 
                                  class="field-container standard {{filters.project_filter.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project_filter.styles.style"
                                  [ngClass]="filters.project_filter.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project_filter.label + (filters.project_filter.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project_filter.label}}<span *ngIf="filters.project_filter.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Invoices-freight_billing_projects_selector_single 
                                  data-cy="selector"
                                  [type]="filters.project_filter.control.type"
                                  formControlName="project_filter"
                                  (displayTextChange)="filters.project_filter.control.displayText=$event"
                                  [placeholder]="filters.project_filter.control.placeholder"
                                  [styles]="filters.project_filter.control.styles"
                                  [tooltip]="filters.project_filter.control.tooltip"
                                [owner_id]="$fields_project_filter_selector_inParams_owner_id"
                              >
                              </Invoices-freight_billing_projects_selector_single>
                              <ng-container *ngIf="filters.project_filter.invalid">
                                <ng-container *ngFor="let error of filters.project_filter.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-account_filter" *ngIf="!filters.account_filter.hidden" 
                                  class="field-container standard {{filters.account_filter.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.account_filter.styles.style"
                                  [ngClass]="filters.account_filter.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.account_filter.label + (filters.account_filter.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.account_filter.label}}<span *ngIf="filters.account_filter.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Invoices-freight_billing_account_selector_single 
                                  data-cy="selector"
                                  [type]="filters.account_filter.control.type"
                                  formControlName="account_filter"
                                  (displayTextChange)="filters.account_filter.control.displayText=$event"
                                  [placeholder]="filters.account_filter.control.placeholder"
                                  [styles]="filters.account_filter.control.styles"
                                  [tooltip]="filters.account_filter.control.tooltip"
                                [owner_id]="$fields_account_filter_selector_inParams_owner_id"
                              >
                              </Invoices-freight_billing_account_selector_single>
                              <ng-container *ngIf="filters.account_filter.invalid">
                                <ng-container *ngFor="let error of filters.account_filter.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.freight_matrix.hidden" class="tab" data-cy="tab-freight_matrix">
              <h2 [className]="tabs.freight_matrix.active? 'active': ''" (click)="tabs.freight_matrix.activate()">{{tabs.freight_matrix.title}}</h2>
            </div>
          </div>
        
              <Invoices-freight_billing_header_grid *ngIf="tabs.freight_matrix.active"
              #$tabs_freight_matrix
              [warehouse_id]="$tabs_freight_matrix_freight_billing_header_grid_inParams_warehouse_id"
              [owner_id]="$tabs_freight_matrix_freight_billing_header_grid_inParams_owner_id"
              [account_id]="$tabs_freight_matrix_freight_billing_header_grid_inParams_account_id"
              [project_id]="$tabs_freight_matrix_freight_billing_header_grid_inParams_project_id"
              ($refreshEvent)="refresh(false, false, '$tabs_freight_matrix')">
              </Invoices-freight_billing_header_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>