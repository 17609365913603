import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Orderful.frontend.types'

@Injectable({ providedIn: 'root' })
export class Orderful_orderful_846_mapService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { datex_inventory_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, mapping_summary?: boolean }): Promise<{ payload?: any[], messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/Orderful/functions/orderful_846_map`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

