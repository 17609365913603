import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { AsnOrders_OperationService } from './AsnOrders.operation.service';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { AsnOrders_FlowService } from './AsnOrders.flow.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { AsnOrders_LocalizationService } from './AsnOrders.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './AsnOrders.frontend.types'
import { $frontendTypes as $types} from './AsnOrders.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'AsnOrders-set_asn_receive_task_test',
  templateUrl: './AsnOrders.set_asn_receive_task_test.component.html'
})
export class AsnOrders_set_asn_receive_task_testComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { existingTaskId?: number, licenseplateId?: number, lotId?: number, materialId?: number, orderId?: number, packagedAmount?: number, packagingId?: number, vendorlotId?: number } = { existingTaskId: null, licenseplateId: null, lotId: null, materialId: null, orderId: null, packagedAmount: null, packagingId: null, vendorlotId: null };
  //#region Inputs
  @Input('existingTaskId') set $inParams_existingTaskId(v: number) {
    this.inParams.existingTaskId = v;
  }
  get $inParams_existingTaskId(): number {
    return this.inParams.existingTaskId;
  }
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('packagedAmount') set $inParams_packagedAmount(v: number) {
    this.inParams.packagedAmount = v;
  }
  get $inParams_packagedAmount(): number {
    return this.inParams.packagedAmount;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  @Input('vendorlotId') set $inParams_vendorlotId(v: number) {
    this.inParams.vendorlotId = v;
  }
  get $inParams_vendorlotId(): number {
    return this.inParams.vendorlotId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { taskId?: number, orderLineNumber?: number } = { taskId: null, orderLineNumber: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ taskId?: number, orderLineNumber?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    taskId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    orderLineNumber: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      test: new ToolModel(new ButtonModel('test', new ButtonStyles(null, null), false, 'test', '', null)
    )
  };

  fields = {
    taskId: new FieldModel(new NumberBoxModel(this.formGroup.controls['taskId'] as DatexFormControl, null, true, '', '', null)
, new ControlContainerStyles(null, null), 'Task id', false),
    orderLineNumber: new FieldModel(new NumberBoxModel(this.formGroup.controls['orderLineNumber'] as DatexFormControl, null, true, '', '', null)
, new ControlContainerStyles(null, null), 'Order line number', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Label of newGroup1', false, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    taskId: this.fields.taskId.control.valueChanges
    ,
    orderLineNumber: this.fields.orderLineNumber.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: AsnOrders_ShellService,
private datasources: AsnOrders_DatasourceService,
private flows: AsnOrders_FlowService,
private reports: AsnOrders_ReportService,
private localization: AsnOrders_LocalizationService,
private operations: AsnOrders_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'set_asn_receive_task_test';
  
    const $form = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .taskId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .orderLineNumber
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_test_clicked(event = null) {
    return this.on_test_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_test_clickedInternal(
    $form: AsnOrders_set_asn_receive_task_testComponent,
  
    $shell: AsnOrders_ShellService,
    $datasources: AsnOrders_DatasourceService,
    $flows: AsnOrders_FlowService,
    $reports: AsnOrders_ReportService,
    $settings: SettingsValuesService,
    $operations: AsnOrders_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: AsnOrders_LocalizationService,
    $event: any
  ) {
    this.logger.log('AsnOrders', 'set_asn_receive_task_test.on_test_clicked');
  
  
             let test =  (await $flows.AsnOrders.set_asn_receiving_task_flow({
                  licenseplateId: $form.inParams.licenseplateId,
                  lotId: $form.inParams.lotId,
                  packagingId: $form.inParams.packagingId,
                  orderId: $form.inParams.orderId,
                  materialId: $form.inParams.materialId,
                  vendorlotId: $form.inParams.vendorlotId,
                  packagedAmount: $form.inParams.packagedAmount,
                  existingTaskId: $form.inParams.existingTaskId
              }));
  
          //Check if License Plate is empty, then archive the LP
          /*if (!$utils.isDefined((await $datasources.AsnOrders.ds_get_licenseplatecontents_by_licenseplateId.get({ licenseplateId: $form.inParams.licenseplateId }))?.result[0]?.LicensePlateId)) {
  
              await $flows.Utilities.crud_update_flow({ entitySet: "LicensePlates", id: $form.inParams.licenseplateId, entity: { "Archived": 1 } })
  
          };  */     
    
  $form.fields.taskId.control.value = test.taskId
  $form.fields.orderLineNumber.control.value = test.orderlineNumber
  
      $form.close;
      
  }
  //#endregion private flows
}
