<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-options"
                     *ngIf="!filtersets.options.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.options.collapsible }">
                    <div *ngIf="!filtersets.options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.options.toggle()">
                      <span class="fieldsetsTitle-text">Options</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.options.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.options.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-test_ids" *ngIf="!filters.test_ids.hidden" 
                                  class="field-container full {{filters.test_ids.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.test_ids.styles.style"
                                  [ngClass]="filters.test_ids.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.test_ids.label + (filters.test_ids.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.test_ids.label}}<span *ngIf="filters.test_ids.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <FootPrintManager-support_tests_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.test_ids.control.type"
                                  formControlName="test_ids"
                                  (displayTextChange)="filters.test_ids.control.displayText=$event"
                                  [placeholder]="filters.test_ids.control.placeholder"
                                  [styles]="filters.test_ids.control.styles"
                                  [tooltip]="filters.test_ids.control.tooltip"
                              >
                              </FootPrintManager-support_tests_dd_multi>
                              <ng-container *ngIf="filters.test_ids.invalid">
                                <ng-container *ngFor="let error of filters.test_ids.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-test_runs" *ngIf="!filters.test_runs.hidden" 
                                  class="field-container standard {{filters.test_runs.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.test_runs.styles.style"
                                  [ngClass]="filters.test_runs.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.test_runs.label + (filters.test_runs.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.test_runs.label}}<span *ngIf="filters.test_runs.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="numberBox" formControlName="test_runs"
                                      matInput
                                      numberBox
                                      [format]="filters.test_runs.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.test_runs.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.test_runs.control.placeholder}}"
                                      [ngStyle]="filters.test_runs.control.styles.style"
                                      [ngClass]="filters.test_runs.control.styles.classes"
                                      [matTooltip]="filters.test_runs.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">
                              <ng-container *ngIf="filters.test_runs.invalid">
                                <ng-container *ngFor="let error of filters.test_runs.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-rows_override" *ngIf="!filters.rows_override.hidden" 
                                  class="field-container standard {{filters.rows_override.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.rows_override.styles.style"
                                  [ngClass]="filters.rows_override.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.rows_override.label + (filters.rows_override.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.rows_override.label}}<span *ngIf="filters.rows_override.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="numberBox" formControlName="rows_override"
                                      matInput
                                      numberBox
                                      [format]="filters.rows_override.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.rows_override.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.rows_override.control.placeholder}}"
                                      [ngStyle]="filters.rows_override.control.styles.style"
                                      [ngClass]="filters.rows_override.control.styles.classes"
                                      [matTooltip]="filters.rows_override.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">
                              <ng-container *ngIf="filters.rows_override.invalid">
                                <ng-container *ngFor="let error of filters.rows_override.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.tests.hidden" class="tab" data-cy="tab-tests">
              <h2 [className]="tabs.tests.active? 'active': ''" (click)="tabs.tests.activate()">{{tabs.tests.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-support_tests_grid *ngIf="tabs.tests.active"
              #$tabs_tests
              [test_ids]="$tabs_tests_support_tests_grid_inParams_test_ids"
              [test_runs]="$tabs_tests_support_tests_grid_inParams_test_runs"
              [rows_override]="$tabs_tests_support_tests_grid_inParams_rows_override"
              ($refreshEvent)="refresh(false, false, '$tabs_tests')">
              </FootPrintManager-support_tests_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>