<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title " >{{headerTitle}}</mat-card-title>
        </div>

      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Count</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-expected_amount" *ngIf="!content.fields.expected_amount.hidden" 
                            class="field-container standard {{content.fields.expected_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.expected_amount.styles.style"
                            [ngClass]="content.fields.expected_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.expected_amount.label + (content.fields.expected_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.expected_amount.label}}<span *ngIf="content.fields.expected_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_amount"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.expected_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.expected_amount.control.placeholder}}"
                                [ngStyle]="content.fields.expected_amount.control.styles.style"
                                [ngClass]="content.fields.expected_amount.control.styles.classes"
                                [matTooltip]="content.fields.expected_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.expected_amount.invalid">
                          <ng-container *ngFor="let error of content.fields.expected_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_amount" *ngIf="!content.fields.actual_amount.hidden" 
                            class="field-container standard {{content.fields.actual_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.actual_amount.styles.style"
                            [ngClass]="content.fields.actual_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.actual_amount.label + (content.fields.actual_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.actual_amount.label}}<span *ngIf="content.fields.actual_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="actual_amount"
                                matInput
                                numberBox
                                [format]="content.fields.actual_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{content.fields.actual_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.actual_amount.control.placeholder}}"
                                [ngStyle]="content.fields.actual_amount.control.styles.style"
                                [ngClass]="content.fields.actual_amount.control.styles.classes"
                                [matTooltip]="content.fields.actual_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="content.fields.actual_amount.invalid">
                          <ng-container *ngFor="let error of content.fields.actual_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed_on" *ngIf="!content.fields.completed_on.hidden" 
                            class="field-container standard {{content.fields.completed_on.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.completed_on.styles.style"
                            [ngClass]="content.fields.completed_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.completed_on.label + (content.fields.completed_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.completed_on.label}}<span *ngIf="content.fields.completed_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="completed_on"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.completed_on.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.completed_on.control.placeholder}}"
                                [ngStyle]="content.fields.completed_on.control.styles.style"
                                [ngClass]="content.fields.completed_on.control.styles.classes"
                                [matTooltip]="content.fields.completed_on.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.completed_on.invalid">
                          <ng-container *ngFor="let error of content.fields.completed_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed_by" *ngIf="!content.fields.completed_by.hidden" 
                            class="field-container standard {{content.fields.completed_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.completed_by.styles.style"
                            [ngClass]="content.fields.completed_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.completed_by.label + (content.fields.completed_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.completed_by.label}}<span *ngIf="content.fields.completed_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="completed_by"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{content.fields.completed_by.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{content.fields.completed_by.control.placeholder}}"
                                [ngStyle]="content.fields.completed_by.control.styles.style"
                                [ngClass]="content.fields.completed_by.control.styles.classes"
                                [matTooltip]="content.fields.completed_by.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="content.fields.completed_by.invalid">
                          <ng-container *ngFor="let error of content.fields.completed_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-discrepancy_action" *ngIf="!content.fields.discrepancy_action.hidden" 
                            class="field-container full {{content.fields.discrepancy_action.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.discrepancy_action.styles.style"
                            [ngClass]="content.fields.discrepancy_action.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.discrepancy_action.label + (content.fields.discrepancy_action.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.discrepancy_action.label}}<span *ngIf="content.fields.discrepancy_action.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <InventoryCounts-count_task_discrepancy_action_dd_single 
                            data-cy="selector"
                            [type]="content.fields.discrepancy_action.control.type"
                            formControlName="discrepancy_action"
                            (displayTextChange)="content.fields.discrepancy_action.control.displayText=$event"
                            [placeholder]="content.fields.discrepancy_action.control.placeholder"
                            [styles]="content.fields.discrepancy_action.control.styles"
                            [tooltip]="content.fields.discrepancy_action.control.tooltip"
                        >
                        </InventoryCounts-count_task_discrepancy_action_dd_single>
                        <ng-container *ngIf="content.fields.discrepancy_action.invalid">
                          <ng-container *ngFor="let error of content.fields.discrepancy_action.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>